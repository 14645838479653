import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import EventItem from "../../components/EventItem";
import Page from "../../components/Page";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import SearchItem from "../../components/SearchItem";
import useDebounce from "../../components/UseDebounce";
import SearchInput from "../../components/SearchInput";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";

import { THEME_MODE } from "../../constants/Theme";
import {CONTAINER_HEIGHT, FOOTER_XPADDING} from "../../constants";

const PAGE_SIZE = 16;

const Events = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const navigate = useNavigate();

  const [cardLoading, setCardLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [eventList, setEventList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchEvent, setSearchEvent] = useState("");
  const [searchEventList, setSearchEventList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const debouncedEventName = useDebounce(searchEvent, 500);

  const AntTabs = styled(Tabs)({
    borderBottom: "1px solid " + theme_mode.text,
    "& .MuiTabs-indicator": {
      borderBottom: "3px solid " + theme_mode.borderBottom,
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
    textTransform: "none",
    minWidth: 150,
    fontFamily: "InterBold",
    color: theme_mode.text3,
    "&.Mui-selected": {
      color: theme_mode.text,
    },
  }));

  useEffect(() => {
    let start_date = "";
    let end_date = "";
    if (tabValue == 0) {
      let date1 = moment().valueOf();
      start_date = "lte-" + date1;
      end_date = "gte-" + date1;
      getEventList(1, start_date, end_date);
    } else if (tabValue == 1) {
      let date1 = moment().valueOf();
      start_date = "gt-" + date1;
      getEventList(1, start_date, "");
    } else if (tabValue == 2) {
      let date1 = moment().valueOf();
      end_date = "lt-" + date1;
      getEventList(1, "", end_date);
    }
  }, [tabValue]);

  useEffect(() => {
    if (debouncedEventName) {
      getEventList("all", startDate, endDate);
    } else {
      setSearchEventList([]);
    }
  }, [debouncedEventName]);

  const getEventList = (pageNo, start_date, end_date) => {
    let filter = `?pageSize=${PAGE_SIZE}`;
    if (pageNo != "all") {
      filter = `?pageSize=${PAGE_SIZE}&pageNum=${pageNo}`;
      setCardLoading(true);
    } else {
      filter += `&search=${searchEvent}`;
    }

    if (start_date !== "") {
      filter += `&filter_eventStartDate=${start_date}`;
    }
    if (end_date !== "") {
      filter += `&filter_eventEndDate=${end_date}`;
    }

    Api.getMyEventList(filter, props.restaurantId).then((response) => {
      if (pageNo != "all") {
        if (response.success) {
          let data = response.data.rows;
          setEventList(data);
          setPageNum(pageNo);
          setStartDate(start_date);
          setEndDate(end_date);
          setTotalPages(response.data.pages);
        } else {
          setCardLoading(false);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setCardLoading(false);
      } else {
        if (response.success) {
          let data = response.data.rows;
          setSearchEventList(data);
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      }
    });
  };

  const onTabChange = (event, newValue) => {
    setSearchEvent("");
    setTabValue(newValue);
  };

  return (
    <Container
      page={"settings"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0}
    >
      <Grid
        p={1.5}
        style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
      >
        <Grid container justifyContent="space-between">
          <Grid item xs={2} md={8}>
            <Box sx={styles.titleMainBox}>
              <Box
                mr={1.5}
                onClick={() => navigate("/settings")}
                sx={{ cursor: "pointer" }}
              >
                <BackBtnIcon width={30} height={30} />
              </Box>
              <Typography mb={1} variant={"h6"} sx={styles.menuText}>
                My Events
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8} md={4} className="search-event-input">
            <SearchInput
              value={searchEvent}
              setValue={setSearchEvent}
              placeholder={"Search events here"}
              themeMode={props.themeMode}
              inputBg={theme_mode.search}
            />
            {searchEventList.length ? (
              <Grid item xs={12} sx={styles.searchListView}>
                {searchEventList.map((x, i) => {
                  return (
                    <SearchItem
                      type="event"
                      key={i}
                      data={x}
                      themeMode={props.themeMode}
                      lastIndex={searchEventList.length - 1 == i ? 1 : 0}
                    />
                  );
                })}
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <Box sx={{ width: "100%" }} mt={1}>
          <Box>
            <AntTabs value={tabValue} onChange={onTabChange}>
              <AntTab label="Current Events" />
              <AntTab label="Upcoming" />
              <AntTab label="Completed" />
            </AntTabs>
            <Box sx={{ p: 3 }} />
          </Box>
        </Box>

        {cardLoading ? (
          <Loader height={{ height: CONTAINER_HEIGHT - 230 }} />
        ) : (
          <Grid style={{ minHeight: CONTAINER_HEIGHT - 230 }}>
            <Grid container direction="row">
              {eventList.map((item, index) => {
                return (
                  <EventItem
                    key={index}
                    themeMode={props.themeMode}
                    firstItem={index % 4 === 0}
                    lastItem={(index + 1) % 4 === 0}
                    data={item}
                  />
                );
              })}
            </Grid>
          </Grid>
        )}
        {eventList.length ? (
          <Page
            themeMode={props.themeMode}
            totalPages={totalPages}
            pageNum={pageNum}
            onSelectPage={(value) => getEventList(value, startDate, endDate)}
          />
        ) : null}
      </Grid>
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(Events);

const styles1 = (Theme) => ({
  titleMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  menuText: {
    color: Theme.text,
    fontFamily: "InterBold",
  },
  searchListView: {
    position: "absolute",
    backgroundColor: Theme.card,
    width: "100%",
    maxHeight: 200,
    borderRadius: 2,
    overflow: "auto",
    my: 0.5,
  },
});
