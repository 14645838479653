import React from "react";
import { createTheme, styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import moment from "moment";

import styles from "./CustomerBookingTable.module.css";

import { THEME_MODE } from "../constants/Theme";

let table_header_list = [
  "Name",
  "Phone Number",
  "Booking Date",
  "Booking Seats",
];

const CustomerBookingTable = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#828282",
      fontFamily: "InterSemiBold",
      fontSize: 14,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      color: theme_mode.tableRowText,
      fontFamily: "InterSemiBold",
      fontSize: 13,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: theme_mode.card2,
    "&:hover": {
      backgroundColor: theme_mode.theme,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  let data = props.itemList;

  return (
    <TableContainer component={Paper} elevation={0}>
      {data.length ? (
        <Table aria-label="customized table">
          <TableHead
            sx={{
              backgroundColor: theme_mode.card2,
              borderBottom: "1px solid #CECECE",
            }}
          >
            <TableRow>
              {table_header_list.map((x, i) => {
                return (
                  <StyledTableCell
                    key={i}
                    sx={{
                      borderBottom: "none",
                      textAlign:
                        table_header_list.length == i + 1 ? "center" : "left",
                    }}
                  >
                    <Box>{x}</Box>
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <StyledTableRow
                key={index}
                onClick={() => props.onClick(item)}
              >
                <StyledTableCell>
                  <Box className={styles.nameText}>{item.name}</Box>
                  <Box className={styles.emailText}>{item.email}</Box>
                </StyledTableCell>
                <StyledTableCell>{item.phoneNumber}</StyledTableCell>
                <StyledTableCell>{moment(item.bookingDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}</StyledTableCell>
                <StyledTableCell>
                  <Box className={styles.seatsMainView}>
                    <Box
                      className={styles.seatsView}
                      style={{
                        color: theme_mode.lightText,
                        backgroundColor: theme_mode.bg5,
                      }}
                    >
                      {item?.noOfGuests}
                    </Box>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </TableContainer>
  );
};

export default CustomerBookingTable;
