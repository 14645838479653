import * as React from "react";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import { connect } from "react-redux";

import { THEME_MODE } from "../constants/Theme";
import { CONTAINER_HEIGHT } from "../constants";

const Loader = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const theme = createTheme({
    palette: {
      loading_color: {
        main: theme_mode.loader,
      },
    },
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={
        props?.height ? props?.height : { minHeight: CONTAINER_HEIGHT - 100 }
      }
    >
      <ThemeProvider theme={theme}>
        <CircularProgress color="loading_color" />
      </ThemeProvider>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(Loader);
