import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import "./../assets/css/custom.css";

import { ReactComponent as CloseIcon } from "../assets/images/close_white.svg";
import { ReactComponent as RadioOnIcon } from "../assets/images/radio_on.svg";
import { ReactComponent as RadioOffIcon } from "../assets/images/radio_off.svg";

import { THEME_COLOR } from "../constants";
import { THEME_MODE } from "../constants/Theme";

const MenuStyleModal = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const onSelectMenuStyle = (val) => {
    props.onSubmit(val);
  };

  return (
    <Modal
      style={styles.modelView}
      open={props.modelVisible}
      onClose={props.onClose}
    >
      <Grid item xs={12} sx={styles.mainView}>
        <Grid item xs={12} sx={styles.headerView}>
          <Box sx={styles.titleView}>
            <Typography sx={styles.titleText}>Choose Menu Style</Typography>
          </Box>
          <Box
            className="cursor-pointer"
            sx={{
              ellipse: { fill: theme_mode.iconBg },
              line: { stroke: theme_mode.whiteBlack },
            }}
            onClick={() => props.onToggle(false)}
          >
            <CloseIcon width={50} height={50} />
          </Box>
        </Grid>
        <Grid
          container
          direction="row"
          item
          xs={12}
          mt={3}
          sx={styles.mainGrid}
        >
          {props.menuDisplayList.map((img, i) => {
            return (
              <Grid
                key={i}
                container
                direction="column"
                item
                xs={12 / props.menuDisplayList.length}
                sx={{ alignItems: "center" }}
              >
                <Box
                  mx={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => onSelectMenuStyle(i)}
                >
                  <Box
                    sx={{
                      height: window.innerHeight - 200,
                    }}
                  >
                    {/*{img.imageURL ?*/}
                    {/*  <img src={img.imageURL} style={{width: '100%', height: '100%', objectFit: 'contain'}}/>*/}
                    <img
                      src={require("../assets/images/menu_style1.png")}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                    {/*: null}*/}
                  </Box>
                  <Box pt={2} mb={2}>
                    {props.menuStyle == i ? (
                      <Box
                        sx={{
                          g: { stroke: theme_mode.iconBg },
                          "#circle2": { fill: theme_mode.iconBg },
                        }}
                      >
                        <RadioOnIcon width={20} height={20} />
                      </Box>
                    ) : (
                      <Box sx={{ path: { stroke: theme_mode.iconBg } }}>
                        <RadioOffIcon width={20} height={20} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default MenuStyleModal;

const styles1 = (Theme) => ({
  modelView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  mainView: {
    backgroundColor: Theme.modal,
    width: { md: "60%", xs: "98%" },
    maxWidth: { md: "60%", xs: "98%" },
    borderRadius: 3,
    "&:focus": {
      outline: "none",
    },
    overflow: "hidden",
  },
  headerView: {
    backgroundColor: Theme.theme,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 80,
    px: 2,
  },
  titleView: {
    flex: 1,
  },
  titleText: {
    color: Theme.text,
    fontFamily: "InterBold",
    textAlign: "center",
    fontSize: 20,
  },
  mainGrid: {
    px: 2,
  },
});
