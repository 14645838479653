import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Collapse from "@mui/material/Collapse";

import styles from "./AddOptions.module.css";

import { connect } from "react-redux";

import SettingTextField from "./SettingTextField";
import AddOptionItemInput from "./AddOptionItemInput";

import { THEME_MODE } from "../constants/Theme";

import { ReactComponent as Delete1Icon } from "../assets/images/delete3.svg";
import { ReactComponent as DownIcon } from "../assets/images/down_white_arrow.svg";
import { ReactComponent as UpIcon } from "../assets/images/up_white_arrow.svg";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#455A64",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const AddOptions = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [optionList, setOptionList] = useState([]);
  const [optionName, setOptionName] = useState("");
  const [optionError, setOptionError] = useState("");
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (props.options.length) {
      setExpanded(true);
      setOptionList([
        ...props.options,
        { _id: "add", required: false, items: [] },
      ]);
    } else {
      setOptionList([{ _id: "add", required: false, items: [] }]);
    }
  }, [props.options]);

  useEffect(() => {
    props.setOptionList(optionList);
  }, [optionList]);

  const onActionBtnClick = (item, action) => {
    let list = [...optionList];
    let index = list.findIndex((x) => x._id == item._id);
    if (index >= 0) {
      if (action == "save_option") {
        setOptionError("");
        if (optionName == "") {
          setOptionError("Option name required");
          return;
        }
        if (item?.items?.length <= 0) {
          setOptionError("Items required");
          return;
        }
        list[index] = {
          ...item,
          _id: Date.now() + Math.random(),
          isNew: true,
          header: optionName,
        };
        list = [...list, { _id: "add", required: false, items: [] }];
        setOptionName("");
      } else if (action == "required") {
        list[index] = {
          ...item,
          required: !item.required,
        };
      }
      setOptionList(list);
    }
  };

  const onAddOptionItem = (item, option) => {
    let list = [...optionList];
    let index = list.findIndex((x) => x._id == option._id);
    if (index >= 0) {
      list[index]["items"] = [...list[index]?.["items"], item];
    }
    setOptionList(list);
  };

  const onRemoveMenuItem = (option, item) => {
    let list = [...optionList];
    let index = list.findIndex((x) => x._id == option._id);
    if (index >= 0) {
      list[index].items = list[index].items.filter((x) => x != item);
    }
    setOptionList(list);
  };

  return (
    <Box className={styles.mainView}>
      <Box
        className={styles.titleView}
        style={{ backgroundColor: theme_mode.headerBg }}
        px={2}
        onClick={() => setExpanded(!expanded)}
      >
        <Typography
          className={styles.titleText}
          style={{ color: theme_mode.lightText }}
        >
          Options
        </Typography>
        {expanded ? (
          <DownIcon width={25} height={25} />
        ) : (
          <UpIcon width={25} height={25} />
        )}
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box mt={1.5} className={styles.optionList}>
          {optionList.map((x, i) => {
            return (
              <Box
                mb={1}
                key={i}
                className={styles.optionView}
                style={{ backgroundColor: theme_mode.bg8 }}
              >
                <Box px={1.4} py={1.3} className={styles.optionTitleMainView}>
                  <Box className={styles.optionTitleView} pr={2}>
                    {x._id == "add" ? (
                      <SettingTextField
                        page={"addOption"}
                        value={optionName}
                        setValue={setOptionName}
                        placeholder={"Enter option name"}
                        placeholderColor={theme_mode.placeholder}
                        border={"1px solid " + theme_mode.border3}
                        required={false}
                        variant={"filled"}
                        error={optionError}
                      />
                    ) : (
                      <Typography
                        className={styles.optionTitleText}
                        style={{ color: theme_mode.text }}
                      >
                        {x.header}
                      </Typography>
                    )}
                  </Box>
                  <Box className={styles.requiredView}>
                    <Typography
                      className={styles.requiredText}
                      mr={1.5}
                      style={{ color: theme_mode.text9 }}
                    >
                      Is Required
                    </Typography>
                    <AntSwitch
                      checked={x.required}
                      onChange={() => onActionBtnClick(x, "required")}
                      inputProps={{ "aria-label": "ant design" }}
                    />
                  </Box>
                </Box>
                <Box className={styles.optionItemList}>
                  {x?.items?.map((y, i2) => {
                    return (
                      <Box
                        key={i2}
                        className={styles.itemView}
                        style={{
                          borderBottom:
                            x?.items?.length == i2 + 1
                              ? "0"
                              : "1px solid #D6D6D6",
                        }}
                        px={1.4}
                        py={1}
                      >
                        <Box className={styles.itemNameView}>
                          <Typography
                            className={styles.itemNameText}
                            style={{ color: theme_mode.text }}
                          >
                            {y}
                          </Typography>
                        </Box>
                        <Box className={styles.itemRightView}>
                          <Box
                            sx={{
                              cursor: "pointer",
                              path: { stroke: theme_mode.iconBg },
                            }}
                            onClick={() => onRemoveMenuItem(x, y)}
                          >
                            <Delete1Icon width={15} height={15} />
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Box px={1.4} mb={2}>
                  <AddOptionItemInput
                    themeMode={props.themeMode}
                    option={x}
                    onAddItem={onAddOptionItem}
                  />
                </Box>

                {x._id == "add" ? (
                  <Box
                    onClick={() => onActionBtnClick(x, "save_option")}
                    className={styles.actionBtn}
                    style={{
                      backgroundColor: theme_mode.black,
                      width: "100%",
                    }}
                  >
                    <Typography
                      className={styles.actionBtnText}
                      style={{ color: theme_mode.btnText }}
                      mr={1}
                    >
                      Save Option
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(AddOptions);
