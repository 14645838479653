import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import * as Api from "../../api";

import { connect } from "react-redux";
import { updateRestaurantDetail } from "../../redux/actions/userDataActions";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import FloorManagement from "./FloorManagement";
import TableSetup from "../../components/TableSetup";
import AlertMsg from "../../components/AlertMsg";

const FloorTableLayout = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [tableLayout, setTableLayout] = useState("ordered");

  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getTableLayout();
  }, []);

  const getTableLayout = () => {
    setLoading(true);
    Api.getRestaurantData(props.restaurantId).then((response) => {
      if (response.success) {
        let data = response.data;
        if (response.data?.tableType) {
          setTableLayout(response.data.tableType);
        }
        props.updateRestaurantDetail(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <Container page={"settings"} p={0} fpx={0} fpy={0}>
        <Loader />
        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      </Container>
    );
  } else {
    return (
      <>
        {tableLayout == "layout" ? (
          <DndProvider backend={HTML5Backend}>
            <TableSetup />
          </DndProvider>
        ) : (
          <FloorManagement />
        )}
        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      </>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorTableLayout);
