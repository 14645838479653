import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import styles from "./OrderTimeSettings.module.css";

import { connect } from "react-redux";
import { updateRestaurantDetail } from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import SettingTextField from "../../components/SettingTextField";
import AlertMsg from "../../components/AlertMsg";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import { ReactComponent as TimeIcon } from "../../assets/images/time.svg";

import { THEME_MODE } from "../../constants/Theme";
import {CONTAINER_HEIGHT, FOOTER_XPADDING} from "../../constants";

const OrderTimeSettings = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles1 = styles2(theme_mode);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [deliveryEta, setDeliveryEta] = useState("");
  const [diningEta, setDiningEta] = useState("");
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const [error, setError] = useState(null);
  const [editable, setEditable] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);

  useEffect(() => {
    setError(null);
    getRestaurantData();
  }, []);

  useEffect(() => {
    if (editable) {
      let data = props.restaurantDetail;
      if (data.deliveryETA !== deliveryEta || data.diningETA !== diningEta) {
        setSaveBtnDisabled(false);
      } else {
        setSaveBtnDisabled(true);
      }
    }
  }, [deliveryEta, diningEta]);

  const getRestaurantData = () => {
    setLoading(true);
    Api.getRestaurantData(props.restaurantId).then((response) => {
      if (response.success) {
        let data = response.data;
        setDeliveryEta(data.deliveryETA);
        setDiningEta(data.diningETA);
        props.updateRestaurantDetail(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onSaveGeneralSettings = () => {
    let err = null;

    if (deliveryEta == "" || deliveryEta == null) {
      err = { ...err, deliveryEta: "Delivery ETA Required" };
    } else {
      if (/[^0-9]/g.test(deliveryEta)) {
        err = { ...err, deliveryEta: "Invalid Delivery ETA" };
      }
    }

    if (diningEta == "" || diningEta == null) {
      err = { ...err, diningEta: "Dining ETA Required" };
    } else {
      if (/[^0-9]/g.test(diningEta)) {
        err = { ...err, deliveryEta: "Invalid Dining ETA" };
      }
    }

    setError(err);
    if (err != null) {
      return;
    }

    let data = {
      deliveryETA: deliveryEta,
      diningETA: diningEta,
    };
    let datas = JSON.stringify(data);

    setLoading(true);
    Api.updateRestaurant(datas, props.restaurantId).then((response) => {
      if (response.success) {
        props.updateRestaurantDetail({
          ...props.restaurantDetail,
          deliveryETA: data.deliveryETA,
          diningETA: data.diningETA,
        });
        setEditable(false);
        setSaveBtnDisabled(true);
        setMsgAlert({ open: true, message: response.msg, msgType: "success" });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onEditSettings = () => {
    setEditable(true);
  };

  return (
    <Container
      page={"settings"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0.5}
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid
          p={1.5}
          item
          xs={12}
          style={{
            backgroundColor: theme_mode.card3,
            borderRadius: 15,
            minHeight: CONTAINER_HEIGHT - 70,
          }}
        >
          <Grid container direction={"column"} item md={4.5} xs={12} mt={1}>
            <Box
              mb={2}
              onClick={() => navigate("/settings")}
              sx={{ cursor: "pointer" }}
            >
              <BackBtnIcon width={33} height={33} />
            </Box>

            <Grid item xs={12} mt={2}>
              <Box mt={2} mb={1}>
                <Typography
                  className={styles.textInputLabel}
                  sx={styles1.textInputLabel}
                >
                  Delivery ETA
                </Typography>
              </Box>
              <Box>
                <SettingTextField
                  page={"settings"}
                  editable={editable}
                  value={deliveryEta}
                  setValue={(val) => setDeliveryEta(val.replace(/[^0-9]/g, ""))}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  // type="number"
                  leftView={<TimeIcon width={20} height={20} />}
                  rightView={
                    <Typography
                      className={styles.minutesText}
                      sx={styles1.minutesText}
                    >
                      minutes
                    </Typography>
                  }
                  error={error?.deliveryEta}
                />
              </Box>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Box mb={1}>
                <Typography
                  className={styles.textInputLabel}
                  sx={styles1.textInputLabel}
                >
                  Pickup ETA
                </Typography>
              </Box>
              <Box>
                <SettingTextField
                  page={"settings"}
                  editable={editable}
                  value={diningEta}
                  setValue={(val) => setDiningEta(val.replace(/[^0-9]/g, ""))}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  // type="number"
                  leftView={<TimeIcon width={20} height={20} />}
                  rightView={
                    <Typography
                      className={styles.minutesText}
                      sx={styles1.minutesText}
                    >
                      minutes
                    </Typography>
                  }
                  error={error?.diningEta}
                />
              </Box>
            </Grid>

            <Grid item xs={12} mt={2}>
              <Box className={styles.btnMainView} mt={2}>
                <Button
                  type="button"
                  className={styles.editBtn}
                  sx={styles1.editBtn}
                  onClick={onEditSettings}
                >
                  Edit
                </Button>
                <Button
                  type="button"
                  disabled={saveBtnDisabled}
                  className={styles.saveBtn}
                  sx={{
                    ...styles1.saveBtn,
                    backgroundColor: saveBtnDisabled
                      ? theme_mode.btnDisabledBg
                      : theme_mode.btnBg4,
                  }}
                  onClick={onSaveGeneralSettings}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantDetail: state.userData.restaurantDetail,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTimeSettings);

const styles2 = (Theme) => ({
  textInputLabel: {
    color: Theme.text2,
  },
  minutesText: {
    color: Theme.minutesText,
  },
  saveBtn: {
    backgroundColor: Theme.btnBg4,
    color: Theme.btnText,
    ml: 1,
    "&:hover": {
      backgroundColor: Theme.btnBg4 + " !important",
    },
  },
  editBtn: {
    backgroundColor: Theme.btnBg5,
    border: "2px solid " + Theme.btnBg5Border,
    color: Theme.text,
    mr: 1,
    "&:hover": {
      backgroundColor: Theme.btnBg5 + " !important",
    },
  },
});
