import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {connect} from "react-redux";

import CreateOrderModalItem from "./CreateOrderModalItem";
import CreateOrderModalCartItem from "./CreateOrderModalCartItem";
import CreateOrderAddNote from "./CreateOrderAddNote";
import Loader from "./Loader";
import AddTable from "./AddTable";
import AlertMsg from "./AlertMsg";

import {THEME_MODE} from "../constants/Theme";

let typeData = {
  add: {
    titleText: 'Create Order',
    submitBtnText: 'PLACE ORDER',
  },
  edit: {
    titleText: 'Update Order',
    submitBtnText: 'UPDATE ORDER',
  },
}

const CreateOrderModal = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [categoryMenuList, setCategoryMenuList] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [tableNo, setTableNo] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [itemNoteModalData, setItemNoteModalData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addTableModalVisible, setAddTableModalVisible] = useState(false);
  const [msgAlert, setMsgAlert] = useState({open: false, message: '', msgType: 'error'});

  useEffect(() => {
    if (categoryMenuList.length) {
      setIsLoading(false);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  }, [categoryMenuList]);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  useEffect(() => {
    setCategoryMenuList(props.categoryMenuList);
  }, [props.categoryMenuList]);

  useEffect(() => {
    if (!props.open) {
      setIsLoading(true);
      setCategoryMenuList([]);
      setCartItems([]);
      setTableNo('');
      setTotalPrice(0);
    } else {
      setCategoryMenuList(props.categoryMenuList);
      if (props.addEdit == 'edit') {
        setCartItems(props.editData.cart);
      }
    }
    if (props.categoryMenuList.length) {
      setSelectedCategory(props.categoryMenuList[0])
    }
  }, [props.open, props.addEdit]);

  useEffect(() => {
    let total = 0;
    cartItems.map((x, i) => {
      total = total + (x.price.value * x.quantity);
    });
    setTotalPrice(total);
  }, [cartItems]);

  const onPlaceOrder = () => {
    if (cartItems.length == 0) {
      setMsgAlert({open: true, message: 'Add Items to Cart', msgType: 'error'});
      return;
    }

    if (tableNo == '') {
      setMsgAlert({open: true, message: 'Select Table Number', msgType: 'error'});
      return;
    }

    if (props.addEdit == 'edit') {
      let data = {
        ...props.editData,
        price: {value: totalPrice, currency: props.priceUnit},
        tableNo: tableNo,
        cart: cartItems,
      }
      props.onPlaceOrder(data);
    } else {
      let data = {
        name: 'Test User',
        price: {value: totalPrice, currency: props.priceUnit},
        tableNo: tableNo,
        cart: cartItems,
      }
      props.onPlaceOrder(data);
    }
  }

  const onSelectCategory = (val, index) => {
    setSelectedCategory(val);
    let sectionRight = document.querySelector('#CategoryId' + index);
    sectionRight.scrollIntoView({behavior: 'auto', block: 'start', inline: 'start'});
  }

  const onDeleteMenu = (item) => {
    let cart_list = [...cartItems];
    let filter = cart_list.filter(x => !(x._id == item._id && x._idCategory == item._idCategory));
    setCartItems(filter);

    let catIndex = categoryMenuList.findIndex(x => x._id == item._idCategory);
    if (catIndex >= 0) {
      let cat_list = [...categoryMenuList];
      let menuIndex = cat_list[catIndex]['cart'].findIndex(x => x._id == item._id);
      if (menuIndex >= 0) {
        cat_list[catIndex]['cart'][menuIndex]['quantity'] = 0;
        setCategoryMenuList(cat_list);
      }
    }
  }

  const onAddNote = (data) => {
    setItemNoteModalData(data);
  };

  const onSubmitNote = (item) => {
    let cart_list = [...cartItems];
    let cartIndex = cart_list.findIndex(x => x._id == item._id && x._idCategory == item._idCategory);
    if (cartIndex >= 0) {
      cart_list[cartIndex]['note'] = item.note;
      setCartItems(cart_list);
    }
  }

  const onAddTableNo = (val) => {
    setTableNo(val.table);
    setAddTableModalVisible(false)
  }

  const onToggleAddTableModalVisible = (val) => {
    setAddTableModalVisible(val)
  }

  const onAddSubMenuItem = (type, item) => {
    let catIndex = categoryMenuList.findIndex(x => x._id == item._idCategory);
    if (catIndex >= 0) {
      let cat_list = [...categoryMenuList];
      let menuIndex = cat_list[catIndex]['cart'].findIndex(x => x._id == item._id);
      if (menuIndex >= 0) {
        let count = cat_list[catIndex]['cart'][menuIndex]?.quantity ? cat_list[catIndex]['cart'][menuIndex].quantity : 0;
        if (type == '-') {
          count = parseInt(count) - 1
        }
        if (type == '+') {
          count = parseInt(count) + 1
        }

        if (count > 0) {
          cat_list[catIndex]['cart'][menuIndex]['quantity'] = count;
          setCategoryMenuList(cat_list);

          let cart_list = [...cartItems];
          let cartIndex = cart_list.findIndex(x => x._id == item._id && x._idCategory == item._idCategory);
          if (cartIndex >= 0) {
            cart_list[cartIndex]['quantity'] = count;
            setCartItems(cart_list);
          } else {
            setCartItems([...cartItems, {...cat_list[catIndex]['cart'][menuIndex], status: 'preparing'}]);
          }
        }
      }
    }
  }

  const MenuList = React.useMemo(() => {
    return (
      categoryMenuList.map((x, i) => {
        return (
          <Box mt={3} key={i} id={'CategoryId' + i}>
            <Typography style={styles.menuTitleText}>{x.name}</Typography>
            <Box mt={1.5} sx={{display: 'flex', flexWrap: 'wrap'}}>
              {x?.cart?.map((y, i2) => {
                return (
                  <CreateOrderModalItem
                    key={i2}
                    item={y}
                    onAddSubMenu={onAddSubMenuItem}
                    onDeleteMenu={onDeleteMenu}
                    themeMode={props.themeMode}
                  />
                )
              })}
            </Box>
          </Box>
        )
      })
    )
  }, [categoryMenuList]);

  return (
    <SwipeableDrawer
      anchor={'right'}
      open={props.open}
      onClose={props.onClose}
      sx={{
        '& .MuiPaper-root': {
          right: 10,
          top: 10,
          bottom: 10,
          left: 10,
          height: window.innerHeight - 20,
          borderRadius: '10px',
        }
      }}>
      <Grid container xs={12} sx={styles.mainView}>
        <Grid container direction="row" item xs={12} md={12} sx={styles.headerView} px={3} pt={1.4} pb={1.2}>
          <Grid item xs={12} md={8.5} sx={styles.headerLeftView}>
            <Typography sx={styles.createOrderText}>{typeData[props.addEdit]['titleText']}</Typography>
            <Box sx={styles.tableNoView}>
              {tableNo ? <Typography sx={styles.tableNoText} mr={1.5}>
                <span>Table No : </span><span style={styles.tableNoValText}>{tableNo}</span>
              </Typography> : null}
              <Box sx={styles.editBtnView} onClick={() => setAddTableModalVisible(true)}>
                <Typography sx={styles.editBtnText}>{tableNo ? 'EDIT' : 'ADD TABLE'}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3.5} sx={styles.headerRightView}>
            {props.addEdit == 'edit' ?
              <Box mx={{md: 2, xs: 0}}>
                <Typography sx={styles.orderIdText}>Order #{props.editData._id.toString().substr(-5)}</Typography>
                <Typography sx={styles.orderDateText}>{props.editData.date}</Typography>
                <Typography sx={styles.nameText}>{props.editData.name}</Typography>
              </Box> : null}
            <Box sx={styles.totalPriceView}>
              <Typography sx={styles.totalText}>Total</Typography>
              <Typography sx={styles.totalValText}>
                {props.priceUnit ? props.priceUnit + ':' : ''} {totalPrice.toFixed(2)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container item direction="row" xs={12} md={12} sx={styles.dataView}>
          <Grid item xs={12} md={8.5} sx={styles.dataListView}>
            {categoryMenuList.length > 0 ?
              <Box sx={{
                px: 3,
                mt: 3,
                mb: 2,
                ml: -4,
              }}>
                <Tabs
                  value={selectedCategory?._id}
                  variant="scrollable"
                  scrollButtons={true}
                  aria-label=""
                  orientation="horizontal"
                  sx={{
                    minHeight: 'fit-content',
                    '& .MuiTabs-indicator': {
                      display: 'none',
                    },
                  }}
                  allowScrollButtonsMobile
                >
                  {categoryMenuList.map((item, index) => {
                    return (
                      <Tab key={index} value={item._id}
                           onClick={() => onSelectCategory(item, index)}
                           label={item.name}
                           sx={[styles.categoryData, selectedCategory?._id == item._id ? styles.selectedCategory : styles.nonSelectedCategory]}/>
                    )
                  })}
                </Tabs>
              </Box> :
              (isLoading ? <Loader height={{height: window.innerHeight - 210}}/> :
                <Box style={styles.noCatView}>
                  <Typography sx={styles.noCatText}>No Items</Typography>
                </Box>)}
            {categoryMenuList.length > 0 ?
              <Box sx={styles.menuListView}>
                {MenuList}
              </Box> : null}
          </Grid>
          <Grid item xs={12} md={3.5} sx={styles.dataCartView}>
            <Box sx={[styles.dataCartListView, cartItems.length ? null : {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }]}>
              {cartItems.map((x, i) => {
                return (
                  <CreateOrderModalCartItem
                    key={i}
                    item={x}
                    onAddSubMenu={onAddSubMenuItem}
                    onDeleteMenu={onDeleteMenu}
                    addNote={onAddNote}
                    themeMode={props.themeMode}
                  />
                )
              })}
              {cartItems.length ? null :
                <Typography sx={styles.noCatText}>Cart Empty</Typography>}
            </Box>
            <Box sx={styles.dataCartBottomView}>
              <Box sx={styles.placeOrderBtnView} onClick={onPlaceOrder}>
                <Typography sx={styles.placeOrderBtnText}>{typeData[props.addEdit]['submitBtnText']}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <CreateOrderAddNote
        open={!(itemNoteModalData == null)}
        data={itemNoteModalData}
        onClose={() => setItemNoteModalData(null)}
        onSubmitNote={onSubmitNote}
      />
      <AddTable
        modelVisible={addTableModalVisible}
        noOfTables={props.noOfTables ? props.noOfTables : 0}
        onSubmit={onAddTableNo}
        onToggle={onToggleAddTableModalVisible}
        inUseTables={props.noOfTables >= 3 ? [1] : []}
        reservedTables={props.noOfTables >= 3 ? [3] : []}
        themeMode={props.themeMode}
      />
      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={() => setMsgAlert({open: false, message: '', msgType: 'error'})}/>
    </SwipeableDrawer>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(CreateOrderModal);

const styles1 = Theme => ({
  mainView: {},
  headerView: {
    backgroundColor: Theme.theme,
  },
  headerLeftView: {
    flex: 1,
  },
  createOrderText: {
    color: Theme.text,
    fontFamily: 'InterBold',
    fontSize: 18,
  },
  tableNoView: {
    display: 'flex',
    alignItems: 'center',
  },
  tableNoText: {
    color: Theme.text,
    fontFamily: 'InterBold',
    fontSize: 16,
  },
  tableNoValText: {
    fontSize: 22,
  },
  editBtnView: {
    backgroundColor: Theme.btnBg,
    cursor: 'pointer',
    borderRadius: '5px',
    minWidth: 90,
    py: 0.7,
    px: 2,
  },
  editBtnText: {
    color: Theme.btnText,
    fontFamily: 'InterBold',
    textAlign: 'center',
    fontSize: 12,
  },
  headerRightView: {
    display: 'flex',
    flexDirection: 'row',
  },
  orderIdText: {
    color: Theme.text,
    fontFamily: 'InterBold',
    fontSize: 17,
  },
  orderDateText: {
    color: Theme.text,
    fontFamily: 'InterMedium',
    fontSize: 13,
    my: 0.1
  },
  nameText: {
    color: Theme.text,
    fontFamily: 'InterBold',
    fontSize: 13,
  },
  totalPriceView: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  totalText: {
    color: 'rgba(0,0,0,0.6)',
    textAlign: 'right',
    fontFamily: 'InterBold',
    fontSize: 16,
  },
  totalValText: {
    color: Theme.text,
    fontFamily: 'InterBold',
    textAlign: 'right',
    fontSize: 16,
  },
  dataView: {},
  dataListView: {},
  menuListView: {
    height: window.innerHeight - 190,
    px: 3,
    overflow: 'auto',
  },
  dataCartView: {
    backgroundColor: '#E0E0E0',
    borderLeft: '1px solid #DFDFDF',
    justifyContent: 'space-between',
  },
  dataCartListView: {
    backgroundColor: '#FFF',
    height: window.innerHeight - 190,
    overflow: 'auto',
  },
  categoryData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: '1px solid ' + Theme.catBtnText,
    fontFamily: 'InterBold',
    fontSize: 13,
    textTransform: 'capitalize',
    borderRadius: 4,
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: 2,
    minWidth: '10%',
    minHeight: 35,
  },
  noCatView: {
    display: 'flex',
    height: window.innerHeight - 210,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  noCatText: {
    textAlign: 'center',
    fontFamily: 'InterMedium'
  },
  menuTitleText: {
    color: Theme.text2,
    fontFamily: 'InterBold',
    fontSize: 18,
  },
  selectedCategory: {
    backgroundColor: Theme.theme,
    '&.Mui-selected': {
      color: Theme.text,
    },
  },
  nonSelectedCategory: {
    color: Theme.catBtnText2,
    backgroundColor: Theme.catBtnBg2,
    borderColor: Theme.catBtnBorder,
  },
  dataCartBottomView: {
    display: 'flex',
    backgroundColor: '#E0E0E0',
    justifyContent: 'center',
    alignItems: 'center',
    py: 3,
    px: 2,
  },
  placeOrderBtnView: {
    backgroundColor: Theme.greenBg,
    width: '100%',
    cursor: 'pointer',
    borderRadius: '5px',
    py: 1,
    px: 4,
  },
  placeOrderBtnText: {
    color: Theme.lightText,
    fontFamily: 'InterBold',
    textAlign: 'center',
    fontSize: 12,
  },
});
