import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import styles from "./DiningDetailModifierList.module.css";

import { THEME_MODE } from "../constants/Theme";

// let withExtraList = ["with", "without", "extra"];
let withExtraList = ["with"];

const DiningDetailModifierList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [withExtra, setWithExtra] = useState("with");

  let tabBg = { true: theme_mode.theme, false: theme_mode.headerBg };
  let tabText = { true: theme_mode.text, false: theme_mode.lightText };

  let { item } = props;

  useEffect(() => {
    if (props.selectedModifier?.length) {
      setWithExtra(props.selectedModifier[0].withExtra);
    } else {
      if (withExtra == "") {
        setWithExtra("with");
      }
    }
  }, [props.selectedModifier]);

  const onWithExtraClick = (val) => {
    // setWithExtra(val);
    // if (props.selectedModifier?.length) {
    //   props.onModifierSelect(
    //     "withExtra",
    //     props.modifierNo,
    //     item,
    //     val,
    //     props.selectedModifier._idMenuItem
    //   );
    // }
  };

  const onModifierSelect = (val) => {
    props.onModifierSelect(
      "menuItem",
      props.modifierNo,
      item,
      withExtra,
      val._id
    );
  };

  return (
    <Box
      className={styles.modifierItemView}
      pb={2}
      style={{
        borderBottom:
          props.index + 1 == props.modifiersLength ? "0" : "1px solid #969697",
      }}
    >
      <Box className={styles.withExtraMainView} my={2}>
        {withExtraList.map((x2, i2) => {
          return (
            <Box
              onClick={() => onWithExtraClick(x2)}
              key={i2}
              className={styles.withExtraView}
              style={
                props.themeMode == "dark" && withExtra == x2
                  ? {
                      // border: "2px solid " + tabBg[withExtra == x2],
                      backgroundColor: tabBg[withExtra == x2],
                    }
                  : { backgroundColor: tabBg[withExtra == x2] }
              }
            >
              <Typography
                className={styles.withExtraTxt}
                style={{ color: tabText[withExtra == x2] }}
              >
                {x2}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box mt={0.6} className={styles.modifierMainView}>
        {item.items.map((x3, i3) => {
          let exist = props.selectedModifier
            ?.map((x) => x?._idMenuItem)
            .includes(x3?._id);
          return (
            <Box
              key={i3}
              mb={0.5}
              style={{ minWidth: 160 }}
              onClick={() => onModifierSelect(x3)}
            >
              <Box
                className={styles.modifierView}
                style={{ backgroundColor: tabBg[exist], width: "98%" }}
              >
                <Typography
                  className={styles.modifierTxt}
                  style={{ color: tabText[exist] }}
                >
                  {x3.name}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default DiningDetailModifierList;
