import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { THEME_MODE } from "../constants/Theme";
import {
  CURRENCY_SYMBOL,
  DISCOUNT_VALUE_TYPE,
  IS_LARGE_SCREEN,
} from "../constants";

import styles from "./OrderSummaryCartItem.module.css";

import { ReactComponent as CircleIcon } from "../assets/images/circle.svg";
import { ReactComponent as CircleDotIcon } from "../assets/images/circle_dot.svg";
import { getModifierTotalPrice } from "../constants/CommonFunc";

const ICON_SIZE = IS_LARGE_SCREEN ? 30 : 20;

const OrderSummaryCartItem = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  let item = props.item;

  let mod_name = {};
  let modifiers = [];
  let mod_price = 0;

  const modifiersList = item?.modifiers
    ?.filter((x) => !x.addUpPrices)
    ?.map((x) => x?._id);

  item?.cartModifier?.forEach((item1, i1) => {
    let mod = [];
    mod_price += getModifierTotalPrice(modifiersList, item1);
    item1.forEach((y1, i2) => {
      mod_name[y1.menuData._id] = y1.menuData.name;
      mod.push(y1.menuData.name);
    });
    if (mod.length) {
      modifiers.push(mod);
    }
  });

  let counts = {};
  if (item?.cartModifier?.length) {
    let arr = item.cartModifier.reduce((a, b) => a.concat(b), []);
    arr.forEach(
      (x) => (counts[x.menuData._id] = (counts[x.menuData._id] || 0) + 1)
    );
  }

  return (
    <Box
      id={"cartItemId" + item._idCategory + item._id}
      pt={2}
      py={1.5}
      px={1}
      style={{
        cursor: props.disabled
          ? "default"
          : props.clickable
          ? "pointer"
          : "default",
        // borderBottom:
        //   `${props.showBorderBottom ? 1 : 0}px solid ` +
        //   theme_mode.borderBottom3,
        backgroundColor: props.selected ? theme_mode.theme : theme_mode.card2,
        opacity: props.disabled ? 0.7 : 1,
      }}
      className={styles.mainView}
      onClick={() =>
        props.disabled
          ? null
          : props.clickable
          ? props.onSelectMenu(item)
          : null
      }
    >
      <Box className={styles.cartItemDataView}>
        {props.clickable ? (
          <Box pr={1} style={{ lineHeight: 0 }}>
            {props.selected ? (
              <Box
                sx={{
                  "#circle_dot1": {
                    stroke: theme_mode.iconBg,
                  },
                  "#circle_dot2": {
                    stroke: theme_mode.iconBg,
                    fill: theme_mode.iconBg,
                  },
                }}
              >
                <CircleDotIcon height={ICON_SIZE} width={ICON_SIZE} />
              </Box>
            ) : (
              <Box
                sx={{
                  circle: {
                    stroke: theme_mode.iconBg,
                  },
                }}
              >
                <CircleIcon height={ICON_SIZE} width={ICON_SIZE} />
              </Box>
            )}
          </Box>
        ) : null}
        <Box sx={{ flex: 1 }}>
          <Typography
            className={styles.cartItemNameText}
            style={{ color: theme_mode.cardDescText3 }}
          >
            {item.quantity}x {item.name}
          </Typography>
          {props.discount ? (
            <Typography
              className={styles.cartDiscountText}
              style={{ color: theme_mode.cardDescText3 }}
            >
              (Discount:{" "}
              {props.discount.discountValueType == DISCOUNT_VALUE_TYPE["amount"]
                ? `${props.currency} ${props.discount.value}`
                : `${props.discount.value} %`}
              )
            </Typography>
          ) : null}
        </Box>
        <Box className={styles.cartItemBtnPriceView} pl={1}>
          <Box className={styles.cartItemPriceTextView} ml={1.5}>
            <Typography
              className={styles.cartItemPriceText}
              style={{ color: theme_mode.cardText }}
            >
              {CURRENCY_SYMBOL[item.price?.currency]}{" "}
              {(
                parseFloat(
                  item.price?.value * (item?.quantity ? item.quantity : 1)
                ) + mod_price
              ).toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Box>
      {modifiers.length || item?.notes ? (
        <Box
          mt={0.3}
          px={1}
          pb={0.5}
          pt={0.03}
          sx={{ backgroundColor: theme_mode.diningCartCourseTitle }}
        >
          {Object.keys(counts).map((key, index) => {
            return (
              <Typography
                key={index}
                mt={1}
                className={styles.cartItemModText}
                style={{ color: theme_mode.cardDescText3 }}
              >
                --{counts[key]} {mod_name[key]}
              </Typography>
            );
          })}

          {item?.notes ? (
            <Typography
              mt={1}
              className={styles.cartItemNoteText}
              style={{ color: theme_mode.cardDescText3 }}
            >
              {item.notes}
            </Typography>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default OrderSummaryCartItem;
