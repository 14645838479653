import React, {useEffect, useState} from 'react';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import DiningOrderModalItemList from "./DiningOrderModalItemList";

import {DINING_MODAL_WIDTH, THEME_COLOR} from "../constants";
import DiningAddSubMenuBtn from "./DiningAddSubMenuBtn";
import {THEME_MODE} from "../constants/Theme";

let dataType = {
  placed_order: {
    submitBtnText: 'Accept',
    cancelBtnText: 'Cancel',
  },
  preparing: {
    submitBtnText: 'Ready to serve',
    cancelBtnText: 'Cancel',
  },
  pending_payment: {
    submitBtnText: 'Paid',
    cancelBtnText: 'Cancel',
  },
  reject_confirm: {
    submitBtnText: 'No, Don’t Reject',
    cancelBtnText: 'Cancel',
  },
}

const DiningOrderDetailModal = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  let {data} = props;

  const onCancelClick = () => {
    props.onCancel('cancelled');
  }

  const onSubmitClick = () => {
    let type = props.data.orderStatus;
    let status = '';
    if (type == 'placed_order') {
      status = 'preparing';
    } else if (type == 'preparing') {
      status = 'pending_payment';
    } else if (type == 'pending_payment') {
      status = 'completed';
    }
    if (status != '') {
      props.onSubmit(status);
    }
  }

  const BottomView = ({modalType}) => {
    return (
      <Box sx={styles.bottomView}>
        <Box sx={styles.bottomSubView}>
          <Box sx={styles.btnView} onClick={() => onCancelClick(modalType)}>
            <Typography sx={styles.btnText}>{dataType[modalType]['cancelBtnText']}</Typography>
          </Box>
          <Box sx={styles.submitBtnView} onClick={() => onSubmitClick(modalType)}>
            <Typography sx={styles.btnText}>{dataType[modalType]['submitBtnText']}</Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <SwipeableDrawer
      anchor={'right'}
      open={props.open}
      onClose={props.onClose}
      sx={{
        '& .MuiPaper-root': {
          right: 10,
          top: 10,
          left: {xs: 10, md: 'unset'},
          height: window.innerHeight - 20,
          borderRadius: '10px',
        }
      }}>
      {props.data ?
        <Box sx={styles.mainView}>
          <Box sx={{...styles.dataView, alignItems: data.orderStatus == 'preparing' ? 'flex-start' : 'flex-end',}}
               px={3}
               pt={1.4} pb={1.2}>
            <Box sx={styles.orderDataView}>
              <Typography sx={styles.orderIdText}>Order #{data._id.toString().substr(-5)}</Typography>
              <Typography sx={styles.orderDateText}>{data.date}</Typography>
              <Typography sx={styles.nameText}>{data.name}</Typography>
              <Typography sx={styles.priceText}>{data.price.currency}: {data.price.value?.toFixed(2)}</Typography>
            </Box>
            <Box>
              {data.orderStatus == 'preparing' ? <Typography sx={styles.orderPrepareText}>Order preparing</Typography> :
                <Box sx={styles.addItemBtnView} onClick={() => props.onUpdateOrder(data.orderStatus, data)}>
                  <Typography sx={styles.addItemBtnText}>ADD ITEMS</Typography>
                </Box>}
            </Box>
          </Box>
          <Box sx={{
            ...styles.menuListView,
            height: {
              md: window.innerHeight - 220,
              xs: window.innerHeight - 220
            }
          }}>
            {data.cart.map((x, i) => {
              return (
                <DiningOrderModalItemList
                  key={i}
                  item={x}
                  type={data.orderStatus}
                  themeMode={props.themeMode}
                  onDelete={props.onDeleteItem}
                  onAddSubMenu={props.onAddSubMenuItem}
                />
              )
            })}
          </Box>
          <BottomView modalType={data.orderStatus}/>
        </Box>
        : null}
    </SwipeableDrawer>
  );
};

export default DiningOrderDetailModal;

const styles1 = Theme => ({
  mainView: {
    backgroundColor: Theme.modal,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: DINING_MODAL_WIDTH
  },
  dataView: {
    display: 'flex',
    backgroundColor: Theme.theme,
  },
  orderDataView: {
    flex: 1,
  },
  subDataView: {
    flex: 0.8,
    ml: 2
  },
  orderIdText: {
    color: Theme.cardText,
    fontFamily: 'InterBold',
    fontSize: 16,
  },
  orderDateText: {
    color: Theme.cardText,
    fontFamily: 'InterMedium',
    fontSize: 14,
    my: 0.1
  },
  priceText: {
    color: Theme.cardText,
    fontFamily: 'InterBold',
    fontSize: 17,
    my: 0.7
  },
  nameText: {
    color: Theme.cardText,
    fontFamily: 'InterBold',
    fontSize: 13,
  },
  orderPrepareText: {
    color: Theme.cardText,
    fontFamily: 'InterBold',
    fontSize: 16,
    textTransform: 'uppercase',
  },
  addItemBtnView: {
    backgroundColor: Theme.btnBg,
    cursor: 'pointer',
    borderRadius: '9px',
    py: 1,
    px: 2.2,
  },
  addItemBtnText: {
    color: Theme.btnText,
    fontFamily: 'InterBold',
    fontSize: 14,
  },
  menuListView: {
    overflow: 'auto',
    pt: 1,
    pb: 2,
  },
  bottomView: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(224, 224, 224, 0.4)',
    justifyContent: 'center',
    py: 1.5,
    px: 2,
  },
  bottomSubView: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnView: {
    backgroundColor: Theme.redBg,
    borderRadius: '9px',
    cursor: 'pointer',
    flex: 1,
    py: 1.3,
    mr: 1,
  },
  submitBtnView: {
    backgroundColor: Theme.greenBg,
    borderRadius: '9px',
    cursor: 'pointer',
    flex: 1,
    py: 1.3,
    ml: 1,
  },
  btnText: {
    color: Theme.lightText,
    fontFamily: 'InterSemiBold',
    fontSize: 13,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
});
