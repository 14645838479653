import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { THEME_COLOR3 } from "../constants";
import { THEME_MODE } from "../constants/Theme";
import { allDays } from "./AddRestaurant";

import "./../assets/css/custom.css";

import styles2 from "./AddRestaurantOpeningHours.module.css";

const theme = (theme_mode) =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: theme_mode.card + " !important",
            border: "1px solid " + theme_mode.lightText,
            color: theme_mode.text,
            "& .MuiButtonBase-root, .MuiTypography-root": {
              color: theme_mode.text,
              fontFamily: "InterMedium !important",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid #fff !important",
          },
        },
      },
    },
    palette: {
      gray: {
        main: THEME_COLOR3,
      },
      red: {
        main: "#FF0000",
      },
    },
  });

const TimePickerSlotProps = {
  actionBar: {
    sx: {
      "& .MuiButton-root": {
        color: "#fff",
        fontFamily: "InterSemiBold",
      },
    },
  },
  digitalClockSectionItem: {
    sx: {
      fontSize: 13,
      fontFamily: "InterSemiBold",
      "&.Mui-selected": {
        backgroundColor: "#DD5903",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#DD5903",
          color: "#fff",
        },
      },
    },
  },
  textField: {
    color: "gray",
    variant: "standard",
    InputLabelProps: {
      style: { color: "#fff", fontFamily: "InterRegular" },
    },
    inputProps: {
      readOnly: true,
      sx: {
        height: 30,
        color: "#fff",
        fontSize: 13,
        fontFamily: "InterSemiBold",
      },
    },
    sx: {
      svg: { color: "#fff", fontSize: 18 },
      "& .MuiButtonBase-root": {
        width: 35,
        height: 35,
      },
    },
  },
};

const AddRestaurantInfo = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [openingHourList, setOpeningHourList] = useState(props.openingHourList);

  const onActiveSwitch = (data) => {
    let openingHour = [...openingHourList];
    let openingHourIndex = openingHour.findIndex(
      (item) => item.day === data.day
    );
    if (openingHourIndex >= 0) {
      const active = !openingHour[openingHourIndex]["active"];
      openingHour[openingHourIndex]["active"] = active;
      if (active) {
        const { open, close } = openingHourList?.find((x) => x?.day == allDays);
        openingHour[openingHourIndex]["open"] = open;
        openingHour[openingHourIndex]["close"] = close;
      }
      setOpeningHourList(openingHour);
    }
  };

  const openCloseTime = (val, data, type) => {
    let openingHour = [...openingHourList];
    let openingHourIndex = openingHour.findIndex(
      (item) => item.day === data.day
    );
    if (openingHourIndex >= 0) {
      openingHour[openingHourIndex][type] = val;
    }
    setOpeningHourList(openingHour);
  };

  const handlerOnApplyClick = () => {
    let openingHour = [...openingHourList];
    const findIndex = openingHour.findIndex((item) => item.day === allDays);
    if (findIndex >= 0) {
      const allDayDate = openingHour[findIndex];
      openingHour = openingHour.map((item) => {
        item["open"] = allDayDate?.open;
        item["close"] = allDayDate?.close;
        return item;
      });
    }
    setOpeningHourList(openingHour);
  };

  const onPrevBtnClick = () => {
    props.resOpeningHours("", "prev");
  };

  const onSaveBtnClick = () => {
    props.resOpeningHours(openingHourList, "next");
  };

  return (
    <Grid className={`${styles2?.["main-view"]}`}>
      <Grid
        container
        item
        component="form"
        noValidate
        autoComplete="off"
        justifyContent="center"
      >
        {openingHourList.map((item, index) => {
          return (
            <Grid
              container
              item
              xs={10}
              key={index}
              className={`${styles2?.["open-hrs-view"]} ${
                styles2?.[item?.day === allDays ? "all-day-view" : null]
              }`}
              mt={2}
            >
              <Grid container direction="row" item xs={12} md={4} mt={2}>
                <Grid item xs={12} md={6}>
                  <label
                    className="day-label"
                    style={{
                      color: theme_mode.text,
                      fontFamily: "InterSemiBold",
                      textTransform: "capitalize",
                    }}
                  >
                    {item.day}
                  </label>
                </Grid>
                <Grid item xs={12} md={6}>
                  {item?.day == allDays ? null : (
                    <ThemeProvider theme={theme(theme_mode)}>
                      <Switch
                        checked={item.active}
                        onChange={() => onActiveSwitch(item)}
                        color={"red"}
                        size="small"
                        sx={{
                          "& .MuiSwitch-track": {
                            height: 15,
                            backgroundColor: theme_mode.text9 + " !important",
                            marginTop: -0.3,
                          },
                          "& .MuiSwitch-thumb": {
                            backgroundColor: "#999",
                          },
                          "& .Mui-checked .MuiSwitch-thumb": {
                            backgroundColor: "red !important",
                          },
                        }}
                      />
                    </ThemeProvider>
                  )}{" "}
                </Grid>
              </Grid>
              <Grid container direction="row" item xs={12} md={8}>
                {item.active == 1 ? (
                  <ThemeProvider theme={theme(theme_mode)}>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sx={{ paddingRight: 5, marginTop: { xs: 2, md: 0 } }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Time Open"
                          value={item.open}
                          format="hh:mm aa"
                          onChange={(val) => openCloseTime(val, item, "open")}
                          slotProps={TimePickerSlotProps}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sx={{ paddingRight: 5, marginTop: { xs: 2, md: 0 } }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Time Closed"
                          value={item.close}
                          format="hh:mm aa"
                          onChange={(val) => openCloseTime(val, item, "close")}
                          slotProps={TimePickerSlotProps}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={`${styles2?.["apply-btn-view"]}`}
                    >
                      {item?.day === allDays ? (
                        <Button
                          disabled={false}
                          type={"button"}
                          className={`${styles2?.["apply-btn"]}`}
                          onClick={handlerOnApplyClick}
                        >
                          Apply All
                        </Button>
                      ) : null}
                    </Grid>
                  </ThemeProvider>
                ) : null}
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={7}
        mb={1}
      >
        <Typography
          onClick={() => onPrevBtnClick()}
          gutterBottom
          sx={styles.btnText}
        >
          Previous
        </Typography>
        <Typography
          ml={2}
          onClick={() => onSaveBtnClick()}
          gutterBottom
          sx={styles.btnText}
        >
          Save
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AddRestaurantInfo;

const styles1 = (Theme) => ({
  btnText: {
    backgroundColor: Theme.theme,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontFamily: "InterBold",
    fontSize: 13,
    color: Theme.text,
    borderRadius: 1,
    width: 100,
    height: 35,
  },
});
