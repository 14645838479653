import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import {THEME_MODE} from "../constants/Theme";

let dataBg = {
  placed_order: {
    color: '#F17400',
    btnText: 'Accept',
    gradient1: '#FFB876',
    gradient2: '#F17400',
  },
  preparing: {
    color: '#CBB11E',
    btnText: 'Ready to serve',
    gradient1: '#FFE342',
    gradient2: '#D7B700',
  },
  pending_payment: {
    color: '#CD0000',
    btnText: 'Pay now',
    gradient1: '#FFB8B8',
    gradient2: '#CD0000',
  },
}

let count_down_time = 60;

let toHHMMSS = (secs) => {
  let sec_num = parseInt(secs, 10)
  let hours = Math.floor(sec_num / 3600)
  let minutes = Math.floor(sec_num / 60) % 60
  let seconds = sec_num % 60

  return [hours, minutes, seconds]
    .map(v => v < 10 ? "0" + v : v)
    .filter((v, i) => v !== "00" || i > 0)
    .join(":")
}

const DiningOrderItemList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  let item = props.item;

  const [orderTimer, setOrderTimer] = useState(60 * count_down_time);

  useEffect(() => {
    if (orderTimer > 0) {
      const timerId = setInterval(
        () => (orderTimer > 0 ? setOrderTimer(orderTimer - 1) : null),
        1000,
      );
      return () => clearInterval(timerId);
    }
  }, [orderTimer]);

  let quantity = 0;
  item.cart.map(x => {
    quantity = quantity + (x?.quantity ? x.quantity : 0);
  });

  const CountDownTimer = React.useMemo(() => {
    return (
      <Typography
        sx={{color: item?.delay ? theme_mode.redText : theme_mode.cardText, ...styles.timeCountText}}>
        {orderTimer == 60 * count_down_time ? '00:00' : toHHMMSS(orderTimer)}
      </Typography>
    )
  }, [orderTimer]);


  const customProgressBar = (
    <div style={{position: 'relative'}}>
      <CircularProgress
        variant="determinate"
        sx={{color: '#EEE'}}
        size={45}
        thickness={4}
        value={100}
      />
      <svg style={{height: 0, width: 0}}>
        <defs>
          <linearGradient
            id={'gradientId' + props.type + props.index}
            gradientTransform="rotate(90)">
            <stop offset="24.88%" stopColor={dataBg[props.type]['gradient1']}/>
            <stop offset="75.12%" stopColor={dataBg[props.type]['gradient2']}/>
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        size={45}
        thickness={4}
        sx={{
          'svg circle': {stroke: 'url(#gradientId' + props.type + props.index + ')'},
          position: 'absolute',
          left: 0,
        }}
        variant="determinate"
        value={Math.floor((orderTimer / (60 * count_down_time)) * 100)}/>
      {CountDownTimer}
    </div>
  )

  return (
    <Box sx={{...styles.mainView, border: `2px solid ${item?.delay ? theme_mode.redText3 : theme_mode.card}`}}
         onClick={() => props.onItemClick(item)}>
      <Box sx={styles.orderView}>
        <Box sx={styles.orderSubView}>
          <Box sx={styles.delayOrderIdView}>
            <Typography sx={styles.orderIdText}>Order #{item._id.toString().substr(-5)}</Typography>
          </Box>
          <Typography sx={styles.orderDateText}>{item.date}</Typography>
          <Typography sx={styles.nameText}>{item.name}</Typography>
        </Box>
        {customProgressBar}
      </Box>

      <Box sx={styles.dataView} my={2}>
        <Box sx={{...styles.tableNoView, backgroundColor: dataBg[props.type]['color']}}>
          <Typography sx={styles.tableNoValText}>{item.tableNo}</Typography>
          <Typography sx={styles.tableNoText}>Table No</Typography>
        </Box>
      </Box>

      <Box sx={styles.bottomView}>
        <Box sx={styles.priceCountView}>
          <Typography sx={styles.itemCountText}>X{quantity} Items</Typography>
          <Typography sx={styles.priceText}>{item.price.currency} {item.price.value?.toFixed(2)}</Typography>
        </Box>
        <Box>
          <Box sx={{backgroundColor: dataBg[props.type]['color'], ...styles.btnView}}
               onClick={() => props.onSubmitClick(item)}>
            <Typography sx={styles.btnText}>{dataBg[props.type]['btnText']}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DiningOrderItemList;

const styles1 = Theme => ({
  mainView: {
    backgroundColor: Theme.card,
    width: '100%',
    borderRadius: '8px',
    cursor: 'pointer',
    my: 0.5,
    py: 1.5,
    px: 2,
  },
  orderView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  orderSubView: {
    flex: 1,
  },
  delayOrderIdView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  orderIdText: {
    color: Theme.cardText,
    fontFamily: 'InterBold',
    fontSize: 16,
  },
  delayView: {
    backgroundColor: Theme.redText3,
    borderRadius: '10px',
    ml: 1,
    py: 0.3,
    px: 1,
  },
  delayText: {
    color: Theme.lightText,
    fontFamily: 'InterSemiBold',
    fontSize: 10,
  },
  orderDateText: {
    color: Theme.cardText,
    fontFamily: 'InterSemiBold',
    fontSize: 10,
    opacity: 0.5,
  },
  dataView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tableNoView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 100,
    height: 100,
    borderRadius: '50%',
  },
  tableNoValText: {
    color: Theme.lightText,
    fontFamily: 'InterBold',
    fontSize: 40,
    lineHeight: '40px',
  },
  tableNoText: {
    color: Theme.lightText,
    fontFamily: 'InterMedium',
    fontSize: 11,
  },
  nameText: {
    color: Theme.cardText,
    fontFamily: 'InterBold',
    fontSize: 12,
  },
  bottomView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    borderTop: '1px solid ' + Theme.borderBottom3,
    pt: 1,
    mt: 1
  },
  priceCountView: {
    flex: 1,
  },
  priceText: {
    color: Theme.cardText,
    fontFamily: 'InterBold',
    fontSize: 16,
  },
  itemCountText: {
    color: Theme.cardDescText3,
    fontFamily: 'InterBold',
    fontSize: 13,
  },
  timeCountText: {
    fontFamily: 'InterSemiBold',
    fontSize: 12,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    top: -2,
    bottom: 0,
    left: 0,
    right: 0,
  },
  btnView: {
    borderRadius: '9px',
    py: 1,
    px: 1.5,
    minWidth: 100,
  },
  btnText: {
    color: Theme.lightText,
    fontFamily: 'InterBold',
    fontSize: 12,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
