import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import styles from "./OrderSummaryDiscountSplit.module.css";

import { THEME_MODE } from "../constants/Theme";
import {
  DINING_DETAIL_CONTENT_HEIGHT,
  IS_LARGE_SCREEN,
  NAVBAR_HEIGHT,
} from "../constants";

let CONTENT_HEIGHT = DINING_DETAIL_CONTENT_HEIGHT - NAVBAR_HEIGHT - 5;

let BTN_HEIGHT = IS_LARGE_SCREEN ? 100 : 70;
let CALC_HEIGHT = CONTENT_HEIGHT - 2 * BTN_HEIGHT;
let ITEM_BTN_HEIGHT = IS_LARGE_SCREEN ? 80 : 60;

let btnList1 = [];
let btnList2 = [];

const OrderSummaryDiscountSplit = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  if (props.discountSplit == "split") {
    btnList1 = [];
    btnList2 = [
      { name: "Split Equal", type: "split_equal" },
      { name: "Split By 2", type: "split_by_2" },
      { name: "Split By Items", type: "split_by_item" },
    ];
  } else {
    btnList1 = [
      { name: "%", type: "percentage" },
      { name: props.currency, type: "amount" },
    ];
    btnList2 = [
      { name: "All Items", type: "all_item" },
      { name: "Select Items", type: "select_item" },
    ];
  }

  const [discountSplitType, setDiscountSplitType] = useState("");
  const [pricePercVal, setPricePercVal] = useState(0);
  const [percPriceList, setPercPriceList] = useState("");
  const [allOrSelectItem, setAllOrSelectItem] = useState("");
  const [covers, setCovers] = useState(0);
  const [initialCall, setInitialCall] = useState(false);

  let tabBg = { true: theme_mode.theme, false: theme_mode.headerBg2 };
  let tabText = { true: theme_mode.text, false: theme_mode.lightText };

  useEffect(() => {
    setInitialCall(false);
    if (btnList1.length) {
      setPercPriceList(btnList1[0].type);
      setPricePercVal(0);
    }
    if (btnList2.length && props.discountSplit == "discount") {
      setAllOrSelectItem(btnList2[0].type);
    }
  }, [props.discountSplit]);

  useEffect(() => {
    if (!initialCall) {
      setDiscountSplitType(props.discountSplit);
      setInitialCall(true);
    }
  }, [initialCall]);

  useEffect(() => {
    if (initialCall && props.discountSplit == discountSplitType) {
      if (props.discountSplit == "discount") {
        if (allOrSelectItem == "all_item") {
          props.onApplyDisc(percPriceList, props.calcValue, false);
        } else {
          props.onChangeDiscount(percPriceList, props.calcValue);
        }
        setPricePercVal(props.calcValue);
        setCovers(0);
      } else {
        if (
          (props.calcValue == 1 || props.calcValue == 0) &&
          allOrSelectItem == "split_equal"
        ) {
          props.onApplySplit("", props.calcValue, false);
        } else {
          props.onApplySplit(allOrSelectItem, props.calcValue, false);
        }
        setCovers(props.calcValue);
        setPricePercVal(0);
      }
    }
  }, [props.calcValue]);

  const onSelectAllOrSelectItem = (val) => {
    setInitialCall(true);
    setAllOrSelectItem(val);
    props.setAllOrSelectItem(val);
    if (props.discountSplit == "split") {
      if (val == "split_by_2") {
        props.onApplySplit(val, 2, false);
      }
      if (val == "split_equal") {
        setCovers(props.coversVal);
        props.onApplySplit(val, props.coversVal, false);
      }
    }
  };

  const onApplyDiscount = () => {
    if (props.discountSplit == "discount") {
      props.onApplyDisc(percPriceList, pricePercVal, true);
    } else {
      if ((covers == 1 || covers == 0) && allOrSelectItem == "split_equal") {
        props.onApplySplit("", covers, true);
      } else {
        props.onApplySplit(allOrSelectItem, covers, true);
      }
    }
  };

  return (
    <Box
      mt={{ xs: "5px", md: "0px" }}
      style={{ height: CONTENT_HEIGHT }}
      className={styles.mainView}
    >
      <Box
        className={styles.topView}
        style={{
          height: CALC_HEIGHT,
          width: "100%",
        }}
      >
        <Box className={styles.tabMainView}>
          {props.discountSplit == "split" ? null : (
            <Box
              style={{
                width: "100%",
              }}
              py={0}
              mb={2}
            >
              {btnList1.map((x, i) => {
                return (
                  <Box
                    key={i}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    className={styles.tabView}
                    mb={0.5}
                    onClick={() => {
                      setPricePercVal(0);
                      setPercPriceList(x.type);
                      props.setCalcValue(0);
                    }}
                    style={{
                      backgroundColor: tabBg[percPriceList == x.type],
                      height: ITEM_BTN_HEIGHT,
                    }}
                  >
                    <Typography
                      className={styles.tabText}
                      color={tabText[percPriceList == x.type]}
                    >
                      by{" "}
                      {x.type == "amount"
                        ? `${x.name}${
                            x.type == percPriceList ? pricePercVal : 0
                          }`
                        : `${x.type == percPriceList ? pricePercVal : 0}${
                            x.name
                          }`}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
          <Box
            style={{
              width: "100%",
            }}
          >
            {btnList2.map((x, i) => {
              return (
                <Box
                  key={i}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className={styles.tabView}
                  mb={0.5}
                  onClick={() => onSelectAllOrSelectItem(x.type)}
                  style={{
                    backgroundColor: tabBg[allOrSelectItem == x.type],
                    height: ITEM_BTN_HEIGHT,
                  }}
                >
                  <Typography
                    className={styles.tabText}
                    color={tabText[allOrSelectItem == x.type]}
                  >
                    {x.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box
        style={{ height: BTN_HEIGHT }}
        className={styles.discountBtnMainView}
      >
        <Box
          onClick={props.onCancel}
          className={styles.btnView}
          style={{
            backgroundColor: theme_mode.redBg,
            height: BTN_HEIGHT,
          }}
        >
          <Typography color={theme_mode.lightText} className={styles.btnText}>
            Cancel
          </Typography>
        </Box>
        <Box
          onClick={onApplyDiscount}
          className={styles.btnView}
          style={{
            backgroundColor: theme_mode.greenBg,
            height: BTN_HEIGHT,
          }}
        >
          <Typography color={theme_mode.lightText} className={styles.btnText}>
            Apply
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSummaryDiscountSplit;
