import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";

import {THEME_COLOR3} from "../constants";
import {THEME_MODE} from "../constants/Theme";

const Footer = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  return (
    <Grid container
          direction="row"
          justifyContent="space-between"
          mt={2}>
      <Typography sx={styles.footerText}>
        Terms & Privacy Policy
      </Typography>
      <Typography style={styles.footerText}>
        Copyright © 2010-2022 Foodo Company. All rights reserved.
      </Typography>
    </Grid>
  );
};

export default Footer;

const styles1 = Theme => ({
  footerText: {
    color: '#AAAAAA',
    fontFamily: 'InterMedium',
    fontSize: 10
  }
});
