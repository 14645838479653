import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const AntSwitch = styled(Switch)(({ theme, themeMode }) => ({
  width: 50,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 45,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(-1px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#455A64",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    transition: theme.transitions.create(["width"], {
      duration: 100,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 50,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : themeMode == "light"
        ? "rgba(0,0,0,.25)"
        : "#333333",
    boxSizing: "border-box",
  },
}));

const SwitchInput = (props) => {
  return (
    <AntSwitch
      disabled={props?.disabled ?? false}
      checked={props.checked}
      onChange={(e) => props.onChange(e.target.checked)}
      inputProps={{ "aria-label": "ant design" }}
      themeMode={props.themeMode}
    />
  );
};

export default SwitchInput;
