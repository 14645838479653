import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { THEME_MODE } from "../constants/Theme";

import styles from "./DiningDetailHeaderTab.module.css";

import { DINING_DETAIL_BTN_HEIGHT } from "../constants";

let HEADER_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;

const DiningDetailHeaderTab = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const [selectedTab, setSelectedTab] = useState(props.selectedTab);

  let tabBg = { true: theme_mode.theme, false: theme_mode.diningCartHeader };
  let tabText = { true: theme_mode.text, false: theme_mode.lightText };

  let TAB_HEADER = [
    { id: "table", label: "TABLE", value: props.table },
    { id: "covers", label: "COVERS", value: props.covers },
    {
      id: "assign",
      label: "ASSIGN",
      value: props.assigned?.name ? props.assigned.name : "",
    },
  ];

  useEffect(() => {
    setSelectedTab(props.selectedTab);
  }, [props.selectedTab]);

  const onSelectTab = (tab) => {
    setSelectedTab(tab);
    props.onSelectTab(tab);
  };

  return (
    <Box className={styles.headerMainView}>
      {TAB_HEADER.map((x, i) => {
        return (
          <Box
            key={i}
            ml={i == 0 ? 0 : "2px"}
            className={styles.headerView}
            style={{
              backgroundColor: tabBg[selectedTab == x.id],
              height: HEADER_BTN_HEIGHT,
            }}
            onClick={() => onSelectTab(x.id)}
          >
            <Typography
              className={styles.headerText}
              style={{
                color: tabText[selectedTab == x.id],
              }}
            >
              {x.id == "assign" ? (x.value ? x.value : x.label) : `${x.label}`}
              {x.id == "assign" ? null : (
                <span className={styles.headerValText}> {x.value}</span>
              )}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default DiningDetailHeaderTab;
