import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props1, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props1} />;
});

const AlertMsg = (props) => {
  let { msgAlert } = props;
  return (
    <Snackbar
      open={msgAlert.open}
      autoHideDuration={6000}
      onClose={props.onCloseAlertMsg}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert
        onClose={props.onCloseAlertMsg}
        severity={msgAlert.msgType}
        sx={{
          width: "100%",
          height: "fit-content !important",
          "&.MuiPaper-root": {
            backgroundColor:
              msgAlert.msgType == "error"
                ? "#d32f2f !important"
                : "#2e7d32 !important",
          },
        }}
      >
        {msgAlert.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMsg;
