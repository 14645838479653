import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import styles from "./GiftCardHistory.module.css";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import SearchInput from "../../components/SearchInput";
import GiftCardHistoryTable from "../../components/GiftCardHistoryTable";

import { THEME_MODE } from "../../constants/Theme";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import { FOOTER_XPADDING } from "../../constants";

let LISTS = [
  {
    _id: "1",
    name: "John Wick",
    email: "johnwick@gmail.com",
    date: "16-06-2023",
    amount: "10",
    status: "top_up",
  },
  {
    _id: "2",
    name: "John Wick",
    email: "johnwick@gmail.com",
    date: "16-06-2023",
    amount: "10",
    status: "redeemed",
  },
  {
    _id: "3",
    name: "John Wick",
    email: "johnwick@gmail.com",
    date: "16-06-2023",
    amount: "10",
    status: "top_up",
  },
  {
    _id: "4",
    name: "John Wick",
    email: "johnwick@gmail.com",
    date: "16-06-2023",
    amount: "10",
    status: "redeemed",
  },
  {
    _id: "5",
    name: "John Wick",
    email: "johnwick@gmail.com",
    date: "16-06-2023",
    amount: "10",
    status: "redeemed",
  },
];

let FILTER_LIST = [
  { _id: "all", name: "All" },
  { _id: "redeemed", name: "Redeemed" },
  { _id: "top_up", name: "Top up" },
];

const GiftCardHistory = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [historyList, setHistoryList] = useState(LISTS);
  const [statusFilter, setStatusFilter] = useState("all");
  const [searchText, setSearchText] = useState("");

  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  let statusFilterBg = { true: theme_mode.btnBg, false: theme_mode.bg9 };
  let statusFilterText = {
    true: theme_mode.btnText,
    false: theme_mode.text9,
  };

  return (
    <Container
      page={"gift_cards"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0}
    >
      {/*{isLoading ? (*/}
      {/*  <Box className={styles.loadingView}>*/}
      {/*    <Loader />*/}
      {/*  </Box>*/}
      {/*) : null}*/}
      <Grid
        container
        item
        xs={12}
        p={1.5}
        style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
      >
        <Grid container item xs={12}>
          <Grid item xs={4.5} className={styles.topLeftView}>
            <Box
              onClick={() => navigate("/gift_cards")}
              mr={2}
              sx={{ cursor: "pointer", lineHeight: "0.5" }}
            >
              <BackBtnIcon width={33} height={33} />
            </Box>
            <SearchInput
              value={searchText}
              setValue={setSearchText}
              placeholder={"Enter gift card number"}
              themeMode={props.themeMode}
              inputBg={theme_mode.search}
            />
          </Grid>
          <Grid item xs={7.5} className={styles.statusFilterMainView}>
            <Box
              className={styles.statusFilterListView}
              style={{ backgroundColor: statusFilterBg[false] }}
            >
              {FILTER_LIST.map((x, i) => {
                return (
                  <Box
                    key={i}
                    onClick={() => setStatusFilter(x._id)}
                    className={styles.statusFilterView}
                    style={{
                      backgroundColor: statusFilterBg[x._id == statusFilter],
                    }}
                  >
                    <Typography
                      className={styles.statusFilterText}
                      style={{ color: statusFilterText[x._id == statusFilter] }}
                    >
                      {x.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
        <Grid container item xs={12} mt={4}>
          <GiftCardHistoryTable
            themeMode={props.themeMode}
            itemList={historyList.filter(
              (x) => x.status == statusFilter || statusFilter == "all"
            )}
          />
        </Grid>
        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    drawerOpen: state.userData.drawerOpen,
    restaurantId: state.userData.restaurantId,
  };
};

export default connect(mapStateToProps, null)(GiftCardHistory);
