import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

import { connect } from "react-redux";

import { THEME_MODE } from "../constants/Theme";

const AlertUserRoleModal = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  return (
    <Modal
      style={styles.modelView}
      open={props.modelVisible}
      onClose={() => null}
    >
      <Grid item xs={12} sx={styles.mainView}>
        <Typography sx={styles.dataText}>No roles assigned to user.</Typography>
        <Typography sx={styles.dataText}>
          Please logout and login back to continue.
        </Typography>

        <Button
          type="button"
          sx={styles.logoutBtn}
          onClick={() => (window.location.href = "/logout/pin")}
        >
          LOG OUT
        </Button>
      </Grid>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(AlertUserRoleModal);

const styles1 = (Theme) => ({
  modelView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  mainView: {
    backgroundColor: Theme.card2,
    borderRadius: 3,
    "&:focus": {
      outline: "none",
    },
    overflow: "hidden",
    p: 5,
  },
  mainGrid: {},
  dataText: {
    color: Theme.text,
    fontFamily: "InterSemiBold",
    textAlign: "center",
    fontSize: 16,
  },
  logoutBtn: {
    backgroundColor: "transparent",
    border: `2px solid ${Theme.theme}`,
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.text,
    fontFamily: "InterSemiBold",
    fontSize: 15,
    borderRadius: "9px",
    height: 44,
    width: "100%",
    mt: 3,
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
});

