import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import styles from "./Report.module.css";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";

import {
  CURRENCY_SYMBOL,
  DELIVERY,
  DINEIN,
  NAVBAR_HEIGHT,
  PICKUP,
  SPLIT_PAYMENT_METHOD,
  TABLE,
} from "../../constants";
import { THEME_MODE } from "../../constants/Theme";
import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import FromToDatePicker from "../../components/FromToDatePicker";

let ORDER_TYPE = {};
ORDER_TYPE[DINEIN] = "Dine In";
ORDER_TYPE[DELIVERY] = "Delivery";
ORDER_TYPE[PICKUP] = "Pick Up";
ORDER_TYPE[TABLE] = "Table";

let INCOMES = {};
INCOMES[SPLIT_PAYMENT_METHOD["cash"]] = "Cash";
INCOMES[SPLIT_PAYMENT_METHOD["ecash"]] = "Ecash";
INCOMES[SPLIT_PAYMENT_METHOD["card"]] = "Card";
INCOMES[SPLIT_PAYMENT_METHOD["giftcard"]] = "Giftcard";
INCOMES[SPLIT_PAYMENT_METHOD["invoice"]] = "Invoice";

// Total Sales : Total sales of the restaurant
// Live Tables : Sales on all tables thats still active, where payment in not taken
// Discounts : Total discounts given on that time frame
// Department Sales : Sales breakdown based on eat in, delivery,
// Group Department Sales : Sales on each courses in the restaurant
// Item Sales : Display total count of all items sold on that time period
// User Sales : Sales carried out by each user
// Incomes : Breakdown of cash, card, gift card payments
// Income By terminal : Income received on each terminal of the restaurant
// Income by vouchers : Income by redeeming vouchers if any

let DATE_FORMAT = "YYYY-MM-DD";
let DATE_FORMAT_2 = "DD-MM-YYYY";

const Report = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(location.pathname.split("/").pop());
  const [reportList, setReportList] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [fromDate, setFromDate] = useState(moment().format(DATE_FORMAT));
  const [toDate, setToDate] = useState(moment().format(DATE_FORMAT));
  const [currencyVal, setCurrencyVal] = useState(
    props.restaurantDetail?.currecnyIcon
      ? CURRENCY_SYMBOL[props.restaurantDetail?.currecnyIcon]
      : ""
  );

  useEffect(() => {
    getReports();
  }, [fromDate, toDate]);

  const getReports = () => {
    setIsLoading(true);

    let from = moment(fromDate, DATE_FORMAT).format("YYYY-MM-DD");
    let to = moment(toDate, DATE_FORMAT).add(1, "days").format("YYYY-MM-DD");

    let filter =
      "pageSize=99999&pageNum=1&filter_orderStatus=" +
      '["placed_order", "preparing", "pending_payment", "completed"]';

    filter =
      filter + "&filter_dateRangeFrom=" + from + "&filter_dateRangeTo=" + to;

    Api.getOrders(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let orders = response.data.rows;

        let total_sales = 0;
        let total_sales_list = [];
        let live_tables = 0;
        let live_tables_list = [];
        let discounts = 0;
        let discounts_list = [];
        let department_sales = 0;
        let department_sales_list = [];
        let group_department_sales = 0;
        let group_department_sales_list = [];
        let item_sales = 0;
        let item_sales_list = [];
        let user_sales = 0;
        let user_sales_list = [];
        let incomes = 0;
        let incomes_list = [];
        let incomes_by_terminal = 0;
        let incomes_by_terminal_list = [];
        let incomes_by_vouchers = 0;
        let incomes_by_vouchers_list = [];

        let user_sales_list1 = [];
        let item_sales_list1 = [];
        let department_sales_list1 = [];
        let group_department_sales_list1 = [];
        let incomes_list1 = [];
        let incomes_by_terminal_list1 = [];

        orders.map((x) => {
          if (x?.totalAmount) {
            total_sales = total_sales + x.totalAmount;
          }
          if (x?.totalDiscount) {
            discounts = discounts + x.totalDiscount;
          }

          if (
            (x?.orderStatus == "placed_order" ||
              x?.orderStatus == "preparing" ||
              x?.orderStatus == "pending_payment") &&
            typeof x?._idRestaurantTable != "undefined" &&
            x?.orderType == DINEIN
          ) {
            live_tables = live_tables + x.totalAmount;
          }

          if (typeof x?._idRestaurantUser != "undefined") {
            user_sales_list1.push({
              _id: x._idRestaurantUser,
              label: x?.restaurantUserName ? x.restaurantUserName : "",
              value: x.totalAmount,
            });
            user_sales = user_sales + x.totalAmount;
          }

          if (typeof x?._idRestaurantDevice != "undefined") {
            incomes_by_terminal_list1.push({
              _id: x._idRestaurantDevice,
              label: x?.restaurantDeviceName ? x.restaurantDeviceName : "",
              value: x.totalAmount,
            });

            incomes_by_terminal = incomes_by_terminal + x.totalAmount;
          }

          if (typeof x?.orderType != "undefined") {
            department_sales_list1.push({
              _id: x.orderType,
              label: ORDER_TYPE[x.orderType],
              value: x.totalAmount,
            });
            department_sales = department_sales + x.totalAmount;
          }

          if (
            typeof x?.splitPayment != "undefined" ||
            typeof x?.singlePayment != "undefined"
          ) {
            if (x?.splitPayment?.length) {
              x.splitPayment.map((z) => {
                incomes_list1.push({
                  _id: z.paymentMethod,
                  label: INCOMES[z.paymentMethod],
                  value: z.paidAmount,
                });
                incomes = incomes + z.paidAmount;
              });
            } else if (x?.singlePayment) {
              let z = x?.singlePayment;
              incomes_list1.push({
                _id: z.paymentMethod,
                label: INCOMES[z.paymentMethod],
                value: z.paidAmount,
              });
              incomes = incomes + z.paidAmount;
            }
          }

          x.cart.map((z) => {
            let item_price = 0;
            z.modifiers.map((mod_list) => {
              let mod_list1 = mod_list;
              if (!Array.isArray(mod_list1)) {
                mod_list1 = JSON.parse(mod_list1);
              }
              mod_list1.map((mod) => {
                item_price =
                  item_price + (mod?.price?.value ? mod?.price?.value : 0);
              });
            });

            item_price =
              item_price +
              (z?.price?.value ? z?.price?.value : 0) *
                (z?.quantity ? z?.quantity : 1);

            item_sales_list1.push({
              _id: z?._idMenuItem ? z?._idMenuItem : "",
              label: z?.menuItemName ? z.menuItemName : "",
              value: item_price,
            });
            item_sales = item_sales + item_price;

            group_department_sales_list1.push({
              _id: z?._idRestaurantCourse ? z?._idRestaurantCourse : "",
              label: z?.restaurantCourseName ? z.restaurantCourseName : "",
              value: item_price,
            });
            group_department_sales = group_department_sales + item_price;
          });
        });

        user_sales_list = arrangeListItem(user_sales_list1);
        department_sales_list = arrangeListItem(department_sales_list1);
        group_department_sales_list = arrangeListItem(
          group_department_sales_list1
        );
        item_sales_list = arrangeListItem(item_sales_list1);
        incomes_list = arrangeListItem(incomes_list1);
        incomes_by_terminal_list = arrangeListItem(incomes_by_terminal_list1);

        let list = [
          {
            label: "Total Sales",
            type: "total_sales",
            value: total_sales,
            list: total_sales_list,
          },
          {
            label: "Live Tables",
            type: "live_sales",
            value: live_tables,
            list: live_tables_list,
          },
          {
            label: "Discounts",
            type: "discounts",
            value: discounts,
            list: discounts_list,
          },
          {
            label: "Department Sales",
            type: "department_sales",
            value: department_sales,
            list: department_sales_list,
          },

          {
            label: "Group Department Sales",
            type: "group_department_sales",
            value: group_department_sales,
            list: group_department_sales_list,
          },
          {
            label: "Item Sales",
            type: "item_sales",
            value: item_sales,
            list: item_sales_list,
          },
          {
            label: "User Sales",
            type: "user_sales",
            value: user_sales,
            list: user_sales_list,
          },
          {
            label: "Incomes",
            type: "incomes",
            value: incomes,
            list: incomes_list,
          },
          {
            label: "Income By terminal",
            type: "incomes_by_terminal",
            value: incomes_by_terminal,
            list: incomes_by_terminal_list,
          },
          {
            label: "Income by vouchers",
            type: "incomes_by_vouchers",
            value: incomes_by_vouchers,
            list: incomes_by_vouchers_list,
          },
        ];

        setReportList(list);
        setIsLoading(false);
      }
    });
  };

  const arrangeListItem = (list) => {
    let rslt_list = [];
    let rslt = list.reduce((c, v) => {
      c[v._id] = {
        label: v.label,
        value: (c?.[v._id]?.["value"] || 0) + v.value,
      };
      return c;
    }, {});

    for (let prop1 in rslt) {
      rslt_list.push({
        _id: prop1,
        label: rslt[prop1]["label"],
        value: rslt[prop1]["value"],
      });
    }

    rslt_list = rslt_list.sort(function (a, b) {
      return b.value - a.value;
    });

    return rslt_list;
  };

  const onViewMoreClick = (item) => {
    if (item.list.length) {
      setSelectedReport(item);
    }
  };

  const backToSalesReport = (item) => {
    setSelectedReport(null);
  };

  return (
    <Container page={"settings"} p={"8px"} showFooter={false}>
      <Grid
        container
        item
        xs={12}
        alignItems={"flex-start"}
        style={{
          backgroundColor: theme_mode.card3,
          borderRadius: 10,
          minHeight: window.innerHeight - NAVBAR_HEIGHT - 8 * 2,
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Grid container item xs={12}>
            <Grid item xs={12} p={2}>
              <Grid container item xs={12} justifyContent={"space-between"}>
                <Box display={"flex"} alignItems={"center"}>
                  <Box
                    mr={2}
                    onClick={() =>
                      selectedReport
                        ? backToSalesReport()
                        : navigate("/sales_report")
                    }
                    className={styles.backBtn}
                  >
                    <BackBtnIcon width={33} height={33} />
                  </Box>
                  <Typography mr={3} className={styles.pageTitle}>{`${
                    page == "x_report" ? "X" : "Z"
                  } Report`}</Typography>
                  {page == "x_report" ? null : selectedReport ? null : (
                    <Box>
                      <FromToDatePicker
                        themeMode={props.themeMode}
                        format={DATE_FORMAT}
                        inputFormat={DATE_FORMAT_2}
                        fromDate={moment(fromDate, DATE_FORMAT).format(
                          DATE_FORMAT
                        )}
                        toDate={moment(toDate, DATE_FORMAT).format(DATE_FORMAT)}
                        setFromDate={setFromDate}
                        setToDate={setToDate}
                        error={null}
                        showReset={false}
                        showIcon={false}
                        backgroundColor={theme_mode.black}
                        borderRadius={"4px"}
                        border={`1.5px solid ${theme_mode.border7}`}
                        inputFontSize={13}
                        inputWidth={"130px"}
                        textAlign={"center"}
                        marginRight={"0px"}
                      />
                    </Box>
                  )}
                </Box>
                {selectedReport ? null : (
                  <Box
                    ml={2}
                    className={styles.addBtnView}
                    sx={{
                      backgroundColor: theme_mode.black,
                      border: `1.5px solid ${theme_mode.border7}`,
                    }}
                    onClick={() => null}
                    px={6}
                    py={1}
                  >
                    <Typography
                      className={styles.addBtnText}
                      sx={{ color: theme_mode.btnText }}
                    >
                      Print
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid container item xs={12} mt={2}>
                <Typography className={styles.dateText}>
                  {moment(fromDate, DATE_FORMAT).format(DATE_FORMAT_2)} -{" "}
                  {moment(toDate, DATE_FORMAT).format(DATE_FORMAT_2)}
                </Typography>
              </Grid>
            </Grid>
            {selectedReport ? (
              <Grid item xs={10} py={1} px={2}>
                <Box
                  px={2}
                  pt={2}
                  pb={1}
                  className={styles.contentReportView}
                  sx={{ backgroundColor: theme_mode.settingsCardBg }}
                >
                  <Typography mb={1.5} className={styles.reportTitleText}>
                    Sales
                  </Typography>
                  {selectedReport.list.map((y, i) => {
                    return (
                      <Box key={i} mb={1.5} className={styles.reportView}>
                        <Box className={styles.reportDataView}>
                          <Typography
                            ml={0.5}
                            className={styles.reportLabelText}
                          >
                            {y.label}
                          </Typography>
                        </Box>
                        <Typography className={styles.reportValueText}>
                          {currencyVal}
                          {y.value.toFixed(2)}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            ) : (
              <Grid container item xs={12} px={1} pb={1}>
                {reportList.map((x, i) => {
                  return (
                    <Grid key={i} item xs={4} my={0.5}>
                      <Box
                        className={styles.cardView}
                        mx={0.5}
                        sx={{ backgroundColor: theme_mode.settingsCardBg }}
                      >
                        <Box px={2} py={1.5}>
                          <Typography className={styles.titleText}>
                            {x.label}
                          </Typography>
                          <Typography className={styles.totalPriceText}>
                            {currencyVal}
                            {Number(x.value).toFixed(2)}
                          </Typography>
                        </Box>

                        <Divider
                          sx={{
                            "&.MuiDivider-root": {
                              borderColor: theme_mode.card3,
                            },
                          }}
                        />
                        <Box px={2} py={1.5} className={styles.dataView}>
                          <Box className={styles.contentView}>
                            {x.list
                              .filter((x, idx) => idx < 2)
                              .map((y, i) => {
                                return (
                                  <Box
                                    key={i}
                                    mb={0.5}
                                    className={styles.listView}
                                  >
                                    <Box className={styles.listDataView}>
                                      <Box className={styles.bullet} />
                                      <Typography
                                        ml={0.5}
                                        className={styles.listLabelText}
                                      >
                                        {y.label}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      className={styles.listValueText}
                                    >
                                      {currencyVal}
                                      {y.value.toFixed(2)}
                                    </Typography>
                                  </Box>
                                );
                              })}
                          </Box>
                          <Typography
                            className={styles.viewMoreText}
                            onClick={() => onViewMoreClick(x)}
                          >
                            View More
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    restaurantDetail: state.userData.restaurantDetail,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(Report);
