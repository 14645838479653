import * as Api from "../api";

export const getListApi = async (authToken, restaurantId) => {
  let floorList = await getRestaurantFloors(authToken, restaurantId);
  let customerList = await getCustomerList(authToken, restaurantId);
  let categoryList = await getCategoryList(authToken, restaurantId);
  let menuList = await getMenuList(authToken, restaurantId);
  let courseList = await getCourseList(authToken, restaurantId);
  let userRolesList = await getUserRolesList(authToken, restaurantId);
  return {
    floorList: floorList,
    customerList: customerList,
    categoryList: categoryList,
    menuList: menuList,
    courseList: courseList,
    userRolesList: userRolesList,
  };
};

const getCategoryList = (authToken, restaurantId) => {
  let filter = "all";
  return Api.getCategoryList(restaurantId, filter, authToken).then(
    (response) => {
      if (response.success) {
        return response.data.rows.sort((a, b) => a?.order - b?.order);
      } else {
        console.log("msg", response.msg);
        return [];
      }
    }
  );
};

const getMenuList = (authToken, restaurantId) => {
  let filter = "?pageSize=300000&pageNum=1&orderBy=order&orderByDir=asc";
  return Api.getMenuList(restaurantId, filter, authToken).then((response) => {
    if (response.success) {
      let data = response.data.rows;
      return data;
    } else {
      console.log("msg", response.msg);
      return [];
    }
  });
};

const getCourseList = (authToken, restaurantId) => {
  let filter = "/all";
  return Api.getCourseList(restaurantId, filter, authToken).then((response) => {
    if (response.success) {
      return response.data.rows.sort((a, b) => a?.order - b?.order);
    } else {
      console.log("msg", response.msg);
      return [];
    }
  });
};

const getRestaurantFloors = (authToken, restaurantId) => {
  return Api.getRestaurantFloors(restaurantId, authToken).then((response) => {
    if (response.success) {
      let data = response.data.rows;
      let list = [];
      data.map((x) => {
        list.push({
          ...x,
          tableDetails: x.tableDetails.sort(function (a, b) {
            return a.name - b.name;
          }),
        });
      });
      return list;
    } else {
      console.log("msg", response.msg);
      return [];
    }
  });
};

const getCustomerList = (authToken, restaurantId) => {
  let filter = "?pageSize=500000&pageNum=1";
  return Api.getCustomerList(restaurantId, filter, authToken).then(
    async (response) => {
      if (response.success) {
        let data = response.data;
        if (data.rows.length) {
          let list = await getCustomerEvents(
            authToken,
            restaurantId,
            data.rows
          );
          return list;
        } else {
          return data.rows;
        }
      } else {
        console.log("msg", response.msg);
        return [];
      }
    }
  );
};

const getCustomerEvents = (authToken, restaurantId, customer_list) => {
  let filter = "?pageSize=3000000&pageNum=1";
  return Api.getCustomerEvents(restaurantId, filter, authToken).then(
    (response) => {
      if (response.success) {
        let data = response.data;
        let list = [];
        customer_list.map((x) => {
          list.push({
            ...x,
            events: data.rows.filter((y) => y._idRestaurantCustomer == x._id),
          });
        });
        return list;
      } else {
        console.log("msg", response.msg);
        return [];
      }
    }
  );
};

const getUserRolesList = (authToken, restaurantId) => {
  return Api.getUserRolesList(restaurantId, authToken).then((response) => {
    if (response.success) {
      let data = response.data.rows;
      return data;
    } else {
      console.log("msg", response.msg);
      return [];
    }
  });
};
