import React, { useEffect, useState } from "react";

import * as Api from "../api";

import { connect } from "react-redux";
import { updateFloorTableList } from "../redux/actions/userDataActions";

import Container from "./Container";
import AlertMsg from "./AlertMsg";
import FloorTableSetup from "./FloorTableSetup";

import { DINEIN } from "../constants";

const TableSetup = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [floorList, setFloorList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getRestaurantFloors();
  }, []);

  const getRestaurantFloors = () => {
    setIsLoading(true);
    Api.getRestaurantFloors(props.restaurantId).then((response) => {
      if (response.success) {
        if (response.data.rows.length) {
          let floors = response.data.rows;
          let list = [];
          floors.map((x) => {
            let tables = x.tableDetails.map((z) => {
              return {
                ...z,
                type: z.type == null ? "rectangle" : z.type,
                top: z.top == null ? 0 : Number(z.top),
                left: z.left == null ? 0 : Number(z.left),
                width: z.width == null ? 100 : Number(z.width),
                height: z.height == null ? 100 : Number(z.height),
                angle: z.angle == null ? 0 : Number(z.angle),
                cover: z.cover == null ? 0 : z.cover,
              };
            });
            let data1 = {
              ...x,
              tableDetails: tables.sort(function (a, b) {
                return a.name - b.name;
              }),
            };
            list.push(data1);
          });
          setFloorList(list);
          props.updateFloorTableList(JSON.parse(JSON.stringify(list)));
          if (list.length) {
            getOrderList();
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        setIsLoading(false);
      }
    });
  };

  const getOrderList = () => {
    setIsLoading(true);
    let filter =
      "pageSize=300000&pageNum=1&filter_orderStatus=" +
      '["placed_order", "preparing", "pending_payment"]';
    filter = filter + "&filter_orderType=[" + DINEIN + "]";

    Api.getOrders(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let orders = response.data.rows;
        setOrderList(orders);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <Container page={"settings"} showFooter={false} p={0}>
      <FloorTableSetup
        page={"Settings"}
        tableEditable={true}
        floorList={floorList}
        orderList={orderList}
        isLoading={isLoading}
      />
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    drawerOpen: state.userData.drawerOpen,
    floorTableList: state.userData.floorTableList,
    restaurantCourseList: state.userData.restaurantCourseList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFloorTableList: (data) => dispatch(updateFloorTableList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableSetup);
