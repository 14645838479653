import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

import "./../assets/css/custom.css";
import { connect } from "react-redux";
import { THEME_MODE } from "../constants/Theme";
import { IS_LARGE_SCREEN } from "../constants";

const SettingTextField = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const theme = createTheme({
    palette: {
      gray: {
        main: theme_mode.inputColor,
      },
    },
  });

  let bgColor =
    props?.editable != false
      ? props?.backgroundColor
        ? props.backgroundColor
        : theme_mode.inputBg
      : props?.disabledBg
      ? props.disabledBg
      : (props?.inputDisabledBg ? props?.inputDisabledBg : theme_mode.inputDisabledBg);
  return (
    <Box style={{ width: "100%" }}>
      {props?.label ? (
        <Box mb={0.8}>
          <Typography
            sx={[styles.labelText, props?.labelStyle ? props.labelStyle : null]}
          >
            {props.label}
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          ...styles.textInputView,
          pl: props?.leftView ? 1.5 : 0,
          pr: props?.rightView ? 1.5 : 0,
          backgroundColor: bgColor,
          border: props?.border ? props.border : "0px",
          borderRadius: props?.borderRadius ? props.borderRadius : "12px",
          width: props?.width ? props.width : "100%",
          ...(props?.textInputStyle ? props?.textInputStyle : null)
        }}
      >
        <ThemeProvider theme={theme}>
          <TextField
            required={props?.required ? true : false}
            disabled={!(props?.editable == false ? false : true)}
            fullWidth
            label={
              props?.placeholder && props.value == "" ? props?.placeholder : ""
            }
            type={props?.type ? props.type : "default"}
            color="gray"
            variant={props?.variant ? props?.variant : "standard"}
            value={props.value}
            onChange={(e) => props.setValue(e.target.value)}
            sx={{
              "& .MuiInputBase-root": {
                "&:hover": {
                  backgroundColor: bgColor,
                },
                "&.Mui-focused": {
                  backgroundColor: bgColor,
                },
                "&.Mui-disabled": {
                  backgroundColor: bgColor,
                },
                "&:before, :after, :hover:not(.Mui-disabled):before": {
                  borderBottom: 0,
                },
              },
              "& .MuiInputAdornment-positionStart": {
                margin: "0 !important",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: props?.disabledColor
                  ? `${props?.disabledColor} !important`
                  : theme_mode.inputDisabledText,
              },
              "& .MuiFilledInput-input": {
                paddingTop: "0px !important",
                paddingBottom: "0px !important",
                px: props?.px ? props.px : 1.5,
                textAlign: props?.textAlign ? props.textAlign : "left",
              },
              ...props?.textInputCss
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                ...styles.inputLabelStyle,
                color: props?.placeholderColor
                  ? props.placeholderColor
                  : theme_mode.inputLabel,
                mt: props?.marginTop
                  ? props.marginTop
                  : IS_LARGE_SCREEN
                  ? 2.4
                  : 0.8,
                "& .MuiFormLabel-asterisk": {
                  display: "none",
                },
              },
            }}
            InputProps={{
              autoComplete: "none",
              sx: {
                ...styles.textInputValue,
                backgroundColor: bgColor,
                height: props?.height
                  ? props.height
                  : IS_LARGE_SCREEN
                  ? 70
                  : 46,
                fontSize: props?.inputFontSize
                  ? props.inputFontSize
                  : IS_LARGE_SCREEN
                  ? 20
                  : 15,
                ...props?.inputTextStyle,
              },
              startAdornment: props?.leftView ? (
                <InputAdornment
                  position="start"
                  sx={{
                    path: {
                      fill: theme_mode.iconBg2,
                    },
                  }}
                >
                  {props.leftView}
                </InputAdornment>
              ) : null,
              endAdornment: props?.rightView ? (
                <InputAdornment
                  position="end"
                  sx={{
                    path: {
                      fill: theme_mode.iconBg2,
                    },
                  }}
                >
                  {props.rightView}
                </InputAdornment>
              ) : null,
            }}
          />
        </ThemeProvider>
      </Box>
      <Box>
        {props?.error ? (
          <Typography sx={styles.errorText}>{props.error}</Typography>
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(SettingTextField);

const styles1 = (Theme) => ({
  labelText: {
    color: Theme.inputLabelText,
    fontSize: 15,
    fontFamily: "InterSemiBold",
  },
  textInputView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "12px",
    overflow: "hidden",
  },
  inputLabelStyle: {
    fontSize: IS_LARGE_SCREEN ? 20 : 17,
    fontFamily: "InterMedium",
    px: 0,
    mt: 0.8,
  },
  textInputValue: {
    height: 46,
    color: Theme.inputVal,
    fontFamily: "InterSemiBold",
    px: 0,
  },
  errorText: {
    color: Theme.redText,
    fontSize: 10,
    fontFamily: "InterMedium",
  },
});
