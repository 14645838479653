import React, { forwardRef, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ButtonBase from "@mui/material/ButtonBase";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { useLocation, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import {
  updateRestaurantCategoryMenuList,
  updateRestaurantMenuList,
} from "../redux/actions/userDataActions";

import * as Api from "../api";

import MenuItem from "./MenuItem";
import MenuListItem from "./MenuListItem";
import AlertMsg from "./AlertMsg";
import ConfirmAlert from "./ConfirmAlert";
import Loader from "./Loader";

import { ReactComponent as RightArrow } from "../assets/images/right_arrow.svg";
import { ReactComponent as LeftDarkArrow } from "../assets/images/left_dark_arrow.svg";
import { ReactComponent as GridViewIcon } from "../assets/images/grid_view.svg";
import { ReactComponent as ListViewIcon } from "../assets/images/list_view.svg";

import { THEME_MODE } from "../constants/Theme";
import { CONTAINER_HEIGHT } from "../constants";

let menu_page_size = 30000;
let menu_list_height = window.innerHeight - 250;

const CategoryMenuList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const navigate = useNavigate();
  const { hash } = useLocation();

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [menuCategoryList, setMenuCategoryList] = useState([]);
  const [listType, setListType] = useState("grid");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [menuItemData, setMenuItemData] = useState(null);
  const [menuOrderLoading, setMenuOrderLoading] = useState(false);
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const menuScrollRef = useRef([]);

  const theme = createTheme({
    palette: {
      loading_color: {
        main: theme_mode.loader,
      },
    },
  });

  useEffect(() => {
    if (props?.selectedMenuItems?.length) {
      setSelectedMenuItems(props.selectedMenuItems);
    } else {
      setSelectedMenuItems([]);
    }
  }, [props?.selectedMenuItems]);

  useEffect(() => {
    if (menuCategoryList.length) {
      let category_id = hash.split("#").join("");
      if (category_id == "") {
        category_id = menuCategoryList[0]._id;
      }
      setCategory(category_id);
      menuScrollRef.current = menuCategoryList.map((_, i) =>
        document.getElementById("CategoryId" + i)
          ? { current: document.getElementById("CategoryId" + i) }
          : React.createRef()
      );

      if (menuScrollRef.current.length == menuCategoryList.length) {
        let menu_height =
          menuScrollRef.current[menuScrollRef.current.length - 1].current
            ?.clientHeight;
        if (menu_height < menu_list_height) {
          if (document.getElementById("additionalHeight")) {
            document.getElementById("additionalHeight").style.height = `${
              menu_list_height - menu_height
            }px`;
          }
        }
        if (document.getElementById("categoryName" + category_id)) {
          document.getElementById("categoryName" + category_id).style.position =
            "sticky";
        }
      }
      let cat_list = [...menuCategoryList];
      let find_index = cat_list.findIndex((x) => x._id == category_id);
      if (find_index >= 0) {
        let el = document.getElementById("mainViewId");
        if (el) {
          el.scrollTo(0, menuScrollRef.current[find_index]?.current?.offsetTop);
        }
      }
    }
  }, [menuCategoryList]);

  useEffect(() => {
    if (typeof props.categoryMenuList != "undefined") {
      setMenuCategoryList(props.categoryMenuList);
      setLoading(false);
    } else {
      if (typeof props.categoryList !== "undefined") {
        if (props.categoryList.length > 0) {
          getMenuList(props.categoryList);
        } else {
          setLoading(false);
        }
      } else {
        getCategoryList();
      }
    }
  }, [props.categoryMenuList]);

  const onSelectCategory = (data, index) => {
    setCategory(data._id);
    let cat_list = [...menuCategoryList];
    let find_index = cat_list.findIndex((x) => x._id == data._id);
    if (find_index >= 0) {
      menuCategoryList.map((x, i) => {
        if (x._id == data._id) {
          document.getElementById("categoryName" + x._id).style.position =
            "sticky";
        } else {
          document.getElementById("categoryName" + x._id).style.position =
            "relative";
        }
      });
      if (menuScrollRef.current[find_index]?.current) {
        let el = document.getElementById("mainViewId");
        if (el) {
          el.scrollTo(0, menuScrollRef.current[find_index]?.current?.offsetTop);
        }
      } else {
        menuScrollRef.current = menuCategoryList.map(
          (_, i) => menuScrollRef.current[i] ?? React.createRef()
        );
      }
    }
  };

  const selectListType = (type) => {
    setMenuOrderLoading(true);
    setListType(type);
    setTimeout(() => {
      let cat_list = [...menuCategoryList];
      let find_index = cat_list.findIndex((x) => x._id == category);
      if (find_index >= 0) {
        menuCategoryList.map((x, i) => {
          if (x._id == category) {
            document.getElementById("categoryName" + x._id).style.position =
              "sticky";
          } else {
            document.getElementById("categoryName" + x._id).style.position =
              "relative";
          }
        });
        let el = document.getElementById("mainViewId");
        if (el) {
          el.scrollTo(0, menuScrollRef.current[find_index]?.current?.offsetTop);
        }
      }
      setMenuOrderLoading(false);
    }, 500);
  };

  const getCategoryList = () => {
    let filter = "all";
    Api.getCategoryList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        const data = response.data.rows.sort((a, b) => a?.order - b?.order);
        if (data.length > 0) {
          getMenuList(data);
        } else {
          setLoading(false);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const getMenuList = (cat_list) => {
    let filter =
      "?pageSize=" + menu_page_size + "&pageNum=1&orderBy=order&orderByDir=asc";
    Api.getMenuList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        let list = [];
        cat_list.forEach((x) => {
          let cat_menu = {
            ...x,
            menu: data.filter((y) => y._idCategory == x._id),
          };
          list.push(cat_menu);
        });
        props.updateRestaurantCategoryMenuList({
          categoryList: cat_list,
          menuList: data,
        });
        list = list.filter((y) => y.menu.length > 0);
        setMenuCategoryList(list);
        if (props.page == "Menu") {
          props.onSetMenuList(data);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const editMenu = (item) => {
    navigate("/add_menu/" + item._id);
  };

  const deleteMenu = (item) => {
    setMenuItemData(item);
    setConfirmDelete(true);
  };

  const onCloseConfirmDelete = () => {
    setConfirmDelete(false);
    setMenuItemData(null);
  };

  const confirmDeleteMenu = () => {
    setBtnLoading(true);
    Api.deleteMenu(menuItemData._id, props.restaurantId).then((response) => {
      if (response.success) {
        let catList = [...menuCategoryList];
        let catFindIndex = catList.findIndex(
          (x) => x._id == menuItemData._idCategory
        );
        if (catFindIndex >= 0) {
          let itemIndex = catList[catFindIndex].menu.findIndex(
            (item) =>
              item._id === menuItemData._id &&
              item._idCategory == menuItemData._idCategory
          );
          if (itemIndex >= 0) {
            catList[catFindIndex].menu.splice(itemIndex, 1);
            setMenuCategoryList(catList);
          }
        }
        props.updateRestaurantMenuList(
          props.restaurantMenuList.filter((x) => x._id != menuItemData._id)
        );
        setConfirmDelete(false);
        setMenuItemData(null);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setBtnLoading(false);
    });
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onSortEnd = (oldIndex, newIndex, cat_id) => {
    let catList = [...menuCategoryList];
    let catFindIndex = catList.findIndex((x) => x._id == cat_id);
    if (catFindIndex >= 0) {
      let old_list = [...catList[catFindIndex].menu];
      let new_list = arrayMoveImmutable(
        catList[catFindIndex].menu,
        oldIndex,
        newIndex
      );
      let order_list = new_list.map((x, i) => {
        return { ...x, order: catList[catFindIndex].menu[i].order };
      });

      let data_list = order_list.map((x) => {
        return { order: x.order, _idMenuItem: x._id };
      });

      let data = JSON.stringify(data_list);

      catList[catFindIndex].menu = order_list;
      setMenuCategoryList(catList);
      setMenuOrderLoading(true);
      Api.menuRearrange(data, props.restaurantId).then((response) => {
        if (response.success) {
          let list1 = [...props.restaurantMenuList];
          data_list.map((m) => {
            let index1 = list1.findIndex((z) => z._id == m._idMenuItem);
            if (index1 >= 0) {
              list1[index1]["order"] = m.order;
            }
          });
          props.updateRestaurantMenuList(
            list1.sort(function (a, b) {
              return a.order - b.order;
            })
          );
        } else {
          catList[catFindIndex].menu = old_list;
          setMenuCategoryList(catList);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setMenuOrderLoading(false);
      });
    }
  };

  const handleMainScroll = (e) => {
    if (e.target.scrollTop) {
      let filter_cat = menuScrollRef.current.filter(
        (x) =>
          x.current?.offsetTop + x.current?.clientHeight - 10 >=
          e.target.scrollTop
      );
      if (filter_cat.length > 0) {
        let cat_id = filter_cat[0].current?.attributes["cat-id"]["value"];
        if (category != cat_id) {
          menuCategoryList.map((x, i) => {
            if (x._id == cat_id) {
              document.getElementById("categoryName" + cat_id).style.position =
                "sticky";
            } else {
              document.getElementById("categoryName" + x._id).style.position =
                "relative";
            }
          });
          setCategory(cat_id);
        }
      }
    } else {
    }
  };

  useEffect(() => {
    if (selectedItem) {
      let list = [...selectedMenuItems];
      let index = list.findIndex((x) => x._id == selectedItem._id);
      if (index >= 0) {
        list = list.filter((x) => x._id != selectedItem._id);
      } else {
        list.push(selectedItem);
      }
      setSelectedItem(null);
      setSelectedMenuItems(list);
      props.onSelectMenu(list);
    }
  }, [selectedItem]);

  const onSelect = (item) => {
    if (props.page == "ArModelSelectMenu") {
      props.onSelectMenu(item);
    } else if (props.page == "AddModifierItemModal") {
      setSelectedItem(item);
    }
  };

  const MenuListView = React.useMemo(() => {
    return menuCategoryList.map((cat, index1) => {
      let filterMenu = cat.menu.filter(
        (x) =>
          x?.name?.toLowerCase()?.match(props.searchMenu.toLowerCase()) ||
          x?.description?.toLowerCase()?.match(props.searchMenu.toLowerCase())
      );
      if (filterMenu.length == 0) {
        return (
          <Grid
            key={index1}
            ref={menuScrollRef.current[index1]}
            id={"CategoryId" + index1}
            cat-id={cat._id}
          >
            <Box id={"categoryName" + cat._id} />
          </Grid>
        );
      }

      return (
        <Grid
          mb={2}
          key={index1}
          ref={menuScrollRef.current[index1]}
          id={"CategoryId" + index1}
          cat-id={cat._id}
        >
          <Typography
            sx={{
              ...styles.menuTitleText,
              backgroundColor: props?.headerBg ? props.headerBg : theme_mode.bg,
            }}
            id={"categoryName" + cat._id}
          >
            {cat.name}
          </Typography>
          <Grid mt={1.5}>
            {cat.menu.length ? (
              <Grid>
                {listType == "grid" ? (
                  <Grid container direction="row">
                    {filterMenu.map((item, index) => {
                      let selected = false;
                      if (props?.inititalSelectedItems?.length) {
                        let findIndex = props.inititalSelectedItems.findIndex(
                          (x) => x._id == item._id
                        );
                        if (findIndex >= 0) {
                          selected = true;
                        }
                      }
                      return (
                        <MenuItem
                          key={index}
                          firstItem={index % 4 === 0}
                          lastItem={(index + 1) % 4 === 0}
                          data={item}
                          selected={selected}
                          allowSelect={props.page == "AddModifierItemModal"}
                          showMenuPopup={props.showMenuPopup}
                          editMenu={editMenu}
                          deleteMenu={deleteMenu}
                          onSelect={onSelect}
                          themeMode={props.themeMode}
                        />
                      );
                    })}
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    style={{ position: "relative" }}
                  >
                    <SortableList
                      allowDrag={props.allowSort}
                      className="menu-order-list-view"
                      onSortEnd={(oldIndex, newIndex) =>
                        onSortEnd(oldIndex, newIndex, cat._id)
                      }
                      draggedItemClassName="menuListOrderDragged"
                    >
                      {cat.menu.map((item, index) => {
                        return (
                          <SortableItem key={item._id}>
                            <Box className="testing">
                              <MenuListItem
                                key={index}
                                data={item}
                                allowSort={props.allowSort}
                                showMenuPopup={props.showMenuPopup}
                                editMenu={editMenu}
                                deleteMenu={deleteMenu}
                                onSelect={onSelect}
                                themeMode={props.themeMode}
                              />
                            </Box>
                          </SortableItem>
                        );
                      })}
                    </SortableList>
                  </Grid>
                )}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      );
    });
  }, [
    menuCategoryList,
    listType,
    props.searchMenu,
    props?.inititalSelectedItems,
  ]);

  const TabScrollBtn = forwardRef((props, ref) => {
    const { direction, disabled, ...other } = props;

    return (
      <ButtonBase
        component="div"
        ref={ref}
        style={{
          opacity: 1,
          zIndex: 3,
          position: "absolute",
          right: direction === "right" ? 30 : 72,
        }}
        {...other}
      >
        {direction === "right" ? (
          <Grid
            className="menu-arrow-icon-grid"
            sx={{
              cursor: "pointer",
              backgroundColor: theme_mode.bg8,
              border: "1px solid " + theme_mode.border2,
              path: {
                stroke: theme_mode.iconBg2,
              },
            }}
          >
            <RightArrow />
          </Grid>
        ) : (
          <Grid
            className="menu-arrow-icon-grid"
            sx={{
              marginRight: 1,
              cursor: "pointer",
              backgroundColor: theme_mode.bg8,
              border: "1px solid " + theme_mode.border2,
              path: {
                stroke: theme_mode.iconBg2,
              },
            }}
          >
            <LeftDarkArrow />
          </Grid>
        )}
      </ButtonBase>
    );
  });

  return (
    <>
      {loading ? (
        <Loader height={{ height: CONTAINER_HEIGHT - 170 }} />
      ) : (
        <Grid mt={-1.5}>
          <Grid>
            {menuCategoryList.length > 0 ? (
              <Box
                sx={{
                  maxWidth: {
                    xs: window.innerWidth - 150,
                    sm: props?.tabWidth
                      ? props.tabWidth
                      : props.drawerOpen
                      ? window.innerWidth - 430
                      : window.innerWidth - 200,
                  },
                  mt: 1,
                }}
              >
                <Tabs
                  value={category}
                  variant="scrollable"
                  scrollButtons={true}
                  aria-label=""
                  orientation="horizontal"
                  sx={{
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                  allowScrollButtonsMobile
                  ScrollButtonComponent={(e) => <TabScrollBtn {...e} />}
                >
                  {menuCategoryList.map((item, index) => {
                    let filterMenu = item.menu.filter(
                      (x) =>
                        x?.name
                          ?.toLowerCase()
                          ?.match(props.searchMenu?.toLowerCase()) ||
                        x?.description
                          ?.toLowerCase()
                          ?.match(props.searchMenu?.toLowerCase())
                    );

                    if (filterMenu.length == 0) {
                      return null;
                    }
                    return (
                      <Tab
                        key={index}
                        value={item._id}
                        onClick={() => onSelectCategory(item, index)}
                        label={item.name}
                        sx={[
                          styles.categoryData,
                          category == item._id
                            ? styles.selectedCategory
                            : styles.nonSelectedCategory,
                        ]}
                      />
                    );
                  })}
                </Tabs>
              </Box>
            ) : (
              <Box style={styles.noCatView}>
                <Typography sx={styles.noCatText}>No Items</Typography>
              </Box>
            )}
          </Grid>

          <Grid sx={{ position: "relative" }}>
            {menuOrderLoading ? (
              <Grid
                container
                style={{
                  position: "absolute",
                  zIndex: 10,
                  backgroundColor: "rgba(0,0,0,0.05)",
                  height: "100%",
                }}
              >
                <Grid container justifyContent="center" alignItems="center">
                  <ThemeProvider theme={theme}>
                    <CircularProgress color="loading_color" />
                  </ThemeProvider>
                </Grid>
              </Grid>
            ) : null}
            {menuCategoryList.length > 0 ? (
              props.showGridIcon ? (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    top: 5,
                    right: 18,
                    zIndex: 2,
                  }}
                >
                  <Grid
                    className="menu-grid-list-view-grid"
                    sx={{
                      display: "flex",
                      backgroundColor: theme_mode.bg2,
                      border: "1px solid " + theme_mode.bg2,
                    }}
                  >
                    <Box
                      sx={{
                        path: {
                          fill:
                            listType == "grid" ? theme_mode.iconBg : "#8A8A8A",
                        },
                      }}
                    >
                      <GridViewIcon
                        height={14}
                        width={14}
                        className="menu-grid-view-icon"
                        onClick={() =>
                          listType == "grid" ? null : selectListType("grid")
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        path: {
                          stroke:
                            listType == "list" ? theme_mode.iconBg : "#8A8A8A",
                        },
                      }}
                    >
                      <ListViewIcon
                        height={14}
                        width={14}
                        className="cursor-pointer"
                        onClick={() =>
                          listType == "list" ? null : selectListType("list")
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : null
            ) : null}
            <Grid
              id="mainViewId"
              onScroll={handleMainScroll}
              sx={{
                maxHeight: menu_list_height,
                position: "relative",
                overflow: "auto",
                mt: -0.5,
              }}
            >
              {MenuListView}
              {menuCategoryList.length > 1 ? (
                <Box id={"additionalHeight"} />
              ) : null}
            </Grid>
          </Grid>

          <ConfirmAlert
            confirmDelete={confirmDelete}
            title={"Are you sure?"}
            content={"You will not be able to recover this item"}
            firstBtn={"No"}
            secondBtn={btnLoading ? "Please Wait" : "Yes ! Delete it"}
            btnLoading={btnLoading}
            onCloseConfirmDelete={onCloseConfirmDelete}
            onConfirmDelete={confirmDeleteMenu}
            themeMode={props.themeMode}
          />

          <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
        </Grid>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantCategoryMenuList: (data) =>
      dispatch(updateRestaurantCategoryMenuList(data)),
    updateRestaurantMenuList: (data) =>
      dispatch(updateRestaurantMenuList(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    drawerOpen: state.userData.drawerOpen,
    themeMode: state.userData.themeMode,
    restaurantMenuList: state.userData.restaurantMenuList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMenuList);

const styles1 = (Theme) => ({
  titleMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuText: {
    color: Theme.text,
    fontFamily: "InterBold",
  },
  titleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  mainDialog: {
    "& .MuiDialog-paper": {
      borderRadius: 3,
    },
  },
  searchListView: {
    position: "absolute",
    backgroundColor: Theme.card,
    width: "100%",
    maxHeight: 200,
    borderRadius: 2,
    overflow: "auto",
    my: 0.5,
    zIndex: 14,
  },
  confirmTitleText: {
    color: Theme.text,
    fontFamily: "InterBold",
    textAlign: "center",
  },
  confirmContentText: {
    color: Theme.text,
    fontFamily: "InterMedium",
    textAlign: "center",
  },
  confirmBtnDialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  btnText: {
    textTransform: "capitalize",
  },
  noBtnText: {
    color: Theme.text,
    "&:hover": {
      backgroundColor: "#fff !important",
    },
  },
  yesBtnText: {
    backgroundColor: "#FE724C",
    color: "#fff",
    borderRadius: 2,
    px: 2,
    "&:hover": {
      backgroundColor: "#FE724C !important",
    },
  },
  categoryData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "1px solid #000",
    fontFamily: "InterBold",
    fontSize: 13,
    textTransform: "capitalize",
    borderRadius: 4,
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: 2,
    marginTop: 1,
    marginBottom: 2,
    minWidth: "10%",
  },
  noCatView: {
    display: "flex",
    height: window.innerHeight - 250,
    alignItems: "center",
    justifyContent: "center",
  },
  noCatText: {
    color: Theme.text,
    textAlign: "center",
    fontFamily: "InterMedium",
  },
  selectedCategory: {
    backgroundColor: Theme.theme,
    borderColor: Theme.theme,
    "&.Mui-selected": {
      color: Theme.text,
    },
  },
  nonSelectedCategory: {
    backgroundColor: Theme.catMenuBg,
    borderColor: Theme.catBtnBorder,
    color: Theme.catBtnText,
  },
  menuTitleText: {
    color: Theme.text2,
    fontFamily: "InterBold",
    fontSize: 18,
    zIndex: 1,
    top: -2,
    pt: 1,
    pb: 1,
  },
});
