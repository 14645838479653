import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { THEME_MODE } from "../constants/Theme";
import { CURRENCY_SYMBOL } from "../constants";

import styles from "./DiningDetailCartItem.module.css";
import { getCartItemsTotalPrice } from "../constants/CommonFunc";

const DiningDetailCartItem = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  let item = props.item;

  let mod_name = {};
  let modifiers = [];
  const totalPrice = getCartItemsTotalPrice(item, [])?.toFixed(2) ?? 0;
  const currency = CURRENCY_SYMBOL[item.price?.currency];

  item?.cartModifier?.forEach((item1, i1) => {
    let mod = [];
    item1.forEach((y1, i2) => {
      mod_name[y1.menuData._id] = y1.menuData.name;
      mod.push(y1.menuData.name);
    });
    if (mod.length) {
      modifiers.push(mod);
    }
  });

  let counts = {};
  if (item?.cartModifier?.length) {
    let arr = item.cartModifier.reduce((a, b) => a.concat(b), []);
    arr.forEach(
      (x) => (counts[x.menuData._id] = (counts[x.menuData._id] || 0) + 1)
    );
  }

  return (
    <Box
      id={"cartItemId" + item._idCategory + item._id}
      pt={2}
      py={1.5}
      px={1}
      style={{
        // borderBottom: "1px solid " + theme_mode.border9,
        backgroundColor: props.selected ? theme_mode.theme : "transparent",
        // : theme_mode.diningDetailBg,
      }}
      className={styles.mainView}
      onClick={() => props.onSelectMenu(item)}
    >
      <Box className={styles.cartItemDataView}>
        <Box sx={{ flex: 1, display: "flex" }}>
          {props?.placedOrder ? (
            <Box>
              <Box mr={0.5} className={styles.cartPlacedOrder}></Box>
            </Box>
          ) : null}
          <Typography
            className={styles.cartItemNameText}
            style={{ color: theme_mode.text2 }}
          >
            <span className={styles.cartItemQtyText}>{item.quantity}x</span>{" "}
            {item.name}
          </Typography>
        </Box>
        <Box className={styles.cartItemBtnPriceView} pl={1}>
          <Box className={styles.cartItemPriceTextView} ml={1.5}>
            <Typography
              className={styles.cartItemPriceText}
              style={{ color: theme_mode.cardText }}
            >
              {currency} {totalPrice}
            </Typography>
          </Box>
        </Box>
      </Box>
      {modifiers.length || item?.notes ? (
        <Box
          px={1}
          pb={0.5}
          sx={{ backgroundColor: theme_mode.diningCartCourseTitle }}
        >
          {Object.keys(counts).map((key, index) => {
            return (
              <Typography
                key={index}
                mt={1}
                className={styles.cartItemModText}
                style={{ color: theme_mode.diningCartNoteText }}
              >
                --{counts[key]} {mod_name[key]}
              </Typography>
            );
          })}

          {item?.notes ? (
            <Typography
              mt={1}
              className={styles.cartItemNoteText}
              style={{ color: theme_mode.diningCartNoteText }}
            >
              {item.notes}
            </Typography>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default DiningDetailCartItem;
