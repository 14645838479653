import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import styles from "./FloorManagement.module.css";

import { connect } from "react-redux";
import { updateFloorTableList } from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import AddListFloor from "../../components/AddListFloor";
import AlertPopUp from "../../components/AlertPopUp";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";

import { THEME_MODE } from "../../constants/Theme";
import { CONTAINER_HEIGHT, DINEIN, FOOTER_XPADDING } from "../../constants";

const FloorManagement = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [floorList, setFloorList] = useState([]);
  const [alertPopUpVisible, setAlertPopUpVisible] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getRestaurantFloors();
  }, []);

  const getRestaurantFloors = () => {
    setLoading(true);
    Api.getRestaurantFloors(props.restaurantId).then((response) => {
      if (response.success) {
        if (response.data.rows.length) {
          let data = response.data.rows;
          setFloorList(data);

          let list = [];
          data.map((x) => {
            list.push({
              ...x,
              tableDetails: x.tableDetails.sort(function (a, b) {
                return a.name - b.name;
              }),
            });
          });
          props.updateFloorTableList(list);
        }
        setLoading(false);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const onAddFloor = () => {
    let list = [...floorList, { _id: Date.now() + Math.random(), type: "add" }];
    setFloorList(list);
  };

  const onEditFloor = (item) => {
    let list = [...floorList];
    let findIndex = list.findIndex((x) => x._id == item._id);
    if (findIndex >= 0) {
      list[findIndex] = { ...item, type: "edit" };
    }
    setFloorList(list);
  };

  function numberRange(start, end) {
    return new Array(parseInt(end) + 1 - start)
      .fill()
      .map((d, i) => i + parseInt(start));
  }

  const onSaveFloor = (item) => {
    if (item?.type == "add") {
      createFloor(item);
    } else {
      updateFloorTable(item);
    }
  };

  const createFloor = (item) => {
    setLoading(true);
    let data1 = {
      name: item.name,
      fromNumber: parseInt(item.from),
      toNumber: parseInt(item.to),
      isTakeaway: item.isTakeaway,
    };
    let datas = JSON.stringify(data1);
    Api.createRestaurantFloors(datas, props.restaurantId, null).then(
      (response) => {
        if (response.success) {
          let floorData = response.data;
          let _idRestaurantFloors = floorData._id;
          let tables = [];
          let range = numberRange(item.from, item.to);
          range.map((x) => {
            tables.push({
              _idRestaurant: props.restaurantId,
              _idRestaurantFloors: _idRestaurantFloors,
              name: x,
            });
          });

          let data2 = JSON.stringify(tables);
          Api.createRestaurantTables(data2).then((response1) => {
            if (response1.success) {
              let list = [...floorList.filter((x) => x?.type != "add")];
              let tableData = response1.data.rows;
              floorData = {
                ...floorData,
                tableDetails: tableData,
              };
              list.push(floorData);
              props.updateFloorTableList([...props.floorTableList, floorData]);
              setFloorList(list);
              setLoading(false);
            } else {
              setLoading(false);
              setMsgAlert({
                open: true,
                message: response1.msg,
                msgType: "error",
              });
            }
          });
        } else {
          setLoading(false);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      }
    );
  };

  const updateFloorTable = async (item) => {
    setLoading(true);
    let floor_data = { ...item };

    let props_floor_list = [...props.floorTableList];
    let props_floor_index = props_floor_list.findIndex(
      (x) => x._id == item._id
    );
    let floor_list = [...floorList];
    let floor_index = floor_list.findIndex((x) => x._id == item._id);

    let tables = [];
    let tables_nos = [];
    let range = numberRange(item.from, item.to);
    range.map((x) => {
      tables_nos.push(x);
      tables.push({
        _idRestaurant: props.restaurantId,
        _idRestaurantFloors: item._id,
        name: x,
      });
    });

    let tableDetails = item.tableDetails.map((x) => parseInt(x.name));

    let new_added_tables = [];
    let deleted_tables = [];
    let exist_tables = [];

    tables.map((x) => {
      if (!tableDetails.includes(x.name)) {
        new_added_tables.push(x);
      }
    });

    item.tableDetails.map((x) => {
      if (!tables_nos.includes(parseInt(x.name))) {
        deleted_tables.push(x);
      } else {
        exist_tables.push(x);
      }
    });

    if (deleted_tables.length == 0) {
    } else {
      let tableList = deleted_tables.map((x) => x._id);

      let filter =
        "pageSize=30000&pageNum=1&filter_orderStatus=" +
        '["placed_order", "preparing", "pending_payment"]';
      filter = filter + "&filter_orderType=[" + DINEIN + "]";
      let response = await Api.getOrders(props.restaurantId, filter);

      if (response.success) {
        let orders = response.data.rows;
        let filterOrder = orders.filter((x) =>
          (x._idRestaurantTable?.length ? x._idRestaurantTable : []).some((t) =>
            tableList.includes(t)
          )
        );
        if (filterOrder.length == 0) {
        } else {
          setAlertPopUpVisible(true);
          setLoading(false);
          return;
        }
      } else {
        setLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        return;
      }
    }

    let data1 = {
      name: item.name,
      fromNumber: parseInt(item.from),
      toNumber: parseInt(item.to),
      isTakeaway: item.isTakeaway
    };

    floor_data = {
      ...floor_data,
      ...data1,
    };
    let datas = JSON.stringify(data1);
    Api.createRestaurantFloors(datas, props.restaurantId, item._id).then(
      (response) => {
        if (response.success) {
          if (deleted_tables.length == 0) {
            if (new_added_tables.length) {
              addFloorTables(floor_data, new_added_tables);
              return;
            } else {
              if (props_floor_index >= 0) {
                props_floor_list[props_floor_index] = {
                  ...props_floor_list[props_floor_index],
                  ...floor_data,
                };
                props.updateFloorTableList(props_floor_list);
              }
              if (floor_index >= 0) {
                floor_list[floor_index] = {
                  ...floor_list[floor_index],
                  ...floor_data,
                  type: "",
                };
                setFloorList(floor_list);
              }
              setLoading(false);
              setMsgAlert({
                open: true,
                message: "Updated Successfully",
                msgType: "success",
              });
              return;
            }
          }

          let data = JSON.stringify({ tableIds: deleted_tables.map((x) => x._id) });
          Api.deleteRestaurantTables(data).then((response) => {
            if (response.success) {
              floor_data = {
                ...floor_data,
                tableDetails: exist_tables.sort(function (a, b) {
                  return a.name - b.name;
                }),
              };

              if (new_added_tables.length) {
                addFloorTables(floor_data, new_added_tables);
              } else {
                if (props_floor_index >= 0) {
                  props_floor_list[props_floor_index] = {
                    ...props_floor_list[props_floor_index],
                    ...floor_data,
                  };
                  props.updateFloorTableList(props_floor_list);
                }
                if (floor_index >= 0) {
                  floor_list[floor_index] = {
                    ...floor_list[floor_index],
                    ...floor_data,
                    type: "",
                  };
                  setFloorList(floor_list);
                }
                setMsgAlert({
                  open: true,
                  message: "Updated Successfully",
                  msgType: "success",
                });
                setLoading(false);
              }
            } else {
              setLoading(false);
              setMsgAlert({
                open: true,
                message: response.msg,
                msgType: "error",
              });
            }
          });
        } else {
          setLoading(false);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      }
    );
  };

  const addFloorTables = (floor_data, tables) => {
    let data2 = JSON.stringify(tables);
    Api.createRestaurantTables(data2).then((response1) => {
      if (response1.success) {
        let tableData = response1.data.rows;
        floor_data = {
          ...floor_data,
          tableDetails: [...floor_data["tableDetails"], ...tableData].sort(
            function (a, b) {
              return a.name - b.name;
            }
          ),
        };

        let props_floor_list = [...props.floorTableList];
        let props_floor_index = props_floor_list.findIndex(
          (x) => x._id == floor_data._id
        );
        let floor_list = [...floorList];
        let floor_index = floor_list.findIndex((x) => x._id == floor_data._id);

        if (props_floor_index >= 0) {
          props_floor_list[props_floor_index] = {
            ...props_floor_list[props_floor_index],
            ...floor_data,
          };
          props.updateFloorTableList(props_floor_list);
        }
        if (floor_index >= 0) {
          floor_list[floor_index] = {
            ...floor_list[floor_index],
            ...floor_data,
            type: "",
          };
          setFloorList(floor_list);
        }
        setLoading(false);
        setMsgAlert({
          open: true,
          message: "Updated Successfully",
          msgType: "success",
        });
      } else {
        setLoading(false);
        setMsgAlert({
          open: true,
          message: response1.msg,
          msgType: "error",
        });
      }
    });
  };

  const onDeletedFloor = (item) => {
    setLoading(true);
    let tableList = item.tableDetails
      .filter((y) => y._idRestaurantFloors == item._id)
      .map((x) => x._id);

    let filter =
      "pageSize=30000&pageNum=1&filter_orderStatus=" +
      '["placed_order", "preparing", "pending_payment"]';
    filter = filter + "&filter_orderType=[" + DINEIN + "]";
    Api.getOrders(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let orders = response.data.rows;
        let filterOrder = orders.filter((x) =>
          (x._idRestaurantTable?.length ? x._idRestaurantTable : []).some((t) =>
            tableList.includes(t)
          )
        );

        if (filterOrder.length == 0) {
          let data = JSON.stringify({ tableIds: tableList });
          Api.deleteRestaurantTables(data).then((response) => {
            if (response.success) {
              Api.deleteRestaurantFloor(item._id, props.restaurantId).then(
                (response1) => {
                  if (response1.success) {
                    props.updateFloorTableList(
                      props.floorTableList.filter((x) => x._id != item._id)
                    );
                    setFloorList([
                      ...floorList.filter((x) => x._id != item._id),
                    ]);
                  } else {
                    setMsgAlert({
                      open: true,
                      message: response1.msg,
                      msgType: "error",
                    });
                  }
                  setLoading(false);
                }
              );
            } else {
              setLoading(false);
              setMsgAlert({
                open: true,
                message: response.msg,
                msgType: "error",
              });
            }
          });
        } else {
          setAlertPopUpVisible(true);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  };

  const onCancelFloor = (item) => {
    const list = [...floorList];
    const findIndex = list.findIndex((x) => x._id == item._id);
    if (findIndex >= 0) {
      list[findIndex] = { ...list[findIndex], type: "" };
    }
    setFloorList(list);
  }

  return (
    <Container
      page={"settings"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0.5}
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid
          container
          direction={"column"}
          item
          xs={12}
          mt={1}
          id={"floorMgmtDivId"}
          p={0.5}
          style={{
            backgroundColor: theme_mode.card3,
            borderRadius: 15,
            minHeight: CONTAINER_HEIGHT - 80,
          }}
        >
          <Grid item xs={12} className={styles.headerView} px={1} mb={2}>
            <Box
              onClick={() => navigate("/settings")}
              className={styles.backBtn}
            >
              <BackBtnIcon width={33} height={33} />
            </Box>
            <Box
              className={styles.btnView}
              onClick={onAddFloor}
              sx={{
                backgroundColor: theme_mode.black,
                border: `1.5px solid ${theme_mode.border7}`,
              }}
            >
              <Typography className={styles.btnText}>Add Floor</Typography>
            </Box>
          </Grid>
          <Grid item md={12} className={styles.floorListView}>
            {floorList.map((item, index) => {
              return (
                <Grid key={index} item md={4} px={1}>
                  <AddListFloor
                    item={item}
                    themeMode={props.themeMode}
                    onDelete={onDeletedFloor}
                    onEdit={onEditFloor}
                    onSave={onSaveFloor}
                    onCancel={onCancelFloor}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
      <AlertPopUp
        modalVisible={alertPopUpVisible}
        msg={"Tables have active orders"}
        themeMode={props.themeMode}
        onClick={() => setAlertPopUpVisible(false)}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantId: state.userData.restaurantId,
    restaurantDetail: state.userData.restaurantDetail,
    floorTableList: state.userData.floorTableList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFloorTableList: (data) => dispatch(updateFloorTableList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorManagement);
