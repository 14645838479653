import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import MenuStyleModal from "../../components/MenuStyleModal";
import SubscriptionFeatureList from "../../components/SubscriptionFeatureList";
import AlertMsg from "../../components/AlertMsg";

import { CONTAINER_HEIGHT, SUBSCRIPTION_IDS } from "../../constants";
import { THEME_MODE } from "../../constants/Theme";

let SubscriptionData = {};

SubscriptionData[`${SUBSCRIPTION_IDS["starter"]}`] = {
  bgImg: require("../../assets/images/plan_starter.png"),
};

SubscriptionData[`${SUBSCRIPTION_IDS["plus"]}`] = {
  bgImg: require("../../assets/images/plan_plus.png"),
};

SubscriptionData[`${SUBSCRIPTION_IDS["premium"]}`] = {
  bgImg: require("../../assets/images/plan_premium.png"),
};

// let MenuDisplayList = [require('../../assets/images/menu_style1.png'), require('../../assets/images/menu_style2.png')];

const Subscriptions = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [currentPlan, setCurrentPlan] = useState("");
  const [activePlan, setActivePlan] = useState("");
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [menuStyle, setMenuStyle] = useState(0);
  const [menuDisplayList, setMenuDisplayList] = useState([]);
  const [menuStyleModalVisible, setMenuStyleModalVisible] = useState(false);
  const [planFeatureList, setPlanFeatureList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getSubscriptionsList();
  }, []);

  const getSubscriptionsList = () => {
    setLoading(true);
    Api.getSubscriptions().then((response) => {
      if (response.success) {
        let data = response.data;
        if (data.rows.length) {
          getSubscriptionFeaturesList(data.rows);
        } else {
          setLoading(false);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        setLoading(false);
      }
    });
  };

  const getSubscriptionFeaturesList = (subs) => {
    setLoading(true);
    Api.getSubscriptionFeatures().then((response) => {
      if (response.success) {
        let data = response.data;
        if (data.rows.length) {
          let list = [];
          subs.map((x, i) => {
            let subData = {
              ...x,
              features: data.rows.filter((y) => y._idSubscriptions == x._id),
            };
            let index = subData.features.findIndex(
              (y) =>
                y?.addOnFeatures?.length &&
                y?.addOnFeatures?.[0]?.styleName != ""
            );
            if (index >= 0) {
              subData.features[index] = {
                ...subData.features[index],
                active: true,
              };
            }
            list.push(subData);
          });
          setSubscriptionList(list);
          setPlanFeatureList(list[0]["features"]);
        } else {
          setSubscriptionList(subs);
        }
        setCurrentPlan(subs[0]["_id"]);
        setActivePlan(subs[0]);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onActiveFeature = (data, active) => {
    let list = [...planFeatureList];
    let index = list.findIndex((x) => x._id == x._id);
    if (index >= 0) {
      list[index]["active"] = active;
      setPlanFeatureList(list);
    }
  };

  const onUpgradePlan = () => {
    let list = [...subscriptionList];
    let findIndex = list.findIndex((x) => x._id == activePlan._id);

    if (findIndex >= 0) {
      let nextIndex = findIndex + 1;
      if (nextIndex < subscriptionList.length && nextIndex >= 0) {
        let nextData = subscriptionList[nextIndex];
        setMenuStyle(0);
        setActivePlan(nextData);
        let features = [];
        if (nextData?.features?.length) {
          nextData?.features.map((x) => {
            features.push({ ...x, active: false });
          });
        }
        setPlanFeatureList(features);
        let sectionRight = document.querySelector("#PlanListViewId");
        sectionRight.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  const onToggleMenuStyleModalVisible = (val) => {
    setMenuStyleModalVisible(val);
  };

  const openMenuStyleModalVisible = (item) => {
    setMenuDisplayList(item?.addOnFeatures);
    setMenuStyleModalVisible(true);
  };

  const SubscriptionFeature = React.useMemo(() => {
    return planFeatureList.map((x, i) => {
      return (
        <SubscriptionFeatureList
          key={i}
          item={x}
          themeMode={props.themeMode}
          menuStyle={
            menuDisplayList.length ? menuDisplayList[menuStyle]?.imageURL : ""
          }
          onActiveFeature={onActiveFeature}
          openMenuStyleModalVisible={openMenuStyleModalVisible}
        />
      );
    });
  }, [activePlan, menuStyle, menuDisplayList]);

  return (
    <Container
      page={"settings"}
      navTitle={"Subscriptions"}
      p={"0"}
      fpx={3}
      fpy={3}
      minHeight={CONTAINER_HEIGHT - 90}
      bgColor={theme_mode.card}
    >
      {loading ? (
        <Loader />
      ) : subscriptionList.length ? (
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          id="PlanListViewId"
        >
          {props.themeMode == "light" &&
          activePlan._id == SUBSCRIPTION_IDS["starter"] ? (
            <Box sx={styles.planLeftBg}>
              <img
                src={require("../../assets/images/plan_starter_bg.png")}
                style={styles.planLeftBgImg}
              />
            </Box>
          ) : null}

          {props.themeMode == "light" &&
          SubscriptionData[activePlan._id]?.bgImg ? (
            <Box sx={styles.planBg}>
              <img
                src={SubscriptionData[activePlan._id]?.bgImg}
                style={styles.planBgImg}
              />
            </Box>
          ) : null}

          <Grid
            mt={2}
            item
            xs={10}
            md={5}
            p={2}
            style={{
              zIndex: 3,
              backgroundColor:
                props.themeMode == "light" ? "transparent" : theme_mode.card2,
              borderRadius: 8,
            }}
          >
            <Box sx={styles.activePlanView}>
              {activePlan["_id"] == currentPlan ? (
                <Box sx={styles.activePlan}>ACTIVE PLAN</Box>
              ) : null}
            </Box>
            <Typography mt={2} sx={styles.planTitle}>
              {activePlan["name"]}
            </Typography>
            <Box sx={styles.planPriceView}>
              <Typography sx={styles.planPrice}>
                {activePlan["currency"]}
                {activePlan["price"]["value"]}
              </Typography>
              <Typography sx={styles.planDuration}>
                {activePlan["duration"]}
              </Typography>
            </Box>
            {planFeatureList.length ? (
              <>
                <Box mt={3}>
                  <Typography sx={styles.featureTitleText}>
                    Features:
                  </Typography>
                  <Box>{SubscriptionFeature}</Box>
                </Box>
                <Box sx={styles.descView}>
                  <Typography sx={styles.descText}>
                    Tap on the features to{" "}
                  </Typography>
                  <Typography mx={0.2} sx={styles.descUpdateText}>
                    {" "}
                    Update/Unlock{" "}
                  </Typography>
                  <Typography sx={styles.descText}>the plan</Typography>
                </Box>
              </>
            ) : null}
            <Box>
              <Button
                type="button"
                sx={styles.upgradePlanBtn}
                onClick={onUpgradePlan}
              >
                Upgrade Plan
              </Button>
            </Box>
            {/*<Box sx={styles.cancelSaveBtn}>*/}
            {/*  <Button*/}
            {/*    type='button'*/}
            {/*    sx={styles.saveBtn}*/}
            {/*    onClick={() => null}>*/}
            {/*    Save*/}
            {/*  </Button>*/}
            {/*  <Button*/}
            {/*    type='button'*/}
            {/*    sx={styles.cancelBtn}*/}
            {/*    onClick={() => null}>*/}
            {/*    Cancel*/}
            {/*  </Button>*/}
            {/*</Box>*/}
          </Grid>

          <MenuStyleModal
            themeMode={props.themeMode}
            modelVisible={menuStyleModalVisible}
            menuStyle={menuStyle}
            menuDisplayList={menuDisplayList}
            onSubmit={setMenuStyle}
            onToggle={onToggleMenuStyleModalVisible}
          />
        </Grid>
      ) : null}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(Subscriptions);

const styles1 = (Theme) => ({
  planBg: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  planBgImg: {
    height: "100%",
    width: "100%",
  },
  planLeftBg: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 3,
  },
  planLeftBgImg: {
    height: "100%",
    width: window.innerWidth / 3,
  },
  activePlan: {
    backgroundColor: Theme.activePlanBg,
    cursor: "pointer",
    textAlign: "center",
    fontFamily: "InterSemiBold",
    color: Theme.lightText,
    fontSize: 10,
    borderRadius: "30px",
    width: 100,
    py: 0.8,
  },
  activePlanView: {
    minHeight: 30,
    mt: 2,
  },
  planTitle: {
    color: Theme.text2,
    fontSize: 30,
    fontFamily: "InterBold",
  },
  planPriceView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  planPrice: {
    color: Theme.text8,
    fontSize: 15,
    fontFamily: "InterSemiBold",
  },
  planDuration: {
    color: Theme.text8,
    fontSize: 10,
    fontFamily: "InterMedium",
    ml: 1,
  },
  featureTitleText: {
    color: Theme.text5,
    fontSize: 15,
    fontFamily: "InterBold",
  },
  descView: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 204, 0, 0.3)",
    borderRadius: "5px",
    px: 2,
    py: 0.8,
    mt: 2,
  },
  descText: {
    color: Theme.text5,
    fontSize: 12,
    fontFamily: "InterMedium",
  },
  descUpdateText: {
    color: Theme.text5,
    fontSize: 12,
    fontFamily: "InterSemiBold",
  },
  upgradePlanBtn: {
    backgroundColor: Theme.greenBg,
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.lightText,
    fontFamily: "InterSemiBold",
    fontSize: 14,
    borderRadius: "9px",
    height: 40,
    width: "100%",
    mt: 2,
    "&:hover": {
      backgroundColor: Theme.greenBg + " !important",
    },
  },
  cancelSaveBtn: {
    display: "flex",
    flexDirection: "row",
  },
  saveBtn: {
    backgroundColor: Theme.greenBg,
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.lightText,
    fontFamily: "InterSemiBold",
    fontSize: 14,
    borderRadius: "9px",
    height: 40,
    width: "100%",
    mr: 0.5,
    "&:hover": {
      backgroundColor: Theme.greenBg + " !important",
    },
  },
  cancelBtn: {
    backgroundColor: Theme.redBg,
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.lightText,
    fontFamily: "InterSemiBold",
    fontSize: 14,
    borderRadius: "9px",
    height: 40,
    width: "100%",
    ml: 0.5,
    "&:hover": {
      backgroundColor: Theme.redBg + " !important",
    },
  },
});
