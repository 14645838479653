import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import {ReactComponent as DeleteIcon} from "../assets/images/delete.svg";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {forwardRef} from "react";
import Slide from "@mui/material/Slide";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {DINING_MODAL_WIDTH} from "../constants";

import {ReactComponent as CancelOrderIcon} from "../assets/images/cancel_order.svg";
import {ReactComponent as CloseIcon} from "../assets/images/close_white.svg";
import {THEME_MODE} from "../constants/Theme";


const DiningConfirmAlert = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  return (
    <Modal
      style={{...styles.modelView, justifyContent: props.page == 'dining' ? 'flex-end' : 'center'}}
      open={!(props.data == null)}
      onClose={props.onClose}>
      <Box sx={{...styles.mainView, mr: props.page == 'dining' ? '10px' : 0}}>
        <Box sx={styles.subView}>
          <Box sx={styles.closeIconView} onClick={props.onClose}>
            <CloseIcon width={40} height={40}/>
          </Box>
          <CancelOrderIcon width={70} height={70}/>
          <Typography mt={1} sx={styles.sureText}>Are you sure ?</Typography>
          <Typography mt={1} sx={styles.sureDescText}>
            Are you sure you want to remove <Typography sx={styles.nameText}>{props.data?.name}</Typography>
          </Typography>

          <Box sx={styles.removeBtnView} mt={3} onClick={props.onDelete}>
            <Typography sx={styles.removeBtnText}>Remove</Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DiningConfirmAlert;

const styles1 = Theme => ({
  modelView: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  mainView: {
    width: DINING_MODAL_WIDTH,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "&:focus": {
      outline: 'none',
    },
  },
  subView: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: Theme.modal,
    width: {xs: '95%', md: '54%'},
    minHeight: window.innerHeight / 1.5,
    borderRadius: 3,
    "&:focus": {
      outline: 'none',
    },
    py: 2,
    px: 4,
  },
  closeIconView: {
    position: 'absolute',
    cursor: 'pointer',
    top: 7,
    right: 7,
  },
  sureText: {
    color: Theme.cardDescText3,
    fontFamily: 'InterBold',
    fontSize: 25,
    whiteSpace: 'nowrap',
  },
  sureDescText: {
    color: Theme.cardText,
    fontFamily: 'InterSemiBold',
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  nameText: {
    color: Theme.cardText,
    fontFamily: 'InterBold',
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'center',
    whiteSpace: 'break-spaces',
  },
  removeBtnView: {
    backgroundColor: Theme.btnBg,
    cursor: 'pointer',
    borderRadius: '7px',
    py: 1,
    px: 5,
  },
  removeBtnText: {
    color: Theme.btnText,
    fontFamily: 'InterBold',
    fontSize: 14,
  },
});
