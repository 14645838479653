import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { connect } from "react-redux";

import { THEME_MODE } from "../constants/Theme";
import { IS_LARGE_SCREEN } from "../constants";

const theme = (theme_mode, date, format) =>
  createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: theme_mode.card + " !important",
            border: "1px solid " + theme_mode.lightText,
            color: theme_mode.text,
            "& .MuiButtonBase-root, .MuiTypography-root": {
              color: theme_mode.text,
              fontFamily: "InterMedium !important",
            },
            "& .MuiClockPointer-root, .MuiClock-pin": {
              backgroundColor: theme_mode.theme + " !important",
            },
            "& .MuiClock-amButton": {
              backgroundColor:
                moment(date, format).format("A") == "AM"
                  ? theme_mode.theme
                  : "transparent",
            },
            "& .MuiClock-pmButton": {
              backgroundColor:
                moment(date, format).format("A") == "PM"
                  ? theme_mode.theme
                  : "transparent",
            },
            "& .MuiClock-amButton:hover, .MuiClock-pmButton:hover": {
              backgroundColor: theme_mode.inputDisabledBg + " !important",
            },
            "& .MuiClockPointer-thumb": {
              backgroundColor: theme_mode.theme + " !important",
              border: "16px solid " + theme_mode.theme + " !important",
            },
            "& .Mui-disabled": {
              color: theme_mode.inputDisabledBg,
            },
            "& .MuiPickersArrowSwitcher-root": {
              color: theme_mode.text,
            },
            "& .MuiClock-clock": {
              backgroundColor: theme_mode.card3 + " !important",
              color: theme_mode.text,
            },
            "& .MuiClockNumber-root": {
              color: theme_mode.text,
              fontFamily: "InterMedium !important",
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {
              color: theme_mode.iconBg2,
            },
          },
        },
      },
    },
  });

const SettingTimePicker = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  let bgColor = props.backgroundColor;

  const handlerOnClose = () => {
    setDatePickerOpen(false);
  };

  const handlerOnClick = () => {
    if (!props.disabled) {
      setDatePickerOpen(true);
    }
  };

  return (
    <Box style={{ width: "100%" }}>
      {props?.label ? (
        <Box mb={0.8}>
          <Typography
            sx={[
              {
                fontSize: IS_LARGE_SCREEN ? 17 : 12,
                fontFamily: "InterSemiBold",
                color: theme_mode.inputLabelText,
              },
              props?.labelStyle ? props.labelStyle : null,
            ]}
          >
            {props.label}
          </Typography>
        </Box>
      ) : null}
      <ThemeProvider theme={theme(theme_mode, props.value, props.inputFormat)}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            value={props.value}
            format={props.inputFormat}
            onChange={props.onChange}
            open={datePickerOpen}
            minTime={props?.minTime ?? null}
            onClose={handlerOnClose}
            disabled={props.disabled}
            slotProps={{
              actionBar: {
                sx: {
                  "& .MuiButton-root": {
                    color: "#fff",
                    fontFamily: "InterSemiBold",
                  },
                },
              },
              digitalClockSectionItem: {
                sx: {
                  fontSize: 13,
                  fontFamily: "InterSemiBold",
                  "&.Mui-selected": {
                    backgroundColor: "#DD5903",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#DD5903",
                      color: "#fff",
                    },
                  },
                },
              },
              textField: {
                variant: "filled",
                onClick: handlerOnClick,
                sx: {
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    height: IS_LARGE_SCREEN ? 70 : 46,
                    fontFamily: "InterSemiBold",
                    fontSize: IS_LARGE_SCREEN ? 17 : 12,
                    py: 1,
                    pl: 1.5,
                    pr: IS_LARGE_SCREEN ? 2 : 1,
                    backgroundColor: `${bgColor} !important`,
                    "&:before, :after, :hover:not(.Mui-disabled):before": {
                      borderBottom: 0,
                    },
                  },
                  "& .MuiFilledInput-input": {
                    p: "0 !important",
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: props?.disabledColor
                      ? `${props?.disabledColor} !important`
                      : theme_mode.inputDisabledText,
                  },
                  "& .MuiFormLabel-root": {
                    display: "none !important",
                  },
                },
                InputLabelProps: {
                  shrink: true,
                  sx: {
                    mt: 0.8,
                    "& .MuiFormLabel-asterisk": {
                      display: "none",
                    },
                  },
                },
                inputProps: {
                  placeholder: props?.placeholder ?? "",
                  sx: {
                    color: theme_mode.inputVal,
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
      </ThemeProvider>
      <Box>
        {props?.error ? (
          <Typography
            sx={{
              color: theme_mode.redText,
              fontSize: IS_LARGE_SCREEN ? 14 : 10,
              fontFamily: "InterMedium",
            }}
          >
            {props.error}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(SettingTimePicker);
