import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { connect } from "react-redux";

import Container from "../../components/Container";
import useDebounce from "../../components/UseDebounce";
import SearchItem from "../../components/SearchItem";
import AlertMsg from "../../components/AlertMsg";
import CategoryMenuList from "../../components/CategoryMenuList";
import Loader from "../../components/Loader";
import SearchInput from "../../components/SearchInput";

import { THEME_MODE } from "../../constants/Theme";
import {FOOTER_XPADDING} from "../../constants";

const Menu = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [loading, setLoading] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [searchMenu, setSearchMenu] = useState("");
  const [searchMenuList, setSearchMenuList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const debouncedMenuName = useDebounce(searchMenu, 500);

  useEffect(() => {
    if (debouncedMenuName) {
      let data = menuList.filter((x) =>
        x.name.toLowerCase().match(searchMenu.toLowerCase())
      );
      setSearchMenuList(data);
    } else {
      setSearchMenuList([]);
    }
  }, [debouncedMenuName]);

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  return (
    <Container page={"menu"} p={1.5} fpx={FOOTER_XPADDING[props.themeMode]} fpy={0}>
      {loading ? (
        <Loader />
      ) : (
        <Grid
          p={1.5}
          style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
        >
          <Grid mt={props.themeMode == "light" ? -1.5 : 0}>
            <Grid container justifyContent="space-between" mb={2}>
              <Grid item xs={2} md={8}>
                <Box sx={styles.titleMainBox}>
                  <Typography variant={"h6"} sx={styles.menuText}>
                    Menu
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={8} md={4} className="search-menu-input">
                <SearchInput
                  value={searchMenu}
                  setValue={setSearchMenu}
                  placeholder={"Search menu item here"}
                  themeMode={props.themeMode}
                  inputBg={theme_mode.search}
                />
                {searchMenuList.length ? (
                  <Grid item xs={12} sx={styles.searchListView}>
                    {searchMenuList.map((x, i) => {
                      return (
                        <SearchItem
                          type="menu"
                          key={i}
                          data={x}
                          themeMode={props.themeMode}
                          lastIndex={searchMenuList.length - 1 == i ? 1 : 0}
                        />
                      );
                    })}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>

            <CategoryMenuList
              page={"Menu"}
              headerBg={theme_mode.card3}
              searchMenu={""}
              allowSort={true}
              showGridIcon={true}
              showMenuPopup={true}
              onSetMenuList={setMenuList}
            />
          </Grid>
          <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
        </Grid>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    drawerOpen: state.userData.drawerOpen,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(Menu);

const styles1 = (Theme) => ({
  titleMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuText: {
    color: Theme.text,
    fontFamily: "InterBold",
  },
  titleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  mainDialog: {
    "& .MuiDialog-paper": {
      borderRadius: 3,
    },
  },
  searchListView: {
    position: "absolute",
    backgroundColor: Theme.card,
    width: "100%",
    maxHeight: 200,
    borderRadius: 2,
    overflow: "auto",
    my: 0.5,
    zIndex: 14,
  },
  confirmTitleText: {
    color: Theme.text,
    fontFamily: "InterBold",
    textAlign: "center",
  },
  confirmContentText: {
    color: Theme.text,
    fontFamily: "InterMedium",
    textAlign: "center",
  },
  confirmBtnDialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  btnText: {
    textTransform: "capitalize",
  },
  noBtnText: {
    color: Theme.text,
    "&:hover": {
      backgroundColor: "#fff !important",
    },
  },
  yesBtnText: {
    backgroundColor: "#FE724C",
    color: "#fff",
    borderRadius: 2,
    px: 2,
    "&:hover": {
      backgroundColor: "#FE724C !important",
    },
  },
  categoryData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "1px solid #000",
    fontFamily: "InterBold",
    fontSize: 13,
    textTransform: "capitalize",
    borderRadius: 4,
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0,
    paddingBottom: 0,
    marginRight: 2,
    marginTop: 1,
    marginBottom: 2,
    minWidth: "10%",
  },
  noCatView: {
    display: "flex",
    height: window.innerHeight - 250,
    alignItems: "center",
    justifyContent: "center",
  },
  noCatText: {
    color: Theme.text,
    textAlign: "center",
    fontFamily: "InterMedium",
  },
  selectedCategory: {
    backgroundColor: Theme.theme,
    borderColor: Theme.theme,
    "&.Mui-selected": {
      color: Theme.text,
    },
  },
  nonSelectedCategory: {
    backgroundColor: "transparent",
    borderColor: Theme.catBtnBorder,
    color: Theme.catBtnText,
  },
  menuTitleText: {
    backgroundColor: Theme.bg,
    color: Theme.text2,
    fontFamily: "InterBold",
    fontSize: 18,
    zIndex: 1,
    top: -2,
    pt: 1,
    pb: 1,
  },
});
