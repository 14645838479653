import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import DiningAddSubMenuBtn from "./DiningAddSubMenuBtn";
import DiningDetailModifierList from "./DiningDetailModifierList";

import styles from "./DiningDetailModifiers.module.css";

import { THEME_MODE } from "../constants/Theme";
import {
  CURRENCY_SYMBOL,
  DINING_DETAIL_BTN_HEIGHT,
  DINING_DETAIL_CONTENT_HEIGHT,
  IS_LARGE_SCREEN,
} from "../constants";
import { getModifierTotalPrice } from "../constants/CommonFunc";

let CONTENT_HEIGHT = DINING_DETAIL_CONTENT_HEIGHT - DINING_DETAIL_BTN_HEIGHT;
let HEADER_HEIGHT = DINING_DETAIL_BTN_HEIGHT + 20;
let CLOSE_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;

let MARGIN_WIDTH = 5;
let MARGIN_WIDTH2 = 10;
let MARGIN_WIDTH3 = 12;

const DiningDetailModifiers = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [note, setNote] = useState("");
  const [menuData, setMenuData] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(HEADER_HEIGHT);

  useEffect(() => {
    if (document.getElementById("headerViewId")) {
      setHeaderHeight(document.getElementById("headerViewId").offsetHeight);
    }
  }, [menuData, props.menu, props.drawerOpen]);

  useEffect(() => {
    setNote(props.menu?.notes ? props.menu.notes : "");
    setMenuData(JSON.parse(JSON.stringify(props.menu)));
  }, [props.menu]);

  const onChangeNote = (val) => {
    setNote(val);
    props.onUpdate({ ...menuData, notes: val });
  };

  const onApply = () => {
    props.onApply({ ...menuData, notes: note });
  };

  const onModifierSelect = (
    menuExtraType,
    qty,
    modifier,
    withExtra,
    selected
  ) => {
    let item = { ...menuData };
    let modIndex = -1;
    if (qty <= item?.["cartModifier"]?.length) {
      if (item?.["cartModifier"][qty - 1].length) {
        modIndex = item?.["cartModifier"][qty - 1]?.findIndex(
          (mod) =>
            mod._idModifier == modifier._id && mod._idMenuItem == selected
        );
      }
    }

    let menu_data = null;
    if (modifier.items.length) {
      let mod_data = modifier.items.filter((m) => m._id == selected);
      if (mod_data.length) {
        menu_data = mod_data[0];
      }
    }

    let data = {
      withExtra: withExtra,
      _idModifier: modifier._id,
      _idMenuItem: selected,
      price: {
        value: menu_data?.price?.value,
        currency: props.restaurantDetail?.currecnyIcon
          ? props.currency
          : menu_data?.price?.currency,
      },
      menuData: menu_data,
    };

    let cart_mod_data = item?.["cartModifier"][qty - 1];
    if (modIndex >= 0) {
      let filter_data = item?.["cartModifier"][qty - 1].filter(
        (x) =>
          !(
            x._idModifier == data._idModifier &&
            x._idMenuItem == data._idMenuItem
          )
      );
      if (
        modifier.required &&
        filter_data.filter((x) => x._idModifier == data._idModifier).length == 0
      ) {
        item["cartModifier"][qty - 1][modIndex] = data;
      } else {
        if (menuExtraType == "menuItem") {
          item["cartModifier"][qty - 1] = filter_data;
        } else {
          item["cartModifier"][qty - 1][modIndex] = data;
        }
      }
    } else {
      if (!modifier?.multiSelection) {
        item["cartModifier"][qty - 1] = item?.["cartModifier"][qty - 1].filter(
          (x) => x._idModifier != data._idModifier
        );
      }
      if (cart_mod_data?.length) {
        item["cartModifier"][qty - 1].push(data);
      } else {
        item["cartModifier"][qty - 1] = [data];
      }
    }
    setMenuData(item);
    props.onUpdate({ ...item, notes: note });
  };

  const onAddSubMenu = (type) => {
    let item = { ...menuData };
    let count = item?.["quantity"];
    if (type == "-") {
      count = parseInt(count) - 1;
    }
    if (type == "+") {
      count = parseInt(count) + 1;
    }

    if (count > 0) {
      item["quantity"] = count;
      if (item?.["cartModifier"]?.length) {
        if (type == "+") {
        } else {
          item["cartModifier"] = item?.["cartModifier"].filter(
            (x, i) => i + 1 <= count
          );
        }
      }

      if (type == "+") {
        let cart_modifier = [];
        item.modifiers
          .filter((m1) => m1.required == true || m1?.defaultSelection)
          .map((m) => {
            if (m.items.length) {
              let defaultValue = m?.defaultSelection
                ? m?.defaultSelection
                : m.items[0]._id;
              let menu_data = props.menuList.find((y) => y._id == defaultValue);
              if (menu_data) {
                let data = {
                  withExtra: "with",
                  _idModifier: m._id,
                  _idMenuItem: menu_data._id,
                  price: {
                    value: menu_data?.price?.value,
                    currency: props.restaurantDetail?.currecnyIcon
                      ? props.currency
                      : menu_data?.price?.currency,
                  },
                  menuData: menu_data,
                };
                cart_modifier.push(data);
              }
            }
          });

        if (item?.["cartModifier"]?.length) {
          item["cartModifier"] = [...item["cartModifier"], cart_modifier];
        } else {
          item["cartModifier"] = [cart_modifier];
        }
      }

      setMenuData(item);
      props.onUpdate({ ...item, notes: note });
    } else if (count == 0) {
      props.onDeleteCartItem(item);
    }
  };

  const onVoidItem = () => {
    props.onDeleteCartItem(menuData);
  };

  let void_enable = props?.isAdmin;
  if (void_enable == false) {
    if (props.orderData) {
      if (props.orderData?.cart?.length) {
        let findItemIndex = props.orderData?.cart.findIndex(
          (x) =>
            x._idMenuItem == props.menu._id &&
            x._idRestaurantCourse == props.menu._idRestaurantCourse
        );
        if (findItemIndex >= 0) {
        } else {
          void_enable = true;
        }
      }
    } else {
      void_enable = true;
    }
  }

  return (
    <Box px={`${MARGIN_WIDTH}px`}>
      <Box
        id={"headerViewId"}
        px={`${MARGIN_WIDTH2}px`}
        style={{ minHeight: HEADER_HEIGHT }}
        className={styles.cartItemDataView}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            className={styles.cartItemNameText}
            style={{
              color: theme_mode.cardDescText3,
              fontSize: IS_LARGE_SCREEN ? 32 : 22,
              lineHeight: `${IS_LARGE_SCREEN ? 32 : 22}px`,
            }}
          >
            {menuData?.name}
          </Typography>
        </Box>
        <Box
          px={1}
          onClick={void_enable ? onVoidItem : null}
          className={styles.voidBtn}
          style={{
            width: 130,
            height: IS_LARGE_SCREEN ? 80 : 50,
            cursor: void_enable ? "pointer" : "default",
            backgroundColor: void_enable ? theme_mode.redBg : "#777",
          }}
        >
          <Typography
            className={styles.voidBtnText}
            style={{ color: theme_mode.lightText }}
          >
            VOID
          </Typography>
        </Box>
        <Box pl={1}>
          <DiningAddSubMenuBtn
            count={menuData?.quantity}
            minWidth={IS_LARGE_SCREEN ? 270 : 170}
            circleSize={IS_LARGE_SCREEN ? 80 : 50}
            addSubFontSize={IS_LARGE_SCREEN ? 40 : 34}
            fontSize={IS_LARGE_SCREEN ? 26 : 20}
            border={"1px solid " + theme_mode.addSubBorder}
            // onAddSubMenu={(type) => props.onAddSubMenu(type, menu, false)}
            onAddSubMenu={(type) => onAddSubMenu(type)}
            themeMode={props.themeMode}
          />
        </Box>
      </Box>
      <Box
        className={styles.listView}
        px={`${MARGIN_WIDTH2}px`}
        style={{ height: CONTENT_HEIGHT - headerHeight - CLOSE_BTN_HEIGHT }}
      >
        {menuData?.modifiers?.length
          ? Array.from({ length: menuData?.quantity }, (_, i) => i + 1).map(
              (item, i) => {
                let total_price = 0;

                menuData?.modifiers?.forEach(async (x1, i1) => {
                  if (item <= menuData?.cartModifier?.length) {
                    if (menuData?.cartModifier[item - 1].length) {
                      let modList = menuData?.cartModifier[item - 1]?.filter(
                        (mod) => mod._idModifier == x1._id
                      );
                      total_price += getModifierTotalPrice(
                        x1.addUpPrices ? [] : [x1?._id],
                        modList
                      );
                    }
                  }
                });

                return (
                  <Box
                    key={i}
                    px={`${MARGIN_WIDTH3}px`}
                    pt={2.5}
                    mb={1}
                    className={styles.modifierListView}
                    style={{ backgroundColor: theme_mode.card7 }}
                  >
                    <Box className={styles.modifierTitleView}>
                      <Typography
                        className={styles.modifierTitleTxt}
                        style={{ color: theme_mode.text }}
                      >
                        Item {item} - Modifiers
                      </Typography>

                      <Typography
                        className={styles.modifierPriceTxt}
                        style={{ color: theme_mode.text }}
                      >
                        {CURRENCY_SYMBOL[props.currency]} {total_price}
                      </Typography>
                    </Box>
                    {menuData?.modifiers?.map((x1, i1) => {
                      let mod = [];
                      if (item <= menuData?.cartModifier?.length) {
                        if (menuData?.cartModifier[item - 1].length) {
                          mod = menuData?.cartModifier[item - 1]?.filter(
                            (mod) => mod._idModifier == x1._id
                          );
                        }
                      }
                      return (
                        <DiningDetailModifierList
                          key={i1}
                          index={i1}
                          item={x1}
                          modifierNo={item}
                          themeMode={props.themeMode}
                          modifiersLength={menuData?.modifiers?.length}
                          selectedModifier={mod}
                          onModifierSelect={onModifierSelect}
                        />
                      );
                    })}
                  </Box>
                );
              }
            )
          : null}
        <Box
          mb={1}
          mt={3}
          p={1.5}
          className={styles.addNoteView}
          style={{ backgroundColor: theme_mode.card2 }}
        >
          <Typography
            className={styles.addNoteLabel}
            style={{ color: theme_mode.cardText }}
          >
            Add any notes
          </Typography>
          <TextField
            required
            focused
            fullWidth
            multiline
            minRows={IS_LARGE_SCREEN ? 2.3 : 1.3}
            maxRows={10}
            value={note}
            variant="filled"
            sx={{
              "& .MuiInputBase-root": {
                backgroundColor: theme_mode.inputBg5 + " !important",
                "&:before, :after, :hover:not(.Mui-disabled):before": {
                  borderBottom: 0,
                },
                borderRadius: "9px",
                p: 1,
                mt: 1.5,
              },
            }}
            inputProps={{
              autoComplete: "none",
              sx: {
                fontSize: IS_LARGE_SCREEN ? 18 : 13,
                fontFamily: "InterSemiBold",
                color: theme_mode.text,
              },
            }}
            onChange={(e) => onChangeNote(e.target.value)}
          />
        </Box>
      </Box>
      <Box className={styles.btnView}>
        <Box
          px={1}
          onClick={props.onClose}
          className={styles.closeBtn}
          style={{
            height: CLOSE_BTN_HEIGHT,
            backgroundColor: theme_mode.redBg,
          }}
        >
          <Typography
            className={styles.closeBtnText}
            style={{ color: theme_mode.lightText }}
          >
            CLOSE
          </Typography>
        </Box>
        {/*<Box*/}
        {/*  px={1}*/}
        {/*  onClick={onApply}*/}
        {/*  className={styles.closeBtn}*/}
        {/*  style={{*/}
        {/*    height: CLOSE_BTN_HEIGHT,*/}
        {/*    backgroundColor: theme_mode.greenBg,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography*/}
        {/*    className={styles.closeBtnText}*/}
        {/*    style={{ color: theme_mode.lightText }}*/}
        {/*  >*/}
        {/*    ADD TO CART*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
      </Box>
    </Box>
  );
};

export default DiningDetailModifiers;
