import React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ReactComponent as OrdersTable } from "./../assets/images/orders_table.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/logout2.svg";

import { THEME_MODE } from "../constants/Theme";
import { IS_LARGE_SCREEN, NAVBAR_HEIGHT } from "../constants";

import styles from "./DiningNavbar.module.css";

let ICON_SIZE = IS_LARGE_SCREEN ? 30 : 20;

const DiningNavbar = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const onLogout = () => {
    if (props.restaurantId) {
      window.location.href = "/logout/pin";
    } else {
      window.location.href = "/logout";
    }
  };

  return (
    <>
      {props?.left ? props?.left : null}
      {props?.center ? props?.center : null}
      <Box className={styles.navbarRightView}>
        <Box
          className={styles.tableBtn}
          px={2}
          sx={{
            width: { md: 160, xl: 180 },
            backgroundColor: theme_mode.theme,
            path: {
              stroke: theme_mode.iconBg,
            },
          }}
          onClick={() => navigate("/dining")}
        >
          <Box sx={{ height: { xl: 23, md: 22 } }}>
            <OrdersTable height={ICON_SIZE} width={ICON_SIZE} />
          </Box>
          <Typography
            className={styles.tableBtnText}
            sx={{
              color: theme_mode.text,
            }}
          >
            TABLE
          </Typography>
        </Box>
        <Box
          ml={"2px"}
          className={styles.logoutBtn}
          onClick={onLogout}
          sx={{
            backgroundColor: "#555",
            width: NAVBAR_HEIGHT * 0.9,
            path: { fill: theme_mode.lightText },
          }}
        >
          <LogoutIcon width={ICON_SIZE} height={ICON_SIZE} />
        </Box>
      </Box>
    </>
  );
};

export default DiningNavbar;
