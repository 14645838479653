import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { forwardRef } from "react";
import Slide from "@mui/material/Slide";

import { ReactComponent as DeleteIcon } from "../assets/images/delete.svg";

import { THEME_MODE } from "../constants/Theme";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmAlert = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  return (
    <Dialog
      open={props.confirmDelete}
      TransitionComponent={Transition}
      keepMounted
      // onClose={onCloseConfirmDelete}
      aria-describedby="alert-dialog-slide-description"
      sx={styles.mainDialog}
    >
      <Grid p={4}>
        <Grid container justifyContent="center" alignItems="center">
          {props?.icon ? props.icon : <DeleteIcon height={50} width={50} />}
        </Grid>
        <DialogTitle sx={{ ...styles.confirmTitleText, ...props?.titleStyle }}>
          {props.title}
        </DialogTitle>
        {props.content || props?.name ? (
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={styles.confirmContentText}
            >
              {props.content}
              <br />
              {props?.name}
            </DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions sx={styles.confirmBtnDialog}>
          <Button
            sx={[styles.btnText, styles.noBtnText]}
            disabled={props.btnLoading}
            onClick={props.onCloseConfirmDelete}
          >
            {props.firstBtn}
          </Button>
          <Button
            sx={[styles.btnText, styles.yesBtnText]}
            disabled={props.btnLoading}
            onClick={props.onConfirmDelete}
          >
            {props.secondBtn}
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ConfirmAlert;

const styles1 = (Theme) => ({
  mainDialog: {
    "& .MuiDialog-paper": {
      backgroundColor: Theme.modal,
      borderRadius: 3,
    },
  },
  confirmTitleText: {
    color: Theme.text,
    fontFamily: "InterBold",
    textAlign: "center",
  },
  confirmContentText: {
    color: Theme.text,
    fontFamily: "InterMedium",
    textAlign: "center",
  },
  confirmBtnDialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  btnText: {
    textTransform: "capitalize",
    fontFamily: "InterSemiBold",
  },
  noBtnText: {
    color: Theme.text,
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  yesBtnText: {
    backgroundColor: "#FE724C",
    color: "#fff",
    borderRadius: 2,
    px: 2,
    "&:hover": {
      backgroundColor: "#FE724C !important",
    },
  },
});
