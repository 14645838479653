import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import styles from "./GiftCards.module.css";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import SettingTextField from "../../components/SettingTextField";
import Calculator from "../../components/Calculator";

import { THEME_MODE } from "../../constants/Theme";

let CONTENT_HEIGHT = window.innerHeight - 150;
let TOP_HEIGHT = 80;
let BTN_HEIGHT = 70;

let PRICE_BTN_LIST = [10, 20, 25, 50, "custom"];

const GiftCards = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [cardTitle, setCardTitle] = useState("");
  const [giftCardAmt, setGiftCardAmt] = useState(0);
  const [selectedPriceBtn, setSelectedPriceBtn] = useState("");
  const [error, setError] = useState(null);
  const [calcDivWidth, setCalcDivWidth] = useState(100);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  let tabBg = {
    true: props.themeMode == "dark" ? theme_mode.btnBg : theme_mode.headerBg,
    false: theme_mode.inputBg2,
  };
  let tabText = { true: theme_mode.lightText, false: theme_mode.text };

  useEffect(() => {
    if (document.getElementById("calcDivId")) {
      setCalcDivWidth(document.getElementById("calcDivId").offsetWidth);
    }
  }, [props.drawerOpen]);

  const onAddGiftCard = () => {
    setGiftCardAmt(0);
    setCardTitle("");
    setSelectedPriceBtn("");
  };

  const onPriceSelect = (val) => {
    setSelectedPriceBtn(val);
    if (val != "custom") {
      setGiftCardAmt(val);
    }
  };

  const onNumClick = (calcVal) => {
    setSelectedPriceBtn("custom");
    setGiftCardAmt(calcVal);
  };

  return (
    <Container page={"gift_cards"}>
      {/*{isLoading ? (*/}
      {/*  <Box className={styles.loadingView}>*/}
      {/*    <Loader />*/}
      {/*  </Box>*/}
      {/*) : null}*/}
      <Grid container item xs={12}>
        <Grid
          container
          item
          xs={12}
          className={styles.mainView}
          style={{
            backgroundColor: theme_mode.card2,
            height: { sm: CONTENT_HEIGHT },
          }}
        >
          <Grid container className={styles.mainSubView} item xs={12} px={4}>
            <Grid item xs={12} sm={7}>
              <Grid
                item
                xs={12}
                md={10}
                className={styles.leftView}
                sx={{ height: { sm: CONTENT_HEIGHT - BTN_HEIGHT } }}
              >
                <Box
                  className={styles.addGiftCardTitle}
                  style={{ height: TOP_HEIGHT }}
                >
                  <Typography
                    className={styles.addGiftCardTitleText}
                    style={{ color: theme_mode.text }}
                  >
                    Add New Gift Card
                  </Typography>
                </Box>
                <Box>
                  <SettingTextField
                    page={"diningDetailAssign"}
                    editable={props.customer == null}
                    disabledBg={theme_mode.inputBg2}
                    value={cardTitle}
                    setValue={setCardTitle}
                    backgroundColor={theme_mode.inputBg2}
                    placeholderColor={theme_mode.text}
                    placeholder={"Enter Card Details"}
                    variant={"filled"}
                    borderRadius={"10px"}
                    labelStyle={{ fontSize: 12 }}
                    inputFontSize={12}
                    error={error?.fullName}
                  />
                </Box>
                <Box mt={2} className={styles.priceBtnList}>
                  {PRICE_BTN_LIST.map((x, i) => {
                    return (
                      <Box
                        mr={1.5}
                        px={1.5}
                        py={1.5}
                        mb={1}
                        onClick={() => onPriceSelect(x)}
                        className={styles.priceBtnView}
                        style={{
                          backgroundColor: tabBg[x == selectedPriceBtn],
                        }}
                      >
                        <Typography
                          className={styles.priceBtnText}
                          style={{ color: tabText[x == selectedPriceBtn] }}
                        >
                          {x == "custom" ? x : `$${x.toFixed(2)}`}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  className={styles.giftCardAmtView}
                  sx={{
                    position: { xs: "relative", ms: "absolute" },
                    bottom: { xs: 0, ms: "40px" },
                  }}
                >
                  <Typography
                    className={styles.giftCardAmtText}
                    style={{ color: theme_mode.text }}
                  >
                    Gift Card Amount
                  </Typography>
                  <Typography
                    className={styles.giftCardAmt}
                    style={{ color: theme_mode.text }}
                  >
                    ${Number(giftCardAmt).toFixed(2)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              id={"calcDivId"}
              container
              direction={"column"}
              alignItems={"flex-end"}
              sx={{ height: { sm: CONTENT_HEIGHT - BTN_HEIGHT } }}
            >
              <Box
                style={{ height: TOP_HEIGHT }}
                className={styles.historyBtnView}
              >
                <Box
                  onClick={() => navigate("/gift_cards/history")}
                  className={styles.historyBtn}
                  style={{
                    backgroundColor: theme_mode.black,
                    border: `1.5px solid ${theme_mode.border7}`,
                  }}
                >
                  <Typography
                    className={styles.historyBtnText}
                    style={{ color: theme_mode.btnText }}
                  >
                    Gift Transaction History
                  </Typography>
                </Box>
              </Box>
              <Box
                pl={1.5}
                sx={{
                  width: {
                    xs: 55 * 3.2,
                    sm: (calcDivWidth / 3.5) * 3.2,
                    md: (calcDivWidth / 4.5) * 3.2,
                  },
                }}
              >
                <Calculator
                  width={{
                    xs: 55,
                    sm: calcDivWidth / 3.5,
                    md: calcDivWidth / 4.5,
                  }}
                  height={{
                    xs: 50,
                    sm: (CONTENT_HEIGHT - TOP_HEIGHT - BTN_HEIGHT) / 4.8,
                  }}
                  currentVal={
                    selectedPriceBtn != "custom" && selectedPriceBtn != ""
                      ? 0
                      : giftCardAmt
                  }
                  iconSize={
                    (CONTENT_HEIGHT - TOP_HEIGHT - BTN_HEIGHT) / 4.8 / 3
                  }
                  textStyle={{
                    fontSize:
                      (CONTENT_HEIGHT - TOP_HEIGHT - BTN_HEIGHT) / 4.8 / 4,
                  }}
                  emptyValue={false}
                  onUpdate={onNumClick}
                  themeMode={props.themeMode}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box
              onClick={onAddGiftCard}
              className={styles.addBtn}
              style={{
                backgroundColor: theme_mode.greenBg,
                height: BTN_HEIGHT,
              }}
            >
              <Typography
                className={styles.addBtnText}
                style={{ color: theme_mode.lightText }}
              >
                Add Gift Card
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    drawerOpen: state.userData.drawerOpen,
    restaurantId: state.userData.restaurantId,
  };
};

export default connect(mapStateToProps, null)(GiftCards);
