import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import moment from "moment";

import SettingDatePicker from "./SettingDatePicker";

import { THEME_MODE } from "../constants/Theme";
import { IS_LARGE_SCREEN } from "../constants";

import styles from "./FromToDatePicker.module.css";

import { ReactComponent as ResetIcon } from "../assets/images/reset.svg";
import { ReactComponent as CalenderIcon } from "../assets/images/calender_gray.svg";

const ICON_SIZE = IS_LARGE_SCREEN ? 30 : 20;

const FromToDatePicker = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  return (
    <Grid item xs={12} container direction="row" alignItems="center">
      <Box
        my={0.2}
        mx={1}
        sx={{ width: props?.inputWidth ? props?.inputWidth : "unset" }}
      >
        <SettingDatePicker
          value={props.fromDate}
          disabled={false}
          inputFormat={props?.inputFormat ? props.inputFormat : "DD MMM YYYY"}
          onChange={(newValue) => {
            props.setFromDate(
              newValue.format(props?.format ? props.format : "DD MMM YYYY")
            );
          }}
          maxDate={
            props.toDate
              ? props.toDate
              : moment().format(props?.format ? props.format : "DD MMM YYYY")
          }
          labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
          inputFontSize={props?.inputFontSize ? props?.inputFontSize : 12}
          placeholder={"From"}
          backgroundColor={
            props?.backgroundColor ? props.backgroundColor : theme_mode.bg10
          }
          border={props?.border ? props.border : "0px"}
          borderRadius={props?.borderRadius ? props.borderRadius : "6px"}
          inputTextAlign={props?.textAlign ? props.textAlign : "left"}
          marginRight={props?.marginRight ? props.marginRight : "8px"}
          error={props.error?.from}
          leftView={null}
          rightView={
            props?.showIcon ? (
              <CalenderIcon width={ICON_SIZE} height={ICON_SIZE} />
            ) : null
          }
        />
      </Box>
      <Box
        my={0.2}
        mx={1}
        sx={{ width: props?.inputWidth ? props?.inputWidth : "unset" }}
      >
        <SettingDatePicker
          value={props.toDate}
          disabled={false}
          inputFormat={props?.inputFormat ? props.inputFormat : "DD MMM YYYY"}
          onChange={(newValue) => {
            props.setToDate(
              newValue.format(props?.format ? props.format : "DD MMM YYYY")
            );
          }}
          minDate={props.fromDate ? props.fromDate : null}
          maxDate={moment().format(
            props?.format ? props.format : "DD MMM YYYY"
          )}
          labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
          inputFontSize={props?.inputFontSize ? props?.inputFontSize : 12}
          placeholder={"To"}
          backgroundColor={
            props?.backgroundColor ? props.backgroundColor : theme_mode.bg10
          }
          border={props?.border ? props.border : "0px"}
          borderRadius={props?.borderRadius ? props.borderRadius : "6px"}
          inputTextAlign={props?.textAlign ? props.textAlign : "left"}
          marginRight={props?.marginRight ? props.marginRight : "8px"}
          error={props.error?.to}
          leftView={null}
          rightView={
            props?.showIcon ? (
              <CalenderIcon width={ICON_SIZE} height={ICON_SIZE} />
            ) : null
          }
        />
      </Box>
      {props?.showIcon ? (
        <Box
          my={0.2}
          className={styles.resetView}
          ml={2}
          onClick={props.onReset}
        >
          <ResetIcon width={20} height={20} />
          <Typography className={styles.resetText}>Reset</Typography>
        </Box>
      ) : null}
    </Grid>
  );
};

export default FromToDatePicker;
