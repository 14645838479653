import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import "./../assets/css/custom.css";

import { THEME_MODE } from "../constants/Theme";
import { IS_LARGE_SCREEN } from "../constants";

const DiningAddSubMenuBtn = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  let minWidth = props?.minWidth ? props.minWidth : 90;
  let circleSize = props?.circleSize ? props.circleSize : 26;
  let circleBg = props?.circleBg ? props.circleBg : theme_mode.addSubBtnBg;
  let addSubFontSize = props?.addSubFontSize ? props.addSubFontSize : 14;
  let addSubFontColor = props?.addSubFontColor
    ? props.addSubFontColor
    : theme_mode.whiteBlack;
  let fontSize = props?.fontSize ? props.fontSize : IS_LARGE_SCREEN ? 20 : 14;
  let border = props?.border ? props.border : "0px";
  let padding = props?.border ? 0.3 : 0;

  let circleStyle = {
    width: circleSize,
    height: circleSize,
    borderRadius: circleSize / 2,
    backgroundColor: circleBg,
  };

  let circleFontStyle = {
    fontSize: addSubFontSize,
    color: addSubFontColor,
  };

  return (
    <Box
      sx={{
        ...styles.mainView,
        minWidth: minWidth,
        border: border,
        p: padding,
      }}
    >
      {props?.showAddBtnOnly == true ? null : (
        <>
          <Box
            sx={{ ...styles.addSubView, ...circleStyle }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onAddSubMenu("-");
            }}
          >
            <Typography sx={{ ...styles.addSubText, ...circleFontStyle }}>
              -
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ ...styles.countText, fontSize: fontSize }}>
              {props.count}
            </Typography>
          </Box>
        </>
      )}
      <Box
        sx={{ ...styles.addSubView, ...circleStyle }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.onAddSubMenu("+");
        }}
      >
        <Typography sx={{ ...styles.addSubText, ...circleFontStyle }}>
          +
        </Typography>
      </Box>
    </Box>
  );
};

export default DiningAddSubMenuBtn;

const styles1 = (Theme) => ({
  mainView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 100,
    borderRadius: 21,
  },
  addSubView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  countText: {
    color: Theme.text,
    fontFamily: "InterBold",
    textAlign: "center",
  },
  addSubText: {
    fontFamily: "InterBold",
    textAlign: "center",
  },
});
