import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import DiningAddSubMenuBtn from "./DiningAddSubMenuBtn";

import {ReactComponent as DeleteIcon} from "../assets/images/delete_circle.svg";
import {ReactComponent as MenuImg} from "../assets/images/file1.svg";
import CreateOrderModalCartItem from "./CreateOrderModalCartItem";
import {THEME_MODE} from "../constants/Theme";

let bgData = {
  preparing: '#CBB11E',
  served: '#009621'
}

const DiningOrderModalItemList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  let item = props.item;
  if (props.type == 'preparing') {
    item.status = 'preparing'
  } else if (props.type == 'pending_payment') {
    item.status = item?.status ? item?.status : 'preparing';
  }

  const onDeleteItem = () => {
    props.onDelete(item)
  }

  return (
    <Box px={2} pt={2} sx={styles.mainView}>
      <Box>
        {props.type == 'placed_order' ? (item.images?.length ? <Box sx={styles.imageView}>
          <img src={item.images[0]} style={styles.imageStyle}/>
        </Box> : <Box sx={{...styles.imageView, ...styles.menuImgView}}>
          <MenuImg width={'40%'} height={'40%'}/>
        </Box>) : <Box p={0.6} sx={{...styles.statusView, backgroundColor: bgData[item.status]}}>
          <Typography sx={styles.statusText}>{item.status}</Typography>
        </Box>}
      </Box>
      <Box ml={2} pb={2} sx={styles.dataView}>
        <Box sx={styles.nameAddOnView}>
          <Typography sx={styles.nameText}>{item.name}</Typography>
          {item.add_on ? <Box sx={styles.addOnView}>
            <Typography sx={styles.addOnText}>Add on : {item.add_on}</Typography>
          </Box> : null}
        </Box>
        {item.note ? <Typography mt={1} sx={styles.noteText}>Notes : {item.note}</Typography> : null}
        <Box sx={styles.priceQtyView} mt={1}>
          <Box sx={styles.priceTextView}>
            <Typography
              sx={styles.priceText}>{item.quantity > 1 ? item.quantity + 'x' : ''} {item.price?.currency}: {item.price?.value?.toFixed(2)}</Typography>
          </Box>
          {props.type == 'placed_order' ?
            <Box mr={1.5} sx={{cursor: 'pointer'}} onClick={onDeleteItem}>
              <DeleteIcon width={30} height={30}/>
            </Box> : null}
          <DiningAddSubMenuBtn
            count={item.quantity}
            minWidth={100}
            circleSize={30}
            addSubFontSize={20}
            onAddSubMenu={(type) => props.onAddSubMenu(type, item)}
            themeMode={props.themeMode}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default DiningOrderModalItemList;

const styles1 = Theme => ({
  mainView: {
    display: 'flex',
    flexDirection: 'row',
  },
  dataView: {
    flex: 1,
    borderBottom: '1px solid ' + Theme.borderBottom3,
  },
  imageView: {
    width: 56,
    height: 56,
    borderRadius: 28,
    overflow: 'hidden',
  },
  menuImgView: {
    backgroundColor: Theme.imgBg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imageStyle: {
    width: '100%',
    height: '100%',
    // resizeMode: 'cover',
  },
  statusView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 56,
    height: 56,
    borderRadius: 28,
    overflow: 'hidden',
  },
  statusText: {
    color: Theme.lightText,
    fontFamily: 'InterMedium',
    textAlign: 'center',
    textTransform: 'capitalize',
    overflowWrap: 'anywhere',
    fontSize: 12,
  },
  nameAddOnView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nameText: {
    color: Theme.cardDescText3,
    fontFamily: 'InterBold',
    fontSize: 16,
  },
  addOnView: {
    border: '1px solid ' + Theme.borderBottom3,
    borderRadius: 37,
    px: 1,
  },
  addOnText: {
    color: Theme.cardDescText3,
    fontFamily: 'InterBold',
    fontSize: 12,
  },
  noteText: {
    color: Theme.cardDescText3,
    fontFamily: 'InterMedium',
    fontSize: 12,
    lineHeight: '13px',
  },
  priceQtyView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  priceTextView: {
    display: 'flex',
    flex: 1,
  },
  priceText: {
    color: Theme.cardText,
    fontFamily: 'InterSemiBold',
    fontSize: 14,
  },
  qtyText: {
    color: Theme.cardText,
    fontFamily: 'InterBold',
    fontSize: 14,
  },
});
