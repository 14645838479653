import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { THEME_MODE } from "../constants/Theme";
import {
  DINING_DETAIL_CONTENT_HEIGHT,
  DINING_DETAIL_BTN_HEIGHT, IS_LARGE_SCREEN,
} from "../constants";

import { ReactComponent as BackspaceIcon } from "../assets/images/backspace.svg";

import styles from "./DiningDetailCovers.module.css";

let CONTENT_HEIGHT = DINING_DETAIL_CONTENT_HEIGHT;

let HEADER_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;
let TOP_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;
let BOTTOM_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;
let MARGIN = 5;
let MARGIN_HEIGHT = MARGIN;

let CALC_NO = [1, 2, 3, 4, 5, 6, 7, 8, 9, "C", 0, "x"];

const DiningDetailCovers = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [coversVal, setCoversVal] = useState(props.covers);
  const [viewWidth, setViewWidth] = useState(0);

  let showBottomBtn = true;
  let calcViewHeight =
    CONTENT_HEIGHT -
    HEADER_BTN_HEIGHT -
    TOP_BTN_HEIGHT -
    (showBottomBtn ? BOTTOM_BTN_HEIGHT : 0) -
    MARGIN_HEIGHT;

  useEffect(() => {
    setCoversVal(props.covers);
  }, [props.covers]);

  useEffect(() => {
    if (document.getElementById("coversViewId")) {
      setViewWidth(document.getElementById("coversViewId").clientWidth);
    }
  }, [props.drawerOpen]);

  const onNumClick = (no) => {
    if (no == "C") {
      setCoversVal(0);
    } else if (no == "x") {
      let val = coversVal.length ? coversVal.slice(0, -1) : 0;
      if (val == "") {
        val = 0;
      }
      setCoversVal(val);
    } else {
      if (coversVal == "" || coversVal == 0) {
        setCoversVal(no);
      } else {
        setCoversVal(coversVal + "" + no);
      }
    }
  };

  const onSubmit = () => {
    props.onSubmit(coversVal);
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <Box
      className={styles.mainView}
      mx={`${MARGIN}px`}
      mt={`${MARGIN}px`}
      style={{
        backgroundColor: theme_mode.diningContentBg,
        height: CONTENT_HEIGHT - HEADER_BTN_HEIGHT - MARGIN_HEIGHT,
      }}
    >
      <Box
        px={1.5}
        className={styles.headerView}
        style={{
          backgroundColor: theme_mode.orderTableBg,
          height: TOP_BTN_HEIGHT,
        }}
      >
        <Box className={styles.noOfTextView} mr={1}>
          <Typography
            className={styles.noOfText}
            style={{ color: theme_mode.btnText }}
          >
            NUMBER OF PEOPLES
          </Typography>
        </Box>
        <Typography
          className={styles.coversText}
          style={{ color: theme_mode.btnText }}
        >
          {coversVal}
        </Typography>
      </Box>
      <Box
        id={"coversViewId"}
        className={styles.calcListView}
        style={{
          height: calcViewHeight,
        }}
      >
        <Box
          className={styles.calcMainView}
          sx={{ width: { xs: viewWidth, md: viewWidth / 1.4 } }}
          px={1.5}
          pb={1}
        >
          {CALC_NO.map((x, i) => {
            let xswidth = viewWidth / 3.5;
            let width = viewWidth / 1.4 / 3.5;
            return (
              <Box
                key={i}
                onClick={() => onNumClick(x)}
                className={styles.calcView}
                mt={1}
                sx={{
                  backgroundColor:
                    x == "x" ? theme_mode.bg3 : theme_mode.calcBg,
                  width: { xs: xswidth, md: width },
                  height: { xs: xswidth - 20, md: width - 20 },
                }}
              >
                {x == "x" ? (
                  <BackspaceIcon width={IS_LARGE_SCREEN ? 32 : 24} height={IS_LARGE_SCREEN ? 32 : 24} />
                ) : (
                  <Typography
                    className={styles.calcText}
                    sx={{ color: theme_mode.lightText }}
                  >
                    {x}
                  </Typography>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
      {showBottomBtn ? (
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Box
            onClick={onCancel}
            className={styles.submitBtn}
            style={{
              backgroundColor: theme_mode.redBg,
              height: BOTTOM_BTN_HEIGHT,
            }}
          >
            <Typography
              className={styles.submitBtnText}
              style={{ color: theme_mode.lightText }}
            >
              Cancel
            </Typography>
          </Box>
          <Box
            onClick={onSubmit}
            className={styles.submitBtn}
            style={{
              backgroundColor: theme_mode.greenBg,
              height: BOTTOM_BTN_HEIGHT,
            }}
          >
            <Typography
              className={styles.submitBtnText}
              style={{ color: theme_mode.lightText }}
            >
              Apply
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default DiningDetailCovers;
