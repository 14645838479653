import * as actionTypes from "./types";

export const updateUserToken = (data) => ({
  type: actionTypes.UPDATE_USER_TOKEN,
  payload: data,
});

export const updateRefreshToken = (data) => ({
  type: actionTypes.UPDATE_REFRESH_TOKEN,
  payload: data,
});

export const updateDrawerOpen = (data) => ({
  type: actionTypes.UPDATE_DRAWER_OPEN,
  payload: data,
});

export const updateRestaurantId = (data) => ({
  type: actionTypes.UPDATE_RESTAURANT_ID,
  payload: data,
});

export const updateRestaurantImg = (data) => ({
  type: actionTypes.UPDATE_RESTAURANT_IMG,
  payload: data,
});

export const updateFcmToken = (data) => ({
  type: actionTypes.UPDATE_FCM_TOKEN,
  payload: data,
});

export const updateUserRole = (data) => ({
  type: actionTypes.UPDATE_USER_ROLE,
  payload: data,
});

export const updateUserData = (data) => ({
  type: actionTypes.UPDATE_USER_DATA,
  payload: data,
});

export const updateCreateOrderModalVisible = (data) => ({
  type: actionTypes.UPDATE_CREATE_ORDER_MODAL_VISIBLE,
  payload: data,
});

export const updateOrderOrTableType = (data) => ({
  type: actionTypes.UPDATE_ORDER_OR_TABLE_TYPE,
  payload: data,
});

export const updateRestaurantDetail = (data) => ({
  type: actionTypes.UPDATE_RESTAURANT_DETAIL,
  payload: data,
});

export const updateFloorTableList = (data) => ({
  type: actionTypes.UPDATE_FLOOR_TABLE_LIST,
  payload: data,
});

export const updateUserRolesList = (data) => ({
  type: actionTypes.UPDATE_USER_ROLES_LIST,
  payload: data,
});

export const updateTableOrderList = (data) => ({
  type: actionTypes.UPDATE_TABLE_ORDER_LIST,
  payload: data,
});

export const updateDiningTableOrder = (data) => ({
  type: actionTypes.UPDATE_DINING_TABLE_ORDER,
  payload: data,
});

export const updateRestaurantCategoryMenuList = (data) => ({
  type: actionTypes.UPDATE_RESTAURANT_CATEGORY_MENU_LIST,
  payload: data,
});

export const updateRestaurantCategoryList = (data) => ({
  type: actionTypes.UPDATE_RESTAURANT_CATEGORY_LIST,
  payload: data,
});

export const updateRestaurantMenuList = (data) => ({
  type: actionTypes.UPDATE_RESTAURANT_MENU_LIST,
  payload: data,
});

export const updateRestaurantCourseList = (data) => ({
  type: actionTypes.UPDATE_RESTAURANT_COURSE_LIST,
  payload: data,
});

export const updateDiningSelectedFloor = (data) => ({
  type: actionTypes.UPDATE_DINING_SELECTED_FLOOR,
  payload: data,
});

export const updatePinLogin = (data) => ({
  type: actionTypes.UPDATE_PIN_LOGIN,
  payload: data,
});

export const emptyPinLogin = () => ({
  type: actionTypes.EMPTY_PIN_LOGIN,
});

export const updateUserDetail = (data) => ({
  type: actionTypes.UPDATE_USER_DETAIL,
  payload: data,
});

export const updateTableOrderBillingList = (data) => ({
  type: actionTypes.UPDATE_TABLE_ORDER_BILLING_LIST,
  payload: data,
});

export const updateOfflineSettings = (data) => ({
  type: actionTypes.UPDATE_OFFLINE_SETTINGS,
  payload: data,
});

export const updateActivePrinter = (data) => ({
  type: actionTypes.UPDATE_ACTIVE_PRINTER,
  payload: data,
});

export const updateRestaurantCustomerList = (data) => ({
  type: actionTypes.UPDATE_RESTAURANT_CUSTOMER_LIST,
  payload: data,
});

export const updateLoginListData = (data) => ({
  type: actionTypes.UPDATE_LOGIN_LIST_DATA,
  payload: data,
});

export const logOut = () => ({
  type: actionTypes.LOG_OUT,
});
