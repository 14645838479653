import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import DiningAddSubMenuBtn from "./DiningAddSubMenuBtn";

import {ReactComponent as DeleteIcon} from "../assets/images/delete_circle.svg";

import {THEME_MODE} from "../constants/Theme";

const CreateOrderModalCartItem = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  let item = props.item;

  return (
    <Box sx={styles.mainView}>
      <Box sx={{flex: 1}}>
        <Box sx={styles.nameView}>
          <Box sx={styles.nameTextView}>
            <Typography sx={styles.nameText}>{item.name}</Typography>
          </Box>
          <Box>
            <Box sx={styles.addNoteBtnView} ml={1.5} onClick={() => props.addNote(item)}>
              <Typography sx={styles.addNoteBtnText}>Add Note</Typography>
            </Box>
          </Box>
        </Box>
        {item?.note ? <Typography my={0.8} sx={styles.noteText}>
          Notes : {item.note}
        </Typography> : null}
        <Typography sx={styles.priceText}>{item.quantity}x {item.price.currency} {item.price.value.toFixed(2)}</Typography>
      </Box>
      <Box sx={styles.btnView} mt={1}>
        <Box sx={{cursor: 'pointer', lineHeight: 0}} mr={1.5} onClick={() => props.onDeleteMenu(item)}>
          <DeleteIcon width={26} height={26}/>
        </Box>
        <DiningAddSubMenuBtn
          count={item.quantity}
          minWidth={90}
          circleSize={26}
          addSubFontSize={16}
          onAddSubMenu={(type) => props.onAddSubMenu(type, item)}
          themeMode={props.themeMode}
        />
      </Box>
    </Box>
  );
}

export default CreateOrderModalCartItem;

const styles1 = Theme => ({
  mainView: {
    p: 1.5,
    borderBottom: '1px solid #DEDEDE'
  },
  nameView: {
    display: 'flex',
  },
  nameTextView: {
    flex: 1,
  },
  nameText: {
    color: Theme.text2,
    fontFamily: 'InterBold',
    fontSize: 16,
  },
  addNoteBtnView: {
    backgroundColor: Theme.btnBg,
    cursor: 'pointer',
    borderRadius: '5px',
    py: 0.6,
    px: 0.8,
  },
  addNoteBtnText: {
    color: Theme.btnText,
    fontFamily: 'InterBold',
    fontSize: 10,
  },
  noteText: {
    color: Theme.text2,
    fontFamily: 'InterMedium',
    fontSize: 12,
  },
  priceText: {
    color: Theme.text2,
    fontFamily: 'InterMedium',
    fontSize: 12,
  },
  btnView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
});
