import React from "react";
import { ReactComponent as NonVegIcon } from "../assets/images/nonveg.svg";
import { ReactComponent as VegIcon } from "../assets/images/veg.svg";
import { ReactComponent as PlantBasedIcon } from "../assets/images/plantbased.svg";
import { ReactComponent as SeafoodIcon } from "../assets/images/seafood.svg";

export const IS_2FA_LOGIN = process.env.REACT_APP_IS_2FA_LOGIN == "true";

export const THEME_COLOR = "#ffe342";
export const THEME_COLOR2 = "#C4C4C4";
export const THEME_COLOR3 = "#AAA";
export const LOADING_COLOR = "#000";
export const FOOTER_XPADDING = { light: 1.5, dark: 0 };

export const IS_LARGE_SCREEN = document.documentElement.clientWidth >= 1920;
console.log("screenwidth", document.documentElement.clientWidth);
export const NAVBAR_HEIGHT = IS_LARGE_SCREEN ? 90 : 64;

export const DINING_MODAL_WIDTH = {
  xs: window.innerWidth - 20,
  md: window.innerWidth / 2.2,
};

export const CURRENCY_LIST = [
  { label: "GBP - £", value: "gbp", currency: "GBP", symbol: "£" },
  { label: "Euro - €", value: "euro", currency: "EUR", symbol: "€" },
  {
    label: "Dirham - د. إ.",
    value: "dirham",
    currency: "AED",
    symbol: "د. إ.",
  },
  { label: "Rupee - ₹", value: "rupee", currency: "INR", symbol: "₹" },
  {
    label: "Bahraini Dinar - .د.ب",
    value: "bahraini dinar",
    currency: "BD",
    symbol: ".د.ب",
  },
  {
    label: "Saudi Riyal - ﷼",
    value: "saudi riyal",
    currency: "SR",
    symbol: "﷼",
  },
];

export const CURRENCY_SYMBOL = CURRENCY_LIST.reduce(
  (o, key) => ({ ...o, [key.currency]: key.symbol }),
  {}
);

export const MENU_TYPE = [
  "None",
  "Non vegetarian",
  "Vegetarian",
  "Plant based",
  "Seafood",
];

export const FIREBASE_CONFIG = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG
);

export const RESTAURANT_ADMIN_ROLE_ID =
  process.env.REACT_APP_RESTAURANT_ADMIN_ROLE_ID;
export const RESTAURANT_USER_ROLE_ID =
  process.env.REACT_APP_RESTAURANT_USER_ROLE_ID;

export const USER_ROLE_LIST = [
  { label: "Admin", value: RESTAURANT_ADMIN_ROLE_ID },
  { label: "User", value: RESTAURANT_USER_ROLE_ID },
];

export const SUBSCRIPTION_IDS = {
  starter: "647478594ec0fe09ca7db8e7",
  plus: "647478884ec0fe09ca7db8ed",
  premium: "6474789f4ec0fe09ca7db8ef",
};

export const DINING_DETAIL_CONTENT_HEIGHT = window.innerHeight;
export const DINING_DETAIL_BTN_HEIGHT = IS_LARGE_SCREEN ? 90 : 60;
export const CONTAINER_HEIGHT = window.innerHeight - NAVBAR_HEIGHT;

export const DINEIN = 1;
export const DELIVERY = 2;
export const PICKUP = 3;
export const TABLE = 4;

export const ORDER_TYPE = {
  dinein: DINEIN,
  delivery: DELIVERY,
  pickup: PICKUP,
  table: TABLE,
};

//1=>Single Payment, 2=>Split Payment
export const SPLIT_PAYMENT_TYPE = {
  single_payment: 1,
  split_payment: 2,
};

//1=>Equal Split, 2=>Split By 2, 3=>Split by Item
export const SPLIT_TYPE = {
  split_equal: 1,
  split_by_2: 2,
  split_by_item: 3,
  split_by_amount: 4,
};

//1=>Cash, 2=>E-Cash, 3=>Card, 4=>Gift Card
export const SPLIT_PAYMENT_METHOD = {
  cash: 1,
  ecash: 2,
  card: 3,
  giftcard: 4,
  invoice: 5,
};

//1=>All Item, 2=>Select Item
export const DISCOUNT_TYPE = {
  all_item: 1,
  select_item: 2,
};

//1=>Amount, 2=>Percentage
export const DISCOUNT_VALUE_TYPE = {
  amount: 1,
  percentage: 2,
};

export const APP_TYPE_FOODO = "FOODO";
export const APP_TYPE_GOPOSH = "GOPOSH";
export const APP_TYPE_BOTH = "BOTH";
