import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import { connect } from "react-redux";
import { updateRestaurantCustomerList } from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import useDebounce from "../../components/UseDebounce";
import DiningDetailAssign from "../../components/DiningDetailAssign";
import DiningDetailCustomer from "../../components/DiningDetailCustomer";
import AlertMsg from "../../components/AlertMsg";

import styles from "./Customers.module.css";

import { THEME_MODE } from "../../constants/Theme";
import {
  CONTAINER_HEIGHT,
  DINING_DETAIL_BTN_HEIGHT,
  FOOTER_XPADDING,
  IS_LARGE_SCREEN,
} from "../../constants";

import { ReactComponent as CloseIcon } from "../../assets/images/close_black.svg";

const Customers = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerPageNum, setCustomerPageNum] = useState(1);
  const [customerTotalPage, setCustomerTotalPage] = useState(0);
  const [searchCustomer, setSearchCustomer] = useState("");
  const [addCustomerModalVisible, setAddCustomerModalVisible] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    if (props.restaurantCustomerList.length) {
      setCustomerList(props.restaurantCustomerList);
    } else {
      setCustomerList([]);
    }
  }, [props.restaurantCustomerList]);

  useEffect(() => {
    getCustomerList(1);
  }, []);

  const getCustomerList = (num) => {
    setIsLoading(true);
    let filter = "?pageSize=500000&pageNum=" + num;
    // if (searchCustomer) {
    //   filter = filter + "&filter_name=" + searchCustomer;
    // }
    Api.getCustomerList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let data = response.data;
        setCustomerPageNum(num);
        setCustomerTotalPage(response.data.pages);
        if (data.rows.length) {
          getCustomerEvents(data.rows);
        } else {
          props.updateRestaurantCustomerList(data.rows);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const getCustomerEvents = (customer_list) => {
    let filter = "?pageSize=3000000&pageNum=1";
    Api.getCustomerEvents(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let data = response.data;
        let list = [];
        customer_list.map((x) => {
          list.push({
            ...x,
            events: data.rows.filter((y) => y._idRestaurantCustomer == x._id),
          });
        });
        props.updateRestaurantCustomerList(list);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const onSearchCustomer = (val) => {
    setSearchCustomer(val);
  };

  const onSelectPage = (val) => {
    getCustomerList(val);
  };

  const onSelectCustomer = (val) => {
    setSelectedCustomer(val);
    setAddCustomerModalVisible(true);
  };

  const addCustomerBtnClick = () => {
    setSelectedCustomer(null);
    setAddCustomerModalVisible(true);
  };

  const toggleDrawer = (val) => {
    setSelectedCustomer(null);
    setAddCustomerModalVisible(val);
  };

  const onAddCustomer = () => {
    setAddCustomerModalVisible(false);
  };

  return (
    <Container
      page={"customers"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0}
    >
      <Grid
        p={1.5}
        style={{ backgroundColor: theme_mode.card2, borderRadius: 15 }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <DiningDetailCustomer
            customers={customerList.filter(
              (x) =>
                x.name.toLowerCase().match(searchCustomer.toLowerCase()) ||
                x.email.toLowerCase().match(searchCustomer.toLowerCase()) ||
                x.phoneNumber.toLowerCase().match(searchCustomer.toLowerCase())
            )}
            selected={null}
            searchCustomer={searchCustomer}
            leftViewWidth={"100%"}
            contentHeight={CONTAINER_HEIGHT - 60}
            themeMode={props.themeMode}
            pageNum={customerPageNum}
            totalPages={customerTotalPage}
            onSelectPage={onSelectPage}
            onSelect={onSelectCustomer}
            addCustomer={addCustomerBtnClick}
            onSearchCustomer={onSearchCustomer}
            showHeight={false}
          />
        )}
      </Grid>
      <SwipeableDrawer
        anchor={"right"}
        open={addCustomerModalVisible}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: theme_mode.card2,
          },
        }}
      >
        <Box
          sx={{
            width: {
              xs: window.innerWidth,
              sm: window.innerWidth / 2,
              md: window.innerWidth / 3,
            },
            height: "100%",
          }}
        >
          <Box
            className={styles.modalHeaderView}
            sx={{ backgroundColor: theme_mode.modalHeaderBg }}
            px={2}
          >
            <Typography
              className={styles.modalHeaderText}
              sx={{ color: theme_mode.lightText }}
            >
              {selectedCustomer ? "Edit" : "Add"} Customer
            </Typography>
            <Box className="cursor-pointer" onClick={() => toggleDrawer(false)}>
              <CloseIcon width={40} height={40} />
            </Box>
          </Box>
          <DiningDetailAssign
            page={"Customers"}
            customer={selectedCustomer}
            assigned={null}
            modalVisible={addCustomerModalVisible}
            editCustomer={true}
            restaurantId={props.restaurantId}
            dataViewHeight={
              IS_LARGE_SCREEN
                ? window.innerHeight - DINING_DETAIL_BTN_HEIGHT - 90
                : window.innerHeight - DINING_DETAIL_BTN_HEIGHT - 70
            }
            btnText={selectedCustomer ? "CLOSE" : "ADD CUSTOMER"}
            restaurantCustomerList={props.restaurantCustomerList}
            updateRestaurantCustomerList={props.updateRestaurantCustomerList}
            onSubmit={onAddCustomer}
            onCancel={() => toggleDrawer(false)}
            themeMode={props.themeMode}
          />
        </Box>
      </SwipeableDrawer>
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    restaurantCustomerList: state.userData.restaurantCustomerList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantCustomerList: (data) =>
      dispatch(updateRestaurantCustomerList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
