import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import moment from "moment";

import {connect} from "react-redux";
import {updateCreateOrderModalVisible} from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import Container from "../../components/Container";
import DiningOrderItemList from "../../components/DiningOrderItemList";
import DiningOrderDetailModal from "../../components/DiningOrderDetailModal";
import DiningConfirmAlert from "../../components/DiningConfirmAlert";
import CreateOrderModal from "../../components/CreateOrderModal";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";

import {ReactComponent as SearchIcon} from "../../assets/images/search.svg";
import {ReactComponent as CloseIcon} from "../../assets/images/close.svg";

import {THEME_MODE} from "../../constants/Theme";
import {DINEIN} from "../../constants";

let countBg = {
  placed_order: '#F17400',
  preparing: '#CBB11E',
  pending_payment: '#CD0000'
}

let cat_page_size = 30000;
let menu_page_size = 30000;

const Dining = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [isLoading, setIsLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [placedOrderList, setPlacedOrderList] = useState([]);
  const [preparingList, setPreparingList] = useState([]);
  const [pendingPaymentList, setPendingPaymentList] = useState([]);
  const [drawerSideMenuData, setDrawerSideMenuData] = useState(null);
  const [drawerSideMenuVisible, setDrawerSideMenuVisible] = useState(false);
  const [alertConfirmModalData, setAlertConfirmModalData] = useState(null);
  const [createOrderModalVisible, setCreateOrderModalVisible] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [menuList, setMenuList] = useState({list: []});
  const [createOrderModalData, setCreateOrderModalData] = useState(null);
  const [priceUnit, setPriceUnit] = useState('');
  const [noOfTables, setNoOfTables] = useState('');
  const [searchTable, setSearchTable] = useState('');
  const [msgAlert, setMsgAlert] = useState({open: false, message: '', msgType: 'error'});

  useEffect(() => {
    if (props.tableOrderNotif) {
      let data = props.tableOrderNotif;
      let cart_list = [];
      data.cart.map(y => {
        let findIndex = menuList?.list.findIndex(z => z._id == y._idMenu);
        if (findIndex >= 0) {
          cart_list.push({...y, ...menuList[findIndex]})
        }
      });
      let list_item = {
        ...data,
        date: moment(data.createdAt).format('DD MMM Y, HH:mm A'),
        name: data?.userDetails?.name,
        price: {value: data.totalAmount, currency: priceUnit},
        tableNo: 22,
        cart: cart_list,
      };

      if (data?.orderStatus == 'pending_payment') {
        let list = [...pendingPaymentList];
        let findItemIndex = list.findIndex(z => z._id == data._id);
        if (findItemIndex >= 0) {
          list[findItemIndex] = list_item;
          setPendingPaymentList(list);
        } else {
          setPendingPaymentList([...pendingPaymentList, list_item]);
        }
      } else if (data?.orderStatus == 'preparing') {
        let list = [...preparingList];
        let findItemIndex = list.findIndex(z => z._id == data._id);
        if (findItemIndex >= 0) {
          list[findItemIndex] = list_item;
          setPreparingList(list);
        } else {
          setPreparingList([...preparingList, list_item]);
        }
      } else if (data?.orderStatus == 'placed_order') {
        let list = [...placedOrderList];
        let findItemIndex = list.findIndex(z => z._id == data._id);
        if (findItemIndex >= 0) {
          list[findItemIndex] = list_item;
          setPlacedOrderList(list);
        } else {
          setPlacedOrderList([...placedOrderList, list_item]);
        }
      }

    }
  }, [props.tableOrderNotif]);

  useEffect(() => {
    if (props.createOrderModalVisible) {
      props.updateCreateOrderModalVisible(false);
      setCreateOrderModalVisible(props.createOrderModalVisible);
      let parts = window.location.href.split('/');
      if (parts.length > 0) {
        if (parts[parts.length - 1] == 'dining') {
          onCreateOrderModal();
        }
      }
    }
  }, [props.createOrderModalVisible]);

  useEffect(() => {
    getRestaurantData();
  }, []);

  const getRestaurantData = () => {
    Api.getRestaurantData(props.restaurantId).then((response) => {
      if (response.success) {
        let data = response.data;
        setNoOfTables(data.noOfTables);
        getCategoryList();
      } else {
        setIsLoading(false);
        setModalLoading(false);
        setMsgAlert({open: true, message: response.msg, msgType: 'error'});
      }
    });
  };

  const getCategoryList = () => {
    setIsLoading(true);
    // let filter = '?pageSize=' + cat_page_size + '&pageNum=1&orderBy=order&orderByDir=asc';
    let filter = 'all';
    Api.getCategoryList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        const data = response.data.rows.sort((a, b) => a?.order - b?.order);
        if (data.length > 0) {
          getMenuList(data);
        } else {
          setIsLoading(false);
          setModalLoading(false);
        }
      } else {
        setIsLoading(false);
        setModalLoading(false);
      }
    });
  };

  const getMenuList = (category) => {
    let filter = '?pageSize=' + menu_page_size + '&pageNum=1&orderBy=order&orderByDir=asc';
    Api.getMenuList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        let currency = '';
        if (data.length) {
          setPriceUnit(data[0]['price']?.currency);
          currency = data[0]['price']?.currency;
        }
        setCategoryList(category);
        if (props.createOrderModalVisible) {
          let list = [];
          category.forEach(x => {
            let cat_menu = {
              ...x,
              cart: data.filter(y => y._idCategory == x._id)
            };
            list.push(cat_menu);
          });
          setCreateOrderModalData({type: 'add', list: JSON.parse(JSON.stringify(list))});
        }
        setMenuList(JSON.parse(JSON.stringify({list: data})));
        setModalLoading(false);
        getOrderList(data, currency);
      } else {
        setIsLoading(false);
        setModalLoading(false);
      }
    });
  };

  const getOrderList = (menu_list, currency) => {
    setIsLoading(true);
    let filter =
      "pageSize=30000&pageNum=1&filter_orderStatus=" +
      '["placed_order", "preparing", "pending_payment"]';
    filter = filter + "&filter_orderType=[" + DINEIN + "]";
    Api.getOrders(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        let placed_order_list = [];
        let preparing_list = [];
        let pending_payment_list = [];
        data.map((x, i) => {
          let cart_list = [];
          x.cart.map(y => {
            let findIndex = menu_list.findIndex(z => z._id == y._idMenu);
            if (findIndex >= 0) {
              cart_list.push({...y, ...menu_list[findIndex]})
            }
          });
          let list_item = {
            ...x,
            date: moment(x.createdAt).format('DD MMM Y, HH:mm A'),
            name: x?.userDetails?.name,
            price: {value: x.totalAmount, currency: currency},
            tableNo: 22,
            cart: cart_list,
          };
          if (x?.orderStatus == 'preparing') {
            preparing_list.push(list_item)
          } else if (x?.orderStatus == 'pending_payment') {
            pending_payment_list.push(list_item)
          } else if (x?.orderStatus == 'placed_order') {
            placed_order_list.push(list_item)
          }
        });
        setPlacedOrderList(placed_order_list);
        setPreparingList(preparing_list);
        setPendingPaymentList(pending_payment_list);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }

  const onUpdateOrder = (type, item) => {
    let list = [];
    let menu_list = JSON.parse(JSON.stringify(menuList.list));
    item.cart.map((x, i) => {
      let findIndex = menu_list.findIndex(y => y._idCategory == x._idCategory && y._id == x._id);
      if (findIndex >= 0) {
        menu_list[findIndex]['quantity'] = x.quantity;
      }
    });

    categoryList.forEach(x => {
      let cat_menu = {
        ...x,
        cart: menu_list.filter(y => y._idCategory == x._id)
      };
      list.push(cat_menu);
    });
    setCreateOrderModalData({type: 'edit', list: JSON.parse(JSON.stringify(list)), item: item});
    setCreateOrderModalVisible(true);
  }

  const onSelectOrderMenu = (item) => {
    setDrawerSideMenuData(item);
    setDrawerSideMenuVisible(true);
  }

  const onDiningSubmitClick = (item) => {
  }

  const onOrderModalSubmitClick = (status) => {
    onUpdateTableOrder('status_update', drawerSideMenuData, status);
  }

  const onOrderModalCancelClick = (status) => {
    onUpdateTableOrder('status_update', drawerSideMenuData, status);
  }

  const toggleDrawer = (val) => {
    setDrawerSideMenuVisible(val);
  }

  const toggleConfirmModal = (val) => {
    setAlertConfirmModalData(val);
  }

  const toggleCreateOrderModal = (val) => {
    setCreateOrderModalVisible(val);
  }

  const onCreateOrderModal = () => {
    let list = [];
    categoryList.forEach(x => {
      let cat_menu = {
        ...x,
        cart: menuList.list.filter(y => y._idCategory == x._id)
      };
      list.push(cat_menu);
    });
    setCreateOrderModalData({type: 'add', list: JSON.parse(JSON.stringify(list))});
    setCreateOrderModalVisible(true);
  }

  const onPlaceOrder = (data) => {
    if (createOrderModalData?.type == 'add') {
      setListLoading(true);
      let cart_items = [];
      data.cart.map(x => {
        cart_items.push({
          _idMenu: x._id,
          quantity: x.quantity,
          note: x.note,
        })
      });
      let data1 = {
        _idRestaurant: props.restaurantId,
        _idRestaurantTable: "62ce2284df743b3d9eaa6e74",
        orderStatus: 'placed_order',
        totalAmount: data.price.value,
        cart: cart_items,
        userDetails: {
          name: data.name
        },
      };

      let datas = JSON.stringify(data1);
      // Api.createTableOrder(datas).then((response) => {
      //   setListLoading(false);
      //   if (response.success) {
      //     setPlacedOrderList([...placedOrderList, {
      //       ...data,
      //       _id: response?.data?._id,
      //       orderStatus: 'placed_order',
      //       date: moment(response?.data?.createdAt).format('DD MMM Y, HH:mm A')
      //     }]);
      //     toggleCreateOrderModal(false);
      //   } else {
      //     setMsgAlert({open: true, message: response.msg, msgType: 'error'});
      //   }
      // });
    } else {
      onUpdateTableOrder('update_order', data, data.orderStatus);
    }
  }

  const onAddSubMenuItem = (type, item) => {
    let list = [...placedOrderList];
    if (drawerSideMenuData.orderStatus == 'preparing') {
      list = [...preparingList];
    } else if (drawerSideMenuData.orderStatus == 'pending_payment') {
      list = [...pendingPaymentList];
    }
    let findIndex = list.findIndex(x => x._id == drawerSideMenuData._id);
    if (findIndex >= 0) {
      let menuIndex = list[findIndex]['cart'].findIndex(x => x._id == item._id);
      if (menuIndex >= 0) {
        let count = list[findIndex]['cart'][menuIndex]['quantity'] ? list[findIndex]['cart'][menuIndex]['quantity'] : 0;
        if (type == '-' && count > 0) {
          count = parseInt(count) - 1
        }
        if (type == '+') {
          count = parseInt(count) + 1
        }

        if (count > 0) {
          list[findIndex]['cart'][menuIndex]['quantity'] = count;
          onUpdateTableOrder('update_cart_item_quantity', list[findIndex], list[findIndex]['orderStatus']);
        }
      }
    }
  }

  const onConfirmDelete = () => {
    let list = [...placedOrderList];
    if (drawerSideMenuData?.orderStatus == 'preparing') {
      list = [...preparingList];
    } else if (drawerSideMenuData?.orderStatus == 'pending_payment') {
      list = [...pendingPaymentList];
    }
    let findIndex = list.findIndex(x => x._id == drawerSideMenuData._id);
    if (findIndex >= 0) {
      let data = {...drawerSideMenuData};
      list[findIndex]['cart'] = data.cart.filter(x => x._id != alertConfirmModalData._id);
      onUpdateTableOrder('delete_cart_item', list[findIndex], list[findIndex]['orderStatus']);
    }
  }

  const onUpdateTableOrder = (update_type, item_data, status) => {
    if (update_type != 'update_cart_item_quantity') {
      setListLoading(true);
    }
    let cart_items = [];
    item_data.cart.map(x => {
      let cart_item = {
        _idMenu: x._id,
        quantity: x.quantity,
        note: x?.note ? x.note : '',
      }
      if (update_type == 'status_update') {
        if (status == 'pending_payment') {
          cart_item = {...cart_item, status: 'served'}
        }
      }
      cart_items.push(cart_item)
    });
    let data1 = {
      _idRestaurant: props.restaurantId,
      _idRestaurantTable: item_data._idRestaurantTable,
      orderStatus: status,
      totalAmount: item_data.price.value,
      userDetails: item_data.userDetails,
      cart: cart_items
    };

    let datas = JSON.stringify(data1);
    // Api.updateTableOrder(datas, item_data._id).then((response) => {
    //   if (response.success) {
    //     let data_list = [];
    //     if (item_data?.orderStatus == 'preparing') {
    //       data_list = [...preparingList];
    //     } else if (item_data?.orderStatus == 'pending_payment') {
    //       data_list = [...pendingPaymentList];
    //     } else if (item_data?.orderStatus == 'placed_order') {
    //       data_list = [...placedOrderList];
    //     }
    //     if (update_type == 'status_update') {
    //       let list_item = {
    //         ...item_data,
    //         orderStatus: status,
    //       };
    //
    //       let list = data_list.filter(z => z._id != item_data._id);
    //       if (item_data?.orderStatus == 'preparing') {
    //         setPreparingList(list);
    //       } else if (item_data?.orderStatus == 'placed_order') {
    //         setPlacedOrderList(list);
    //       } else if (item_data?.orderStatus == 'pending_payment') {
    //         setPendingPaymentList(list);
    //       }
    //       if (item_data?.orderStatus == 'placed_order' && data1?.orderStatus == 'preparing') {
    //         setPreparingList([...preparingList, list_item]);
    //       } else if (item_data?.orderStatus == 'preparing' && data1?.orderStatus == 'pending_payment') {
    //         setPendingPaymentList([...pendingPaymentList, list_item]);
    //       }
    //       setDrawerSideMenuData(null)
    //       setDrawerSideMenuVisible(false);
    //     } else if (update_type == 'update_order') {
    //       let findIndex = data_list.findIndex(x => x._id == item_data._id);
    //       if (findIndex >= 0) {
    //         data_list[findIndex] = item_data;
    //       }
    //       if (item_data?.orderStatus == 'preparing') {
    //         setPreparingList(data_list);
    //       } else if (item_data?.orderStatus == 'pending_payment') {
    //         setPendingPaymentList(data_list);
    //       } else {
    //         setPlacedOrderList(data_list);
    //       }
    //       setDrawerSideMenuData({...item_data});
    //       toggleCreateOrderModal(false);
    //     } else if (update_type == 'delete_cart_item' || update_type == 'update_cart_item_quantity') {
    //       let findIndex = data_list.findIndex(x => x._id == item_data._id);
    //       if (findIndex >= 0) {
    //         data_list[findIndex] = item_data;
    //         if (item_data?.orderStatus == 'preparing') {
    //           setPreparingList(data_list);
    //         } else if (item_data?.orderStatus == 'pending_payment') {
    //           setPendingPaymentList(data_list);
    //         } else {
    //           setPlacedOrderList(data_list);
    //         }
    //         setDrawerSideMenuData(data_list[findIndex]);
    //         if (update_type == 'delete_cart_item') {
    //           setAlertConfirmModalData(null);
    //         }
    //       }
    //     }
    //   } else {
    //     setMsgAlert({open: true, message: response.msg, msgType: 'error'});
    //   }
    //   setListLoading(false);
    // });
  }

  const PlacedOrderListView = React.useMemo(() => {
    return (
      placedOrderList.map((x, i) => {
        return (
          <DiningOrderItemList
            key={i}
            index={i}
            item={x}
            type={'placed_order'}
            themeMode={props.themeMode}
            onItemClick={onSelectOrderMenu}
            onSubmitClick={onDiningSubmitClick}
          />
        )
      })
    )
  }, [placedOrderList]);

  const PreparingListView = React.useMemo(() => {
    return (
      preparingList.map((x, i) => {
        return (
          <DiningOrderItemList
            key={i}
            index={i}
            item={x}
            type={'preparing'}
            themeMode={props.themeMode}
            onItemClick={onSelectOrderMenu}
            onSubmitClick={onDiningSubmitClick}
          />
        )
      })
    )
  }, [preparingList]);

  const PendingPaymentListView = React.useMemo(() => {
    return (
      pendingPaymentList.map((x, i) => {
        return (
          <DiningOrderItemList
            key={i}
            index={i}
            item={x}
            type={'pending_payment'}
            themeMode={props.themeMode}
            onItemClick={onSelectOrderMenu}
            onSubmitClick={onDiningSubmitClick}
          />
        )
      })
    )
  }, [pendingPaymentList]);

  const TitleView = ({title, count, type}) => {
    return (
      <Box sx={styles.listTitleView} mt={1}>
        <Typography sx={styles.listTitleText}>{title}</Typography>
        <Box sx={{backgroundColor: countBg[type], ...styles.countView}}>
          <Typography sx={styles.countText}>
            {count > 9 ? count : '0' + count}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Container page={"dining"} navTitle={'Table orders'}>
      {isLoading ? <Loader height={{height: window.innerHeight - 150}}/> : null}
      {listLoading ?
        <Box sx={styles.loader}>
          <Loader height={{height: window.innerHeight - 150}}/>
        </Box>
        : null}
      {!isLoading ? <>
        <Grid sx={{position: 'relative'}} container justifyContent="space-between" alignItems="center" mb={1}>
          <Grid item xs={12} md={12} container justifyContent="flex-end" alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                focused
                value={searchTable}
                variant="filled"
                autoFocus={searchTable != ''}
                placeholder={"Search Tables"}
                hiddenLabel={true}
                onChange={e => setSearchTable(e.target.value)}
                sx={styles.searchBox}
                InputProps={{
                  disableUnderline: true,
                  autoComplete: 'none',
                  sx: styles.searchBoxInput,
                  startAdornment: (
                    <InputAdornment position="start" sx={{marginLeft: 1, cursor: 'pointer'}}>
                      <SearchIcon height={15} width={15}/>
                    </InputAdornment>),
                  endAdornment: searchTable != '' ? (
                    <InputAdornment
                      onClick={() => setSearchTable('')} position="end"
                      sx={{marginLeft: 1, cursor: 'pointer'}}>
                      <CloseIcon height={15} width={15}/>
                    </InputAdornment>) : null,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={12} md={3.9} container sx={styles.listMainView}>
            <TitleView type={'placed_order'} title={'Placed Orders'} count={placedOrderList.length}/>
            {PlacedOrderListView}
          </Grid>
          <Grid item xs={12} md={3.9} container sx={styles.listMainView}>
            <TitleView type={'preparing'} title={'Preparing'} count={preparingList.length}/>
            {PreparingListView}
          </Grid>
          <Grid item xs={12} md={3.9} container sx={styles.listMainView}>
            <TitleView type={'pending_payment'} title={'Pending Payment'} count={pendingPaymentList.length}/>
            {PendingPaymentListView}
          </Grid>
        </Grid>
        <DiningConfirmAlert
          page={'dining'}
          data={alertConfirmModalData}
          themeMode={props.themeMode}
          onClose={() => toggleConfirmModal(null)}
          onDelete={onConfirmDelete}
        />
      </> : null}
      <DiningOrderDetailModal
        open={drawerSideMenuVisible}
        data={drawerSideMenuData}
        themeMode={props.themeMode}
        onClose={() => toggleDrawer(false)}
        onDeleteItem={(d) => setAlertConfirmModalData(d)}
        onSubmit={onOrderModalSubmitClick}
        onCancel={onOrderModalCancelClick}
        onAddSubMenuItem={onAddSubMenuItem}
        onUpdateOrder={onUpdateOrder}
      />
      <CreateOrderModal
        open={createOrderModalVisible}
        isLoading={modalLoading}
        noOfTables={noOfTables}
        addEdit={createOrderModalData?.type ? createOrderModalData?.type : 'add'}
        categoryMenuList={createOrderModalData?.list ? createOrderModalData?.list : []}
        editData={createOrderModalData?.item ? createOrderModalData?.item : null}
        priceUnit={priceUnit}
        onClose={() => toggleCreateOrderModal(false)}
        onPlaceOrder={onPlaceOrder}
      />
      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={() => setMsgAlert({open: false, message: '', msgType: 'error'})}/>
    </Container>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    updateCreateOrderModalVisible: (data) => dispatch(updateCreateOrderModalVisible(data)),
  }
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    tableOrderNotif: state.userData.tableOrderNotif,
    createOrderModalVisible: state.userData.createOrderModalVisible,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dining);

const styles1 = Theme => ({
  searchBox: {
    '& .MuiInputBase-root': {
      "&:hover": {
        backgroundColor: Theme.search,
      },
      "&.Mui-focused": {
        backgroundColor: Theme.search,
      },
      "&.Mui-disabled": {
        backgroundColor: Theme.search,
      },
    },
  },
  searchBoxInput: {
    backgroundColor: Theme.search,
    fontSize: 12,
    fontFamily: 'InterSemiBold',
    height: 40,
    borderRadius: 2,
    width: '100%',
  },
  loader: {
    backgroundColor: 'rgba(238, 238, 238, 0.5)',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  listMainView: {},
  listTitleView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    mb: 1.5,
  },
  listTitleText: {
    color: Theme.text2,
    fontFamily: 'InterBold',
    textTransform: 'capitalize',
    fontSize: 18,
    mr: 1,
  },
  countView: {
    display: 'flex',
    width: '26px',
    height: '26px',
    borderRadius: '13px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  countText: {
    color: Theme.lightText,
    fontFamily: 'InterSemiBold',
    fontSize: 11,
  },
});
