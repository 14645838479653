import React from 'react';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';

import './../assets/css/custom.css';

import {THEME_MODE} from "../constants/Theme";

const Page = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const handleChange = (event, value) => {
    props.onSelectPage(value);
  };

  return (
    <Grid className="pagination-main-grid">
      <Grid className="pagination-grid" sx={{
        backgroundColor: theme_mode.page,
        border: '1px solid ' + theme_mode.page
      }}>
        <Pagination sx={{
          '& .MuiPaginationItem-root': {
            color: theme_mode.pageText,
            fontFamily: 'InterSemiBold',
            fontSize: 13,
            '&.Mui-selected': {
              backgroundColor: theme_mode.theme
            }
          }
        }} count={props.totalPages} page={props.pageNum} onChange={handleChange}/>
      </Grid>
    </Grid>
  );
}

export default Page;
