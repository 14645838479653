import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { THEME_MODE } from "../constants/Theme";
import { MENU_GAP_PADDING, MENU_LEFT_PADDING } from "./DiningDetailCatMenuList";
import { IS_LARGE_SCREEN } from "../constants";

import styles from "./DiningTopBtn.module.css";

const DiningTopBtn = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();
  const [menuItemWidth, setMenuItemWidth] = useState(
    IS_LARGE_SCREEN ? 250 : 150
  );

  let btnList = [
    {
      btn: props.selectedCourse?.name
        ? props.selectedCourse.name + " AWAY"
        : "SELECT COURSE",
      type: "course_away",
      enabled: props.orderData ? true : false,
    },
    { btn: "ALL TOGETHER", type: "all_together", enabled: true },
    {
      btn: "VOID TABLE",
      type: "void_table",
      enabled: props.orderData ? props.isAdmin : true,
    },
    { btn: "MISC", type: "misc", enabled: true },
  ];

  useEffect(() => {
    if (document.getElementById("rightViewId")) {
      setMenuItemWidth(document.getElementById("rightViewId").clientWidth);
    }
  }, []);

  const onActionClick = (btn) => {
    if (btn.type == "void_table") {
      props.onVoidTable();
    } else if (btn.type == "course_away") {
      if (props.selectedCourse?.name) {
        props.onCourseAway(props.selectedCourse);
      }
    }
  };

  return (
    <Box className={styles.navbarView} ml={`${MENU_LEFT_PADDING}px`}>
      {btnList.map((x, i) => {
        return (
          <Box
            key={i}
            px={2}
            onClick={() =>
              x.enabled || x.type == "void_table" ? onActionClick(x) : null
            }
            className={styles.voidBtn}
            mr={`${MENU_LEFT_PADDING}px`}
            style={{
              width: menuItemWidth / 3,
              cursor: x.enabled ? "pointer" : "default",
              backgroundColor: x.enabled ? theme_mode.diningCartHeader : "#777",
            }}
          >
            <Typography
              className={styles.voidBtnText}
              style={{ color: theme_mode.lightText }}
            >
              {x.btn}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default DiningTopBtn;
