import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate, useLocation } from "react-router-dom";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import AddSettingCategory from "../../components/AddSettingCategory";

import { THEME_MODE } from "../../constants/Theme";
import { FOOTER_XPADDING } from "../../constants";

const AddCategory = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [catData, setCatData] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    let params = location.pathname.split("/");
    if (params.length >= 3) {
      let catId = params[2];
      getCategoryData(catId);
    } else {
      setIsLoading(false);
    }
  }, []);

  const getCategoryData = (catId) => {
    Api.getCategoryData(props.restaurantId, catId).then((response) => {
      if (response.success) {
        let data = response.data;
        let imageList = data.images.map((x) => {
          let index = x.lastIndexOf("/") + 1;
          let filename = x.substr(index);
          return {
            id: new Date(),
            name: filename,
            displayName: filename,
            size: 0,
            image: x,
            upload: true,
          };
        });
        data.images = imageList;
        setCatData(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Container
      page={"settings"}
      bgColor={theme_mode.bg2}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0}
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <Grid
          container
          item
          xs={12}
          p={1.5}
          style={{ backgroundColor: theme_mode.card2, borderRadius: 15 }}
        >
          <Grid item xs={12} md={6} pb={2}>
            <AddSettingCategory
              catData={catData}
              restaurantId={props.restaurantId}
              themeMode={props.themeMode}
              onCancel={() => navigate("/settings/category")}
              onSubmit={() => navigate("/settings/category")}
            />
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantId: state.userData.restaurantId,
  };
};

export default connect(mapStateToProps, null)(AddCategory);
