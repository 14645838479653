import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import SettingTextField from "../../components/SettingTextField";
import SettingSelectField from "../../components/SettingSelectField";
import AlertMsg from "../../components/AlertMsg";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";

import { THEME_MODE } from "../../constants/Theme";
import { CONTAINER_HEIGHT } from "../../constants";

let country_list = [
  { label: "UK", value: "UK" },
  { label: "UAE", value: "UAE" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "India", value: "India" },
];

const AccountDetail = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [countryVal, setCountryVal] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [iban, setIban] = useState("");

  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const [error, setError] = useState(null);
  const [editable, setEditable] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [accountDetailData, setAccountDetailData] = useState(null);

  useEffect(() => {
    setError(null);
    getRestaurantAccountDetail();
  }, []);

  useEffect(() => {
    if (editable) {
      let data = { ...accountDetailData };
      if (
        data.accountNumber !== accountNumber ||
        data.beneficiaryName !== beneficiaryName ||
        data.iban !== iban ||
        data.country !== countryVal ||
        data.sortCode !== sortCode
      ) {
        setSaveBtnDisabled(false);
      } else {
        setSaveBtnDisabled(true);
      }
    }
  }, [accountNumber, beneficiaryName, iban, countryVal, sortCode]);

  const getRestaurantAccountDetail = () => {
    setLoading(true);
    Api.getRestaurantAccountDetail(props.restaurantId).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        if (data.length) {
          setAccountDetailData(data[0]);
          setAccountNumber(data[0]["accountNumber"]);
          setBeneficiaryName(data[0]["beneficiaryName"]);
          setIban(data[0]["iban"]);
          setCountryVal(data[0]["country"]);
          setSortCode(data[0]["sortCode"]);
        } else {
          setEditable(true);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onSaveAccountDetail = () => {
    let err = null;

    if (countryVal.trim() == "") {
      err = { ...err, country: "Country Required" };
    }

    if (beneficiaryName.trim() == "") {
      err = { ...err, beneficiaryName: "Beneficiary Name Required" };
    }

    if (accountNumber == "" || accountNumber == null) {
      err = { ...err, accountNumber: "Account Number Required" };
    } else {
      if (/[^0-9]/g.test(accountNumber)) {
        err = { ...err, accountNumber: "Invalid Account Number" };
      }
    }

    if (sortCode == "" || sortCode == null) {
      err = { ...err, sortCode: "Sort Code Required" };
    } else {
      if (/[^0-9]/g.test(sortCode)) {
        err = { ...err, sortCode: "Invalid Sort Code" };
      }
    }

    if (iban == "" || iban == null) {
      err = { ...err, iban: "IBAN Required" };
    } else {
      if (/[^0-9]/g.test(iban)) {
        err = { ...err, iban: "Invalid IBAN" };
      }
    }

    setError(err);
    if (err != null) {
      return;
    }

    let data = {
      _idRestaurant: props.restaurantId,
      beneficiaryName: beneficiaryName,
      sortCode: sortCode,
      iban: iban,
      country: countryVal,
      accountNumber: accountNumber,
    };
    let datas = JSON.stringify(data);

    setLoading(true);
    Api.addUpdateRestaurantAccountDetail(
      datas,
      props.restaurantId,
      accountDetailData?._id ? accountDetailData?._id : null
    ).then((response) => {
      if (response.success) {
        if (accountDetailData?._id) {
          setAccountDetailData({
            ...accountDetailData,
            ...data,
          });
        } else {
          setAccountDetailData(response.data);
        }
        setEditable(false);
        setSaveBtnDisabled(true);
        setMsgAlert({
          open: true,
          message: response.msg,
          msgType: "success",
        });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onEditSettings = () => {
    setEditable(true);
  };

  return (
    <Container page={"settings"} p={1.5} fpx={0} fpy={0.5}>
      {loading ? (
        <Loader />
      ) : (
        <Grid
          p={1.5}
          item
          xs={12}
          style={{
            backgroundColor: theme_mode.card3,
            borderRadius: 15,
            minHeight: CONTAINER_HEIGHT - 70,
          }}
        >
          <Grid container item md={6} xs={12} mt={1}>
            <Box
              onClick={() => navigate("/settings")}
              sx={{ cursor: "pointer" }}
            >
              <BackBtnIcon width={33} height={33} />
            </Box>
            <Grid container item xs={12} sx={styles.textInputMainView}>
              <Grid item xs={4.5} sx={styles.textInputLabelView}>
                <Typography sx={styles.textInputLabel}>Country</Typography>
              </Grid>
              <Grid item xs={7.5}>
                <SettingSelectField
                  page={"settings"}
                  value={countryVal}
                  setValue={setCountryVal}
                  editable={editable}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  menuList={country_list}
                  error={error?.country}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={styles.textInputMainView}>
              <Grid item xs={4.5} sx={styles.textInputLabelView}>
                <Typography sx={styles.textInputLabel}>
                  Beneficiary Name
                </Typography>
              </Grid>
              <Grid item xs={7.5}>
                <SettingTextField
                  page={"accountDetail"}
                  value={beneficiaryName}
                  setValue={setBeneficiaryName}
                  editable={editable}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  error={error?.beneficiaryName}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={styles.textInputMainView}>
              <Grid item xs={4.5} sx={styles.textInputLabelView}>
                <Typography sx={styles.textInputLabel}>
                  Account Number
                </Typography>
              </Grid>
              <Grid item xs={7.5}>
                <SettingTextField
                  page={"accountDetail"}
                  value={accountNumber}
                  setValue={setAccountNumber}
                  editable={editable}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  type={"number"}
                  error={error?.accountNumber}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={styles.textInputMainView}>
              <Grid item xs={4.5} sx={styles.textInputLabelView}>
                <Typography sx={styles.textInputLabel}>Sort Code</Typography>
              </Grid>
              <Grid item xs={7.5}>
                <SettingTextField
                  page={"accountDetail"}
                  value={sortCode}
                  setValue={setSortCode}
                  editable={editable}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  type={"number"}
                  error={error?.sortCode}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={styles.textInputMainView}>
              <Grid item xs={4.5} sx={styles.textInputLabelView}>
                <Typography sx={styles.textInputLabel}>IBAN</Typography>
              </Grid>
              <Grid item xs={7.5}>
                <SettingTextField
                  page={"accountDetail"}
                  value={iban}
                  setValue={setIban}
                  editable={editable}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  type={"number"}
                  error={error?.iban}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={styles.textInputMainView}>
              <Grid item xs={4.5} sx={styles.textInputLabelView}></Grid>
              <Grid item xs={7.5}>
                <Box sx={styles.btnMainView}>
                  <Button
                    type="button"
                    sx={styles.editBtn}
                    onClick={onEditSettings}
                  >
                    Edit
                  </Button>
                  <Button
                    type="button"
                    disabled={saveBtnDisabled}
                    sx={{
                      ...styles.saveBtn,
                      backgroundColor: saveBtnDisabled
                        ? theme_mode.btnDisabledBg
                        : theme_mode.btnBg4,
                    }}
                    onClick={onSaveAccountDetail}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantId: state.userData.restaurantId,
  };
};

export default connect(mapStateToProps, null)(AccountDetail);

const styles1 = (Theme) => ({
  textInputMainView: {
    mt: 2,
  },
  textInputLabelView: {
    display: "flex",
    alignItems: "center",
  },
  textInputLabel: {
    color: Theme.text2,
    fontSize: 14.5,
    fontFamily: "InterSemiBold",
  },
  btnMainView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    mt: 2,
  },
  saveBtn: {
    backgroundColor: Theme.btnBg4,
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.btnText,
    fontFamily: "InterSemiBold",
    fontSize: 15,
    borderRadius: "9px",
    height: 44,
    width: "100%",
    ml: 1,
    "&:hover": {
      backgroundColor: Theme.btnBg4 + " !important",
    },
  },
  editBtn: {
    backgroundColor: Theme.btnBg5,
    border: "2px solid " + Theme.btnBg5Border,
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.text,
    fontFamily: "InterSemiBold",
    fontSize: 15,
    borderRadius: "9px",
    height: 44,
    width: "100%",
    mr: 1,
    "&:hover": {
      backgroundColor: Theme.btnBg5 + " !important",
    },
  },
});
