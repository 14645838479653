import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ResizableRect from "react-resizable-rotatable-draggable";

import TableSetupPopup from "./TableSetupPopup";

import styles from "./TableSetupData.module.css";

import { ReactComponent as EditIcon } from "./../assets/images/edit2.svg";
import { ReactComponent as DeleteIcon } from "./../assets/images/delete2.svg";

import { TABLE_WIDTH, TABLE_HEIGHT } from "./FloorTableSetup";

const status_list = {
  pending: "#DB7C24",
  pending_payment: "red",
  placed_order: "#DB7C24",
  preparing: "green",
};

export let POPUP_WIDTH = 200;

const TableSetupData = (props) => {
  const [visible, setVisible] = useState(false);
  const [selectTable, setSelectTable] = useState(true);
  const [cover, setCover] = useState(0);
  const [tableNumber, setTableNumber] = useState(0);
  const [dragHeight, setDragHeight] = useState(100);
  const [dragWidth, setDragWidth] = useState(100);
  const [rotateAngleVal, setRotateAngleVal] = useState(0);

  let { item, uiStyle } = props;

  const bgColor = item?.status
    ? status_list[item?.status]
    : selectTable
    ? uiStyle[props.page]["activeTable"]
    : uiStyle[props.page]["table1"];

  useEffect(() => {
    let scale = Math.min(
      props.tableHeight / TABLE_HEIGHT,
      props.tableWidth / TABLE_WIDTH
    );
    let w = item?.width * scale;
    let h = item?.height * (w / item?.width);
    setDragHeight(h);
    setDragWidth(w);
  }, [props.tableHeight, props.tableWidth, props.item]);

  useEffect(() => {
    setRotateAngleVal(item?.angle);
    setTableNumber(item?.name);
    if (item?.name > 0) {
      setSelectTable(false);
      setVisible(false);
    }
  }, []);

  useEffect(() => {
    if (!props.editable) {
      setVisible(props.editable);
      setSelectTable(props.editable);
    }
  }, [props.editable]);

  const onMouseDownClick = () => {
    if (props.editable) {
      setSelectTable(true);
    } else {
      props.onTableClick(item?._id);
    }
  };

  const onSaveClick = (data) => {
    setSelectTable(false);
    setVisible(false);
    setCover(data.cover);
    setTableNumber(data.name);

    props.onSave(data, item?._id);
  };

  const onDelete = () => {
    props.onDelete(item?._id);
  };

  const handleRotate = (val) => {
    setVisible(false);
    setRotateAngleVal(val);

    props.onRotate(val, item?._id);
  };

  const handleResize = (style, isShiftKey, type) => {
    setVisible(false);
    let { top, left, width, height } = style;

    let top1 = top * (TABLE_HEIGHT / props.tableHeight);
    let left1 = left * (TABLE_WIDTH / props.tableWidth);

    top = Math.round(top1);
    left = Math.round(left1);
    width = Math.round(width);
    if (item?.type == "rectangle") {
      height = Math.round(height) + 20;
    } else {
      height = Math.round(height);
    }

    setDragHeight(height);
    setDragWidth(width);

    props.onResize(width, height, left, top, item?._id);
  };

  const handleDrag = (deltaX, deltaY) => {
    if (props.editable) {
      let x = item?.left + deltaX;
      let y = item?.top + deltaY;
      props.onDrag(x, y, item?._id);
    }
  };

  const tableNumberFontSize = 16;

  const contentStyle = {
    width: dragWidth,
    height: dragHeight,
    backgroundColor: bgColor,
    borderRadius: item?.type == "circle" ? "50%" : "5px",
  };

  let left1 = item?.left * (props.tableWidth / TABLE_WIDTH);
  let top1 = item?.top * (props.tableHeight / TABLE_HEIGHT);

  let chairHeight = dragHeight * 0.1 > 10 ? 10 : dragHeight * 0.1;
  let chairSpace = 10 - chairHeight;
  let chairWidth = chairHeight * 3;

  const ChairBox = (props1) => {
    return (
      <Box
        className={[styles.chairBox, props1.className]}
        style={{
          ...props1.style,
          backgroundColor: selectTable
            ? uiStyle[props.page]["activeChair"]
            : uiStyle[props.page]["chair"],
        }}
      />
    );
  };

  return (
    <Box className={styles.mainView} onMouseDown={onMouseDownClick}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        style={{
          width: dragWidth + 30,
          height: dragHeight + 30,
          top: top1 - 10,
          left: left1 - 10,
          transform: `rotate(${rotateAngleVal}deg)`,
          cursor: props.editable ? "default" : "pointer",
          position: "absolute",
          ...(selectTable && props.editable
            ? { border: "1px solid #7A8DA3", borderStyle: "dashed" }
            : { border: "unset", borderStyle: "unset" }),
        }}
      >
        <ChairBox
          className={styles.leftChairBox}
          style={{
            height: chairWidth,
            width: chairHeight,
            left: chairSpace,
            top: (dragHeight + 30) / 2 - chairWidth / 2,
          }}
        />
        <ChairBox
          style={{
            height: chairWidth,
            width: chairHeight,
            right: chairSpace,
            bottom: (dragHeight + 30) / 2 - chairWidth / 2,
          }}
          className={styles.rightChairBox}
        />
        <ChairBox
          style={{
            height: chairHeight,
            width: chairWidth,
            top: chairSpace,
            left: (dragWidth + 30) / 2 - chairWidth / 2,
          }}
          className={styles.topChairBox}
        />
        <ChairBox
          style={{
            height: chairHeight,
            width: chairWidth,
            bottom: chairSpace,
            left: (dragWidth + 30) / 2 - chairWidth / 2,
          }}
          className={styles.bottomChairBox}
        />
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{
            ...contentStyle,
            cursor: props.editable ? "default" : "pointer",
            overflow: "hidden",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              style={{
                padding: 1,
                minHeight: tableNumberFontSize * 2,
                minWidth: tableNumberFontSize * 2,
                borderRadius: "50%",
                border: "1px solid #fff",
              }}
            >
              <Typography
                style={{
                  fontSize: tableNumberFontSize,
                  color: "#fff",
                }}
                className={styles.tableNumberText}
              >
                {tableNumber}
              </Typography>
            </Box>
            {item?.status ? (
              <Box>
                {item?.mins != "" ? (
                  <Box className={styles.minsView} mt={0.3}>
                    <Typography
                      style={{
                        fontSize:
                          dragHeight < dragWidth
                            ? dragHeight / 10 > 13
                              ? 13
                              : dragHeight / 10
                            : dragWidth / 10 > 13
                            ? 13
                            : dragWidth / 10,
                        color: "#fff",
                      }}
                      className={styles.minsText}
                    >
                      {item?.mins ? item.mins : "0 min"}
                    </Typography>
                  </Box>
                ) : null}
                {item?.courseAway ? (
                  <Box className={styles.courseNameView} mt={0.3}>
                    <Typography
                      style={{
                        fontSize:
                          dragHeight < dragWidth
                            ? dragHeight / 10 > 15
                              ? 15
                              : dragHeight / 10
                            : dragWidth / 10 > 15
                            ? 15
                            : dragWidth / 10,
                      }}
                      className={styles.courseNameText}
                    >
                      {item.courseAway}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            ) : null}
          </Box>

          {selectTable && props.editable ? (
            <>
              <Box
                onClick={() => setVisible(true)}
                className={[styles.iconView, styles.editIconView]}
              >
                <EditIcon height={20} width={20} />
              </Box>
              <Box
                onClick={() => onDelete()}
                className={[styles.iconView, styles.deleteIconView]}
              >
                <DeleteIcon height={20} width={20} />
              </Box>
            </>
          ) : null}
        </Box>
      </Box>

      {visible ? (
        <Box
          className={styles.editPopUpView}
          left={
            props?.rightViewWidth - (left1 + dragWidth) < 400
              ? left1 + dragWidth - POPUP_WIDTH
              : left1 + dragWidth + 40
          }
          top={
            window.innerHeight - 60 - top1 < 300
              ? top1 - POPUP_WIDTH
              : top1 - 40
          }
        >
          <TableSetupPopup data={props.item} onSave={onSaveClick} />
        </Box>
      ) : null}

      {props.editable ? (
        <ResizableRect
          left={left1}
          top={top1}
          rotatable={selectTable}
          aspectRatio={item?.type == "circle" ? 1 : 0}
          width={dragWidth}
          height={dragHeight}
          onDrag={handleDrag}
          onRotate={handleRotate}
          onResize={handleResize}
          zoomable={selectTable ? "se" : ""}
          rotateAngle={rotateAngleVal}
        />
      ) : null}
    </Box>
  );
};

export default TableSetupData;
