import React from "react";
import { createTheme, styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { ReactComponent as EditIcon } from "./../assets/images/edit3.svg";
import { ReactComponent as DeleteIcon } from "./../assets/images/delete3.svg";

import { THEME_MODE } from "../constants/Theme";

let table_header_list = ["Username", "Email", "Phone Number", "User Role"];

const UserTableList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme_mode.tableHeaderText2,
      fontFamily: "InterMedium",
      fontSize: 14,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: `${props.themeMode == "dark" ? 5 : 1}px solid ${
        theme_mode.tableBorder
      }`,
      color: theme_mode.tableRowText,
      fontFamily: "InterMedium",
      fontSize: 13,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme_mode.tableRow2,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme_mode.tableRow,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  let data = props.itemList;

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: props.themeMode == "dark" ? 0 : 5,
        "&.MuiTableContainer-root": { backgroundColor: theme_mode.table },
      }}
      elevation={0}
    >
      {data.length ? (
        <Table aria-label="customized table">
          <TableHead sx={{ backgroundColor: theme_mode.tableHeader2 }}>
            <TableRow>
              {table_header_list.map((x, i) => {
                return (
                  <StyledTableCell key={i} sx={{ borderBottom: "none" }}>
                    <Box>{x}</Box>
                  </StyledTableCell>
                );
              })}
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <Box sx={styles.nameBox}>{item.name}</Box>
                </StyledTableCell>
                <StyledTableCell>{item.email}</StyledTableCell>
                <StyledTableCell>{item.phoneNumber}</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ textTransform: "capitalize" }}>
                    {item.userRole}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={styles.actionBox} pr={2}>
                    <EditIcon
                      height={20}
                      width={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => props.editItem(item)}
                    />
                    {props.loggedUser == item._idUser ? null : (
                      <DeleteIcon
                        height={20}
                        width={20}
                        style={{ marginLeft: 14, cursor: "pointer" }}
                        onClick={() => props.deleteItem(item)}
                      />
                    )}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </TableContainer>
  );
};

export default UserTableList;

const styles1 = (Theme) => ({
  imageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nameBox: {
    minWidth: 110,
  },
  priceBox: {
    fontFamily: "InterBold",
    minWidth: 80,
  },
  descBox: {},
  actionBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    path: {
      stroke: Theme.iconBg2,
    },
  },
  arrowIconBox: {
    float: "right",
    marginRight: 5,
  },
  arrowIcon: {
    cursor: "pointer",
    lineHeight: 0,
  },
});
