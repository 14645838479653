import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AddSettingItem from "../../components/AddSettingItem";
import AlertMsg from "../../components/AlertMsg";
import ArModelView from "./ArModelView";

import { THEME_MODE } from "../../constants/Theme";

import styles from "./AddMenu.module.css";

const AddMenu = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("edit_menu");
  const [menuData, setMenuData] = useState(null);
  const [modelData, setModelData] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    let params = location.pathname.split("/");
    if (params.length >= 3) {
      let menuId = params[2];
      getMenuData(menuId);
    } else {
      setIsLoading(false);
    }
  }, []);

  const getMenuData = (menuId) => {
    Api.getMenuData(props.restaurantId, menuId).then((response) => {
      if (response.success) {
        let data = response.data;
        let imageList = data.images.map((x) => {
          let index = x.lastIndexOf("/") + 1;
          let filename = x.substr(index);
          return {
            id: new Date(),
            name: filename,
            displayName: filename,
            size: 0,
            image: x,
            upload: true,
          };
        });
        data._id = menuId;
        data.images = imageList;
        setMenuData(data);
        if (data?.modelId) {
          getArModelData(data?.modelId);
        } else {
          setIsLoading(false);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        setIsLoading(false);
      }
    });
  };

  const getArModelData = (model_id) => {
    setIsLoading(true);
    Api.getArModelData(model_id).then((response) => {
      if (response.success) {
        let data = response.data;
        setModelData(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Container
      page={"settings"}
      bgColor={theme_mode.bg2}
      p={1.5}
      fpx={0}
      fpy={0}
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <>
          {/*{menuData?.modelId ? (*/}
          {/*  <Grid container item xs={12} md={11}>*/}
          {/*    <Box display="flex" alignItems="center">*/}
          {/*      <Button*/}
          {/*        type="button"*/}
          {/*        className={styles.tabBtn}*/}
          {/*        sx={{*/}
          {/*          mr: 1,*/}
          {/*          color: theme_mode.text,*/}
          {/*          backgroundColor:*/}
          {/*            selectedTab == "edit_menu"*/}
          {/*              ? theme_mode.card2*/}
          {/*              : theme_mode.bg2,*/}
          {/*          "&:hover": {*/}
          {/*            backgroundColor: theme_mode.headerBg + " !important",*/}
          {/*          },*/}
          {/*        }}*/}
          {/*        onClick={() => setSelectedTab("edit_menu")}*/}
          {/*      >*/}
          {/*        Edit Menu*/}
          {/*      </Button>*/}
          {/*      <Button*/}
          {/*        type="button"*/}
          {/*        className={styles.tabBtn}*/}
          {/*        sx={{*/}
          {/*          color: theme_mode.text,*/}
          {/*          backgroundColor:*/}
          {/*            selectedTab == "ar_view"*/}
          {/*              ? theme_mode.card2*/}
          {/*              : theme_mode.bg2,*/}
          {/*          "&:hover": {*/}
          {/*            backgroundColor: theme_mode.headerBg + " !important",*/}
          {/*          },*/}
          {/*        }}*/}
          {/*        onClick={() => setSelectedTab("ar_view")}*/}
          {/*      >*/}
          {/*        Edit AR Model*/}
          {/*      </Button>*/}
          {/*    </Box>*/}
          {/*  </Grid>*/}
          {/*) : null}*/}

          <Grid
            p={1.5}
            style={{
              backgroundColor: theme_mode.card2,
              borderRadius: 15,
              // borderTopLeftRadius: menuData?.modelId ? 0 : 15,
              borderTopLeftRadius: 15,
            }}
          >
            {/*{menuData?.modelId ? null : (*/}
              <Grid
                container
                item
                xs={12}
                md={11}
                sx={{ px: { md: 1, xs: 3 } }}
              >
                <Typography
                  className={styles.titleText}
                  sx={{ color: theme_mode.titleText, pt: 1, pb: 3 }}
                >
                  {menuData != null ? "Edit" : "Add"} a menu
                </Typography>
              </Grid>
            {/*// )}*/}
            {selectedTab == "edit_menu" ? (
              <AddSettingItem
                page={"setting"}
                itemData={menuData}
                themeMode={props.themeMode}
                restaurantId={props.restaurantId}
                currencyIcon={props.restaurantDetail?.currecnyIcon}
                onCancel={() =>
                  navigate(
                    menuData?._idCategory
                      ? `/settings/menu#${menuData?._idCategory}`
                      : `/settings/menu`
                  )
                }
                onSubmit={() =>
                  navigate(
                    menuData?._idCategory
                      ? `/settings/menu#${menuData?._idCategory}`
                      : `/settings/menu`
                  )
                }
              />
            ) : null}
            {selectedTab == "ar_view" ? (
              <ArModelView
                arModel={
                  modelData?.modelPath?.glb ? modelData?.modelPath?.glb : ""
                }
                onCancel={() =>
                  navigate(
                    menuData?._idCategory
                      ? `/settings/menu#${menuData?._idCategory}`
                      : `/settings/menu`
                  )
                }
                onSubmit={() =>
                  navigate(
                    menuData?._idCategory
                      ? `/settings/menu#${menuData?._idCategory}`
                      : `/settings/menu`
                  )
                }
              />
            ) : null}
          </Grid>
        </>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantId: state.userData.restaurantId,
    restaurantDetail: state.userData.restaurantDetail,
  };
};

export default connect(mapStateToProps, null)(AddMenu);
