import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate, useLocation } from "react-router-dom";

import Container from "../../components/Container";
import SettingCategoryList from "../../components/SettingCategoryList";
import AlertMsg from "../../components/AlertMsg";
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import useDebounce from "../../components/UseDebounce";
import SearchInput from "../../components/SearchInput";
import CategoryMenuList from "../../components/CategoryMenuList";
import ConfirmAlert from "../../components/ConfirmAlert";

import { connect } from "react-redux";
import { updateRestaurantCategoryList } from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import { ReactComponent as AddIcon } from "../../assets/images/add_plus.svg";
import { ReactComponent as EditIcon } from "../../assets/images/add_plus_white.svg";
import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";

import { THEME_MODE } from "../../constants/Theme";
import { CONTAINER_HEIGHT, FOOTER_XPADDING } from "../../constants";

const pageSize = 20;

const SettingsMenu = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [tabValue, setTabValue] = useState(
    location.pathname.split("/").length >= 3
      ? location.pathname.split("/")[2]
      : "category"
  );
  const [categoryList, setCategoryList] = useState([]);
  const [categoryFullList, setCategoryFullList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const [searchName, setSearchName] = useState("");
  const [searchItemName, setSearchItemName] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [categoryItemData, setCategoryItemData] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  const debouncedCatName = useDebounce(searchName, 500);
  const debouncedMenuName = useDebounce(searchItemName, 500);

  const AntTabs = styled(Tabs)({
    backgroundColor: theme_mode.bg8,
    borderRadius: 8,
    borderBottom: 0,
    height: 0,
    width: "fit-content",
    "& .MuiTabs-indicator": {
      height: 0,
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
    textTransform: "none",
    minWidth: 150,
    height: 40,
    fontFamily: "InterBold",
    color: theme_mode.text3,
    "&.Mui-selected": {
      color: theme_mode.btnText,
      backgroundColor: theme_mode.black,
      borderRadius: 8,
    },
  }));

  useEffect(() => {
    setCategoryList(
      categoryFullList?.filter((x) =>
        x?.name?.toLowerCase().includes(debouncedCatName)
      )
    );
  }, [debouncedCatName]);

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    Api.getCategoryList(props.restaurantId, "all").then((response) => {
      if (response.success) {
        const data = response.data.rows.sort((a, b) => a?.order - b?.order);
        setCategoryList(data);
        setCategoryFullList(data);
        props.updateRestaurantCategoryList(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
      setListLoading(false);
    });
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onTabChange = (event, newValue) => {
    let tab = newValue == 1 ? "category" : "menu";
    setListLoading(true);
    setTabValue(tab);
    if (tab == "category") {
      getCategoryList(1, searchName);
    }
  };

  const onAddCatMenu = () => {
    if (tabValue == "category") {
      navigate("/add_category");
    } else {
      navigate("/add_menu");
    }
  };

  const editCategory = (data) => {
    navigate("/add_category/" + data._id);
  };

  const deleteCategory = (data) => {
    let menu_list = [...props.restaurantMenuList];
    let find_menu = menu_list.filter((x) => x._idCategory == data._id);
    if (find_menu.length) {
      setCategoryItemData({ ...data, menuExist: true });
    } else {
      setCategoryItemData({ ...data, menuExist: false });
    }
    setConfirmDelete(true);
  };

  const onCloseConfirmDelete = () => {
    setConfirmDelete(false);
    setCategoryItemData(null);
  };

  const confirmDeleteMenu = () => {
    setBtnLoading(true);
    Api.deleteMenuCategory(categoryItemData._id, props.restaurantId).then(
      (response) => {
        if (response.success) {
          let catList = [...categoryList];
          setCategoryList(catList.filter((x) => x._id != categoryItemData._id));
          props.updateRestaurantCategoryList(
            props.restaurantCategoryList.filter(
              (x) => x._id != categoryItemData._id
            )
          );
          setConfirmDelete(false);
          setCategoryItemData(null);
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setBtnLoading(false);
      }
    );
  };

  const handlerOnBackBtnClick = () => {
    navigate("/settings");
  };

  return (
    <Container
      page={"settings"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0}
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid
          px={1.5}
          py={1.5}
          style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
        >
          <Grid item container xs={12} alignItem={"center"}>
            <Grid item xs={8}>
              <Box sx={styles.titleMainBox}>
                <Box onClick={handlerOnBackBtnClick} sx={styles.backBtn}>
                  <BackBtnIcon width={33} height={33} />
                </Box>
                <Box mr={2} sx={{ minWidth: 250 }}>
                  <SearchInput
                    value={tabValue == "category" ? searchName : searchItemName}
                    setValue={(val) =>
                      tabValue == "category"
                        ? setSearchName(val)
                        : setSearchItemName(val)
                    }
                    placeholder={
                      tabValue == "category"
                        ? "Search category here"
                        : "Search menu item here"
                    }
                    themeMode={props.themeMode}
                    inputBg={theme_mode.search}
                    height={48}
                  />
                </Box>
                <Box>
                  <AntTabs
                    value={tabValue == "category" ? 1 : 0}
                    onChange={onTabChange}
                  >
                    <AntTab label="Menu Settings" />
                    <AntTab label="Category Settings" />
                  </AntTabs>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItem={"center"}
            >
              <Box sx={styles.addCategoryBtn} onClick={onAddCatMenu}>
                <AddIcon width={18} height={18} />
                <Typography sx={styles.addCategoryText} ml={0.5}>
                  Add {tabValue == "category" ? "Category" : "Menu"}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {tabValue == "category" ? (
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItem={"center"}
            >
              <Box
                sx={styles.editCatOrderDiv}
                mt={1}
                onClick={() => {
                  navigate("/category_reorder");
                }}
              >
                <EditIcon width={18} height={18} />
                <Typography sx={styles.editCatOrderText} ml={0.5}>
                  Edit Category Order
                </Typography>
              </Box>
            </Grid>
          ) : null}

          {listLoading && tabValue == "category" ? (
            <Loader height={{ height: CONTAINER_HEIGHT - 240 }} />
          ) : tabValue == "category" ? (
            <Box my={3} sx={{ minHeight: CONTAINER_HEIGHT - 270 }}>
              <SettingCategoryList
                categoryList={categoryList}
                editCategory={editCategory}
                deleteCategory={deleteCategory}
              />
            </Box>
          ) : (
            <Box my={3}>
              <CategoryMenuList
                page={"SettingsMenu"}
                headerBg={theme_mode.card3}
                searchMenu={debouncedMenuName}
                categoryList={categoryFullList}
                allowSort={true}
                showGridIcon={true}
                showMenuPopup={true}
              />
            </Box>
          )}

          <ConfirmAlert
            confirmDelete={confirmDelete}
            title={
              categoryItemData?.menuExist === true
                ? "Menu items exists for this category"
                : "Are you sure?"
            }
            content={
              categoryItemData?.menuExist === true
                ? "Please delete the menu items before deleting the category"
                : "You will not be able to recover this category"
            }
            name={categoryItemData?.name ? categoryItemData?.name : null}
            firstBtn={categoryItemData?.menuExist === true ? "Cancel" : "No"}
            secondBtn={
              btnLoading
                ? "Please Wait"
                : categoryItemData?.menuExist === true
                ? "Ok"
                : "Yes ! Delete it"
            }
            btnLoading={btnLoading}
            onCloseConfirmDelete={onCloseConfirmDelete}
            onConfirmDelete={
              categoryItemData?.menuExist === true
                ? onCloseConfirmDelete
                : confirmDeleteMenu
            }
            themeMode={props.themeMode}
          />
        </Grid>
      )}

      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantCategoryList: (data) =>
      dispatch(updateRestaurantCategoryList(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    drawerOpen: state.userData.drawerOpen,
    themeMode: state.userData.themeMode,
    restaurantCategoryList: state.userData.restaurantCategoryList,
    restaurantMenuList: state.userData.restaurantMenuList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMenu);

const styles1 = (Theme) => ({
  settingsText: {
    color: Theme.text,
    fontFamily: "InterBold",
  },
  titleMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    mr: 1.8,
  },
  editCatOrderDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Theme.black,
    border: `1.5px solid ${Theme.border7}`,
    borderRadius: "8px",
    cursor: "pointer",
    height: 45,
    width: "fit-content",
    px: 1.5,
    py: 0.8,
  },
  editCatOrderText: {
    color: Theme.btnText,
    fontSize: 14,
    fontFamily: "InterSemiBold",
    marginLeft: 1,
  },
  addCategoryBtn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.theme,
    borderRadius: "8px",
    cursor: "pointer",
    height: 45,
    width: "fit-content",
    minWidth: 150,
    ml: 1,
    px: 1.5,
    py: 0.8,
  },
  addCategoryText: {
    color: Theme.text,
    fontSize: 14,
    fontFamily: "InterSemiBold",
    marginLeft: 1,
  },
});
