import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import styles from "./SelectActivePrinterModal.module.css";

import * as Api from "../api";

import AlertMsg from "./AlertMsg";
import SelectizeTextField from "./SelectizeTextField";

import { THEME_MODE } from "../constants/Theme";

const SelectActivePrinterModal = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [isLoading, setIsLoading] = useState(true);
  const [printerList, setPrinterList] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    if (printerList.length == 0 && props.modalVisible) {
      getPrinterList();
    }
  }, [props.modalVisible]);

  const getPrinterList = () => {
    setIsLoading(true);
    Api.getPrinterList(props.restaurantId).then((response) => {
      if (response.success) {
        let list = response.data.rows;
        setPrinterList(list);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Modal
      className={styles.modelView}
      open={props.modalVisible}
      onClose={props.onCancel}
    >
      <Grid
        item
        container
        xs={12}
        className={styles.mainView}
        p={5}
        md={4}
        sx={{
          backgroundColor: theme_mode.card2,
          "&:focus": {
            outline: "none",
          },
          m: { md: "20px", xs: "10px" },
          width: { md: "60%", xs: "100%" },
        }}
      >
        <SelectizeTextField
          page={"course"}
          tagColor={theme_mode.inputBg5}
          multiple={false}
          value={selectedPrinter}
          onChange={(val) => {
            setSelectedPrinter(val);
          }}
          backgroundColor={theme_mode.inputBg4}
          options={printerList}
          variant={"filled"}
          label={""}
          placeholder={selectedPrinter ? "" : "Select Printer"}
        />
        <Box mt={2} className={styles.btnView}>
          <Button
            type="button"
            className={styles.submitBtn}
            sx={{
              backgroundColor: theme_mode.redBg,
              color: theme_mode.lightText,
              "&:hover": {
                backgroundColor: theme_mode.redBg + " !important",
              },
              mt: 1,
              mr: 1,
            }}
            onClick={props.onCancel}
          >
            CANCEL
          </Button>
          <Button
            type="button"
            className={styles.submitBtn}
            sx={{
              backgroundColor: theme_mode.greenBg,
              color: theme_mode.lightText,
              "&:hover": {
                backgroundColor: theme_mode.greenBg + " !important",
              },
              mt: 1,
              ml: 1,
            }}
            onClick={() => props.onSubmit(selectedPrinter)}
          >
            SUBMIT
          </Button>
        </Box>
        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      </Grid>
    </Modal>
  );
};

export default SelectActivePrinterModal;
