import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import SettingTextField from "./SettingTextField";
import UploadLogo from "./UploadLogo";

import styles from "./SocialMedia.module.css";

const SocialMedia = (props) => {
  const { item, index, imageLoading, imageType } = props;
  const {
    name,
    action,
    icon,
    data: { url, iconHeight, iconWidth },
    error,
  } = item;

  const onDelete = () => {
    props.onDelete(index);
  };

  const onUpdate = (field, value) => {
    props.onUpdate(field, value, index);
  };

  const uploadFile = (event, type) => {
    props.uploadLogoFile(event, type);
  };

  return (
    <Grid
      item
      container
      xs={12}
      className={`${styles["social-media-view"]}`}
      pb={2}
      mb={2}
    >
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mb={1}>
          <Typography className={`${styles["text-input-label"]}`}>
            Name
          </Typography>
        </Box>
        <Box>
          <SettingTextField
            page={"SocialMedia"}
            editable={true}
            value={name}
            setValue={(val) => onUpdate("name", val)}
            placeholder={""}
            required={true}
            variant={"filled"}
            border={"1px solid #AAA"}
            error={error?.name}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mb={1}>
          <Typography className={`${styles["text-input-label"]}`}>
            Url
          </Typography>
        </Box>
        <Box>
          <SettingTextField
            page={"SocialMedia"}
            editable={true}
            value={url ?? null}
            setValue={(val) => onUpdate("url", val)}
            placeholder={""}
            required={true}
            variant={"filled"}
            border={"1px solid #AAA"}
            error={error?.url}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mb={1}>
          <Typography className={`${styles["text-input-label"]}`}>
            Action
          </Typography>
        </Box>
        <Box>
          <SettingTextField
            page={"SocialMedia"}
            editable={true}
            value={action ?? null}
            setValue={(val) => onUpdate("action", val)}
            placeholder={""}
            required={true}
            variant={"filled"}
            border={"1px solid #AAA"}
            error={error?.action}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mb={1}>
          <Typography className={`${styles["text-input-label"]}`}>
            Icon width
          </Typography>
        </Box>
        <Box>
          <SettingTextField
            page={"SocialMedia"}
            editable={true}
            value={iconWidth ?? null}
            setValue={(val) =>
              onUpdate("iconWidth", val.replace(/[^0-9.]/g, ""))
            }
            placeholder={""}
            required={true}
            variant={"filled"}
            border={"1px solid #AAA"}
            error={error?.iconWidth}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mb={1}>
          <Typography className={`${styles["text-input-label"]}`}>
            Icon height
          </Typography>
        </Box>
        <Box>
          <SettingTextField
            page={"SocialMedia"}
            editable={true}
            value={iconHeight ?? null}
            setValue={(val) =>
              onUpdate("iconHeight", val.replace(/[^0-9.]/g, ""))
            }
            placeholder={""}
            required={true}
            variant={"filled"}
            border={"1px solid #AAA"}
            error={error?.iconHeight}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mr={2} mb={1}>
          <UploadLogo
            type={"socialMedia" + index}
            uploadFile={uploadFile}
            imageLoading={imageLoading}
            logoImg={
              typeof icon === "string" && icon
                ? props.getImgDetail(icon, "SocialMedia")
                : icon ?? ""
            }
            imageType={imageType}
            placeholder={"SOCIAL LOGO"}
            showViewUploadIcon={true}
            onRemove={() => onUpdate("icon", "")}
          />
          {error?.icon ? (
            <Typography className={`${styles["error-text"]}`}>
              {error?.icon}
            </Typography>
          ) : null}
        </Box>
      </Grid>
      <Grid container item xs={12} mt={2} justifyContent={"flex-end"}>
        <Button
          type="button"
          disabled={imageLoading}
          className={`${styles["delete-btn"]}`}
          sx={{
            opacity: imageLoading ? 0.2 : 1,
          }}
          onClick={imageLoading ? null : onDelete}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

export default SocialMedia;
