import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

import {THEME_COLOR} from "../constants";

import {ReactComponent as CloseIcon} from "../assets/images/close_white.svg";

const textInputCss = {
  '& .MuiFilledInput-root': {
    pt: 1.5,
    borderRadius: '10px',
    '&:before, :after, :hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
};

const CreateOrderAddNote = (props) => {
  const [note, setNote] = useState('');

  useEffect(() => {
    setNote(props.data?.note ? props.data.note : '')
  }, [props.open]);

  const onChangeNote = (val) => {
    setNote(val);
    props.onSubmitNote({...item, note: val})
  }

  let item = props.data;

  return (
    <Modal
      style={styles.modelView}
      open={props.open}
      onClose={props.onClose}>
      <Box sx={styles.mainView}>
        <Box sx={styles.headerView}>
          <Typography sx={styles.nameText}>{item?.name}</Typography>
          <Box sx={styles.closeIconView} onClick={props.onClose}>
            <CloseIcon width={40} height={40}/>
          </Box>
        </Box>
        <Box sx={styles.noteView}>
          <Typography sx={styles.addNoteText}>Add note</Typography>
          <TextField
            required
            focused
            fullWidth
            multiline
            minRows={4}
            maxRows={8}
            value={note}
            variant="filled"
            sx={textInputCss}
            inputProps={{
              autoComplete: 'none',
              sx: {...styles.inputText}
            }}
            onChange={e => onChangeNote(e.target.value)}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default CreateOrderAddNote;

const styles = {
  modelView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  mainView: {
    backgroundColor: '#FFF',
    width: '84%',
    borderRadius: 3,
    "&:focus": {
      outline: 'none',
    },
    overflow: 'hidden',
  },
  headerView: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: THEME_COLOR,
    height: 80,
    p: 2,
  },
  nameText: {
    fontFamily: 'InterBold',
    fontSize: 16,
    color: '#000',
  },
  closeIconView: {
    position: 'absolute',
    cursor: 'pointer',
    top: 7,
    right: 7,
  },
  noteView: {
    p: 2,
  },
  addNoteText: {
    fontSize: 14,
    fontFamily: 'InterBold',
    color: '#000',
    mb: 1,
  },
  inputText: {
    fontSize: 13,
    fontFamily: 'InterSemiBold',
    color: '#000',
  },
};
