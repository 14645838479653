import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { connect } from "react-redux";

import { THEME_MODE } from "../constants/Theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const SettingTextArea = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  let bgColor = props.backgroundColor;

  const theme = createTheme({
    palette: {
      gray: {
        main: theme_mode.inputColor,
      },
    },
  });

  return (
    <Box style={{ width: "100%" }}>
      {props?.label ? (
        <Box mb={0.8}>
          <Typography
            sx={[
              {
                fontSize: 12,
                fontFamily: "InterSemiBold",
                color: theme_mode.inputLabelText,
              },
              props?.labelStyle ? props.labelStyle : null,
            ]}
          >
            {props.label}
          </Typography>
        </Box>
      ) : null}
      <ThemeProvider theme={theme}>
        <TextField
          fullWidth
          variant={props?.variant ? props?.variant : "standard"}
          value={props.value}
          multiline={true}
          minRows={props?.minRows ? props.minRows : 1}
          required={props?.required ? true : false}
          disabled={!(props?.editable == false ? false : true)}
          label={
            props?.placeholder && props.value == "" ? props?.placeholder : ""
          }
          type={props?.type ? props.type : "default"}
          color="gray"
          onChange={(e) => props.setValue(e.target.value)}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: props?.borderRadius ? props.borderRadius : "12px",
              py: 2,
              px: 1,
              "&:hover": {
                backgroundColor: bgColor,
              },
              "&.Mui-focused": {
                backgroundColor: bgColor,
              },
              "&.Mui-disabled": {
                backgroundColor: bgColor,
              },
              "&:before, :after, :hover:not(.Mui-disabled):before": {
                borderBottom: 0,
              },
            },
            "& .MuiInputAdornment-positionStart": {
              margin: "0 !important",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: props?.disabledColor
                ? `${props?.disabledColor} !important`
                : theme_mode.inputDisabledText,
            },
            "& .MuiFilledInput-input": {
              textAlign: "left",
            },
          }}
          InputLabelProps={{
            shrink: true,
            sx: {
              mt: 0.8,
              fontSize: 17,
              fontFamily: "InterMedium",
              color: props?.placeholderColor
                ? props.placeholderColor
                : theme_mode.inputLabel,
              "& .MuiFormLabel-asterisk": {
                display: "none",
              },
            },
          }}
          InputProps={{
            autoComplete: "none",
            sx: {
              backgroundColor: bgColor,
              fontFamily: "InterSemiBold",
              fontSize: props?.inputFontSize ? props.inputFontSize : 15,
              color: theme_mode.inputVal,
              px: 0,
            },
          }}
        />
      </ThemeProvider>
      <Box>
        {props?.error ? (
          <Typography
            sx={{
              color: theme_mode.redText,
              fontSize: 10,
              fontFamily: "InterMedium",
            }}
          >
            {props.error}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(SettingTextArea);
