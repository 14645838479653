import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";

import { ReactComponent as RightArrowIcon } from "../assets/images/right_round_arrow.svg";

import "./../assets/css/custom.css";
import { THEME_MODE } from "../constants/Theme";

const AntSwitch = styled(Switch)(({ theme, themeMode }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#455A64",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : themeMode == "light"
        ? "rgba(0,0,0,.25)"
        : "#333333",
    boxSizing: "border-box",
  },
}));

const SubscriptionFeatureList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [active, setActive] = useState(props.item?.active ? true : false);

  useEffect(() => {
    setActive(props.item?.active ? true : false);
  }, [props.item]);

  let { item } = props;

  const onActiveFeature = () => {
    setActive(!active);
    setTimeout(() => {
      props.onActiveFeature(item, active ? true : false);
    }, 100);
  };

  return (
    <Grid>
      <Box sx={styles.featureView}>
        <Box sx={styles.featureTextView} mr={2}>
          <Typography
            sx={{
              ...styles.featureText,
              color: active ? theme_mode.text5 : theme_mode.text5 + "4d",
            }}
          >
            {item.name}
          </Typography>
        </Box>
        {item?.addOnFeatures ? (
          item?.addOnFeatures?.count > 0 ? (
            <Box mr={3} sx={styles.arMenuView}>
              <Box sx={styles.arMenuCountView}>
                <Typography sx={styles.arMenuCountText}>
                  {item.addOnFeatures.count}
                </Typography>
              </Box>
              <Typography sx={styles.ar3dModelText}>3D Models</Typography>
            </Box>
          ) : null
        ) : null}
        {item?.addOnFeatures?.length ? (
          item?.addOnFeatures[0]?.styleName ? (
            props.menuStyle ? (
              <Box mr={3}>
                <img src={props.menuStyle} style={styles.menuDisplayImg} />
              </Box>
            ) : null
          ) : null
        ) : null}
        {item?.addOnFeatures?.length && item?.addOnFeatures?.[0]?.styleName ? (
          <Box
            sx={styles.menuDisplayBtnView}
            onClick={() => props.openMenuStyleModalVisible(item)}
          >
            <RightArrowIcon width={20} height={20} />
          </Box>
        ) : (
          <AntSwitch
            checked={active}
            onChange={onActiveFeature}
            inputProps={{ "aria-label": "ant design" }}
            themeMode={props.themeMode}
          />
        )}
      </Box>
    </Grid>
  );
};

export default SubscriptionFeatureList;

const styles1 = (Theme) => ({
  featureView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid " + Theme.border5,
    py: 1,
  },
  featureTextView: {
    flex: 1,
  },
  featureText: {
    color: Theme.text5,
    fontSize: 14,
    fontFamily: "InterMedium",
  },
  arMenuView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Theme.text10,
    borderRadius: "3px",
  },
  arMenuCountView: {
    backgroundColor: "rgba(255, 255, 255, 0.33)",
    borderRadius: "3px",
    px: 0.5,
  },
  arMenuCountText: {
    color: Theme.lightText,
    fontSize: 13.5,
    lineHeight: 1.5,
    fontFamily: "InterBold",
  },
  ar3dModelText: {
    color: Theme.lightText,
    fontSize: 11,
    lineHeight: 0.5,
    fontFamily: "InterMedium",
    px: 0.5,
  },
  menuDisplayImg: {
    height: 100,
  },
  menuDisplayBtnView: {
    cursor: "pointer",
  },
});
