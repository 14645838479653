import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import * as Api from "../../api";

import { connect } from "react-redux";
import {
  updateUserDetail,
  updateUserRole,
  updateUserRolesList,
} from "../../redux/actions/userDataActions";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import UserTableList from "../../components/UserTableList";
import AddUser from "../../components/AddUser";
import DiningConfirmAlert from "../../components/DiningConfirmAlert";
import AlertMsg from "../../components/AlertMsg";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import { ReactComponent as AddIcon } from "../../assets/images/add_plus.svg";

import {
  FOOTER_XPADDING,
  RESTAURANT_ADMIN_ROLE_ID,
  RESTAURANT_USER_ROLE_ID,
  THEME_COLOR,
} from "../../constants";
import { THEME_MODE } from "../../constants/Theme";

const Users = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const [alertConfirmModalData, setAlertConfirmModalData] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = () => {
    setLoading(true);
    Api.getUserRolesList(props.restaurantId, null).then((response) => {
      if (response.success) {
        let data1 = response.data;
        props.updateUserRolesList(data1.rows);
        if (data1.rows.length) {
          let list = [];
          data1.rows.map((x, i) => {
            let data = {
              _idRestaurant: x?._idRestaurant,
              _idUser: x?._idUser,
              _idUserRoles: x?._idUserRoles,
              userRoleId: x?._id,
              userRole:
                x?._idUserRoles == RESTAURANT_ADMIN_ROLE_ID
                  ? "Admin"
                  : x?._idUserRoles == RESTAURANT_USER_ROLE_ID
                  ? "User"
                  : "",
            };
            if (x?.userDetails?.length) {
              let detail = x?.userDetails[0];
              data = {
                ...data,
                pin: detail?.pin ? detail.pin : "",
                email: detail?.email ? detail.email : "",
                name: detail?.name ? detail.name : "",
                phoneNumber: detail?.phoneNumber ? detail.phoneNumber : "",
                password: detail?.password ? detail.password : "",
              };
              list.push(data);
            }
          });

          if (list.length) {
            let filter_list = list.filter(
              (z) =>
                ((props.pinUserId == "" || props.pinUserId == null) &&
                  z._idUser == props.userId) ||
                ((props.pinUserId != "" || props.pinUserId != null) &&
                  z._idUser == props.pinUserId)
            );
            if (filter_list.length) {
              let user_data = filter_list[0];
              let user_detail = {
                name: user_data.name,
                email: user_data.email,
                phoneNumber: user_data.phoneNumber,
              };
              props.updateUserRole(user_data._idUserRoles);
              props.updateUserDetail(user_detail);
            } else {
              window.location.href = "/logout/pin";
            }
          }

          setUserList(list);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const toggleAddUserModalVisible = (visible) => {
    setEditUserData(null);
    setAddUserModalVisible(visible);
  };

  const onSubmitAddUser = (data) => {
    if (editUserData) {
      let list = [...userList];
      let findIndex = list.findIndex((x) => x._idUser == editUserData._idUser);
      if (findIndex >= 0) {
        list[findIndex] = data;
        setUserList(list);
      }
    } else {
      setUserList([...userList, data]);
    }
    setEditUserData(null);
    setAddUserModalVisible(false);
  };

  const onEditUser = (data) => {
    setEditUserData(data);
    setAddUserModalVisible(true);
  };

  const onDeleteUser = (data) => {
    setAlertConfirmModalData(data);
  };

  const toggleConfirmModal = (data) => {
    setAlertConfirmModalData(data);
  };

  const onConfirmDelete = () => {
    setAlertConfirmModalData(null);
    setLoading(true);
    Api.deleteUserRole(
      alertConfirmModalData.userRoleId,
      props.restaurantId
    ).then((response) => {
      if (response.success) {
        Api.deleteUser(alertConfirmModalData._idUser).then((response) => {
          if (response.success) {
            props.updateUserRolesList(
              props.userRolesList.filter(
                (x) => x._idUser != alertConfirmModalData._idUser
              )
            );
            setUserList(
              userList.filter((x) => x._idUser != alertConfirmModalData._idUser)
            );
            setMsgAlert({
              open: true,
              message: "Deleted Successfully",
              msgType: "success",
            });
            if (props.pinUserId == "" || props.pinUserId == null) {
              if (alertConfirmModalData._idUser == props.userId) {
                window.location.href = "/logout/pin";
              }
            } else {
              if (alertConfirmModalData._idUser == props.pinUserId) {
                window.location.href = "/logout/pin";
              }
            }
          } else {
            setMsgAlert({
              open: true,
              message: response.msg,
              msgType: "error",
            });
          }
          setLoading(false);
        });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        setLoading(false);
      }
    });
  };

  return (
    <Container
      page={"settings"}
      navTitle={"Users"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0.5}
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid
          p={1.5}
          style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
        >
          <Grid mb={2} container item xs={12}>
            <Grid item xs={5}>
              <Box
                onClick={() => navigate("/settings")}
                sx={{ cursor: "pointer", width: "fit-content" }}
              >
                <BackBtnIcon width={33} height={33} />
              </Box>
            </Grid>
            <Grid container item xs={7} justifyContent={"flex-end"}>
              <Box
                sx={styles.addBtnView}
                onClick={() => toggleAddUserModalVisible(true)}
              >
                {/*<AddIcon width={15} height={15}/>*/}
                <Typography sx={styles.addBtnText} ml={0.5}>
                  Add Staff
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid>
            <UserTableList
              themeMode={props.themeMode}
              itemList={userList}
              loggedUser={
                props.pinUserId == "" || props.pinUserId == null
                  ? props.userId
                  : props.pinUserId
              }
              editItem={onEditUser}
              deleteItem={onDeleteUser}
            />
          </Grid>
        </Grid>
      )}
      <AddUser
        editData={editUserData}
        modelVisible={addUserModalVisible}
        onSubmit={onSubmitAddUser}
        onToggle={toggleAddUserModalVisible}
      />
      <DiningConfirmAlert
        page={"user"}
        data={alertConfirmModalData}
        themeMode={props.themeMode}
        onClose={() => toggleConfirmModal(null)}
        onDelete={onConfirmDelete}
      />
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    pinUserId: state.userData.pinUserId,
    userId: state.userData.userId,
    userRolesList: state.userData.userRolesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserRole: (data) => dispatch(updateUserRole(data)),
    updateUserDetail: (data) => dispatch(updateUserDetail(data)),
    updateUserRolesList: (data) => dispatch(updateUserRolesList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

const styles1 = (Theme) => ({
  addBtnView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Theme.black,
    border: `1.5px solid ${Theme.border7}`,
    borderRadius: "9px",
    cursor: "pointer",
    ml: 1,
    px: 3,
    py: 1,
  },
  addBtnText: {
    color: Theme.btnText,
    fontSize: 13,
    fontFamily: "InterSemiBold",
    marginLeft: 1,
  },
});
