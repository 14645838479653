import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { ReactComponent as SearchIcon } from "../assets/images/search.svg";
import { ReactComponent as CloseIcon } from "../assets/images/close.svg";

import { THEME_MODE } from "../constants/Theme";
import { IS_LARGE_SCREEN } from "../constants";

const ICON_SIZE = IS_LARGE_SCREEN ? 30 : 15;

const SearchInput = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  let inputBg = props?.inputBg ? props.inputBg : theme_mode.search;

  return (
    <TextField
      fullWidth
      focused
      value={props.value}
      variant="filled"
      placeholder={props.placeholder}
      autoFocus={props.value != ""}
      hiddenLabel={true}
      onChange={(e) => props.setValue(e.target.value)}
      sx={{
        "& .MuiInputBase-root": {
          border: props?.border ? props.border : "0px",
          "&:hover": {
            backgroundColor: inputBg,
          },
          "&.Mui-focused": {
            backgroundColor: inputBg,
          },
          "&.Mui-disabled": {
            backgroundColor: inputBg,
          },
        },
      }}
      InputLabelProps={{
        sx: {
          fontFamily: "InterMedium",
          color: props?.placeholderColor
            ? props.placeholderColor
            : theme_mode.inputLabel,
        },
      }}
      InputProps={{
        disableUnderline: true,
        autoComplete: "none",
        sx: {
          backgroundColor: inputBg,
          fontSize: IS_LARGE_SCREEN ? 17 : 12,
          fontFamily: "InterSemiBold",
          height: props?.height ? props.height : IS_LARGE_SCREEN ? 70 : 40,
          borderRadius: 2,
          width: "100%",
          color: theme_mode.inputVal,
        },
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              marginLeft: 1,
              cursor: "pointer",
              path: {
                stroke: theme_mode.iconBg2,
              },
            }}
          >
            <SearchIcon height={ICON_SIZE} width={ICON_SIZE} />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {props.value != "" ? (
              <InputAdornment
                onClick={() => props.setValue("")}
                position="end"
                sx={{
                  marginLeft: 1,
                  cursor: "pointer",
                  path: { fill: theme_mode.iconBg },
                }}
              >
                <CloseIcon height={ICON_SIZE} width={ICON_SIZE} />
              </InputAdornment>
            ) : null}
            {props?.rightView ? props?.rightView : null}
          </>
        ),
      }}
    />
  );
};

export default SearchInput;
