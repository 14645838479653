const initialState = {
  userToken: "",
  userId: "",
  refreshToken: "",
  restaurantId: "",
  fcmToken: "",
  restaurantImg: "",
  drawerOpen: false,
  userRole: "",
  userRoleId: "",
  createOrderModalVisible: false,
  orderOrTableType: "order",
  themeMode: "dark",
  restaurantDetail: null,
  tableOrderList: [],
  floorTableList: [],
  userRolesList: [],
  diningDetailTableOrders: [],
  restaurantCategoryList: [],
  restaurantMenuList: [],
  restaurantCourseList: [],
  restaurantCustomerList: [],
  diningSelectedFloor: null,
  pinLogin: false,
  pinUserId: "",
  userDetail: null,
  tableOrderBillingList: [],
  offlineEnable: process.env.REACT_APP_OFFLINE_ENABLED == "true" ? true : false,
  offlineIpAddress: process.env.REACT_APP_OFFLINE_IP_ADDRESS,
  offlinePort: process.env.REACT_APP_OFFLINE_WS_PORT,
  syncInterval: 30,
  activePrinter: null,
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_USER_TOKEN":
      return {
        ...state,
        userToken: action.payload,
      };

    case "UPDATE_REFRESH_TOKEN":
      return {
        ...state,
        refreshToken: action.payload,
      };

    case "UPDATE_RESTAURANT_ID":
      return {
        ...state,
        restaurantId: action.payload,
      };

    case "UPDATE_DRAWER_OPEN":
      return {
        ...state,
        drawerOpen: action.payload,
      };

    case "UPDATE_RESTAURANT_IMG":
      return {
        ...state,
        restaurantImg: action.payload,
      };

    case "UPDATE_FCM_TOKEN":
      return {
        ...state,
        fcmToken: action.payload,
      };

    case "UPDATE_USER_ROLE":
      return {
        ...state,
        userRole: action.payload,
      };

    case "UPDATE_USER_DATA":
      return {
        ...state,
        userId: action.payload.userId,
        userToken: action.payload.userToken,
        restaurantId: action.payload.restaurantId,
        restaurantImg: action.payload.restaurantImg,
        userRoleId: action.payload.userRoleId,
        userRole: action.payload.userRole,
        restaurantDetail: action.payload.restaurantDetail,
        userDetail: action.payload.userDetail,
      };

    case "UPDATE_CREATE_ORDER_MODAL_VISIBLE":
      return {
        ...state,
        createOrderModalVisible: action.payload,
      };

    case "UPDATE_ORDER_OR_TABLE_TYPE":
      return {
        ...state,
        orderOrTableType: action.payload,
      };

    case "UPDATE_RESTAURANT_DETAIL":
      return {
        ...state,
        restaurantDetail: action.payload,
      };

    case "UPDATE_TABLE_ORDER_LIST":
      return {
        ...state,
        tableOrderList: action.payload,
      };

    case "UPDATE_FLOOR_TABLE_LIST":
      return {
        ...state,
        floorTableList: action.payload,
      };

    case "UPDATE_DINING_TABLE_ORDER":
      return {
        ...state,
        diningDetailTableOrders: action.payload,
      };

    case "UPDATE_RESTAURANT_CATEGORY_MENU_LIST":
      return {
        ...state,
        restaurantCategoryList: action.payload.categoryList,
        restaurantMenuList: action.payload.menuList,
      };

    case "UPDATE_RESTAURANT_CATEGORY_LIST":
      return {
        ...state,
        restaurantCategoryList: action.payload,
      };

    case "UPDATE_RESTAURANT_MENU_LIST":
      return {
        ...state,
        restaurantMenuList: action.payload,
      };

    case "UPDATE_RESTAURANT_COURSE_LIST":
      return {
        ...state,
        restaurantCourseList: action.payload,
      };

    case "UPDATE_DINING_SELECTED_FLOOR":
      return {
        ...state,
        diningSelectedFloor: action.payload,
      };

    case "UPDATE_PIN_LOGIN":
      return {
        ...state,
        pinLogin: action.payload.pinLogin,
        pinUserId: action.payload.pinUserId,
        userRoleId: action.payload.userRoleId,
        userRole: action.payload.userRole,
        userDetail: action.payload.userDetail,
      };

    case "EMPTY_PIN_LOGIN":
      return {
        ...state,
        pinLogin: false,
        pinUserId: "",
        userRoleId: "",
        userRole: "",
        userDetail: null,
      };

    case "UPDATE_USER_DETAIL":
      return {
        ...state,
        userDetail: action.payload,
      };

    case "UPDATE_TABLE_ORDER_BILLING_LIST":
      return {
        ...state,
        tableOrderBillingList: action.payload,
      };

    case "UPDATE_OFFLINE_SETTINGS":
      return {
        ...state,
        offlineEnable: action.payload.offlineEnable,
        offlineIpAddress: action.payload.ipAddress,
        offlinePort: action.payload.port,
        syncInterval: action.payload.syncInterval,
      };

    case "UPDATE_ACTIVE_PRINTER":
      return {
        ...state,
        activePrinter: action.payload,
      };

    case "UPDATE_RESTAURANT_CUSTOMER_LIST":
      return {
        ...state,
        restaurantCustomerList: action.payload,
      };

    case "UPDATE_LOGIN_LIST_DATA":
      return {
        ...state,
        restaurantCategoryList: action.payload.categoryList,
        restaurantMenuList: action.payload.menuList,
        restaurantCourseList: action.payload.courseList,
        restaurantCustomerList: action.payload.customerList,
        floorTableList: action.payload.floorList,
        userRolesList: action.payload.userRolesList,
      };

    case "UPDATE_USER_ROLES_LIST":
      return {
        ...state,
        userRolesList: action.payload,
      };

    case "LOG_OUT":
      return {
        ...state,
        userToken: "",
        userId: "",
        refreshToken: "",
        fcmToken: "",
        restaurantId: "",
        restaurantImg: "",
        drawerOpen: false,
        userRole: "",
        userRoleId: "",
        createOrderModalVisible: false,
        themeMode: "dark",
        restaurantDetail: null,
        tableOrderList: [],
        floorTableList: [],
        userRolesList: [],
        diningDetailTableOrders: [],
        restaurantCategoryList: [],
        restaurantMenuList: [],
        restaurantCourseList: [],
        restaurantCustomerList: [],
        diningSelectedFloor: null,
        pinLogin: false,
        pinUserId: "",
        userDetail: null,
        tableOrderBillingList: [],
        offlineEnable:
          process.env.REACT_APP_OFFLINE_ENABLED == "true" ? true : false,
        offlineIpAddress: process.env.REACT_APP_OFFLINE_IP_ADDRESS,
        offlinePort: process.env.REACT_APP_OFFLINE_WS_PORT,
        syncInterval: 30,
        activePrinter: null,
      };

    default:
      return { ...state };
  }
};

export default userDataReducer;
