import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

import styles from "./LogoutConfirmModal.module.css";

import { THEME_MODE } from "../constants/Theme";

const LogoutConfirmModal = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  return (
    <Modal
      className={styles.modelView}
      open={props.modalVisible}
      onClose={props.onClose}
    >
      <Grid
        item
        xs={12}
        className={styles.mainView}
        p={3}
        sx={{
          maxWidth: {
            xs: "90% !important",
            sm: "40% !important",
            md: "30% !important",
          },
          backgroundColor: theme_mode.modal,
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <Typography className={styles.dataText} sx={{ color: theme_mode.text }}>
          {props.msg}
        </Typography>

        <Box mt={3} className={styles.btnView}>
          <Box
            mt={2}
            mx={0.5}
            className={styles.submitBtn}
            sx={{
              border: `2px solid ${theme_mode.text}`,
              color: theme_mode.text,
            }}
            onClick={props.onClose}
          >
            {props.closeBtnText}
          </Box>
          <Box
            mt={2}
            mx={0.5}
            className={styles.submitBtn}
            sx={{
              backgroundColor: theme_mode.btnBg,
              color: theme_mode.btnText,
            }}
            onClick={props.onSubmit}
          >
            {props.btnText}
          </Box>
        </Box>
      </Grid>
    </Modal>
  );
};

export default LogoutConfirmModal;
