import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import moment from "moment";

import { connect } from "react-redux";

import Loader from "./Loader";
import TableSetupData from "./TableSetupData";

import styles from "./FloorTableSetup.module.css";

import { NAVBAR_HEIGHT } from "../constants";
import { drawerWidth } from "../components/Sidebar";
import { uiStyle } from "./FloorTableSetup";

let CONTENT_HEIGHT = window.innerHeight - NAVBAR_HEIGHT;

const TableOrderTableLayout = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [floorList, setFloorList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [rightViewWidth, setRightViewWidth] = useState(500);
  const [tableSetupWidth, setTableSetupWidth] = useState(300);
  const [tableSetupHeight, setTableSetupHeight] = useState(CONTENT_HEIGHT);

  useEffect(() => {
    setFloorList(props.floorList);
    if (props.floorList.length) {
      let orders = [...props.orderList];
      let floor_list = [];
      props.floorList.map((y) => {
        let table = [];
        y.tableDetails.map((z) => {
          let orderData = null;
          let findIndex = orders.findIndex(
            (o) =>
              o?._idRestaurantTable && o?._idRestaurantTable?.includes(z._id)
          );
          if (findIndex >= 0) {
            orderData = orders[findIndex];
          } else {
            let orders1 = [...props.diningDetailTableOrders];
            let index1 = orders1.findIndex((x) =>
              x._idRestaurantTable.includes(z._id)
            );
            if (index1 >= 0) {
              orderData = orders1[index1];
            }
          }
          if (orderData) {
            let course_name = null;
            let cart_course = [];
            let course_id = orderData?.courseAway;
            cart_course = props.restaurantCourseList.filter(
              (x) => x._id == course_id
            );
            if (cart_course.length) {
              if (cart_course[0]?.name) {
                course_name = cart_course[0]?.name;
              }
            }

            table.push({
              ...z,
              status: orderData?.["orderStatus"]
                ? orderData?.["orderStatus"]
                : "pending",
              mins: orderData?.["createdAt"]
                ? moment(orderData["createdAt"]).fromNow()
                : "",
              courseAway: course_name,
            });
          } else {
            table.push(z);
          }
        });
        floor_list.push({
          ...y,
          tableDetails: table,
        });
      });
      setFloorList(floor_list);
      if (props.selectedFloor) {
        let floor_list1 = floor_list.filter(
          (y) => y._id == props.selectedFloor?._id
        );
        if (floor_list1.length) {
          setTableList(floor_list1[0]?.tableDetails);
        }
      } else {
        setTableList(floor_list[0]?.tableDetails);
      }
    } else {
      setTableList([]);
    }
  }, [props.floorList, props.orderList]);

  useEffect(() => {
    let floor_list = floorList.filter((y) => y._id == props.selectedFloor?._id);
    if (floor_list.length) {
      setTableList(floor_list[0]?.tableDetails);
    }
  }, [props.selectedFloor]);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  useEffect(() => {
    if (document.getElementById("tableSetUpRightViewId")) {
      setRightViewWidth(
        document.getElementById("tableSetUpRightViewId").offsetWidth
      );
    }
  }, [props.drawerOpen]);

  useEffect(() => {
    if (document.getElementById("tableSetupPlatformId")) {
      let w = document.getElementById("tableSetupPlatformId").offsetWidth;
      setTableSetupWidth(w);
      if (props.drawerOpen && w == tableSetupWidth) {
        setTableSetupWidth(w - drawerWidth);
      }
      setTableSetupHeight(
        document.getElementById("tableSetupPlatformId").offsetHeight
      );
    }
  }, [props.drawerOpen]);

  const onTableClick = (table_id) => {
    props.onTableClick(table_id);
  };

  return (
    <>
      <Grid
        id={"tableSetUp"}
        container
        item
        md={12}
        xs={12}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        className={styles.mainView}
        style={{
          height: CONTENT_HEIGHT,
          backgroundColor: uiStyle[props.page]["mainBg"],
        }}
      >
        <Grid
          item
          xs={12}
          pt={1}
          pb={1}
          pr={0.5}
          display={"flex"}
          flexDirection={"column"}
          id={"tableSetUpRightViewId"}
        >
          <Grid
            id={"tableSetupPlatformId"}
            className={styles.rightView}
            sx={{ backgroundColor: uiStyle[props.page]["bg"] }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              tableList.map((item, index) => {
                return (
                  <TableSetupData
                    key={item._id}
                    item={item}
                    rightViewWidth={rightViewWidth}
                    editable={false}
                    tableWidth={tableSetupWidth}
                    tableHeight={tableSetupHeight}
                    uiStyle={uiStyle}
                    page={props.page}
                    onResize={() => null}
                    onDrag={() => null}
                    onRotate={() => null}
                    onDelete={() => null}
                    onSave={() => null}
                    onTableClick={onTableClick}
                  />
                );
              })
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    drawerOpen: state.userData.drawerOpen,
    restaurantCourseList: state.userData.restaurantCourseList,
    diningDetailTableOrders: state.userData.diningDetailTableOrders,
  };
};

export default connect(mapStateToProps, null)(TableOrderTableLayout);
