import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "@google/model-viewer";

import SettingTextField from "../../components/SettingTextField";

import styles from "./ArModelView.module.css";

import { CONTAINER_HEIGHT } from "../../constants";

const ArModelView = (props) => {
  const [lighting1, setLighting1] = useState("");
  const [lighting2, setLighting2] = useState("");
  const [modelZoom, setModelZoom] = useState("");
  const [arModel, setArModel] = useState(props.arModel);

  const menuModelRef = useRef();

  const textInputCss = {
    borderBottom: `1.5px solid #C4C4C4 !important`,
    "& .MuiInputBase-root": {
      "&:before, :after, :hover:not(.Mui-disabled):before": {
        borderBottom: 0,
      },
    },
  };

  useEffect(() => {
    if (menuModelRef.current) {
      if (menuModelRef.current.modelIsVisible) {
      } else {
        let interval = setInterval(progress, 100);
        function progress() {
          if (menuModelRef.current?.modelIsVisible) {
            clearInterval(interval);
            updateLighting();
          }
        }
      }
    }
  }, [arModel]);

  useEffect(() => {
    if (menuModelRef.current) {
      // console.log(menuModelRef.current?.getFieldOfView());
      setArModel(null);
      setTimeout(() => {
        setArModel(props.arModel);
      }, 100);
    }
  }, [modelZoom]);

  useEffect(() => {
    updateLighting();
  }, [lighting1]);

  const updateLighting = () => {
    if (menuModelRef.current) {
      let value = lighting1.split(",");
      let material = menuModelRef.current?.model?.materials?.[0];
      if (value.length == 3) {
        if (material?.emissiveTexture) {
          material.emissiveTexture.setTexture(
            material.pbrMetallicRoughness.baseColorTexture.texture
          );
          material.setEmissiveFactor(value);
        }
      } else {
        if (material) {
          material.setEmissiveFactor([0, 0, 0]);
        }
      }
    }
  };

  const ModelAR = React.useMemo(() => {
    return arModel ? (
      <model-viewer
        ref={menuModelRef}
        style={{
          width: "100%",
          height: "100%",
          zIndex: 3,
          backgroundColor: "transparent",
        }}
        loading={"eager"}
        touch-action="none"
        exposure="1"
        src={arModel}
        disable-tap
        alt=""
        ar-modes="webxr scene-viewer quick-look"
        max-camera-orbit="Infinity 90deg auto"
        field-of-view={modelZoom ? `${Number(modelZoom)}deg` : "auto"}
        ar
        auto-rotate
        camera-controls
      ></model-viewer>
    ) : null;
  }, [arModel]);

  return (
    <Grid container item xs={12} pb={1} justifyContent={"space-between"}>
      <Grid
        container
        item
        alignItems={"center"}
        justifyContent={"center"}
        xs={12}
        md={6}
      >
        <Box
          sx={{
            backgroundColor: "#141414",
            height: CONTAINER_HEIGHT - 110,
            width: 300,
          }}
        >
          {ModelAR}
        </Box>
      </Grid>
      <Grid container item xs={12} md={5}>
        <Grid item xs={12}>
          <Grid item xs={12} mt={2} mb={3}>
            <SettingTextField
              page={"arModelView"}
              value={lighting1}
              setValue={setLighting1}
              editable={true}
              required={true}
              backgroundColor={"transparent"}
              textInputCss={textInputCss}
              borderRadius={"0px"}
              label={"Lighting 1"}
              labelStyle={{
                fontSize: 12,
                color: "#AAAAAA",
                fontFamily: "InterRegular",
                marginBottom: "-8px",
              }}
              inputTextStyle={{
                fontSize: 13,
                fontFamily: "InterSemiBold",
              }}
            />
          </Grid>
          <Grid item xs={12} mb={3}>
            <SettingTextField
              page={"arModelView"}
              value={lighting2}
              setValue={setLighting2}
              editable={true}
              required={true}
              backgroundColor={"transparent"}
              textInputCss={textInputCss}
              borderRadius={"0px"}
              label={"Lighting 2"}
              labelStyle={{
                fontSize: 12,
                color: "#AAAAAA",
                fontFamily: "InterRegular",
                marginBottom: "-8px",
              }}
              inputTextStyle={{
                fontSize: 13,
                fontFamily: "InterSemiBold",
              }}
            />
          </Grid>
          <Grid item xs={12} mb={3}>
            <SettingTextField
              page={"arModelView"}
              value={modelZoom}
              setValue={(val) => setModelZoom(val.replace(/[^0-9.]/g, ""))}
              editable={true}
              required={true}
              backgroundColor={"transparent"}
              textInputCss={textInputCss}
              borderRadius={"0px"}
              label={"Model Zoom"}
              labelStyle={{
                fontSize: 12,
                color: "#AAAAAA",
                fontFamily: "InterRegular",
                marginBottom: "-8px",
              }}
              inputTextStyle={{
                fontSize: 13,
                fontFamily: "InterSemiBold",
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mt={2} justifyContent="flex-end">
          <Button
            type="button"
            className={styles.submitBtn}
            onClick={props.onCancel}
            sx={{
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#D42323" + " !important",
              },
              backgroundColor: "#D42323",
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            className={styles.submitBtn}
            onClick={props.onSubmit}
            sx={{
              ml: 1,
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#148E20" + " !important",
              },
              backgroundColor: "#148E20",
            }}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ArModelView;
