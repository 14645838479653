import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from 'moment';

import {CURRENCY_SYMBOL, THEME_COLOR, THEME_COLOR2} from "../constants";
import {THEME_MODE} from "../constants/Theme";

import {ReactComponent as Like} from './../assets/images/like_bg.svg';
import {ReactComponent as Dots} from './../assets/images/3dots.svg';
import {ReactComponent as CalendarIcon} from './../assets/images/calendar.svg';


const MenuItem = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  let data = props.data;

  return (
    <Grid item xs={12} md={3}
          sx={{
            position: 'relative',
            justifyContent: props.firstItem ? 'left' : props.lastItem ? 'right' : 'center',
            display: 'flex'
          }}>
      <Card elevation={0} sx={styles.mainCard}>
        <Box sx={{position: 'relative'}}>
          <CardMedia
            component="img"
            height="100"
            image={data.images[0]}
            style={{borderRadius: 10}}
          />
          <Box sx={styles.dateBox}>
            <CalendarIcon height={10} width={10} style={{marginBottom: 1.5}}/>
            <Typography sx={styles.dateText}>{moment(data.eventStartDate).format('DD MMM Y')}</Typography>
          </Box>
          <Box sx={styles.likeIconBox}>
            <Like width={30} height={30}/>
          </Box>
        </Box>

        <CardActions disableSpacing>
          <CardContent sx={styles.cardContent}>
            <Typography sx={styles.nameText}>
              {data.name}
            </Typography>
            <Typography sx={styles.descText}>
              {data.description}
            </Typography>
            <Typography sx={styles.priceText}>
              {data.prize.length > 0 ?
                ((typeof CURRENCY_SYMBOL[data.prize[0]['currency']] == 'undefined' ? '' : CURRENCY_SYMBOL[data.prize[0]['currency']])
                  + ' ' + data.prize[0]['value'].toFixed(2)) : '$ ' + 0.00}
            </Typography>
          </CardContent>
          <Box sx={{cursor: 'pointer'}}>
            <Dots height={20}/>
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default MenuItem;

const styles1 = Theme => ({
  mainCard: {
    backgroundColor: Theme.card,
    width: {md: '95%', xs: '100%'},
    marginBottom: 2,
    borderRadius: 3,
    px: 1.5,
    pt: 1.5,
  },
  likeIconBox: {
    position: 'absolute',
    bottom: 0,
    right: 7
  },
  cardContent: {
    width: '100%',
    padding: 0,
    mt: 1
  },
  nameText: {
    color: Theme.cardText,
    fontFamily: 'InterBold',
    fontSize: 13.5,
  },
  descText: {
    color: Theme.text4,
    fontFamily: 'InterRegular',
    fontSize: 12,
  },
  priceText: {
    color: Theme.cardText,
    fontSize: 16,
    fontFamily: 'InterBold'
  },
  dateBox: {
    backgroundColor: Theme.theme,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 9,
    color: Theme.text,
    bottom: 0,
    left: 0,
    pt: 1,
    pb: 0.4,
    px: 2
  },
  dateText: {
    color: Theme.cardText,
    fontFamily: 'InterBold',
    fontSize: 10,
    ml: 1,
  },
});
