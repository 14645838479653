import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import moment from "moment";

import styles from "./AddBookingSlotModal.module.css";

import { connect } from "react-redux";

import * as Api from "../api";

import SettingTextField from "./SettingTextField";
import SettingDatePicker from "./SettingDatePicker";
import SettingTimePicker from "./SettingTimePicker";

import { THEME_MODE } from "../constants/Theme";
import Loader from "./Loader";

const AddBookingSlotModal = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [isLoading, setIsLoading] = useState(true);
  const [dateVal, setDateVal] = useState("");
  const [timeVal, setTimeVal] = useState("");
  const [limit, setLimit] = useState("");
  const [error, setError] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    setError(null);
    if (!props.modalVisible) {
      setDateVal("");
      setTimeVal("");
      setLimit("");
      setIsLoading(false);
      setMsgAlert({ open: false, message: "", msgType: "error" });
    } else {
      setDateVal(props.date);
      setTimeVal(new Date());
    }
  }, [props.modalVisible]);

  useEffect(() => {
    setDateVal(props.date);
  }, [props.date]);

  const onSubmit = () => {
    let err = null;
    if (dateVal == "") {
      err = { date: "Date is required" };
    }
    if (timeVal == "") {
      err = { ...err, time: "Time is required" };
    }
    if (limit == "") {
      err = { ...err, limit: "Limit is required" };
    } else {
      if (/[^0-9]/g.test(limit)) {
        err = { ...err, limit: "Invalid Limit" };
      }
    }

    setError(err);
    if (err != null) {
      return;
    }

    let data = {
      _idRestaurant: props.restaurantId,
      bookingDate: moment(dateVal, "DD MMM YYYY").format("YYYY-MM-DD"),
      bookingTime: moment(timeVal, "hh:mm a").format("HH:mm"),
      limit: limit,
      reserved: 0,
    };

    onAddBookingSlot(data);
  };

  const onAddBookingSlot = (data1) => {
    setIsLoading(true);
    let datas = JSON.stringify(data1);
    Api.addBookingSlot(datas, props.restaurantId).then((response) => {
      if (response.success) {
        props.onSubmit(response.data);
      } else {
        setMsgAlert({
          open: true,
          message: response.msg,
          msgType: "error",
        });
      }
      setIsLoading(false);
    });
  };

  return (
    <Modal
      className={styles.modelView}
      open={props.modalVisible}
      onClose={() => props.onCancel()}
    >
      <Grid
        item
        container
        className={styles.mainView}
        md={4}
        xs={12}
        sx={{
          backgroundColor: theme_mode.modal,
          "&:focus": {
            outline: "none",
          },
          m: { md: "20px", xs: "10px" },
          width: { md: "60%", xs: "100%" },
        }}
      >
        <Grid
          item
          xs={12}
          px={3}
          className={styles.headerView}
          style={{ backgroundColor: theme_mode.modalHeaderBg }}
        >
          <Typography
            className={styles.titleText}
            style={{ color: theme_mode.lightText }}
          >
            Add Booking Slot
          </Typography>
        </Grid>
        {isLoading ? (
          <Loader height={{ height: 280 }} />
        ) : (
          <Grid
            item
            container
            px={2}
            justifyContent={"space-between"}
            md={12}
            xs={12}
          >
            <Grid item xs={12} mt={1}>
              <SettingDatePicker
                value={dateVal}
                disabled={true}
                inputFormat="DD MMM YYYY"
                onChange={(newValue) => {
                  setDateVal(newValue.format("DD MMM YYYY"));
                }}
                labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
                inputFontSize={12}
                label={"Booking Date"}
                placeholder={"Date"}
                backgroundColor={theme_mode.inputBg2}
                borderRadius={"10px"}
                error={error?.date}
                leftView={null}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <SettingTimePicker
                value={timeVal}
                inputFormat="hh:mm a"
                minTime={
                  moment(dateVal, "DD MMM YYYY").format("YYYY-MM-DD") ==
                  moment().format("YYYY-MM-DD")
                    ? new Date()
                    : null
                }
                onChange={(newValue) => {
                  setTimeVal(newValue);
                }}
                labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
                inputFontSize={12}
                label={"Booking Time"}
                placeholder={"Time"}
                backgroundColor={theme_mode.inputBg2}
                borderRadius={"10px"}
                error={error?.time}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <SettingTextField
                page={"settings"}
                value={limit}
                setValue={(val) => setLimit(val.replace(/[^0-9]/g, ""))}
                placeholder={"Limit"}
                label={"Limit"}
                variant={"filled"}
                inputFontSize={12}
                labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
                backgroundColor={theme_mode.inputBg2}
                borderRadius={"10px"}
                error={error?.limit}
              />
            </Grid>
            <Grid container direction="row" justifyContent="center" py={2}>
              <Button
                type="button"
                className={styles.cancelBtn}
                sx={{ mt: 1, mx: 1 }}
                style={{
                  backgroundColor: theme_mode.btnBg5,
                  color: theme_mode.text,
                  border: "2px solid " + theme_mode.btnBg5Border,
                }}
                onClick={() => props.onCancel()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className={styles.saveBtn}
                sx={{ mt: 1, mx: 1 }}
                style={{
                  backgroundColor: theme_mode.btnBg4,
                  color: theme_mode.btnText,
                }}
                onClick={() => onSubmit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(AddBookingSlotModal);
