import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import moment from "moment";
import { initializeApp } from "firebase/app";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import * as Api from "../../api";

import { connect } from "react-redux";

import Container from "../../components/Container";
import OrderItemList from "../../components/OrderItemList";
import OrderDetailModal from "../../components/OrderDetailModal";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";

import { THEME_MODE } from "../../constants/Theme";
import { DELIVERY, DINEIN, FIREBASE_CONFIG, PICKUP } from "../../constants";
import { onSetCartModifierData } from "../../constants/CommonFunc";

let countBg = {
  incoming: "#F17400",
  preparing: "#CBB11E",
  ready: "#009621",
};

let menu_page_size = 30000;

const fapp = initializeApp(FIREBASE_CONFIG);
const db = getFirestore(fapp);

const Orders = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [isLoading, setIsLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [incomingList, setIncomingList] = useState([]);
  const [preparingList, setPreparingList] = useState([]);
  const [readyList, setReadyList] = useState([]);
  const [drawerSideMenuData, setDrawerSideMenuData] = useState(null);
  const [drawerSideMenuVisible, setDrawerSideMenuVisible] = useState(false);
  const [currencyVal, setCurrencyVal] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  // useEffect(() => {
  //   const q = query(
  //     collection(db, "restaurant", props.restaurantId, "orders"),
  //     where("orderStatus", "in", ["incoming", "preparing", "ready"]),
  //     where("orderType", "in", [DELIVERY, PICKUP])
  //   );
  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     setOrderList(querySnapshot.docs);
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  useEffect(() => {
    if (menuList.length && currencyVal) {
      setListLoading(true);
      setOrderListFunc(orderList);
      if (orderList.length == 0) {
        setIsLoading(false);
      }
    }
  }, [orderList, menuList, currencyVal]);

  const setOrderListFunc = (docs) => {
    let incoming_list = [];
    let preparing_list = [];
    let ready_list = [];
    docs.map((doc) => {
      let x = doc.data();
      let cart_list = [];
      x.cart.map((y) => {
        let findIndex = menuList.findIndex((z) => z._id == y._idMenuItem);
        if (findIndex >= 0) {
          cart_list.push({
            ...y,
            ...menuList[findIndex],
            name: y?.menuItemName
              ? y.menuItemName
              : menuList[findIndex]["name"],
            price: y.price,
            cartModifier: onSetCartModifierData(y.modifiers ?? [], menuList),
          });
        }
      });

      let address = "";
      if (x?.userDetails?.address?.shipping) {
        let address1 = x?.userDetails?.address?.shipping;
        if (address1.addressTitle) {
          address = address1.addressTitle + ", ";
        }
        address =
          address +
          address1.address +
          ", " +
          address1.country +
          ", " +
          address1.city +
          ", " +
          ", " +
          address1.pincode;
      }

      let list_item = {
        ...x,
        date: moment(x.createdAt).format("DD MMM Y, HH:mm A"),
        name: x?.userDetails?.name ? x?.userDetails.name : "",
        price: { value: x.totalAmount, currency: currencyVal },
        cart: cart_list,
        orderType: x.orderType == DELIVERY ? "delivery" : "pick up",
        address: address ? address : "",
        phone: x?.userDetails?.phone
          ? x?.userDetails?.phone.code + x?.userDetails?.phone.number
          : "",
        distance: "4km",
      };

      if (x?.orderStatus == "preparing") {
        preparing_list.push(list_item);
      } else if (x?.orderStatus == "ready") {
        ready_list.push(list_item);
      } else if (x?.orderStatus == "incoming") {
        incoming_list.push(list_item);
      }
    });
    setIncomingList(incoming_list);
    setPreparingList(preparing_list);
    setReadyList(ready_list);
    setIsLoading(false);
    setListLoading(false);
  };

  useEffect(() => {
    getMenuList();
  }, []);

  const getMenuList = () => {
    setIsLoading(true);
    let filter =
      "?pageSize=" + menu_page_size + "&pageNum=1&orderBy=order&orderByDir=asc";
    Api.getMenuList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setMenuList(data);
        let currency = "";
        if (data.length) {
          currency = data[0]["price"]?.currency;
          setCurrencyVal(currency);
        }
      } else {
        setIsLoading(false);
      }
    });
  };

  const onSelectOrderMenu = (type, item) => {
    setDrawerSideMenuData({ ...item, type: type });
    setDrawerSideMenuVisible(true);
  };

  const onOrderModalSubmitClick = (status) => {
    onUpdateOrder(status);
  };

  const onOrderModalCancelClick = (status) => {
    onUpdateOrder(status);
  };

  const onUpdateOrder = (status) => {
    setListLoading(true);
    let data1 = {
      orderStatus: status,
    };

    let datas = JSON.stringify(data1);
    Api.updateOrder(datas, drawerSideMenuData._id).then((response) => {
      if (response.success) {
        setDrawerSideMenuData(null);
        setDrawerSideMenuVisible(false);
      } else {
        setListLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const toggleDrawer = (val) => {
    setDrawerSideMenuVisible(val);
  };

  const IncomingListView = React.useMemo(() => {
    return incomingList.map((x, i) => {
      return (
        <OrderItemList
          key={i}
          index={i}
          item={x}
          type={"incoming"}
          themeMode={props.themeMode}
          onItemClick={onSelectOrderMenu}
        />
      );
    });
  }, [incomingList]);

  const PreparingListView = React.useMemo(() => {
    return preparingList.map((x, i) => {
      return (
        <OrderItemList
          key={i}
          index={i}
          item={x}
          type={"preparing"}
          themeMode={props.themeMode}
          onItemClick={onSelectOrderMenu}
        />
      );
    });
  }, [preparingList]);

  const ReadyListView = React.useMemo(() => {
    return readyList.map((x, i) => {
      return (
        <OrderItemList
          key={i}
          index={i}
          item={x}
          type={"ready"}
          themeMode={props.themeMode}
          onItemClick={onSelectOrderMenu}
        />
      );
    });
  }, [readyList]);

  const TitleView = ({ title, count }) => {
    return (
      <Box sx={styles.listTitleView} mt={1}>
        <Typography sx={styles.listTitleText}>{title}</Typography>
        <Box sx={{ backgroundColor: countBg[title], ...styles.countView }}>
          <Typography sx={styles.countText}>
            {count > 9 ? count : "0" + count}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Container page={"orders"} navTitle={"Orders"} showFooter={false}>
      {isLoading ? <Loader /> : null}
      {listLoading ? (
        <Box sx={styles.loader}>
          <Loader />
        </Box>
      ) : null}
      {!isLoading ? (
        <>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid
              item
              xs={12}
              md={3.9}
              p={props.themeMode == "dark" ? 0.7 : 0}
              container
              sx={styles.listMainView}
            >
              <TitleView title={"incoming"} count={incomingList.length} />
              {IncomingListView}
            </Grid>
            <Grid
              item
              xs={12}
              md={3.9}
              p={props.themeMode == "dark" ? 0.7 : 0}
              container
              sx={styles.listMainView}
            >
              <TitleView title={"preparing"} count={preparingList.length} />
              {PreparingListView}
            </Grid>
            <Grid
              item
              xs={12}
              md={3.9}
              p={props.themeMode == "dark" ? 0.7 : 0}
              container
              sx={styles.listMainView}
            >
              <TitleView title={"ready"} count={readyList.length} />
              {ReadyListView}
            </Grid>
          </Grid>

          <OrderDetailModal
            open={drawerSideMenuVisible}
            data={drawerSideMenuData}
            onClose={() => toggleDrawer(false)}
            onSubmit={onOrderModalSubmitClick}
            onCancel={onOrderModalCancelClick}
            themeMode={props.themeMode}
          />
        </>
      ) : null}

      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(Orders);

const styles1 = (Theme) => ({
  loader: {
    backgroundColor: "rgba(238, 238, 238, 0.5)",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  listMainView: {
    backgroundColor: Theme.card4,
    borderRadius: "8px",
  },
  listTitleView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    mb: 1.5,
  },
  listTitleText: {
    color: Theme.text2,
    fontFamily: "InterBold",
    textTransform: "capitalize",
    fontSize: 18,
    mr: 1,
  },
  countView: {
    display: "flex",
    width: "26px",
    height: "26px",
    borderRadius: "13px",
    alignItems: "center",
    justifyContent: "center",
  },
  countText: {
    color: Theme.lightText,
    fontFamily: "InterSemiBold",
    fontSize: 11,
  },
});
