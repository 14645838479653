import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { connect } from "react-redux";
import {
  updateLoginListData,
  updatePinLogin,
  updateRefreshToken,
  updateUserData,
} from "../../redux/actions/userDataActions";

import * as Api from "../../api/index";
import * as Api2 from "../../functions/loginGetList";

import UserLogin from "../../components/UserLogin";
import UserRegister from "../../components/UserRegister";
import OtpVerify from "../../components/OtpVerify";
import AlertMsg from "../../components/AlertMsg";
import LoginDarkTheme from "../../components/LoginDarkTheme";

import "../../assets/css/custom.css";

import { IS_2FA_LOGIN, RESTAURANT_ADMIN_ROLE_ID } from "../../constants";

import BgImg from "../../assets/images/login_bg_img.png";
import { ReactComponent as Logo } from "../../assets/images/logo3.svg";
import { ReactComponent as Frame1 } from "../../assets/images/frame1.svg";
import { ReactComponent as Frame2 } from "../../assets/images/frame2.svg";
import { ReactComponent as Frame3 } from "../../assets/images/frame3.svg";
import { ReactComponent as Ellipse1 } from "../../assets/images/ellipse1.svg";
import { ReactComponent as Ellipse2 } from "../../assets/images/ellipse2.svg";

import { THEME_MODE } from "../../constants/Theme";

const Login = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [pageType, setPageType] = useState("login");
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [pswd, setPswd] = useState("");
  const [mobileView, setMobileView] = useState(window.innerWidth < 600);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setMobileView(window.innerWidth <= 600);
    });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (pageType === "register" || pageType === "login") {
      setEmail("");
      setFullName("");
      setPswd("");
      setMsgAlert({ open: false, message: "", msgType: "error" });
    }
  }, [pageType]);

  const onLogin = (param) => {
    setMsgAlert({ open: false, message: "", msgType: "error" });

    if (pageType == "loginOtpVerify") {
      param = {
        email: email,
        password: pswd,
        ...param,
      };
    }
    let data = JSON.stringify(param);
    setBtnDisabled(true);
    Api.login(data, IS_2FA_LOGIN).then((response) => {
      if (response.success) {
        if (pageType == "loginOtpVerify" || !IS_2FA_LOGIN) {
          props.updateRefreshToken(response.data.refreshToken);
          let rest_id = response.data?.userRoles?._idRestaurant;
          rest_id = rest_id ? rest_id : null;
          // getRestaurantData(rest_id, response.data.token);
          getRestaurantData(
            rest_id,
            response.data.token,
            response.data.user,
            response.data?.userRoles?._idUser ? response.data.userRoles : null
          );
        } else {
          setBtnDisabled(false);
          setEmail(param.email);
          setPswd(param.password);
          setPageType("loginOtpVerify");
        }
      } else {
        setBtnDisabled(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const onRegisterBtn = () => {
    setPageType("register");
  };

  const onLoginBtn = () => {
    setPageType("login");
  };

  const onSendOtp = (param) => {
    setMsgAlert({ open: false, message: "", msgType: "error" });

    if (!param.termsPrivacy) {
      setMsgAlert({
        open: true,
        message: "Accept Terms & privacy policy",
        msgType: "error",
      });
      return;
    }

    if (param.password.length < 8) {
      setMsgAlert({
        open: true,
        message: "Password must contain at least 8 characters",
        msgType: "error",
      });
      return;
    }

    if (param.password !== param.confirmPassword) {
      setMsgAlert({
        open: true,
        message: "Password mismatch",
        msgType: "error",
      });
      return;
    }

    let data = JSON.stringify({
      email: param.email,
    });

    setBtnDisabled(true);
    Api.sendEmailOtp(data).then((response) => {
      setBtnDisabled(false);
      if (response.success) {
        setEmail(param.email);
        setFullName(param.fullName);
        setPswd(param.password);
        setPageType("otpVerify");
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const onVerifyRegister = (param) => {
    setMsgAlert({ open: false, message: "", msgType: "error" });

    if (
      fullName.trim() == "" ||
      email.trim() == "" ||
      pswd.trim() == "" ||
      param.otp == ""
    ) {
      setMsgAlert({
        open: true,
        message: "Fields are required",
        msgType: "error",
      });
      return;
    }

    let data = JSON.stringify({
      name: fullName,
      email: email,
      password: pswd,
      otp: param.otp,
    });
    setBtnDisabled(true);
    Api.userRegister(data).then((response) => {
      if (response.success) {
        setMsgAlert({
          open: true,
          message: "Registered Successfully",
          msgType: "success",
        });
        setTimeout(() => {
          setBtnDisabled(false);
          setPageType("login");
        }, 1000);
      } else {
        setBtnDisabled(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const getRestaurantData = async (restId, authToken, user, userRoles) => {
    let response = null;
    if (restId) {
      response = await Api.getRestaurantData(restId, authToken);
    }
    let restImg = "";
    let userRole = "";
    let userRoleId = "";
    let restaurantId = "";
    let restaurantDetail = null;

    if (response) {
      if (response.success && userRoles && userRoles?._idUserRoles == null) {
        createUpdateUserRoles(user, userRoles, response.data, authToken);
      } else if (response.success) {
        let restData = response.data;
        restaurantId = restData._id;
        let restaurantImgs = restData.images;
        if (restaurantImgs.length > 0) {
          restImg = restaurantImgs[0];
        }
        restaurantDetail = restData;
      } else {
        if (response?.statusCode == 404) {
          if (userRoles) {
            userRole = userRoles._idUserRoles;
            userRoleId = userRoles._id;
          }
        } else {
          setBtnDisabled(false);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
          return;
        }
      }
    } else {
      if (userRoles) {
        userRole = userRoles._idUserRoles;
        userRoleId = userRoles._id;
      }
    }

    let user_detail = {
      name: user.name,
      email: user.email,
      phoneNumber: user.phoneNumber,
    };

    let update_data = {
      userId: user._id,
      userToken: authToken,
      restaurantId: restaurantId,
      restaurantImg: restImg,
      userRoleId: userRoleId,
      userRole: userRole,
      restaurantDetail: restaurantDetail,
      userDetail: user_detail,
    };

    if (userRoles) {
      userRoleId = userRoles._id;
      userRole = userRoles._idUserRoles;
    }

    let pin_data = {
      pinLogin: true,
      pinUserId: "",
      userRoleId: userRoleId,
      userRole: userRole,
      userDetail: user_detail,
    };
    if (
      user?.pin != null &&
      user?.pin != "" &&
      userRole == RESTAURANT_ADMIN_ROLE_ID
    ) {
      if (restaurantId) {
        let data = await Api2.getListApi(authToken, restaurantId);
        props.updateLoginListData(data);
      }
      props.updateUserData(update_data);
      navigate("/");
    } else {
      getUserList(authToken, restaurantId, update_data, pin_data);
    }
  };

  const createUpdateUserRoles = (user, userRole, restaurant, authToken) => {
    let role_id = null;
    if (userRole._id) {
      role_id = userRole._id;
    }
    let restaurantId = restaurant._id;
    let param = {
      _idUser: userRole._idUser,
      _idRestaurant: restaurantId,
      _idUserRoles: RESTAURANT_ADMIN_ROLE_ID,
    };
    let data = JSON.stringify(param);
    Api.createUpdateUserRoles(data, authToken, role_id, restaurantId).then(
      async (response) => {
        if (response.success) {
          let restaurantImgs = restaurant.images;
          let restImg = "";
          if (restaurantImgs.length > 0) {
            restImg = restaurantImgs[0];
          }
          let user_detail = {
            name: user.name,
            email: user.email,
            phoneNumber: user.phoneNumber,
          };

          let update_data = {
            userId: userRole._idUser,
            userToken: authToken,
            restaurantId: restaurantId,
            restaurantImg: restImg,
            userRoleId: "",
            userRole: "",
            restaurantDetail: restaurant,
            userDetail: user_detail,
          };

          let pin_data = {
            pinLogin: true,
            pinUserId: "",
            userRoleId: role_id ? role_id : "",
            userRole: RESTAURANT_ADMIN_ROLE_ID,
            userDetail: user_detail,
          };

          if (user?.pin != null && user?.pin != "") {
            if (restaurantId) {
              let data = await Api2.getListApi(authToken, restaurantId);
              props.updateLoginListData(data);
            }
            props.updateUserData(update_data);
            navigate("/");
          } else {
            getUserList(authToken, restaurantId, update_data, pin_data);
          }
        } else {
          setBtnDisabled(false);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      }
    );
  };

  const getUserList = async (authToken, restaurantId, datas, pin_data) => {
    let api_data = await Api2.getListApi(authToken, restaurantId);
    let data1 = api_data.userRolesList;
    let list = data1.filter(
      (x) =>
        x?._idUserRoles == RESTAURANT_ADMIN_ROLE_ID &&
        x?.userDetails?.length &&
        typeof x?.userDetails[0]?.pin != "undefined" &&
        x?.userDetails[0]?.pin != "" &&
        x?.userDetails[0]?.pin != null
    );

    props.updateLoginListData(api_data);
    props.updateUserData(datas);
    if (list.length) {
    } else {
      props.updatePinLogin(pin_data);
    }
    navigate("/");
  };

  if (props.themeMode == "dark") {
    return (
      <LoginDarkTheme
        pageType={pageType}
        btnDisabled={btnDisabled}
        msgAlert={msgAlert.msgType == "error" ? msgAlert.message : ""}
        onLogin={onLogin}
        onRegister={onRegisterBtn}
        onSendOtp={onSendOtp}
        onLoginBtn={onLoginBtn}
        onVerifyRegister={onVerifyRegister}
      />
    );
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", backgroundColor: theme_mode.bg }}
    >
      {!mobileView ? (
        <Box sx={styles.topBgView}>
          <Frame3
            width={window.innerWidth / 2.5}
            height={window.innerWidth / 2.5}
          />
        </Box>
      ) : null}

      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        xs={mobileView ? 11 : 8}
        sx={styles.cardView}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          xs={12}
          sx={{ height: mobileView ? "100%" : "90%", boxShadow: 20 }}
        >
          {!mobileView ? (
            <Grid item xs={mobileView ? 12 : 6} sx={styles.cardLeftView}>
              <img alt="" src={BgImg} style={styles.bgImage} />
              <Box className="login-bg-image" style={styles.bgShadeImage} />
              <Box sx={styles.frameStyle2}>
                <Frame2
                  width={window.innerWidth / 12}
                  height={window.innerWidth / 12}
                />
              </Box>
              <Box sx={styles.ellipseStyle1}>
                <Ellipse1
                  width={window.innerWidth / 20}
                  height={window.innerWidth / 20}
                />
              </Box>
              <Box sx={styles.textView}>
                <Typography sx={styles.mainText1}>Explore Food</Typography>
                <Box sx={styles.textView2}>
                  <Typography sx={styles.mainText2}>Virtual </Typography>
                  <Typography sx={styles.mainText3}> Reality.</Typography>
                </Box>
              </Box>
            </Grid>
          ) : null}
          <Grid
            item
            xs={mobileView ? 12 : 6}
            container
            justifyContent="center"
            sx={styles.cardRightView}
          >
            <Grid
              item
              xs={mobileView ? 10 : 7}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {!mobileView ? (
                <Box sx={styles.frameStyle1}>
                  <Frame1
                    width={window.innerWidth / 12}
                    height={window.innerWidth / 12}
                  />
                </Box>
              ) : null}

              {!mobileView ? (
                <Box sx={styles.ellipseStyle2}>
                  <Ellipse2
                    width={window.innerWidth / 24}
                    height={window.innerWidth / 24}
                  />
                </Box>
              ) : null}

              <Grid
                pb={
                  pageType == "register"
                    ? 3
                    : pageType == "otpVerify" || pageType == "loginOtpVerify"
                    ? 12
                    : 7
                }
                pt={1.5}
                sx={{
                  display: "flex",
                  path: {
                    fill: theme_mode.iconBg,
                  },
                }}
                justifyContent="center"
              >
                <Logo width={55} height={55} />
              </Grid>

              <Grid pb={5}>
                {pageType == "otpVerify" || pageType == "loginOtpVerify" ? (
                  <OtpVerify
                    pageType={pageType}
                    themeMode={props.themeMode}
                    btnDisabled={btnDisabled}
                    msgAlert={
                      msgAlert.msgType == "error" ? msgAlert.message : ""
                    }
                    onVerifyRegister={
                      pageType == "loginOtpVerify" ? onLogin : onVerifyRegister
                    }
                  />
                ) : null}
                {pageType == "login" ? (
                  <UserLogin
                    themeMode={props.themeMode}
                    btnDisabled={btnDisabled}
                    msgAlert={
                      msgAlert.msgType == "error" ? msgAlert.message : ""
                    }
                    loginText={"Login Now"}
                    showForgotPswd={true}
                    showSignUp={true}
                    onLogin={onLogin}
                    onRegister={onRegisterBtn}
                  />
                ) : null}
                {pageType == "register" ? (
                  <UserRegister
                    themeMode={props.themeMode}
                    btnDisabled={btnDisabled}
                    msgAlert={
                      msgAlert.msgType == "error" ? msgAlert.message : ""
                    }
                    onSendOtp={onSendOtp}
                    onLogin={onLoginBtn}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" xs={12}>
        <Typography sx={styles.copyright}>
          Copyright © 2010-2022 Foodo Company. All rights reserved.
        </Typography>
      </Grid>
      {msgAlert.msgType != "error" ? (
        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      ) : null}
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRefreshToken: (data) => dispatch(updateRefreshToken(data)),
    updateUserData: (data) => dispatch(updateUserData(data)),
    updatePinLogin: (data) => dispatch(updatePinLogin(data)),
    updateLoginListData: (data) => dispatch(updateLoginListData(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const styles1 = (Theme) => ({
  topBgView: {
    position: "absolute",
    top: -(window.innerWidth / 10),
    right: 0,
    path: {
      fill: Theme.theme,
    },
  },
  cardView: {
    height: "80%",
    mt: "4.5%",
  },
  cardLeftView: {
    position: "relative",
    height: "100%",
  },
  bgImage: {
    width: "100%",
    height: "101%",
  },
  bgShadeImage: {
    width: "100%",
    height: "101%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  frameStyle2: {
    display: "flex",
    position: "absolute",
    bottom: -(window.innerWidth / 12) / 1.3,
    left: "30%",
    margin: "auto",
    justifyContent: "center",
    path: {
      fill: Theme.theme,
    },
  },
  ellipseStyle1: {
    display: "flex",
    position: "absolute",
    top: -(window.innerWidth / 20) / 2.5,
    left: "40%",
    margin: "auto",
    justifyContent: "center",
    circle: {
      stroke: Theme.theme,
    },
  },
  textView: {
    position: "absolute",
    bottom: 80,
    left: 60,
  },
  textView2: {
    display: "flex",
    flexDirection: "row",
  },
  mainText1: {
    color: "#fff",
    fontFamily: "InterBold",
    fontSize: 30,
    lineHeight: 1,
  },
  mainText2: {
    color: "#fff",
    fontFamily: "InterBold",
    fontSize: 30,
  },
  mainText3: {
    color: Theme.theme,
    fontFamily: "InterBold",
    fontSize: 30,
    ml: 1,
  },
  cardRightView: {
    position: "relative",
    backgroundColor: Theme.card2,
    height: "100%",
  },
  frameStyle1: {
    position: "absolute",
    bottom: -5,
    right: -(window.innerWidth / 12) / 2,
    path: {
      fill: Theme.theme,
    },
  },
  ellipseStyle2: {
    display: "flex",
    position: "absolute",
    bottom: -(window.innerWidth / 24) / 2,
    right: "30%",
    margin: "auto",
    justifyContent: "center",
    circle: {
      stroke: Theme.iconBg2,
    },
  },
  copyright: {
    fontFamily: "InterRegular",
    textAlign: "center",
    color: "#777",
    fontSize: 10,
    marginTop: -1,
  },
});
