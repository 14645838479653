import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import DiningCourseList from "./DiningCourseList";

import styles from "./DiningDetailCatMenuList.module.css";

import { THEME_MODE } from "../constants/Theme";
import {
  DINING_DETAIL_BTN_HEIGHT,
  DINING_DETAIL_CONTENT_HEIGHT,
  IS_LARGE_SCREEN,
} from "../constants";

let RANDOM_COLOR = [
  "#6D5C02",
  "#556C79",
  "#552A17",
  "#6A6152",
  "#662C52",
  "#342F2C",
  "#698265",
  "#342F2C",
];
let CONTENT_HEIGHT = DINING_DETAIL_CONTENT_HEIGHT - DINING_DETAIL_BTN_HEIGHT;
export let MENU_LEFT_PADDING = 4;
export let MENU_GAP_PADDING = 4;

const DiningDetailCatMenuList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [catBottomHeight, setCatBottomHeight] = useState(0);
  const [selectedCat, setSelectedCat] = useState("");
  const [menuListWidth, setMenuListWidth] = useState(0);
  const [menuListCount, setMenuListCount] = useState(0);

  let { categoryMenuList, leftViewWidth } = props;

  useEffect(() => {
    let count = categoryMenuList
      .filter((x) => x._id == selectedCat || selectedCat == "")
      .reduce((a, b) => a + b.menu.length, 0);
    setMenuListCount(count);
  }, [props.categoryMenuList, selectedCat]);

  useEffect(() => {
    if (document.getElementById("categoryItemListId")) {
      let client_height =
        document.getElementById("categoryItemListId").clientHeight;
      let scroll_height =
        document.getElementById("categoryItemListId").scrollHeight;
      if (scroll_height > client_height) {
        setMenuListWidth(
          document.getElementById("categoryItemListId").offsetWidth
        );
      } else {
        setMenuListWidth(
          document.getElementById("categoryItemListId").clientWidth
        );
      }
    }
  }, [
    props.categoryMenuList,
    props.drawerOpen,
    props.selectedCourse,
    selectedCat,
  ]);

  useEffect(() => {
    if (document.getElementById("catBottomViewId")) {
      setCatBottomHeight(
        document.getElementById("catBottomViewId").clientHeight
      );
    } else {
      setCatBottomHeight(0);
    }
  }, [props.categoryMenuList, props.drawerOpen, props.selectedCourse]);

  useEffect(() => {
    setSelectedCat("");
  }, [props.selectedCourse]);

  const onCategoryClick = (cat) => {
    setSelectedCat(cat._id);
    if (cat._id) {
      props.setSelectedCat(cat._id);
    }
    let sectionRight = document.querySelector("#categoryItemListId");
    sectionRight.scrollIntoView({
      behavior: "auto",
      block: "start",
      inline: "start",
    });
  };

  const splitArray = () => {
    let originalArr = [...categoryMenuList];

    let catCount = Math.ceil(leftViewWidth / 150);
    let splittedArray = [];

    while (originalArr.length > 0) {
      if (categoryMenuList.length <= catCount) {
        splittedArray.push(originalArr.splice(0, 1));
      } else {
        splittedArray.push(originalArr.splice(0, 2));
      }
    }
    return splittedArray;
  };

  const CategoryMenuListView = () => {
    let count = 0;
    return categoryMenuList
      .filter((x) => x._id == selectedCat || selectedCat == "")
      .map((cat, index) => {
        let menuCount = Math.ceil(
          (menuListWidth > 0 ? menuListWidth : leftViewWidth) /
            (IS_LARGE_SCREEN ? 300 : 200)
        );

        let catMenuWidth =
          (menuListWidth > 0 ? menuListWidth : leftViewWidth) / menuCount;

        let fntSize = IS_LARGE_SCREEN ? 28 : 20;
        let fntCnd = IS_LARGE_SCREEN ? 6.5 : 9;
        let catMenuFontSize =
          catMenuWidth / fntCnd > fntSize ? fntSize : catMenuWidth / fntCnd;

        return cat.menu.map((item, index2) => {
          count = count + 1;
          let reminder = 0;
          let selected = false;
          if (props.cartItems.length) {
            selected = props.cartItems.findIndex((x) => x._id == item._id) >= 0;
          }
          if (menuListCount == count) {
            reminder = menuListCount % menuCount;
            reminder = menuCount - reminder;
          }
          return (
            <>
              <Box
                menu-count={menuCount}
                key={index + "" + index2}
                className={styles.menuMainView}
                style={{
                  width: catMenuWidth - MENU_GAP_PADDING,
                }}
                mt={0.8}
              >
                {props.showMenuImg ? (
                  <Box
                    className={styles.menuImgSubView}
                    onClick={() => props.addMenuToCart(item)}
                    style={{
                      height: catMenuWidth * 0.9,
                      border: selected
                        ? `4px solid ${theme_mode.theme}`
                        : "unset",
                    }}
                  >
                    {item.images.length ? (
                      <img src={item.images[0]} className={styles.imgStyle} />
                    ) : null}
                    <Box className={styles.menuBgShade} />
                    <Box className={styles.menuTextView}>
                      <Typography
                        className={styles.menuText}
                        style={{
                          color: theme_mode.lightText,
                          fontSize: catMenuFontSize,
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    className={styles.menuSubView}
                    p={1}
                    onClick={() => props.addMenuToCart(item)}
                    style={{
                      backgroundColor: theme_mode.diningMenuBg,
                      border: selected
                        ? `4px solid ${theme_mode.redText}`
                        : "unset",
                    }}
                  >
                    <Typography
                      className={styles.menuText}
                      style={{
                        color: theme_mode.lightText,
                        fontSize: catMenuFontSize,
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                )}
              </Box>
              {Array(reminder)
                .fill(0)
                .map((d, i) => {
                  return (
                    <Box
                      key={index2 + i}
                      className={styles.menuMainView}
                      style={{
                        width: catMenuWidth - MENU_GAP_PADDING,
                      }}
                      mt={0.8}
                    />
                  );
                })}
            </>
          );
        });
      });
  };

  let j = -1;
  return (
    <Box
      style={{
        backgroundColor: theme_mode.diningDetailBg,
        height: CONTENT_HEIGHT,
      }}
    >
      <Grid item xs={12} display={"flex"}>
        <Grid
          item
          xs={9}
          sx={
            props.children
              ? null
              : typeof props.selectedCourse?._id != "undefined"
              ? null
              : {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }
          }
          style={{
            height: CONTENT_HEIGHT - catBottomHeight,
            overflow: "auto",
          }}
        >
          {props.children ? (
            props.children
          ) : (
            <Box
              mx={`${MENU_LEFT_PADDING}px`}
              className={styles.catMenuListView}
              id={"categoryItemListId"}
            >
              {typeof props.selectedCourse?._id != "undefined" ? (
                <CategoryMenuListView />
              ) : (
                <Typography
                  className={styles.noCourseText}
                  sx={{ color: theme_mode.text }}
                >
                  Please choose a course to view menu items
                </Typography>
              )}
            </Box>
          )}
        </Grid>
        <Grid item xs={3}>
          <DiningCourseList
            catBottomHeight={catBottomHeight}
            themeMode={props.themeMode}
            drawerOpen={props.drawerOpen}
            courseList={props.courseList}
            selectedCourse={props.selectedCourse}
            setSelectedCourse={props.setSelectedCourse}
          />
        </Grid>
      </Grid>
      {props.children ? null : (
        <Box
          id={"catBottomViewId"}
          className={styles.catBottomView}
          style={{ width: leftViewWidth }}
          pt={3}
        >
          {splitArray().map((cat, index) => {
            let mr = 3;
            let width = leftViewWidth;
            let catCount = Math.ceil(width / (IS_LARGE_SCREEN ? 250 : 180));
            width = width - mr * (catCount - 1) - MENU_LEFT_PADDING;
            let catListWidth = width / catCount;

            let fntSize = IS_LARGE_SCREEN ? 24 : 16;
            let fntCnd = IS_LARGE_SCREEN ? 4.5 : 7;

            let catListFontSize =
              catListWidth / fntCnd > fntSize ? fntSize : catListWidth / fntCnd;

            return (
              <Box key={index} className={styles.catListMainView}>
                {cat.map((x, i) => {
                  j++;
                  if (j == RANDOM_COLOR.length) {
                    j = 0;
                  }
                  let selected = false;
                  if (selectedCat == x._id) {
                    selected = true;
                  }
                  let bg_color = RANDOM_COLOR[j];
                  return (
                    <Box
                      key={i}
                      mt={i == 1 ? 0.5 : 0}
                      px={0.2}
                      onClick={() => onCategoryClick(x)}
                      className={styles.catListSubView}
                      style={{
                        marginLeft: index == 0 ? MENU_LEFT_PADDING : mr,
                        minWidth: catListWidth,
                        maxWidth: catListWidth,
                        backgroundColor: bg_color,
                        border: `4px solid ${
                          selected ? theme_mode.redText : bg_color
                        }`,
                      }}
                    >
                      <Typography
                        className={styles.menuText}
                        style={{
                          color: theme_mode.lightText,
                          fontSize: catListFontSize,
                        }}
                      >
                        {x.name}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default DiningDetailCatMenuList;
