import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

import { THEME_MODE } from "../constants/Theme";
import {DINING_DETAIL_BTN_HEIGHT, DINING_DETAIL_CONTENT_HEIGHT} from "../constants";

import styles from "./DiningDetailTable.module.css";

let CONTENT_HEIGHT = DINING_DETAIL_CONTENT_HEIGHT;

let HEADER_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;
let TOP_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;
let BOTTOM_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;
let MARGIN = 5;
let MARGIN_HEIGHT = MARGIN;

const DiningDetailTable = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const location = useLocation();

  const [selectedTableTab, setSelectedTableTab] = useState("join");
  const [selectedTable, setSelectedTable] = useState(props.table);
  const [joinedTable, setJoinedTable] = useState(props.joinedTable);
  const [viewWidth, setViewWidth] = useState(0);

  let tabBg = { true: theme_mode.theme, false: theme_mode.orderTableBg };
  let tabText = { true: theme_mode.text, false: theme_mode.lightText };

  let tableBg = { true: theme_mode.theme, false: theme_mode.orderTableBg };
  let tableText = { true: theme_mode.text, false: theme_mode.lightText };

  let urlTableId = location.pathname.split("/").pop();

  let showBottomBtn =
    (selectedTableTab == "transfer" && props.table != selectedTable) ||
    (selectedTableTab == "join" &&
      JSON.stringify(joinedTable) !== JSON.stringify(props.joinedTable));

  useEffect(() => {
    setSelectedTableTab("transfer");
  }, []);

  useEffect(() => {
    if (document.getElementById("tableListViewId")) {
      setViewWidth(document.getElementById("tableListViewId").clientWidth);
    }
  }, [props.drawerOpen, selectedTableTab]);

  useEffect(() => {
    setSelectedTable(props.table);
  }, [props.table]);

  useEffect(() => {
    setJoinedTable(props.joinedTable);
  }, [props.joinedTable]);

  const onSelectTab = (tab) => {
    setSelectedTable(props.table);
    setJoinedTable(props.joinedTable);
    setSelectedTableTab(tab);
  };

  const onSelectTable = (tbl) => {
    if (selectedTableTab == "join") {
      let list = [...joinedTable];
      let isExits = list.includes(tbl);
      if (isExits) {
        setJoinedTable(list.filter((x) => x != tbl));
      } else {
        list.push(tbl);
        setJoinedTable(list);
      }
    } else {
      setSelectedTable(tbl);
    }
  };

  const onSubmit = () => {
    if(showBottomBtn) {
      if (selectedTableTab == "join") {
        props.onSelectTable(selectedTableTab, joinedTable);
      } else {
        props.onSelectTable(selectedTableTab, selectedTable);
      }
    } else {
      props.onCancel();
    }
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <Box
      className={styles.mainView}
      mx={`${MARGIN}px`}
      mt={`${MARGIN}px`}
      style={{
        backgroundColor: theme_mode.diningContentBg,
        height: CONTENT_HEIGHT - HEADER_BTN_HEIGHT - MARGIN_HEIGHT,
      }}
    >
      <Box className={styles.headerView}>
        <Box
          className={styles.tabView}
          style={{
            backgroundColor: tabBg[selectedTableTab == "transfer"],
            height: TOP_BTN_HEIGHT,
          }}
          onClick={() => onSelectTab("transfer")}
        >
          <Typography
            className={styles.tabText}
            style={{ color: tabText[selectedTableTab == "transfer"] }}
          >
            TRANSFER TABLE
          </Typography>
        </Box>
        <Box
          className={styles.tabView}
          style={{
            backgroundColor: tabBg[selectedTableTab == "join"],
            height: TOP_BTN_HEIGHT,
          }}
          onClick={() => onSelectTab("join")}
        >
          <Typography
            className={styles.tabText}
            style={{ color: tabText[selectedTableTab == "join"] }}
          >
            JOIN TABLE
          </Typography>
        </Box>
      </Box>
      <Box
        className={styles.tableListMainView}
        pt={1}
        pb={0.4}
        style={{
          height:
            CONTENT_HEIGHT -
            HEADER_BTN_HEIGHT -
            TOP_BTN_HEIGHT -
            BOTTOM_BTN_HEIGHT -
            MARGIN_HEIGHT,
        }}
      >
        <Box className={styles.tableListView} id={"tableListViewId"}>
          {props.tableList.map((x, i) => {
            let table_width = viewWidth / 5.06;
            let isExits =
              props.orderList.filter(
                (y) =>
                  (y._idRestaurantTable?.length
                    ? y._idRestaurantTable
                    : []
                  ).includes(x._id) && y._id != props.orderData?._id
              ).length > 0;

            if (!isExits) {
              isExits =
                props.notSendOrderList.filter(
                  (y) =>
                    (y._idRestaurantTable?.length
                      ? y._idRestaurantTable
                      : []
                    ).includes(x._id) && !(y._idRestaurantTable?.length
                      ? y._idRestaurantTable
                      : []
                    ).includes(selectedTable?._id)
                ).length > 0;
            }

            let disabled = isExits;
            let active = false;
            if (selectedTableTab == "join") {
              let isJoinedTblExits = joinedTable
                .map((z) => z._id)
                .includes(x._id);
              if (isJoinedTblExits) {
                active = true;
                disabled = false;
              }
              if (x._id == props.table?._id) {
                disabled = true;
              }
            } else if (selectedTableTab == "transfer") {
              if (x._id == selectedTable?._id) {
                active = true;
              }
              if (x._id == urlTableId) {
                disabled = false;
              }
              let isJoinedTblExits = props.joinedTable
                .map((z) => z._id)
                .includes(x._id);
              if (isJoinedTblExits) {
                disabled = true;
              }
            }
            return (
              <Box
                pb={0.5}
                pl={0.6}
                className={styles.tableMainView}
                style={{
                  width: table_width,
                  height: table_width,
                }}
              >
                <Box
                  style={{
                    backgroundColor: tableBg[active],
                    opacity: disabled ? 0.4 : 1,
                  }}
                  className={styles.tableView}
                  onClick={() => (disabled ? null : onSelectTable(x))}
                >
                  <Box
                    className={styles.tableTextView}
                    style={{
                      width: table_width / 2.5,
                      height: table_width / 2.5,
                      border: `1px solid ${tableText[active]}`,
                    }}
                  >
                    <Typography
                      className={styles.tableText}
                      style={{
                        color: tableText[active],
                        fontSize: table_width / 2.5 / 2,
                      }}
                    >
                      {x.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Box
            onClick={onCancel}
            className={styles.submitBtn}
            style={{
              backgroundColor: theme_mode.redBg,
              height: BOTTOM_BTN_HEIGHT,
            }}
          >
            <Typography
              className={styles.submitBtnText}
              style={{ color: theme_mode.lightText }}
            >
             Cancel
            </Typography>
          </Box>
          <Box
            onClick={onSubmit}
            className={styles.submitBtn}
            style={{
              backgroundColor: theme_mode.greenBg,
              height: BOTTOM_BTN_HEIGHT,
            }}
          >
            <Typography
              className={styles.submitBtnText}
              style={{ color: theme_mode.lightText }}
            >
              {selectedTableTab == "transfer"
                ? (showBottomBtn ? `Transfer table number ${props.table?.name} to ${selectedTable?.name}`
                  : `Transfer from table number ${props.table?.name}`)
                : showBottomBtn ? (joinedTable.length
                  ? `Join table number 
              ${joinedTable.map((x) => x.name).join(", ")} to ${
                    props.table?.name
                  }`
                  : "Remove Joined Tables") : `Join Table`}
            </Typography>
          </Box>
        </Box>
    </Box>
  );
};

export default DiningDetailTable;
