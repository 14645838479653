import React, {useCallback, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import './../assets/css/custom.css';

import {ReactComponent as CloseIcon} from "../assets/images/close_white.svg";

import {THEME_COLOR} from "../constants";
import {THEME_MODE} from "../constants/Theme";

const AddTable = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    if (props.modelVisible) {
      let {inUseTables, reservedTables} = props;
      let tableList = Array.from({length: props.noOfTables}, (_, i) => i + 1);
      let tables = tableList.filter((el) => (!inUseTables.includes(el) && !reservedTables.includes(el))).map((x, i) => {
        return {table: x, status: ''}
      });
      reservedTables.map((x, i) => {
        tables.push({table: x, status: 'reserved'});
      });
      inUseTables.map((x, i) => {
        tables.push({table: x, status: 'inuse'});
      });
      setTableList(tables);
    }
  }, [props.modelVisible]);

  const onSubmit = (val) => {
    props.onSubmit(val)
  }

  return (
    <Modal
      style={styles.modelView}
      open={props.modelVisible}
      onClose={props.onClose}>
      <Grid item xs={12} sx={styles.mainView}>
        <Grid item xs={12} sx={styles.headerView}>
          <Typography sx={styles.titleText}>Select table number</Typography>
          <Box className="cursor-pointer" onClick={() => props.onToggle(false)}>
            <CloseIcon width={50} height={50}/>
          </Box>
        </Grid>
        <Grid item xs={12} mt={3} sx={styles.mainGrid}>
          {tableList.map((x, i) => {
            return (
              <Box key={i} sx={{...styles.tableMainBox, cursor: x.status == '' ? 'pointer' : 'unset'}}
                   onClick={() => x.status == '' ? onSubmit(x) : null}>
                <Box sx={styles.tableBox}>
                  <Typography sx={styles.tableText}>Table {x.table}</Typography>
                </Box>
                {(x.status != '') ?
                  <Box
                    sx={{...styles.statusView, backgroundColor: x.status == 'reserved' ? '#E8505B' : '#1D86FF'}}>
                    <Typography sx={styles.statusText}>
                      {x.status == 'reserved' ? 'Reserved' : 'In Use'}
                    </Typography>
                  </Box> : null}
              </Box>
            )
          })}
          {tableList.length == 0 ?
            <Typography sx={styles.noTableText}>No Tables Available</Typography>
            : null}
        </Grid>
      </Grid>
    </Modal>
  );
}

export default AddTable;

const styles1 = Theme => ({
  modelView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  mainView: {
    backgroundColor: Theme.modal,
    width: {md: '30%', xs: '98%'},
    borderRadius: 3,
    "&:focus": {
      outline: 'none',
    },
    overflow: 'hidden',
  },
  headerView: {
    backgroundColor: Theme.theme,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
    px: 2,
  },
  titleText: {
    color: Theme.text,
    fontFamily: 'InterBold',
    fontSize: 20,
  },
  mainGrid: {
    maxHeight: window.innerHeight - 180,
    minHeight: 200,
    overflow: 'auto',
    px: 2,
    pb: 2,
  },
  tableMainBox: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid ' + Theme.borderBottom2,
    borderRadius: '10px',
    alignItems: 'center',
    height: 50,
    px: 2,
    py: 1,
    mb: 1,
  },
  tableBox: {
    flex: 1,
  },
  tableText: {
    color: Theme.text,
    fontFamily: 'InterBold',
    fontSize: 17,
  },
  statusView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px',
    minWidth: 70,
    height: 20,
    px: 0.5,
  },
  statusText: {
    color: Theme.lightText,
    fontFamily: 'InterMedium',
    textAlign: 'center',
    fontSize: 10,
  },
  noTableText: {
    color: Theme.text,
    fontFamily: 'InterMedium',
    textAlign: 'center',
    fontSize: 10,
  },
});

