import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";

import { connect } from "react-redux";

import Sidebar from "./../components/Sidebar";
import Footer from "./../components/Footer";

import { THEME_MODE } from "../constants/Theme";
import {CONTAINER_HEIGHT, NAVBAR_HEIGHT} from "../constants";

function Container(props) {
  const theme_mode = THEME_MODE[props.themeMode];

  useEffect(() => {
    if (document.getElementById("root")) {
      document.getElementById("root").style.backgroundColor = props?.bgColor
        ? props.bgColor
        : theme_mode.bg;
    }
  }, []);

  let show_sidebar = true;
  if (typeof props.showSidebar != 'undefined') {
    show_sidebar = props.showSidebar;
  }

  let show_toolbar = true;
  if (typeof props.showToolbar != 'undefined') {
    show_toolbar = props.showToolbar;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {show_sidebar ?
        <Sidebar {...props} />
        : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: typeof props.p != 'undefined' ? props.p : 3,
          backgroundColor: props?.bgColor ? props.bgColor : theme_mode.bg,
        }}
      >
        {show_toolbar ?
          <Toolbar style={{height: NAVBAR_HEIGHT}}/>
          : null}
        <Box
          style={{
            minHeight: props?.minHeight
              ? props.minHeight
              : CONTAINER_HEIGHT - 80,
            position: "relative",
          }}
        >
          {props.children}
        </Box>
        {typeof props.showFooter == "undefined" || props?.showFooter == true ? (
          <Box px={props?.fpx ? props.fpx : 0} py={props?.fpy ? props.fpy : 0}>
            <Footer themeMode={props.themeMode} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(Container);
