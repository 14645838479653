import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import styles from "./TableBooking.module.css";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import CustomerBookingTable from "../../components/CustomerBookingTable";
import AddBookingSlotModal from "../../components/AddBookingSlotModal";
import SettingDatePicker from "../../components/SettingDatePicker";

import { THEME_MODE } from "../../constants/Theme";

import { ReactComponent as CalenderIcon } from "../../assets/images/calender_white.svg";
import { ReactComponent as AddIcon } from "../../assets/images/add.svg";
import {CONTAINER_HEIGHT} from "../../constants";

let CONTENT_HEIGHT = CONTAINER_HEIGHT - 50;
let TOP_VIEW_HEIGHT = 190;
let BTN_HEIGHT = 70;

const TableBooking = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [timeList, setTimeList] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [bookingList, setBookingList] = useState([]);
  const [bookingDate, setBookingDate] = useState(
    moment().format("DD MMM YYYY")
  );
  const [tableBookingWidth, setTableBookingWidth] = useState(100);
  const [addBookingSlotModalVisible, setAddBookingSlotModalVisible] =
    useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  let tabBg = { true: theme_mode.activeSlotBg, false: theme_mode.slotBg };
  let tabText = { true: theme_mode.lightText, false: theme_mode.text };

  useEffect(() => {
    if (document.getElementById("sidebarViewId")) {
      setTableBookingWidth(
        window.innerWidth - document.getElementById("sidebarViewId").offsetWidth
      );
    }
  }, [props.drawerOpen]);

  useEffect(() => {
    getBookingSlots();
  }, [bookingDate]);

  useEffect(() => {
    if (selectedTime) {
      getTableBooking(selectedTime?._id);
    } else {
      setTableLoading(false);
    }
  }, [selectedTime]);

  const getBookingSlots = () => {
    setIsLoading(true);
    let filter =
      "?pageSize=3000&pageNum=1&filter_bookingDate=" +
      moment(bookingDate, "DD MMM YYYY").format("YYYY-MM-DD");
    Api.getBookingSlots(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let rows = response.data.rows;
        if (rows.length) {
          let list = rows.sort((a, b) =>
            moment(a.bookingTime, "HH:mm").isBefore(
              moment(b.bookingTime, "HH:mm")
            )
              ? -1
              : 1
          );
          setTimeList(list);
          setSelectedTime(rows[0]);
        } else {
          setTimeList([]);
          setSelectedTime(null);
          setIsLoading(false);
        }
        setBookingList([]);
      } else {
        setMsgAlert({
          open: true,
          message: response.msg,
          msgType: "error",
        });
        setIsLoading(false);
      }
    });
  };

  const getTableBooking = (_idTableBookingSlot) => {
    setTableLoading(true);
    let filter =
      "?pageSize=30000&pageNum=1&filter_status=1&filter__idTableBookingSlot=" +
      _idTableBookingSlot;
    Api.getTableBooking(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let rows = response.data.rows;
        setBookingList(rows);
      } else {
        setMsgAlert({
          open: true,
          message: response.msg,
          msgType: "error",
        });
      }
      setIsLoading(false);
      setTableLoading(false);
    });
  };

  const onAddBookingSlot = (slot) => {
    if (timeList.length == 0) {
      setSelectedTime(slot);
      setTimeList([...timeList, slot]);
    } else {
      let list = [...timeList, slot].sort((a, b) =>
        moment(a.bookingTime, "HH:mm").isBefore(moment(b.bookingTime, "HH:mm"))
          ? -1
          : 1
      );
      setTimeList(list);
    }
    setAddBookingSlotModalVisible(false);
  };

  return (
    <Container page={"bookings"} p={1.5} fpx={3} fpy={1}>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid
          container
          item
          xs={12}
          className={styles.mainView}
          style={{ backgroundColor: theme_mode.card2, height: CONTENT_HEIGHT }}
        >
          <Grid item px={2} xs={12} style={{ height: TOP_VIEW_HEIGHT }}>
            <Grid pt={1.5} item xs={12} className={styles.topDateView}>
              <Box sx={{ width: 180 }}>
                <SettingDatePicker
                  value={bookingDate}
                  disabled={false}
                  inputFormat="DD MMM YYYY"
                  onChange={(newValue) => {
                    setBookingDate(newValue.format("DD MMM YYYY"));
                  }}
                  inputFontSize={12}
                  placeholder={"Date"}
                  color={theme_mode.btnText}
                  backgroundColor={
                    props.themeMode == "light"
                      ? theme_mode.btnBg
                      : theme_mode.inputBg2
                  }
                  borderRadius={"10px"}
                  error={""}
                  leftView={null}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                className={styles.timeListMainView}
                pt={1.5}
                pb={0.5}
                style={{ overflow: "auto", width: tableBookingWidth - 80 }}
              >
                {moment(bookingDate).isBefore(moment(), "day") ? null : (
                  <Box
                    mx={1}
                    p={1}
                    style={{
                      backgroundColor: theme_mode.btnBg4,
                    }}
                    className={styles.addBtnView}
                    onClick={() => setAddBookingSlotModalVisible(true)}
                  >
                    <AddIcon width={30} height={30} />
                  </Box>
                )}
                {timeList.map((x, i) => {
                  return (
                    <Box
                      key={i}
                      mr={1}
                      p={1}
                      style={{
                        backgroundColor: tabBg[selectedTime?._id == x._id],
                      }}
                      className={styles.timeListView}
                      onClick={() => setSelectedTime(x)}
                    >
                      <Typography
                        className={styles.timeText}
                        style={{ color: tabText[selectedTime?._id == x._id] }}
                      >
                        {moment(x.bookingTime, "HH:mm").format("hh:mm A")}
                      </Typography>
                      <Box className={styles.limitReserveView} mt={1}>
                        <Typography
                          className={styles.limitReserveText}
                          style={{ color: tabText[selectedTime?._id == x._id] }}
                        >
                          Limit
                        </Typography>
                        <Typography
                          className={styles.limitReserveText}
                          style={{ color: tabText[selectedTime?._id == x._id] }}
                        >
                          {x.limit}
                        </Typography>
                      </Box>
                      <Box
                        mt={1}
                        className={styles.limitReserveView}
                        style={{ backgroundColor: "#67A047" }}
                      >
                        <Typography
                          className={styles.limitReserveText}
                          style={{ color: theme_mode.lightText }}
                        >
                          Reserved
                        </Typography>
                        <Typography
                          className={styles.limitReserveText}
                          style={{ color: theme_mode.lightText }}
                        >
                          {x.reserved}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            px={2}
            xs={12}
            style={{
              height:
                CONTENT_HEIGHT -
                TOP_VIEW_HEIGHT -
                (moment(bookingDate).isBefore(moment(), "day")
                  ? 0
                  : BTN_HEIGHT),
              overflow: "auto",
            }}
          >
            {tableLoading ? (
              <Loader
                height={{
                  height:
                    CONTENT_HEIGHT -
                    TOP_VIEW_HEIGHT -
                    (moment(bookingDate).isBefore(moment(), "day")
                      ? 0
                      : BTN_HEIGHT),
                }}
              />
            ) : (
              <CustomerBookingTable
                themeMode={props.themeMode}
                itemList={bookingList}
                onClick={(x) =>
                  navigate(
                    "/bookings/" + selectedTime?._id + "/cancel/" + x._id
                  )
                }
              />
            )}
          </Grid>
          {moment(bookingDate).isBefore(
            moment(),
            "day"
          ) ? null : selectedTime &&
            selectedTime?.reserved < selectedTime?.limit ? (
            <Grid item xs={12}>
              <Box
                onClick={() =>
                  selectedTime == null
                    ? null
                    : navigate("/bookings/" + selectedTime?._id + "/add")
                }
                className={styles.addBtn}
                style={{
                  backgroundColor: theme_mode.btnBg4,
                  height: BTN_HEIGHT,
                }}
              >
                <Typography
                  className={styles.addBtnText}
                  style={{ color: theme_mode.btnText }}
                >
                  Add New Booking
                </Typography>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      )}

      <AddBookingSlotModal
        restaurantId={props.restaurantId}
        modalVisible={addBookingSlotModalVisible}
        date={bookingDate}
        onSubmit={onAddBookingSlot}
        onCancel={() => setAddBookingSlotModalVisible(false)}
      />
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    drawerOpen: state.userData.drawerOpen,
    restaurantId: state.userData.restaurantId,
  };
};

export default connect(mapStateToProps, null)(TableBooking);
