import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

import styles from "./AlertPopUp.module.css";

import { THEME_MODE } from "../constants/Theme";

const AlertPopUp = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  return (
    <Modal
      className={styles.modelView}
      open={props.modalVisible}
      onClose={() => null}
    >
      <Grid
        item
        xs={12}
        className={styles.mainView}
        p={5}
        sx={{
          backgroundColor: theme_mode.modal,
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <Typography className={styles.dataText} sx={{ color: theme_mode.text }}>
          {props.msg}
        </Typography>

        <Button
          type="button"
          className={styles.submitBtn}
          sx={{
            border: `2px solid ${theme_mode.theme}`,
            color: theme_mode.text,
            "&:hover": {
              backgroundColor: "transparent !important",
            },
            mt: 3,
          }}
          onClick={props.onClick}
        >
          OK
        </Button>
      </Grid>
    </Modal>
  );
};

export default AlertPopUp;
