import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import styles from "./LoginDarkTheme.module.css";

import { connect } from "react-redux";

import UserLogin from "./UserLogin";
import UserRegister from "./UserRegister";
import OtpVerify from "./OtpVerify";

import { THEME_MODE } from "../constants/Theme";

import { ReactComponent as LogoIcon } from "../assets/images/logo2.svg";
import { ReactComponent as LogoWhiteIcon } from "../assets/images/logo_white.svg";

const LoginDarkTheme = (props) => {
  const theme_mode = THEME_MODE["dark"];
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      className={styles.mainView}
    >
      <Box
        className={styles.logoView}
        sx={{
          path: {
            fill: theme_mode.iconBg,
          },
        }}
      >
        <Typography
          className={styles.poweredByText}
          style={{ color: theme_mode.text }}
        >
          Powered by
        </Typography>
        <LogoIcon height={15} width={100} />
      </Box>
      <Box className={styles.pinLoginBg}>
        <img
          style={{ width: "100%", height: "100%" }}
          src={require("../assets/images/pin_login_dark_bg.png")}
        />
      </Box>
      <Grid container item xs={12} className={styles.subView}>
        <Grid container item xs={12} md={3} className={styles.loginView}>
          <Box mb={6}>
            <LogoWhiteIcon height={60} />
          </Box>
          {props.pageType == "login" ? (
            <UserLogin
              themeMode={"dark"}
              btnDisabled={props.btnDisabled}
              msgAlert={props.msgAlert}
              loginText={"Login"}
              showForgotPswd={false}
              showSignUp={true}
              loginBtnStyle={{ height: 45, fontSize: 15 }}
              registerBtnStyle={{ height: 45, fontSize: 15 }}
              inputsx={{
                "& .MuiInputBase-root": {
                  "&:before, :after, :hover:not(.Mui-disabled):before": {
                    borderBottom: "1px solid #FFF",
                  },
                },
              }}
              inputStyle={{ fontSize: 15, px: 0, mb: -1 }}
              pswdInputStyle={{
                fontSize: 25,
                pb: -2,
                color: "#4F5868",
                letterSpacing: 3,
              }}
              onLogin={props.onLogin}
              onRegister={props.onRegister}
            />
          ) : null}
          {props.pageType == "register" ? (
            <UserRegister
              themeMode={"dark"}
              inputStyle={{ fontSize: 15, px: 0, mb: -1 }}
              loginBtnStyle={{ height: 45, fontSize: 15 }}
              registerBtnStyle={{ height: 45, fontSize: 15 }}
              agreeTermStyle={{ fontSize: 12 }}
              btnDisabled={props.btnDisabled}
              msgAlert={props.msgAlert}
              onSendOtp={props.onSendOtp}
              onLogin={props.onLoginBtn}
            />
          ) : null}
          {props.pageType == "otpVerify" ||
          props.pageType == "loginOtpVerify" ? (
            <OtpVerify
              pageType={props.pageType}
              themeMode={"dark"}
              inputStyle={{ fontSize: 15, px: 0, mb: -1 }}
              btnStyle={{ height: 45, fontSize: 15 }}
              btnDisabled={props.btnDisabled}
              msgAlert={props.msgAlert}
              onVerifyRegister={
                props.pageType == "loginOtpVerify"
                  ? props.onLogin
                  : props.onVerifyRegister
              }
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(LoginDarkTheme);
