import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import styles from "./Calculator.module.css";

import { THEME_MODE } from "../constants/Theme";

import { ReactComponent as BackspaceIcon } from "../assets/images/backspace.svg";

let CALC_NO = [1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "x"];

const Calculator = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  let calcList = props?.calcList?.length ? props?.calcList : CALC_NO;
  const onNumClick = (no) => {
    let currentVal = props.currentVal;
    let calcVal = 0;
    if (no == "C" || no == "Clear") {
      if (props.emptyValue) {
        calcVal = "";
      } else {
        calcVal = 0;
      }
    } else if (no == "x") {
      let val = currentVal == "" ? "" : currentVal.toString().slice(0, -1);
      if (val == "" && !props.emptyValue) {
        val = 0;
      }
      calcVal = val;
    } else {
      if (currentVal == "" && no == ".") {
        calcVal = "0.";
      } else if (no == ".") {
        if (currentVal.toString().search(/\./) < 0) {
          calcVal = currentVal + "" + no;
        } else {
          calcVal = currentVal;
        }
      } else if (
        (currentVal == "" || currentVal == 0) &&
        !props.emptyValue &&
        currentVal.toString().search(/\./) < 0
      ) {
        calcVal = no;
      } else {
        if (currentVal == 0) {
          calcVal = no;
          if (props?.initialZero) {
            calcVal = currentVal + "" + no;
          }
        } else {
          calcVal = currentVal + "" + no;
        }
      }
    }
    props.onUpdate(calcVal);
  };

  const splitArray = () => {
    let originalArr = [...calcList];
    let splittedArray = [];
    while (originalArr.length > 0) {
      splittedArray.push(originalArr.splice(0, 3));
    }
    return splittedArray;
  };

  return (
    <Box
      className={styles.calcMainView}
      sx={props?.mainViewStyle ? props.mainViewStyle : null}
    >
      {splitArray().map((y, i1) => {
        return (
          <Box key={i1} className={styles.calcSubView}>
            {y.map((x, i) => {
              return (
                <Box
                  key={i}
                  onClick={() => (props.disabled ? null : onNumClick(x))}
                  className={styles.calcView}
                  mb={1}
                  sx={{
                    backgroundColor:
                      x == "x" ? theme_mode.bg3 : theme_mode.calcBg,
                    height: props.height,
                    width: props.width,
                    marginLeft: "5px",
                    ...(props?.calcStyle ? props.calcStyle : null),
                  }}
                >
                  {x == "x" ? (
                    <Box
                      sx={{
                        ...(props?.iconStyle ? props.iconStyle : null),
                        lineHeight: `0px`,
                      }}
                    >
                      <BackspaceIcon
                        width={props?.iconSize ? props.iconSize : 24}
                        height={props?.iconSize ? props.iconSize : 24}
                      />
                    </Box>
                  ) : (
                    <Typography
                      className={styles.calcText}
                      sx={{
                        color: theme_mode.lightText,
                        ...(props?.textStyle ? props.textStyle : null),
                      }}
                    >
                      {x}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default Calculator;
