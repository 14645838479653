import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import BookingsTableList from "../../components/BookingsTableList";
import styles from "./BookingsHistory.module.css";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import { ReactComponent as AddIcon } from "../../assets/images/add_plus.svg";

import { THEME_MODE } from "../../constants/Theme";
import { FOOTER_XPADDING } from "../../constants";

let bookingsList = [
  {
    _id: 1,
    name: "John John",
    email: "johnjohn@gmail.com",
    status: "ongoing",
    date: "16-06-2023",
    guests: 4,
  },
  {
    _id: 2,
    name: "John John",
    email: "johnjohn@gmail.com",
    status: "ongoing",
    date: "16-06-2023",
    guests: 4,
  },
  {
    _id: 3,
    name: "John John",
    email: "johnjohn@gmail.com",
    status: "ongoing",
    date: "16-06-2023",
    guests: 4,
  },
  {
    _id: 4,
    name: "John John",
    email: "johnjohn@gmail.com",
    status: "ongoing",
    date: "16-06-2023",
    guests: 4,
  },
  {
    _id: 5,
    name: "John John",
    email: "johnjohn@gmail.com",
    status: "ongoing",
    date: "16-06-2023",
    guests: 4,
  },
  {
    _id: 6,
    name: "John John",
    email: "johnjohn@gmail.com",
    status: "cancelled",
    date: "16-06-2023",
    guests: 4,
  },
  {
    _id: 7,
    name: "John John",
    email: "johnjohn@gmail.com",
    status: "cancelled",
    date: "16-06-2023",
    guests: 4,
  },
  {
    _id: 8,
    name: "John John",
    email: "johnjohn@gmail.com",
    status: "cancelled",
    date: "16-06-2023",
    guests: 4,
  },
  {
    _id: 9,
    name: "John John",
    email: "johnjohn@gmail.com",
    status: "cancelled",
    date: "16-06-2023",
    guests: 4,
  },
  {
    _id: 10,
    name: "John John",
    email: "johnjohn@gmail.com",
    status: "cancelled",
    date: "16-06-2023",
    guests: 4,
  },
];

const BookingHistory = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [bookingStatus, setBookingStatus] = useState("ongoing");

  return (
    <Container
      page={"settings"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0}
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid
          p={1.5}
          style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
        >
          <Grid mb={2} container xs={12}>
            <Grid item xs={5}>
              <Box
                onClick={() => navigate("/settings")}
                sx={{ cursor: "pointer", width: "fit-content" }}
              >
                <BackBtnIcon width={33} height={33} />
              </Box>
            </Grid>
            <Grid container xs={7} justifyContent={"flex-end"}>
              <Box
                display={"flex"}
                sx={{ backgroundColor: theme_mode.bg9, borderRadius: 2 }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  className={styles.statusBtn}
                  sx={{
                    backgroundColor:
                      bookingStatus === "ongoing"
                        ? theme_mode.btnBg
                        : theme_mode.bg9,
                  }}
                  onClick={() => setBookingStatus("ongoing")}
                >
                  <Typography
                    className={styles.addBtnText}
                    sx={{
                      color:
                        bookingStatus === "ongoing"
                          ? theme_mode.lightText
                          : theme_mode.text,
                    }}
                  >
                    Ongoing
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  className={styles.statusBtn}
                  sx={{
                    backgroundColor:
                      bookingStatus === "cancelled"
                        ? theme_mode.btnBg
                        : theme_mode.bg9,
                  }}
                  onClick={() => setBookingStatus("cancelled")}
                >
                  <Typography
                    className={styles.addBtnText}
                    sx={{
                      color:
                        bookingStatus === "cancelled"
                          ? theme_mode.lightText
                          : theme_mode.text,
                    }}
                  >
                    Cancelled
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid>
            <BookingsTableList
              themeMode={props.themeMode}
              itemList={bookingsList.filter((v) => v.status === bookingStatus)}
              bookingStatus={bookingStatus}
              // editItem={onEditUser}
              // deleteItem={onDeleteUser}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(BookingHistory);
