import React, { useEffect, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import OrderModalItemList from "./OrderModalItemList";

import { CURRENCY_SYMBOL } from "../constants";

import { ReactComponent as PhoneIcon } from "../assets/images/phone_circle.svg";
import { ReactComponent as InfoIcon } from "../assets/images/info_black.svg";
import { ReactComponent as CancelOrderIcon } from "../assets/images/cancel_order.svg";
import { ReactComponent as CancelOrderDarkIcon } from "../assets/images/cancel_order_dark.svg";
import { THEME_MODE } from "../constants/Theme";
import { getOrdersTotalPrice } from "../constants/CommonFunc";

let dataType = {
  incoming: {
    submitBtnText: "Accept",
    cancelBtnText: "Cancel",
  },
  preparing: {
    submitBtnText: "Prepared",
    cancelBtnText: "Cancel",
  },
  ready: {
    submitBtnText: "Delivered",
    cancelBtnText: "Cancel Order",
  },
  reject_confirm: {
    submitBtnText: "No, Don’t Reject",
    cancelBtnText: "Cancel",
  },
};

let BOTTOM_HEIGHT = 70;

const OrderDetailModal = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [rejectConfirmation, setRejectConfirmation] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [taxVal, setTaxVal] = useState(20.69);
  const [totalPrice, setTotalPrice] = useState(0);
  const [priceHeight, setPriceHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  let { data } = props;

  useEffect(() => {
    setRejectConfirmation(false);
  }, [props.open]);

  useEffect(() => {
    if (props.data) {
      let total = getOrdersTotalPrice(props?.data?.cart, []);
      setSubTotal(total.toFixed(2));
      setTotalPrice((total + taxVal).toFixed(2));
    }
  }, [props.data]);

  useEffect(() => {
    if (props.open) {
      if (document.getElementById("priceValViewId")) {
        setPriceHeight(document.getElementById("priceValViewId").offsetHeight);
      }
      if (document.getElementById("headerViewId")) {
        setHeaderHeight(document.getElementById("headerViewId").offsetHeight);
      }
    }
  }, [props.open]);

  const onCancelClick = (type) => {
    if (type == "reject_confirm") {
      props.onCancel("cancelled");
    } else {
      setRejectConfirmation(true);
    }
  };

  const onSubmitClick = (type) => {
    if (type == "reject_confirm") {
      setRejectConfirmation(false);
    } else {
      let status = "";
      if (type == "incoming") {
        status = "preparing";
      } else if (type == "preparing") {
        status = "ready";
      } else if (type == "ready") {
        status = "completed";
      }
      if (status != "") {
        props.onSubmit(status);
      }
    }
  };

  const BottomView = ({ modalType }) => {
    return (
      <Box sx={styles.bottomView}>
        <Box sx={styles.bottomSubView}>
          <Box sx={styles.btnView} onClick={() => onCancelClick(modalType)}>
            <Typography sx={styles.btnText}>
              {dataType[modalType]["cancelBtnText"]}
            </Typography>
          </Box>
          <Box
            sx={styles.submitBtnView}
            onClick={() => onSubmitClick(modalType)}
          >
            <Typography sx={styles.btnText}>
              {dataType[modalType]["submitBtnText"]}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={props.open}
      onClose={props.onClose}
      sx={{
        "& .MuiPaper-root": {
          right: 0,
          top: 0,
          left: { xs: 0, md: "unset" },
          height: window.innerHeight,
          // borderRadius: '10px',
        },
      }}
    >
      {props.data ? (
        !rejectConfirmation ? (
          <Box sx={styles.mainView}>
            <Box
              sx={styles.dataView}
              id={"headerViewId"}
              px={3}
              pt={1.5}
              pb={1}
            >
              <Box sx={styles.orderDataView}>
                <Box>
                  <Typography sx={styles.orderIdText}>
                    Order #{data._id.toString().substr(-5)}
                  </Typography>
                  <Typography sx={styles.orderDateText}>{data.date}</Typography>
                </Box>
                <Box>
                  <Box
                    sx={{
                      ...styles.orderTypeView,
                      backgroundColor:
                        data.orderType == "delivery"
                          ? theme_mode.deliveryBg
                          : theme_mode.pickupBg,
                    }}
                  >
                    <Typography sx={styles.orderTypeText}>
                      {data.orderType}
                    </Typography>
                  </Box>
                  <Typography sx={styles.priceText}>
                    Total: {CURRENCY_SYMBOL[data.price.currency]} {totalPrice}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.subDataView}>
                <Typography sx={styles.nameText}>{data.name}</Typography>
                <Box mt={0.7} mb={1} sx={styles.addressView}>
                  <Typography sx={styles.addressText}>
                    {data.address}
                  </Typography>
                  <Typography sx={styles.distanceText}>
                    {data.distance} Away
                  </Typography>
                </Box>
                <Box>
                  <Box sx={styles.phView}>
                    <PhoneIcon width={26} height={26} />
                    <Typography mx={1} sx={styles.phText}>
                      {data.phone}
                    </Typography>
                  </Box>
                  {data.type == "preparing" ? (
                    <Box sx={styles.descView}>
                      <Typography ml={0.5} sx={styles.descText}>
                        This order has been currently prepared
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                ...styles.menuListView,
                height: window.innerHeight - headerHeight - BOTTOM_HEIGHT,
              }}
            >
              <Box
                sx={{
                  minHeight:
                    window.innerHeight -
                    headerHeight -
                    priceHeight -
                    BOTTOM_HEIGHT -
                    10,
                }}
              >
                {data.cart.map((x, i) => {
                  return (
                    <OrderModalItemList
                      key={i}
                      item={x}
                      themeMode={props.themeMode}
                      lastIndex={data.cart.length == i + 1}
                      page={"orderDetail"}
                    />
                  );
                })}
              </Box>
              <Box sx={styles.orderPriceView} id={"priceValViewId"}>
                <Box sx={styles.orderPriceSubView}>
                  <Box sx={styles.subPriceView}>
                    <Typography sx={styles.subTotalText}>Subtotal</Typography>
                  </Box>
                  <Typography sx={styles.subTotalVal}>
                    {CURRENCY_SYMBOL[data.price.currency]} {subTotal}
                  </Typography>
                </Box>
                <Box sx={styles.orderPriceSubView}>
                  <Box sx={styles.subPriceView}>
                    <Typography sx={styles.taxText}>Tax</Typography>
                  </Box>
                  <Typography sx={styles.taxVal}>
                    {CURRENCY_SYMBOL[data.price.currency]} {taxVal}
                  </Typography>
                </Box>
                <Box sx={styles.orderPriceSubView}>
                  <Box sx={styles.subPriceView}>
                    <Typography sx={styles.totalPriceText}>Total</Typography>
                  </Box>
                  <Typography sx={styles.totalPrice}>
                    {CURRENCY_SYMBOL[data.price.currency]} {totalPrice}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <BottomView modalType={data.type} />
          </Box>
        ) : (
          <Box sx={{ ...styles.mainView, backgroundColor: theme_mode.modal }}>
            <Box sx={styles.rejectMainView}>
              {props.themeMode == "light" ? (
                <CancelOrderIcon width={80} height={80} />
              ) : (
                <CancelOrderDarkIcon width={80} height={80} />
              )}
              <Typography mt={1} sx={styles.sureText}>
                Are you sure ?
              </Typography>
              <Typography sx={styles.sureDescText}>
                Are you sure you want to cancel this order?
              </Typography>
            </Box>
            <BottomView modalType={"reject_confirm"} />
          </Box>
        )
      ) : null}
    </SwipeableDrawer>
  );
};

export default OrderDetailModal;

const styles1 = (Theme) => ({
  mainView: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: { xs: window.innerWidth, md: window.innerWidth / 2 },
    backgroundColor: Theme.card2,
    // height: '100%'
  },
  dataView: {
    display: "flex",
    backgroundColor: Theme.modalHeaderBg2,
  },
  orderDataView: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  subDataView: {
    flex: 0.8,
    ml: 2,
  },
  orderIdText: {
    color: Theme.lightText,
    fontFamily: "InterBold",
    fontSize: 16,
  },
  orderDateText: {
    color: Theme.lightText,
    fontFamily: "InterRegular",
    fontSize: 12,
    lineHeight: 2,
    mb: 1.4,
  },
  orderTypeView: {
    width: 100,
    py: 0.3,
    borderRadius: "30px",
  },
  orderTypeText: {
    color: Theme.lightText,
    fontFamily: "InterSemiBold",
    textTransform: "capitalize",
    textAlign: "center",
    fontSize: 13,
  },
  priceText: {
    color: Theme.lightText,
    fontFamily: "InterBold",
    fontSize: 17,
    mt: 0.7,
  },
  nameText: {
    color: Theme.lightText,
    fontFamily: "InterBold",
    textTransform: "capitalize",
    fontSize: 17,
  },
  addressView: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  addressText: {
    color: Theme.lightText,
    fontFamily: "InterMedium",
    fontSize: 12,
  },
  distanceText: {
    color: Theme.lightText,
    fontFamily: "InterBold",
    textAlign: "right",
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  phView: {
    backgroundColor: Theme.black,
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    px: 0.5,
    py: 0.4,
    mb: 1,
  },
  phText: {
    color: Theme.btnText,
    fontFamily: "InterMedium",
    fontSize: 15,
  },
  menuListView: {
    overflow: "auto",
  },
  bottomView: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    // backgroundColor: 'rgba(224, 224, 224, 0.4)',
    justifyContent: "center",
  },
  descView: {
    display: "flex",
    alignItems: "center",
    mb: 1,
  },
  descText: {
    color: Theme.greenBg,
    fontFamily: "InterSemiBold",
    fontSize: 12,
  },
  bottomSubView: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  btnView: {
    backgroundColor: Theme.redBg,
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    flex: 1,
    alignItems: "center",
  },
  submitBtnView: {
    backgroundColor: Theme.greenBg,
    cursor: "pointer",
    flex: 1,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    color: Theme.lightText,
    fontFamily: "InterBold",
    fontSize: 16,
    textAlign: "center",
  },
  rejectMainView: {
    height: window.innerHeight - 75,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  sureText: {
    color: Theme.cardDescText3,
    fontFamily: "InterBold",
    fontSize: 25,
  },
  sureDescText: {
    color: Theme.cardText,
    fontFamily: "InterSemiBold",
    fontSize: 15,
  },
  orderPriceView: {
    borderTop: "1px solid " + Theme.border,
    px: 3,
    py: 1.3,
  },
  orderPriceSubView: {
    display: "flex",
    flexDirection: "row",
  },
  subPriceView: {
    flex: 1,
  },
  subTotalText: {
    color: Theme.text6,
    fontFamily: "InterSemiBold",
    fontSize: 14,
  },
  subTotalVal: {
    color: Theme.cardText,
    fontFamily: "InterBold",
    fontSize: 14,
  },
  taxText: {
    color: Theme.text6,
    fontFamily: "InterSemiBold",
    fontSize: 13,
  },
  taxVal: {
    color: Theme.cardText,
    fontFamily: "InterBold",
    fontSize: 13,
  },
  totalPriceText: {
    color: Theme.cardText,
    fontFamily: "InterBold",
    fontSize: 19,
  },
  totalPrice: {
    color: Theme.cardText,
    fontFamily: "InterBold",
    fontSize: 19,
  },
});
