import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import { THEME_MODE } from "../constants/Theme";

import styles from "./DiningCourseList.module.css";

import {
  DINING_DETAIL_BTN_HEIGHT,
  DINING_DETAIL_CONTENT_HEIGHT,
  IS_LARGE_SCREEN,
} from "../constants";

let RANDOM_COLOR = [
  "#6D5C02",
  "#556C79",
  "#552A17",
  "#6A6152",
  "#662C52",
  "#342F2C",
  "#698265",
  "#2b716b",
];

let CONTENT_HEIGHT = DINING_DETAIL_CONTENT_HEIGHT - DINING_DETAIL_BTN_HEIGHT;

const DiningCourseList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const [courseListWidth, setCourseListWidth] = useState(0);

  useEffect(() => {
    if (document.getElementById("courseListWidth")) {
      setCourseListWidth(
        document.getElementById("courseListWidth").clientWidth
      );
    }
  }, [props.drawerOpen, props.courseList.length]);

  const splitArray = () => {
    let originalArr = [...props.courseList];
    let catCount = 1;
    let splittedArray = [];

    while (originalArr.length > 0) {
      if (props.courseList.length <= catCount) {
        splittedArray.push(originalArr.splice(0, 1));
      } else {
        splittedArray.push(originalArr.splice(0, 2));
      }
    }
    return splittedArray;
  };

  let j = -1;
  return (
    <Box
      className={styles.courseListView}
      style={{
        maxHeight: CONTENT_HEIGHT - props.catBottomHeight,
        overflowX: "hidden",
        overflowY: "auto",
      }}
      id={"courseListWidth"}
    >
      {splitArray().map((course, i) => {
        let fntSize = IS_LARGE_SCREEN ? 24 : 16;
        let fntCnd = IS_LARGE_SCREEN ? 4.5 : 7;

        let coursefs =
          courseListWidth / fntCnd > fntSize
            ? fntSize
            : courseListWidth / fntCnd;

        return (
          <Box className={styles.courseView} key={i}>
            {course.map((x, i2) => {
              let selected = false;
              if (props.selectedCourse?._id === x._id) {
                selected = true;
              }
              j++;
              if (j == RANDOM_COLOR.length) {
                j = 0;
              }
              let bg_color = RANDOM_COLOR[j];
              return (
                <Box
                  key={"i" + i + "" + i2}
                  p={1}
                  onClick={() => props.setSelectedCourse(x)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: courseListWidth / 2,
                    // height: IS_LARGE_SCREEN ? 120 : 80,
                    height: courseListWidth / 2,
                    backgroundColor: bg_color,
                    border: `4px solid ${
                      selected ? theme_mode.theme : bg_color
                    }`,
                  }}
                >
                  <Typography
                    className={styles.menuText}
                    style={{
                      color: theme_mode.lightText,
                      fontSize: coursefs,
                    }}
                  >
                    {x.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default DiningCourseList;
