import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dropzone from 'react-dropzone'

import './../assets/css/custom.css';

import {THEME_COLOR3} from "../constants";
import {THEME_MODE} from "../constants/Theme";

import {ReactComponent as UploadIcon} from "../assets/images/upload.svg";
import {ReactComponent as CloseIcon} from "../assets/images/close.svg";

const UploadFile = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const uploadFile = acceptedFiles => {
    acceptedFiles.map(file => {
      let fileSize = file.size;
      let sizeMb = (fileSize / (1024 * 1024)).toFixed(3);
      let image = URL.createObjectURL(file);

      let new_id = new Date();
      let data = {
        id: new_id,
        name: file.name,
        displayName: file.name,
        size: sizeMb,
        image: image,
        upload: false
      };
      props.uploadFile(data, file);
    });
  };

  const uploadError = (err) => {
    console.log('err', err)
    if (err.length) {
      if (err[0]['errors'].length)
        if (err[0]['errors'][0]['code'] == 'too-many-files') {
          if (props.maxFiles == 1) {
            props.uploadError('Only 1 image is allowed to upload');
          } else {
            props.uploadError(`Only ${props.maxFiles} images are allowed to upload at a time`);
          }
        } else {
          props.uploadError(err[0]['errors'][0]['message']);
        }
    }
  };

  return (
    <Dropzone
      maxFiles={props.maxFiles}
      onDropRejected={err => uploadError(err)}
      accept={{'image/*': ['.jpeg', '.jpg', '.png']}}
      onDrop={uploadFile}>
      {({getRootProps, getInputProps}) => (
        <Grid item
              className={props.imageList.length == 0 ? !props.imageLoading ? "cursor-pointer" : "" : ""}
          // xs={props.page == 'AddSettingItem' || props.page == 'AddSettingCategory' ? (props.imageLength > 0 ? 8 : 12) : 12}
              xs={12}
              container direction="column"
              {...(props.imageList.length == 0 ? !props.imageLoading ? getRootProps() : null : null)}
              sx={[{border: '1.5px solid ' + theme_mode.uploadImgBorder}, {py: props.imageList.length ? 0 : 1}, styles.imageUploadGrid]}>
          {props.imageList.length ?
            <>
              <img src={props.imageList[0]['image']} style={{height: '100%', width: '100%', borderRadius: 10, py: 1}}/>
              {props.imageList[0].upload ?
                props.imageLoading ? null :
                  <Grid style={styles.closeIcon} onClick={() => props.onRemoveImage(props.imageList[0])}>
                    <CloseIcon width={15} height={15}/>
                  </Grid>
                : <Grid style={styles.uploading}>
                  <Box style={styles.uploadingText}>Uploading...</Box>
                </Grid>}
            </> :
            <>
              {!props.imageLoading ? <input {...getInputProps()} /> : null}
              <UploadIcon height={50} width={50} style={{marginTop: 10, marginBottom: 15}}/>
              <Typography className="image-drag-text"
                          sx={{color: theme_mode.uploadImgText, fontFamily: 'InterRegular'}}>
                {props.uploadText}
              </Typography>
              {(props.page == 'AddSettingItem' || props.page == 'AddMenuItem') ?
                <Typography className="image-upload-text"
                            sx={{color: theme_mode.uploadImgText, fontFamily: 'InterRegular'}}>OR</Typography>
                : null}
              <label htmlFor="contained-button-file">
                <Grid className="image-browse-grid" sx={{border: '2px solid ' + theme_mode.uploadImgText}}>
                  <Typography
                    className="image-browse-text"
                    sx={{
                      color: theme_mode.titleText,
                      fontFamily: 'InterBold'
                    }}>{props.imageLoading ? 'Please Wait' : 'Browse'}</Typography>
                </Grid>
              </label>
            </>}
        </Grid>
      )}
    </Dropzone>
  );
};

export default UploadFile;


const styles1 = Theme => ({
  imageUploadGrid: {
    position: 'relative',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'dashed !important',
    borderRadius: '10px',
    height: '240px',
  },
  closeIcon: {
    backgroundColor: Theme.redBg,
    borderRadius: 8,
    width: 18,
    height: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'absolute',
    top: -4,
    right: -4,
  },
  uploading: {
    backgroundColor: 'rgba(0,0,0, 0.3)',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    borderRadius: '10px',
  },
  uploadingText: {
    color: Theme.lightText,
    fontSize: 15,
    fontFamily: 'InterMedium',
  },
});

