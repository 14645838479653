import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { ReactComponent as UpIcon } from "../assets/images/up_white_arrow.svg";
import { ReactComponent as DownIcon } from "../assets/images/down_white_arrow.svg";

import styles from "./ModifierMenuItems.module.css";

const ModifierMenuItems = (props) => {
  const { menuList, selectedItems, selectedModifier, menuCategoryList } = props;

  const [selectedCatId, setSelectedCatId] = useState("");

  useEffect(() => {
    setSelectedCatId("");
  }, [selectedModifier?._id]);

  const handlerOnSelectCat = (cat) => {
    setSelectedCatId(selectedCatId === cat?._id ? "" : cat?._id);
  };

  const handlerOnSelectMenu = (menu) => {
    props.onSelectMenu(menu);
  };

  const MenuListView = ({ menu }) => {
    const checked = selectedItems?.includes(menu?._id);

    return (
      <FormControlLabel
        className={`${styles["checkbox-main-view"]}`}
        control={
          <Box className={`${styles["checkbox-view"]}`}>
            <Checkbox
              defaultChecked={checked}
              onChange={() => handlerOnSelectMenu(menu)}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 18 },
                color: "#FFF",
                "&.Mui-checked": {
                  color: "#FFF",
                },
              }}
            />
            <Typography className={`${styles["menu-text"]}`}>
              {menu.name}
            </Typography>
          </Box>
        }
        label={""}
      />
    );
  };

  return (
    <Box className={`${styles["main-view"]}`}>
      {[
        ...menuList
          .filter((x) => selectedItems?.includes(x?._id))
          .sort((a, b) => a.name.localeCompare(b.name)),
      ].map((menu, index) => {
        return <MenuListView key={index} menu={menu} />;
      })}
      {menuCategoryList.map((cat, index) => {
        const catList = cat?.menuList
          .filter((x) => !selectedItems?.includes(x?._id))
          .sort((a, b) => a.name.localeCompare(b.name));
        if (catList.length === 0) {
          return null;
        }
        const expanded = selectedCatId === cat?._id;
        return (
          <Box key={index}>
            <Box
              className={`${styles["cat-name-view"]}`}
              onClick={() => handlerOnSelectCat(cat)}
            >
              <Typography className={`${styles["cat-name-text"]}`}>
                {cat?.name}
              </Typography>

              {expanded ? (
                <DownIcon width={25} height={25} />
              ) : (
                <UpIcon width={25} height={25} />
              )}
            </Box>
            <Box>
              {expanded
                ? catList.map((menu, index1) => {
                    return <MenuListView key={index1} menu={menu} />;
                  })
                : null}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ModifierMenuItems;
