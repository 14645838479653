import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { connect } from "react-redux";
import {
  updateDiningTableOrder,
  updateTableOrderBillingList,
  updateTableOrderList,
  updateRestaurantCustomerList,
  updateDrawerOpen,
} from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import DiningDetailHeaderTab from "../../components/DiningDetailHeaderTab";
import DiningDetailCatMenuList from "../../components/DiningDetailCatMenuList";
import DiningDetailCart from "../../components/DiningDetailCart";
import DiningDetailTable from "../../components/DiningDetailTable";
import DiningDetailCovers from "../../components/DiningDetailCovers";
import DiningDetailAssign from "../../components/DiningDetailAssign";
import DiningDetailCustomer from "../../components/DiningDetailCustomer";
import DiningDetailModifiers from "../../components/DiningDetailModifiers";
import DiningNavbar from "../../components/DiningNavbar";
import DiningTopBtn from "../../components/DiningTopBtn";
import AlertMsg from "../../components/AlertMsg";
import ConfirmAlert from "../../components/ConfirmAlert";

import { THEME_MODE } from "../../constants/Theme";
import {
  DINEIN,
  DINING_DETAIL_BTN_HEIGHT,
  DINING_DETAIL_CONTENT_HEIGHT,
  RESTAURANT_ADMIN_ROLE_ID,
  RESTAURANT_USER_ROLE_ID,
} from "../../constants";

import styles from "./DiningDetails.module.css";
import {
  getOrdersTotalPrice,
  onSetCartModifierData,
} from "../../constants/CommonFunc";

let CONTENT_HEIGHT = DINING_DETAIL_CONTENT_HEIGHT;

const DiningDetail = (props) => {
  let MISCELLANEOUS_COURSE = {
    _idRestaurantCategory: [],
    _id: null,
    name: "Miscellaneous",
    _idRestaurant: props.restaurantId,
  };

  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [tableId, setTableId] = useState(location.pathname.split("/").pop());
  const [orderData, setOrderData] = useState(null);
  const [orderDataLoading, setOrderDataLoading] = useState(true);
  const [currencyVal, setCurrencyVal] = useState(
    props.restaurantDetail?.currecnyIcon
      ? props.restaurantDetail?.currecnyIcon
      : ""
  );
  const [categoryMenuList, setCategoryMenuList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [leftViewWidth, setLeftViewWidth] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedTab, setSelectedTab] = useState("");
  const [tableList, setTableList] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [joinedTable, setJoinedTable] = useState([]);
  const [coversVal, setCoversVal] = useState(1);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [assignedCustomer, setAssignedCustomer] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [customerPageNum, setCustomerPageNum] = useState(1);
  const [customerTotalPage, setCustomerTotalPage] = useState(0);
  const [searchCustomer, setSearchCustomer] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [showMenuImg, setShowMenuImg] = useState(true);
  const [billingUpdate, setBillingUpdate] = useState(false);
  const [showSendOrderBtn, setShowSendOrderBtn] = useState(true);
  const [confirmAlertVisible, setConfirmAlertVisible] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    if (props.drawerOpen) {
      props.updateDrawerOpen(false);
    }
  }, []);

  useEffect(() => {
    if (orderData == null && !orderDataLoading) {
      let orders = [...props.diningDetailTableOrders];
      onUpdateDiningTableOrders(orders, selectedTable);
    }
  }, [cartItems, assignedCustomer, joinedTable, coversVal]);

  useEffect(() => {
    let list = [];
    props.floorTableList.map((x) => {
      let index = x.tableDetails.findIndex((y) => y._id == tableId);
      if (index >= 0) {
        setSelectedTable(x.tableDetails[index]);
      }
      list = [...list, ...x.tableDetails];
    });

    list = list.sort((a, b) => Number(a.name) - Number(b.name));
    setTableList(list);
  }, [props.floorTableList]);

  useEffect(() => {
    if (
      customerList.length > 0 &&
      assignedCustomer == null &&
      orderData != null
    ) {
      let findIndex = customerList.findIndex(
        (x) => x._id == orderData?.userDetails?._idCustomer
      );
      if (findIndex >= 0) {
        setAssignedCustomer(customerList[findIndex]);
      }
    } else if (
      customerList.length > 0 &&
      assignedCustomer == null &&
      orderData == null
    ) {
      let orders = [...props.diningDetailTableOrders];
      let index = orders.findIndex((x) =>
        x._idRestaurantTable.includes(
          selectedTable._id ? selectedTable?._id : tableId
        )
      );
      if (index >= 0) {
        let findIndex = customerList.findIndex(
          (x) => x._id == orders[index]["customer"]
        );
        if (findIndex >= 0) {
          setAssignedCustomer(customerList[findIndex]);
        }
      }
    }
  }, [customerList, orderData]);

  useEffect(() => {
    if (tableList.length > 0 && menuList.length > 0 && courseList.length > 0) {
      getOrdersWithTableId(tableList);
    }
  }, [tableList, courseList, menuList]);

  useEffect(() => {
    if (document.getElementById("leftViewId")) {
      setLeftViewWidth(document.getElementById("leftViewId").offsetWidth);
    }
  }, [props.drawerOpen]);

  useEffect(() => {
    if (props.restaurantCategoryList.length) {
      if (props.restaurantMenuList.length) {
        updateCategoryMenuListData(
          JSON.parse(JSON.stringify(props.restaurantMenuList)),
          JSON.parse(JSON.stringify(props.restaurantCategoryList))
        );
      } else {
        updateCategoryMenuListData(
          [],
          JSON.parse(JSON.stringify(props.restaurantCategoryList))
        );
      }
    }
  }, [props.restaurantCategoryList]);

  useEffect(() => {
    setTotalPrice(getOrdersTotalPrice(cartItems, [])?.toFixed(2) ?? 0);
  }, [cartItems]);

  useEffect(() => {
    if (props.restaurantCustomerList.length) {
      setCustomerList(props.restaurantCustomerList);
    } else {
      setCustomerList([]);
    }
  }, [props.restaurantCustomerList]);

  const getOrdersWithTableId = async (table_list) => {
    setIsLoading(true);
    let rows = props.tableOrderList.filter((x) =>
      (x._idRestaurantTable?.length ? x._idRestaurantTable : []).includes(
        tableId
      )
    );

    if (rows.length == 0) {
      let filter =
        "pageSize=1&pageNum=1&filter_orderStatus=" +
        '["placed_order", "preparing", "pending_payment"]';
      filter = filter + "&filter_tableId=" + '["' + tableId + '"]';
      let response = await Api.getOrders(props.restaurantId, filter);
      if (response.success) {
        rows = response.data.rows;
        if (rows.length > 0) {
          props.updateTableOrderList([...props.tableOrderList, ...rows]);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    }

    let cart_orders = [];
    if (rows.length > 0) {
      setShowSendOrderBtn(false);
      let orders = [...props.diningDetailTableOrders];
      let orderIndex = orders.findIndex((x) =>
        x._idRestaurantTable.includes(tableId)
      );
      if (orderIndex >= 0) {
        let order_data = orders[orderIndex];
        if (order_data._idRestaurantTable.length == 1) {
          orders = orders.filter(
            (x) => !x._idRestaurantTable.includes(tableId)
          );
        } else if (order_data._idRestaurantTable.length > 1) {
          orders[orderIndex]["_idRestaurantTable"] = orders[orderIndex][
            "_idRestaurantTable"
          ].filter((y) => y != tableId);
        }
        props.updateDiningTableOrder(orders);
      }

      let data = rows[0];
      setOrderData(data);
      setCoversVal(data?.userDetails?.covers ? data.userDetails.covers : 0);
      let join_table = table_list.filter((x) =>
        (data._idRestaurantTable?.length
          ? data._idRestaurantTable
          : []
        ).includes(x._id)
      );

      let cart_list = [];
      data.cart.map((y) => {
        let findIndex = menuList.findIndex((z) => z._id == y._idMenuItem);
        if (findIndex >= 0) {
          cart_list.push({
            ...y,
            ...menuList[findIndex],
            price: y.price,
            name: y?.menuItemName
              ? y.menuItemName
              : menuList[findIndex]["name"],
            _idRestaurantCourse: y?._idRestaurantCourse
              ? y._idRestaurantCourse
              : null,
            restaurantCourseName: y.restaurantCourseName,
            cartModifier: onSetCartModifierData(y.modifiers ?? [], menuList),
          });
        }
      });
      cart_orders = cart_list;
      setJoinedTable(join_table.filter((x) => x._id != tableId));
    } else {
      let orders = [...props.diningDetailTableOrders];
      let index = orders.findIndex((x) =>
        x._idRestaurantTable.includes(tableId)
      );
      if (index >= 0) {
        let data = orders[index];
        let join_table = table_list.filter((x) =>
          (data._idRestaurantTable?.length
            ? data._idRestaurantTable
            : []
          ).includes(x._id)
        );
        cart_orders = data["orders"];
        setCoversVal(data?.covers ? data?.covers : 0);
        setJoinedTable(join_table.filter((x) => x._id != tableId));
      }
    }

    let course_val = null;
    let addCourseList = cart_orders
      .map((x) => {
        return {
          _id: x._idRestaurantCourse,
        };
      })
      .filter((x, i, a) => a.map((y) => y._id).indexOf(x._id) == i);
    addCourseList = JSON.parse(JSON.stringify(addCourseList)).reverse();

    if (addCourseList.length) {
      let cList = courseList.filter((z) => z._id == addCourseList[0]?._id);
      if (cList.length) {
        course_val = cList[0];
      } else {
        if (addCourseList[0]?._id == "" || addCourseList[0]?._id == null) {
          course_val = MISCELLANEOUS_COURSE[0];
          setCourseList([...courseList, MISCELLANEOUS_COURSE]);
        }
      }
    }
    if (course_val) {
      setSelectedCourse(course_val);
    } else {
      setSelectedCourse(courseList[0]);
    }
    setCartItems(cart_orders);
    setIsLoading(false);
    setOrderDataLoading(false);
  };

  const updateCategoryMenuListData = (menu_lists, category_list) => {
    let menu_list = [];

    menu_lists.map((menu) => {
      if (menu["modifiers"]?.length > 0) {
        let modifier_list = [];
        menu["modifiers"].map((mod) => {
          let item_list = [];
          mod?.items?.map((item1) => {
            let menu_item = menu_lists.find((x) => x?._id == item1);
            if (menu_item) {
              item_list.push(menu_item);
            }
          });
          modifier_list.push({
            ...mod,
            items: JSON.parse(JSON.stringify(item_list)),
          });
        });
        menu["modifiers"] = modifier_list;
        menu_list.push(menu);
      } else {
        menu_list.push(menu);
      }
    });

    setMenuList(menu_list);

    if (menu_lists.length && (currencyVal == "" || currencyVal == null)) {
      setCurrencyVal(menu_lists[0]["price"]?.currency);
    }

    let list = [];
    category_list.forEach((x) => {
      let cat_menu = {
        ...x,
        menu: menu_lists.filter((y) => y._idCategory == x._id),
      };
      if (cat_menu.menu.length) {
        list.push(cat_menu);
      }
    });
    setCategoryMenuList(list);
    let courses = MISCELLANEOUS_COURSE;
    let miscellaneous_cat = list
      .filter(
        (x) =>
          !props.restaurantCourseList.some((t) =>
            t._idRestaurantCategory.includes(x._id)
          )
      )
      .map((y) => y._id);
    let course_list = [...props.restaurantCourseList];
    if (miscellaneous_cat.length) {
      course_list = [
        ...props.restaurantCourseList,
        { ...courses, _idRestaurantCategory: miscellaneous_cat },
      ];
    }
    setCourseList(JSON.parse(JSON.stringify(course_list)));
    // if (course_list.length) {
    //   setSelectedCourse(course_list[0]);
    // }
    getCustomerList();
  };

  const getCustomerList = () => {
    setCustomerList(
      props.restaurantCustomerList.filter(
        (x) =>
          x.name.toLowerCase().match(searchCustomer.toLowerCase()) ||
          x.email.toLowerCase().match(searchCustomer.toLowerCase()) ||
          x.phoneNumber.toLowerCase().match(searchCustomer.toLowerCase())
      )
    );
    setIsLoading(false);
  };

  const onSearchCustomer = (val) => {
    setSearchCustomer(val);
  };

  const onSelectTab = (tab) => {
    setSelectedMenu(null);
    if (selectedTab == tab) {
      setSelectedTab("");
    } else {
      setSelectedTab(tab);
      if (tab == "assign") {
        if (assignedCustomer != null) {
          setSelectedCustomer(assignedCustomer);
        }
      }
    }
  };

  const onSelectTable = (tab, tbl) => {
    if (tab == "join") {
      let filter_order = [...props.diningDetailTableOrders].filter(
        (x) =>
          x._idRestaurantTable.some((t) => tbl.map((x) => x._id).includes(t)) &&
          !x._idRestaurantTable.includes(
            selectedTable._id ? selectedTable?._id : tableId
          )
      );

      if (filter_order.length) {
        setMsgAlert({
          open: true,
          message: "Orders are already added to some of these table",
          msgType: "error",
        });
        return;
      }
      setJoinedTable(tbl);

      let table_ids = [selectedTable?._id, ...tbl.map((x) => x._id)];
      let data1 = {
        _idRestaurantTable: table_ids,
      };
      updateOrder(data1, table_ids);
    } else {
      let orders = [...props.diningDetailTableOrders];
      let filter_order = orders.filter((x) =>
        x._idRestaurantTable.includes(tbl._id)
      );

      if (filter_order.length) {
        setMsgAlert({
          open: true,
          message: "Orders are already added to this table",
          msgType: "error",
        });
        return;
      }

      setSelectedTable(tbl);
      let table_ids = [tbl?._id, ...joinedTable.map((x) => x._id)];
      let data1 = {
        _idRestaurantTable: table_ids,
      };
      updateOrder(data1, table_ids);
      if (tbl?._id != selectedTable?._id) {
        orders = [
          ...orders.filter(
            (x) =>
              !x._idRestaurantTable.includes(
                selectedTable._id ? selectedTable?._id : tableId
              )
          ),
        ];
      }
      onUpdateDiningTableOrders(orders, tbl);
    }
    setSelectedTab("");
  };

  const onCancelTable = () => {
    setSelectedTab("");
  };

  const onUpdateDiningTableOrders = (orders, tbl) => {
    let index = orders.findIndex((x) =>
      x._idRestaurantTable.includes(tbl._id ? tbl?._id : tableId)
    );
    if (index >= 0) {
      let data = {
        orders: cartItems,
        covers: coversVal,
        customer: assignedCustomer?._id ? assignedCustomer._id : "",
      };
      let table = [];
      if (tbl?._id ? tbl?._id : tableId) {
        table.push(tbl?._id ? tbl?._id : tableId);
      }
      if (joinedTable.length) {
        table = [...table, ...joinedTable.map((x) => x._id)];
      }

      if (cartItems.length) {
        orders[index] = {
          ...data,
          _idRestaurantTable: table,
        };
      } else {
        orders = orders.filter(
          (x) => !x._idRestaurantTable.includes(tbl._id ? tbl?._id : tableId)
        );
      }
    } else {
      let table = [];
      if (tbl?._id ? tbl?._id : tableId) {
        table.push(tbl?._id ? tbl?._id : tableId);
      }
      if (joinedTable.length) {
        table = [...table, ...joinedTable.map((x) => x._id)];
      }

      let data = {
        orders: cartItems,
        customer: assignedCustomer?._id ? assignedCustomer._id : "",
        _idRestaurantTable: table,
        covers: coversVal,
      };
      if (cartItems.length) {
        orders.push(data);
      }
    }
    props.updateDiningTableOrder(orders);
  };

  const onSelectCovers = (cvr) => {
    setCoversVal(cvr);
    setSelectedTab("");
  };

  const onCancelCover = () => {
    setSelectedTab("");
  };

  const addMenuToCart = (item) => {
    setSelectedMenu(null);
    setSelectedTab("");
    onAddSubMenuItem("+", item, true);
    let cart_list = [...cartItems];
    let cartIndex = cart_list.findIndex(
      (x) => x._id == item._id && x._idCategory == item._idCategory
    );
    if (cartIndex >= 0) {
      let sectionRight = document.querySelector(
        "#cartItemId" +
          cart_list[cartIndex]["_idCategory"] +
          cart_list[cartIndex]["_id"]
      );
      if (sectionRight) {
        sectionRight.scrollIntoView({
          behavior: "auto",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  const onAddSubMenuItem = (type, item, course_check) => {
    let cart_list = [...cartItems];
    let cartIndex = cart_list.findIndex(
      (x) => x._id == item._id && x._idCategory == item._idCategory
    );

    if (cartIndex >= 0) {
      let count = cart_list[cartIndex]["quantity"];
      if (type == "-") {
        count = parseInt(count) - 1;
      }
      if (type == "+") {
        count = parseInt(count) + 1;
      }

      if (
        selectedCourse?._id != cart_list[cartIndex]._idRestaurantCourse &&
        course_check
      ) {
        setMsgAlert({
          open: true,
          message: "Already added in another course",
          msgType: "error",
        });
        return;
      }

      if (count > 0) {
        cart_list[cartIndex]["quantity"] = count;
        if (cart_list[cartIndex]?.["cartModifier"]?.length) {
          if (type == "+") {
          } else {
            cart_list[cartIndex]["cartModifier"] = cart_list[cartIndex]?.[
              "cartModifier"
            ].filter((x, i) => i + 1 <= count);
          }
        }

        if (type == "+") {
          let cart_modifier = [];
          item.modifiers
            .filter((m1) => m1.required == true)
            .map((m) => {
              if (m.items.length) {
                let menu_data = menuList.find((y) => y._id == m.items[0]._id);
                if (menu_data) {
                  let data = {
                    withExtra: "with",
                    _idModifier: m._id,
                    _idMenuItem: menu_data._id,
                    price: {
                      value: menu_data?.price?.value,
                      currency: props.restaurantDetail?.currecnyIcon
                        ? currencyVal
                        : menu_data?.price?.currency,
                    },
                    menuData: menu_data,
                  };
                  cart_modifier.push(data);
                }
              }
            });

          if (cart_list[cartIndex]?.["cartModifier"]?.length) {
            cart_list[cartIndex]["cartModifier"] = [
              ...cart_list[cartIndex]["cartModifier"],
              cart_modifier,
            ];
          } else {
            cart_list[cartIndex]["cartModifier"] = [cart_modifier];
          }
        }

        setCartItems(cart_list);
      } else if (count == 0) {
        onDeleteCartItem(item);
      }
    } else {
      if (selectedCourse == null) {
        setMsgAlert({
          open: true,
          message: "Select a course",
          msgType: "error",
        });
        return;
      }

      let cart_modifier = [];
      item.modifiers
        .filter((m1) => m1.required == true || m1?.defaultSelection)
        .map((m) => {
          if (m.items.length) {
            let defaultValue = m?.defaultSelection
              ? m?.defaultSelection
              : m.items[0]._id;
            let menu_data = menuList.find((y) => y._id == defaultValue);
            if (menu_data) {
              let data = {
                withExtra: "with",
                _idModifier: m._id,
                _idMenuItem: menu_data._id,
                price: {
                  value: menu_data?.price?.value,
                  currency: props.restaurantDetail?.currecnyIcon
                    ? currencyVal
                    : menu_data?.price?.currency,
                },
                menuData: menu_data,
              };
              if (cart_modifier?.[0]?.length) {
                cart_modifier[0].push(data);
              } else {
                cart_modifier.push([data]);
              }
            }
          }
        });

      let cart_data = {
        ...item,
        price: {
          value: item.price.value,
          currency: props.restaurantDetail?.currecnyIcon
            ? currencyVal
            : item.price.currency,
        },
        quantity: 1,
        _idRestaurantCourse: selectedCourse._id,
        cartModifier: cart_modifier,
      };

      if (item.modifiers.length) {
        setSelectedMenu(cart_data);
      }
      cart_list.push(cart_data);
      setCartItems(cart_list);
    }
  };

  const onDeleteCartItem = (item) => {
    if (item?._id == selectedMenu?._id) {
      setSelectedMenu(null);
    }
    let cart_list = [...cartItems];
    let filter = cart_list.filter(
      (x) => !(x._id == item._id && x._idCategory == item._idCategory)
    );
    setCartItems(filter);
  };

  const onSelectCartMenu = (item) => {
    setSelectedMenu(item);
  };

  const onSelectCustomer = (data) => {
    setSelectedCustomer(data);
  };

  const addCustomerBtnClick = () => {
    setSelectedCustomer(null);
  };

  const onCustomerAssign = (data) => {
    if (selectedCustomer == null) {
      setSelectedCustomer(data);
    } else {
      setSelectedCustomer(null);
      if (data?._id == assignedCustomer?._id) {
        setAssignedCustomer(null);
      } else {
        setAssignedCustomer(data);
      }
      setSelectedTab("");
    }
  };

  const onSetCourse = (val) => {
    setSelectedCourse(val);
    setSelectedMenu(null);
    setSelectedTab("");
  };

  const onSetCategory = () => {
    setSelectedMenu(null);
    setSelectedTab("");
  };

  const onCourseAwayClick = (course) => {
    if (orderData == null) {
      return;
    }
    let course_id = course._id;
    let data1 = {
      courseAway: course_id,
    };
    let datas = JSON.stringify(data1);
    setIsLoading(true);
    Api.updateOrder(datas, orderData._id).then((response) => {
      if (response.success) {
        let list1 = [...props.tableOrderList];
        let filter_index = list1.findIndex((x) => x._id == orderData._id);
        if (filter_index >= 0) {
          list1[filter_index] = { ...list1[filter_index], ...data1 };
          props.updateTableOrderList(list1);
        }
        setOrderData({ ...orderData, ...data1 });
        if (course.name) {
          setMsgAlert({
            open: true,
            message: course.name + " Away",
            msgType: "success",
          });
        }
      } else {
        setMsgAlert({
          open: true,
          message: response.msg,
          msgType: "error",
        });
      }
      setIsLoading(false);
    });
  };

  const onUpdateModifierMenu = (item) => {
    let cart_list = [...cartItems];
    let cartIndex = cart_list.findIndex(
      (x) => x._id == item._id && x._idCategory == item._idCategory
    );
    if (cartIndex >= 0) {
      cart_list[cartIndex] = item;
    } else {
      cart_list.push(item);
    }
    setCartItems(cart_list);
  };

  const onApplyModifierMenu = (item) => {
    let cart_list = [...cartItems];
    let cartIndex = cart_list.findIndex(
      (x) => x._id == item._id && x._idCategory == item._idCategory
    );
    if (cartIndex >= 0) {
      cart_list[cartIndex] = item;
    } else {
      cart_list.push(item);
    }
    setCartItems(cart_list);
    setSelectedMenu(null);
  };

  const equalsCheck = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  useEffect(() => {
    if (orderData) {
      let cart_items = [];
      let cart_items2 = [];
      cartItems.map((x) => {
        let modifiersList = [];
        x?.cartModifier?.map((m1) => {
          let modifiers = [];
          m1.map((m) => {
            modifiers.push({
              _idModifier: m._idModifier,
              _idMenuItem: m._idMenuItem,
              withExtra: m.withExtra,
              price: {
                value: m?.menuData?.price?.value
                  ? m?.menuData?.price?.value
                  : 0,
                currency: x.price.currency,
              },
            });
          });
          if (modifiers.length) {
            modifiersList.push(JSON.stringify(modifiers));
          }
        });

        let courseName = null;
        if (x._idRestaurantCourse) {
          let filterIndex = courseList.findIndex(
            (z) => z._id == x?._idRestaurantCourse
          );
          if (filterIndex >= 0) {
            courseName = courseList[filterIndex]["name"];
          }
        }

        cart_items.push({
          _idMenuItem: x._id,
          menuItemName: x.name,
          _idRestaurantCourse: x._idRestaurantCourse,
          restaurantCourseName: courseName,
          price: {
            value: x.price.value,
            currency: x.price.currency,
          },
          quantity: x.quantity,
          modifiers: modifiersList,
          notes: x?.notes ? x.notes : "",
        });
      });

      orderData.cart.map((x) => {
        cart_items2.push({
          _idMenuItem: x._idMenuItem,
          menuItemName: x.menuItemName,
          _idRestaurantCourse: x._idRestaurantCourse,
          restaurantCourseName: x.restaurantCourseName
            ? x.restaurantCourseName
            : null,
          price: {
            value: x.price.value,
            currency: x.price.currency,
          },
          quantity: x.quantity,
          modifiers: x.modifiers,
          notes: x?.notes ? x.notes : "",
        });
      });

      let table_ids = [selectedTable?._id, ...joinedTable.map((x) => x._id)];

      let customer1 = assignedCustomer?._id ? assignedCustomer?._id : "";
      let customer2 = orderData?.userDetails?._idCustomer
        ? orderData?.userDetails?._idCustomer
        : "";
      if (
        equalsCheck(cart_items2, cart_items) &&
        customer1 == customer2 &&
        coversVal == (orderData?.userDetails?.covers ?? 0) &&
        equalsCheck(table_ids.sort(), orderData?._idRestaurantTable.sort())
      ) {
        setShowSendOrderBtn(false);
      } else {
        setShowSendOrderBtn(true);
      }
      if (
        equalsCheck(cart_items2, cart_items) &&
        coversVal == orderData?.userDetails?.covers
      ) {
        setBillingUpdate(false);
      } else {
        setBillingUpdate(true);
      }
    } else {
      setShowSendOrderBtn(true);
    }
  }, [
    cartItems,
    selectedTable,
    joinedTable,
    assignedCustomer,
    coversVal,
    orderData,
  ]);

  const onPayment = () => {
    if (selectedTable == null) {
      setMsgAlert({ open: true, message: "Select Table", msgType: "error" });
      return;
    }
    if (cartItems.length == 0) {
      setMsgAlert({
        open: true,
        message: "Add Items to cart",
        msgType: "error",
      });
      return;
    }

    if (orderData == null) {
      setMsgAlert({
        open: true,
        message: "Send Order",
        msgType: "error",
      });
      return;
    }

    navigate(
      "/dining/OrderSummary/" +
        (selectedTable._id ? selectedTable?._id : tableId)
    );
    // if (showCheckoutBtn ? true : !showSendOrderBtn) {
    //   navigate(
    //     "/dining/OrderSummary/" +
    //       (selectedTable._id ? selectedTable?._id : tableId)
    //   );
    // } else {
    //   setMsgAlert({
    //     open: true,
    //     message: "Send Order",
    //     msgType: "error",
    //   });
    // }
  };

  const onCheckOut = () => {
    if (selectedTable == null) {
      setMsgAlert({ open: true, message: "Select Table", msgType: "error" });
      return;
    }
    if (cartItems.length == 0) {
      setMsgAlert({
        open: true,
        message: "Add Items to cart",
        msgType: "error",
      });
      return;
    }

    onCreateOrder();
  };

  const updateOrder = (data1, table_ids) => {
    if (orderData == null) {
      return;
    }
    let datas = JSON.stringify(data1);
    Api.updateOrder(datas, orderData._id).then((response) => {
      if (response.success) {
        let orders = [...props.diningDetailTableOrders];
        orders = orders.filter(
          (x) =>
            !(x._idRestaurantTable?.length ? x._idRestaurantTable : []).some(
              (t) => table_ids.includes(t)
            )
        );

        props.updateDiningTableOrder(orders);

        let list1 = [...props.tableOrderList];
        let filter_index = list1.findIndex((x) => x._id == orderData._id);
        if (filter_index >= 0) {
          list1[filter_index] = { ...list1[filter_index], ...data1 };
          props.updateTableOrderList(list1);
        }
        setOrderData({ ...orderData, ...data1 });
      } else {
        setMsgAlert({
          open: true,
          message: response.msg,
          msgType: "error",
        });
      }
      setIsLoading(false);
    });
  };

  const onCreateOrder = () => {
    setIsLoading(true);
    let cart_items = [];
    cartItems.map((x) => {
      let modifiersList = [];
      x?.cartModifier?.map((m1) => {
        let modifiers = [];
        m1.map((m) => {
          modifiers.push({
            _idModifier: m._idModifier,
            _idMenuItem: m._idMenuItem,
            withExtra: m.withExtra,
            price: {
              value: m?.menuData?.price?.value ? m?.menuData?.price?.value : 0,
              currency: x.price.currency,
            },
          });
        });
        if (modifiers.length) {
          modifiersList.push(JSON.stringify(modifiers));
        }
      });

      let courseName = null;
      if (x._idRestaurantCourse) {
        let filterIndex = courseList.findIndex(
          (z) => z._id == x?._idRestaurantCourse
        );
        if (filterIndex >= 0) {
          courseName = courseList[filterIndex]["name"];
        }
      }

      cart_items.push({
        _idMenuItem: x._id,
        menuItemName: x.name,
        _idCategory: x._idCategory ?? null,
        _idRestaurantCourse: x._idRestaurantCourse,
        restaurantCourseName: courseName,
        price: {
          value: x.price.value,
          currency: x.price.currency,
        },
        quantity: x.quantity,
        modifiers: modifiersList,
        notes: x?.notes ? x.notes : "",
      });
    });

    let table_ids = [selectedTable?._id, ...joinedTable.map((x) => x._id)];
    let data1 = {
      _idRestaurant: props.restaurantId,
      _idRestaurantTable: table_ids,
      orderStatus: "placed_order",
      totalAmount: Number(totalPrice),
      cart: cart_items,
      userDetails: {
        _idCustomer: assignedCustomer?._id ? assignedCustomer._id : "",
        covers: coversVal ? coversVal : 0,
      },
    };

    if (orderData == null) {
      data1 = {
        ...data1,
        orderType: DINEIN,
        restaurantUserName: props.userDetail?.name
          ? props.userDetail.name
          : null,
        _idRestaurantUser: props.pinUserId ? props.pinUserId : null,
      };
    }

    let datas = JSON.stringify(data1);
    if (orderData == null) {
      Api.createOrder(datas).then((response) => {
        if (response.success) {
          let orders = [...props.diningDetailTableOrders];
          orders = orders.filter(
            (x) =>
              !x._idRestaurantTable.includes(
                selectedTable._id ? selectedTable?._id : tableId
              )
          );
          props.updateDiningTableOrder(orders);

          let list1 = [...props.tableOrderList];
          let filter_index = list1.findIndex((x) =>
            (Array.isArray(x._idRestaurantTable) && x._idRestaurantTable?.length
              ? x._idRestaurantTable
              : []
            ).some((t) => table_ids.includes(t))
          );
          if (filter_index >= 0) {
            list1[filter_index] = response.data;
          } else {
            list1.push(response.data);
          }
          props.updateTableOrderList(list1);
          setOrderData(response.data);
          setShowSendOrderBtn(false);
          if (props.userRole == RESTAURANT_USER_ROLE_ID) {
            if (props.restaurantId) {
              window.location.href = "/logout/pin";
            } else {
              window.location.href = "/logout";
            }
            setTimeout(() => {
              setIsLoading(false);
            }, 50);
          } else {
            setIsLoading(false);
          }
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
          setIsLoading(false);
        }
      });
    } else {
      if (showSendOrderBtn) {
        Api.updateOrder(datas, orderData._id).then((response) => {
          if (response.success) {
            if (billingUpdate) {
              props.updateTableOrderBillingList(
                [...props.tableOrderBillingList].filter(
                  (a) => a.orderId != orderData._id
                )
              );
            }
            let orders = [...props.diningDetailTableOrders];
            orders = orders.filter(
              (x) =>
                !x._idRestaurantTable.includes(
                  selectedTable._id ? selectedTable?._id : tableId
                )
            );
            props.updateDiningTableOrder(orders);

            let list1 = [...props.tableOrderList];
            let filter_index = list1.findIndex((x) => x._id == orderData._id);
            if (filter_index >= 0) {
              list1[filter_index] = { ...list1[filter_index], ...data1 };
              props.updateTableOrderList(list1);
            }

            setOrderData({ ...orderData, ...data1 });
            setSelectedMenu(null);

            if (props.userRole == RESTAURANT_USER_ROLE_ID) {
              if (props.restaurantId) {
                window.location.href = "/logout/pin";
              } else {
                window.location.href = "/logout";
              }
              setTimeout(() => {
                setIsLoading(false);
              }, 100);
            } else {
              setShowSendOrderBtn(false);
              setIsLoading(false);
            }
          } else {
            setMsgAlert({
              open: true,
              message: response.msg,
              msgType: "error",
            });
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(false);
      }
    }
  };

  const onVoidTable = () => {
    if (orderData) {
      setBtnLoading(true);
      let data1 = {
        orderStatus: "cancelled",
      };
      let datas = JSON.stringify(data1);
      Api.updateOrder(datas, orderData._id).then((response) => {
        if (response.success) {
          setOrderData(null);
          props.updateTableOrderBillingList(
            [...props.tableOrderBillingList].filter(
              (a) => a.orderId != orderData._id
            )
          );

          let list1 = [...props.tableOrderList];
          props.updateTableOrderList(
            list1.filter((x) => x._id != orderData._id)
          );

          let orders = [...props.diningDetailTableOrders];
          orders = orders.filter(
            (x) =>
              !x._idRestaurantTable.includes(
                selectedTable._id ? selectedTable?._id : tableId
              )
          );
          setBtnLoading(false);
          props.updateDiningTableOrder(orders);
          setCartItems([]);
          setSelectedMenu(null);
          setSelectedTab("");
          setConfirmAlertVisible(false);
          setJoinedTable([]);
          setCoversVal(1);
          setSelectedCustomer(null);
          setAssignedCustomer(null);
          setShowSendOrderBtn(true);
        } else {
          setBtnLoading(false);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      });
    } else {
      setOrderData(null);
      setCartItems([]);
      setSelectedMenu(null);
      setSelectedTab("");
      setConfirmAlertVisible(false);
      setJoinedTable([]);
      setCoversVal(1);
      setSelectedCustomer(null);
      setAssignedCustomer(null);
      setShowSendOrderBtn(true);
      let orders = [...props.diningDetailTableOrders];
      orders = orders.filter(
        (x) =>
          !x._idRestaurantTable.includes(
            selectedTable._id ? selectedTable?._id : tableId
          )
      );
      props.updateDiningTableOrder(orders);
    }
  };

  const DiningDetailHeaderTabView = React.useMemo(() => {
    return (
      <DiningDetailHeaderTab
        themeMode={props.themeMode}
        selectedTab={selectedTab}
        table={[selectedTable?.name, ...joinedTable.map((x) => x.name)].join(
          ", "
        )}
        covers={coversVal}
        assigned={assignedCustomer}
        onSelectTab={onSelectTab}
      />
    );
  }, [
    props.themeMode,
    selectedTab,
    selectedTable,
    joinedTable,
    coversVal,
    assignedCustomer,
  ]);

  return (
    <Container
      page={"dining_detail"}
      bgColor={theme_mode.diningBg}
      p={"0"}
      showFooter={false}
      showToolbar={false}
      showNavbar={false}
      minHeight={CONTENT_HEIGHT}
    >
      {isLoading ? (
        <Loader height={{ height: CONTENT_HEIGHT }} />
      ) : (
        <Grid container item xs={12} style={{ height: CONTENT_HEIGHT }}>
          <Grid
            item
            // mt={{ xs: 1, md: 0 }}
            xs={3.5}
            md={3.5}
            id={"rightViewId"}
            style={{ backgroundColor: theme_mode.card }}
          >
            {DiningDetailHeaderTabView}
            {selectedTab == "" ? (
              <DiningDetailCart
                cartItems={cartItems}
                currencyVal={currencyVal}
                totalPrice={totalPrice}
                selectedMenu={selectedMenu}
                selectedCourse={selectedCourse}
                courseList={courseList}
                orderData={orderData}
                onAddSubMenu={onAddSubMenuItem}
                onDeleteCartItem={onDeleteCartItem}
                onSelectMenu={onSelectCartMenu}
                onCheckOut={onCheckOut}
                onPayment={onPayment}
                onSetCourse={onSetCourse}
                checkout={!showSendOrderBtn}
                themeMode={props.themeMode}
                onCourseAway={onCourseAwayClick}
              />
            ) : null}
            {selectedTab == "table" ? (
              <DiningDetailTable
                table={selectedTable}
                joinedTable={joinedTable}
                tableList={tableList}
                orderList={props.tableOrderList}
                notSendOrderList={props.diningDetailTableOrders}
                orderData={orderData}
                onSelectTable={onSelectTable}
                themeMode={props.themeMode}
                drawerOpen={props.drawerOpen}
                onCancel={onCancelTable}
              />
            ) : null}
            {selectedTab == "covers" ? (
              <DiningDetailCovers
                covers={coversVal}
                onSubmit={onSelectCovers}
                themeMode={props.themeMode}
                drawerOpen={props.drawerOpen}
                onCancel={onCancelCover}
              />
            ) : null}
            {selectedTab == "assign" ? (
              <DiningDetailAssign
                page={"DiningDetail"}
                customer={selectedCustomer}
                assigned={assignedCustomer}
                editCustomer={selectedCustomer == null}
                modalVisible={false}
                restaurantCustomerList={props.restaurantCustomerList}
                updateRestaurantCustomerList={
                  props.updateRestaurantCustomerList
                }
                restaurantId={props.restaurantId}
                onSubmit={onCustomerAssign}
                themeMode={props.themeMode}
              />
            ) : null}
          </Grid>
          <Grid container item xs={8.5} md={8.5}>
            <Grid
              item
              xs={12}
              className={styles.navbar}
              sx={{ height: DINING_DETAIL_BTN_HEIGHT }}
            >
              <Box sx={{ flex: 1, height: "100%" }}>
                <DiningTopBtn
                  restaurantId={props.restaurantId}
                  themeMode={props.themeMode}
                  selectedCourse={selectedCourse}
                  onCourseAway={onCourseAwayClick}
                  isAdmin={props.userRole == RESTAURANT_ADMIN_ROLE_ID}
                  orderData={orderData}
                  onVoidTable={() => {
                    if (orderData) {
                      if (props.userRole == RESTAURANT_ADMIN_ROLE_ID) {
                        setConfirmAlertVisible(true);
                      } else {
                        setMsgAlert({
                          open: true,
                          message: "Void available only for admin",
                          msgType: "error",
                        });
                      }
                    } else {
                      setConfirmAlertVisible(true);
                    }
                  }}
                />
              </Box>
              <Box ml={1} sx={{ height: "100%" }}>
                <DiningNavbar
                  restaurantId={props.restaurantId}
                  themeMode={props.themeMode}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              id={"leftViewId"}
              style={
                selectedMenu == null
                  ? null
                  : { backgroundColor: theme_mode.diningDetailBg }
              }
            >
              <DiningDetailCatMenuList
                cartItems={cartItems}
                categoryMenuList={categoryMenuList.filter((item1) =>
                  selectedCourse?._idRestaurantCategory?.includes(item1._id)
                )}
                selectedCourse={selectedCourse}
                setSelectedCat={onSetCategory}
                showMenuImg={showMenuImg}
                leftViewWidth={leftViewWidth}
                themeMode={props.themeMode}
                drawerOpen={props.drawerOpen}
                addMenuToCart={addMenuToCart}
                courseList={courseList}
                setSelectedCourse={onSetCourse}
              >
                {selectedMenu == null ? (
                  selectedTab == "assign" ? (
                    <DiningDetailCustomer
                      customers={customerList}
                      selected={selectedCustomer}
                      searchCustomer={searchCustomer}
                      leftViewWidth={leftViewWidth}
                      themeMode={props.themeMode}
                      pageNum={customerPageNum}
                      totalPages={customerTotalPage}
                      onSelectPage={() => null}
                      onSelect={onSelectCustomer}
                      addCustomer={addCustomerBtnClick}
                      onSearchCustomer={onSearchCustomer}
                      showHeight={true}
                    />
                  ) : null
                ) : (
                  <DiningDetailModifiers
                    menu={selectedMenu}
                    menuList={menuList}
                    currency={currencyVal}
                    themeMode={props.themeMode}
                    drawerOpen={props.drawerOpen}
                    orderData={orderData}
                    isAdmin={props.userRole == RESTAURANT_ADMIN_ROLE_ID}
                    onClose={() => setSelectedMenu(null)}
                    onUpdate={onUpdateModifierMenu}
                    onApply={onApplyModifierMenu}
                    onDeleteCartItem={onDeleteCartItem}
                  />
                )}
              </DiningDetailCatMenuList>
            </Grid>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />

      <ConfirmAlert
        confirmDelete={confirmAlertVisible}
        title={" Do you want to remove all the items?"}
        titleStyle={{ fontFamily: "InterMedium !important" }}
        firstBtn={"No"}
        secondBtn={btnLoading ? "Please Wait" : "Yes"}
        btnLoading={btnLoading}
        onCloseConfirmDelete={() => setConfirmAlertVisible(false)}
        onConfirmDelete={onVoidTable}
        themeMode={props.themeMode}
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDiningTableOrder: (data) => dispatch(updateDiningTableOrder(data)),
    updateTableOrderBillingList: (data) =>
      dispatch(updateTableOrderBillingList(data)),
    updateTableOrderList: (data) => dispatch(updateTableOrderList(data)),
    updateRestaurantCustomerList: (data) =>
      dispatch(updateRestaurantCustomerList(data)),
    updateDrawerOpen: (data) => dispatch(updateDrawerOpen(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    drawerOpen: state.userData.drawerOpen,
    floorTableList: state.userData.floorTableList,
    tableOrderList: state.userData.tableOrderList,
    diningDetailTableOrders: state.userData.diningDetailTableOrders,
    restaurantCategoryList: state.userData.restaurantCategoryList,
    restaurantMenuList: state.userData.restaurantMenuList,
    restaurantCourseList: state.userData.restaurantCourseList,
    restaurantDetail: state.userData.restaurantDetail,
    pinUserId: state.userData.pinUserId,
    userDetail: state.userData.userDetail,
    tableOrderBillingList: state.userData.tableOrderBillingList,
    restaurantCustomerList: state.userData.restaurantCustomerList,
    userRole: state.userData.userRole,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiningDetail);
