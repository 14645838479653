import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

import { THEME_MODE } from "../constants/Theme";

const UserEnquiriesTableList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      width: "30%",
      borderBottom: `10px solid ${theme_mode.bg}`,
      color: theme_mode.tableRowText,
      fontFamily: "InterMedium",
      fontSize: 13,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme_mode.bg10,
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  let data = props.itemList;

  return (
    <TableContainer component={Paper} sx={{ borderRadius: 0 }} elevation={0}>
      {data.length ? (
        <Table aria-label="customized table">
          <TableBody>
            {data.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{item.name}</StyledTableCell>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  {item.dateofBirth
                    ? moment(item.dateofBirth, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      )
                    : null}
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "right" }}>
                  {item.phoneNumber}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </TableContainer>
  );
};

export default UserEnquiriesTableList;
