import React from "react";
import Box from "@mui/material/Box";

import { useDrag } from "react-dnd";

import styles from "./TableSetupType.module.css";

const TableSetupType = (props) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Box",
    item: { type: props.data.type, left: props.data.left, top: props.data.top },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  let item = props.data;
  let dragHeight = props.tableHeight * 0.2;
  let dragWidth = item?.type == "rectangle" ? dragHeight - 20 : dragHeight;

  let chairHeight = dragHeight * 0.1 > 10 ? 10 : dragHeight * 0.1;
  let chairWidth = chairHeight * 3;

  let chairBg = props.uiStyle[props.page]["chair"];
  let tableBg = props.uiStyle[props.page]["table"];

  const ChairBox = (props) => {
    return (
      <Box
        // sx={{ height: chairHeight, width: chairWidth }}
        className={[styles.chairBox, props.className]}
        style={{
          ...props.style,
          backgroundColor: chairBg,
        }}
      />
    );
  };

  return (
    <Box
      ref={drag}
      sx={{
        width: dragWidth + 50,
        height: dragHeight + 50,
        border: `1px solid ${tableBg}`,
        borderStyle: "dashed !important",
      }}
      className={styles.boxView}
    >
      <Box
        // className={[isDragging ? styles.selectedBox : styles.nonSelectedBox]}
        sx={{
          width: dragWidth,
          height: dragHeight,
          borderRadius: item.type == "rectangle" ? "3%" : "50%",
          backgroundColor: tableBg,
        }}
        style={{ position: "relative" }}
      >
        <ChairBox
          className={styles.leftChairBox}
          style={{
            height: chairWidth,
            width: chairHeight,
            top: dragHeight / 2 - chairWidth / 2,
            left: -1 * chairHeight - 5,
          }}
        />
        <ChairBox
          className={styles.rightChairBox}
          style={{
            height: chairWidth,
            width: chairHeight,
            bottom: dragHeight / 2 - chairWidth / 2,
            right: -1 * chairHeight - 5,
          }}
        />
        <ChairBox
          className={styles.topChairBox}
          style={{
            height: chairHeight,
            width: chairWidth,
            left: dragWidth / 2 - chairWidth / 2,
            top: -1 * chairHeight - 5,
          }}
        />
        <ChairBox
          className={styles.bottomChairBox}
          style={{
            height: chairHeight,
            width: chairWidth,
            left: dragWidth / 2 - chairWidth / 2,
            bottom: -1 * chairHeight - 5,
          }}
        />
      </Box>
    </Box>
  );
};

export default TableSetupType;
