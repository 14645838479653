import React, { forwardRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Dropzone from "react-dropzone";
import Button from "@mui/material/Button";

import * as Api from "../api";

import AddMenuImageItem from "./AddMenuImageItem";
import UploadLogo from "./UploadLogo";
import AlertMsg from "./AlertMsg";

import "./../assets/css/custom.css";

import { THEME_MODE } from "../constants/Theme";

import { ReactComponent as UploadIcon } from "../assets/images/upload.svg";

const AddRestaurantImagesUpload = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [imageList, setImageList] = useState([]);
  const [logoImg, setLogoImg] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [imageType, setImageType] = useState("image");
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    setImageList(props.image);
  }, [props.image]);

  useEffect(() => {
    setLogoImg(props.logoImage);
  }, [props.logoImage]);

  useEffect(() => {
    let upload_list = imageList.filter((x) => x.upload == false);
    if (upload_list.length > 0) {
      setImageLoading(true);
    } else {
      setImageLoading(false);
    }
  }, [imageList]);

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const uploadFile = (acceptedFiles) => {
    setImageType("image");
    acceptedFiles.map((file) => {
      let fileSize = file.size;
      let sizeMb = (fileSize / (1024 * 1024)).toFixed(3);
      let image = URL.createObjectURL(file);
      let new_id = new Date();
      let data = {
        id: new_id,
        name: file.name,
        displayName: file.name,
        size: sizeMb,
        image: image,
        upload: false,
      };
      fileUploadApi(file, data, "image");
    });
  };

  const uploadLogoFile = (event, type) => {
    setImageType(type);
    let file = event.target.files[0];
    let fileSize = file.size;
    let sizeMb = (fileSize / (1024 * 1024)).toFixed(3);
    let image = URL.createObjectURL(file);
    let new_id = new Date() + type;
    let data = {
      id: new_id,
      name: file.name,
      displayName: file.name,
      size: sizeMb,
      image: image,
      upload: false,
    };
    fileUploadApi(file, data, type);
  };

  const fileUploadApi = (file, data, type) => {
    if (type == "image") {
      setImageList((prev) => [...prev, data]);
    }
    setImageLoading(true);

    let formData = new FormData();
    formData.append("image", file);

    Api.uploadImageFile(formData).then((response) => {
      if (response.success) {
        data["name"] = response.data.fileId;
        data["image"] = response.data.location;
        data["upload"] = true;
        if (type == "image") {
          setImageList((prev) =>
            prev.map((el) => (el.id == data.id ? { ...el, ...data } : el))
          );
        } else {
          if (type == "logo") {
            setLogoImg(data);
          }
          setImageLoading(false);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const onRemoveImage = (data) => {
    let menuImage = [...imageList];
    let imageIndex = menuImage.findIndex((item) => item.id == data.id);
    if (imageIndex >= 0) {
      menuImage.splice(imageIndex, 1);
      setImageList(menuImage);
    }
  };

  const onPrevBtnClick = () => {
    props.resImageUpload("", "", "prev");
  };

  const onNextBtnClick = () => {
    if (logoImg == "") {
      setMsgAlert({
        open: true,
        message: "Logo is required",
        msgType: "error",
      });
      return;
    }
    if (imageList.length === 0) {
      setMsgAlert({
        open: true,
        message: "Image is required",
        msgType: "error",
      });
      return;
    }

    props.resImageUpload(imageList, logoImg, "next");
  };

  const uploadError = (err) => {
    if (err.length) {
      if (err[0]["errors"].length)
        if (err[0]["errors"][0]["code"] == "too-many-files") {
          setMsgAlert({
            open: true,
            message: "Only 5 images are allowed to upload at a time",
            msgType: "error",
          });
        } else {
          setMsgAlert({
            open: true,
            message: err[0]["errors"][0]["message"],
            msgType: "error",
          });
        }
    }
  };

  return (
    <Grid>
      <Grid
        container
        direction="row"
        item
        xs={12}
        component="form"
        autoComplete="off"
        justifyContent="center"
      >
        <Grid item xs={12} md={2.5} mr={2} mb={2}>
          <UploadLogo
            type={"logo"}
            uploadFile={uploadLogoFile}
            imageLoading={imageLoading}
            logoImg={logoImg}
            imageType={imageType}
            onRemove={() => setLogoImg("")}
          />
        </Grid>
        <Dropzone
          maxFiles={5}
          onDropRejected={(err) => uploadError(err)}
          accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
          onDrop={uploadFile}
        >
          {({ getRootProps, getInputProps }) => (
            <Grid
              item
              xs={12}
              md={4}
              className={
                "image-upload-grid " + (!imageLoading ? " cursor-pointer" : "")
              }
              sx={{ border: "1.5px solid " + theme_mode.uploadImgBorder }}
              {...(!imageLoading ? getRootProps() : null)}
            >
              {!imageLoading ? <input {...getInputProps()} /> : null}
              <UploadIcon
                height={50}
                width={50}
                style={{ marginTop: 10, marginBottom: 15 }}
              />
              <Typography
                className="image-drag-text"
                sx={{
                  color: theme_mode.uploadImgText,
                  fontFamily: "InterRegular",
                }}
              >
                Drag and Drop Your Images Here
              </Typography>
              <Typography
                className="image-upload-text"
                sx={{
                  color: theme_mode.uploadImgText,
                  fontFamily: "InterRegular",
                }}
              >
                OR
              </Typography>
              <label htmlFor="contained-button-file">
                <Grid
                  className="image-browse-grid"
                  sx={{ border: "2px solid " + theme_mode.uploadImgText }}
                >
                  <Typography
                    className="image-browse-text"
                    sx={{
                      color: theme_mode.titleText,
                      fontFamily: "InterBold",
                    }}
                  >
                    {imageLoading && imageType == "image"
                      ? "Please Wait"
                      : "Browse"}
                  </Typography>
                </Grid>
              </label>
            </Grid>
          )}
        </Dropzone>

        <Grid
          container
          direction="column"
          item
          xs={12}
          md={5}
          sx={{ marginTop: { xs: 5, md: 0 } }}
          className="image-list-grid"
        >
          {imageList.map((item, index) => {
            return (
              <AddMenuImageItem
                key={index}
                data={item}
                onRemoveImage={onRemoveImage}
                themeMode={props.themeMode}
                page="AddRestaurantImagesUpload"
              />
            );
          })}
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={7}
        mb={1}
      >
        {!imageLoading ? (
          <Button
            onClick={() => onPrevBtnClick()}
            sx={[styles.btnText, styles.btnRight]}
          >
            Previous
          </Button>
        ) : null}
        <Button
          disabled={imageLoading}
          onClick={() => onNextBtnClick()}
          sx={styles.btnText}
        >
          {imageLoading ? "Please Wait" : "Next"}
        </Button>
      </Grid>

      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
    </Grid>
  );
};

export default AddRestaurantImagesUpload;

const styles1 = (Theme) => ({
  btnText: {
    backgroundColor: Theme.theme,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    color: Theme.text,
    fontFamily: "InterBold",
    fontSize: 13,
    borderRadius: 1,
    width: 100,
    height: 35,
    "&:hover": {
      backgroundColor: Theme.theme + " !important",
    },
  },
  btnRight: {
    marginRight: 1,
  },
});
