import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { connect } from "react-redux";
import {
  updateCreateOrderModalVisible,
  updateOrderOrTableType,
} from "../redux/actions/userDataActions";

import * as Api from "../api";

import SearchInput from "./SearchInput";

import { ReactComponent as Bell } from "./../assets/images/bell.svg";
import { ReactComponent as Bar } from "./../assets/images/3bar.svg";
import { ReactComponent as LogoutIcon } from "./../assets/images/logout2.svg";
import { ReactComponent as ProfilePic } from "./../assets/images/profile.svg";
import { ReactComponent as OrdersOnline } from "./../assets/images/orders_online.svg";
import { ReactComponent as OrdersTable } from "./../assets/images/orders_table.svg";
import { ReactComponent as LogoIcon } from "../assets/images/logo2.svg";

import { THEME_MODE } from "../constants/Theme";
import {
  APP_TYPE_BOTH,
  APP_TYPE_GOPOSH,
  IS_LARGE_SCREEN,
  NAVBAR_HEIGHT,
} from "../constants";

const drawerWidth = IS_LARGE_SCREEN ? 280 : 240;
const drawerWindowWidth = 0;
let ICON_SIZE = IS_LARGE_SCREEN ? 30 : 20;

const Navbar = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [notifCount, setNotifCount] = useState(0);
  const [onlineOrderCount, setOnlineOrderCount] = useState(5);
  const [diningOrderCount, setDiningOrderCount] = useState(10);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const appType = props.restaurantDetail?.appType;

  // const open = Boolean(anchorEl);
  // const menu_id = open ? 'menu-popover' : undefined;

  // useEffect(() => {
  //   getNotification();
  // }, []);
  //
  // const getNotification = () => {
  //   let filter = '';
  //   if (props.restaurantId) {
  //     Api.getNotification(props.restaurantId, filter).then((response) => {
  //       if (response.success) {
  //         setNotifCount(response.data.total);
  //       }
  //     });
  //   }
  // };

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const onCreateOrder = () => {
  // props.updateCreateOrderModalVisible(true);
  // navigate("/dining");
  // };

  const handleTableOrOrdersClick = (type) => {
    // props.updateOrderOrTableType(type);
    if (type === "table") {
      navigate("/dining");
    } else {
      navigate("/");
    }
  };

  const onLogout = () => {
    if (props.restaurantId) {
      window.location.href = "/logout/pin";
    } else {
      window.location.href = "/logout";
    }
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: !props.open ? drawerWindowWidth : drawerWidth,
      width: `calc(100% - ${!props.open ? drawerWindowWidth : drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <AppBar
      position={props.position}
      sx={{
        width: {
          sm: `calc(100% - ${!props.open ? drawerWindowWidth : drawerWidth}px)`,
        },
        ml: { sm: `${!props.open ? drawerWindowWidth : drawerWidth}px` },
        boxShadow: "none",
        zIndex: 10,
      }}
    >
      <Toolbar sx={styles.mainNav}>
        <Box sx={styles.leftView}>
          {appType === APP_TYPE_GOPOSH || appType === APP_TYPE_BOTH ? (
            props?.icon ? (
              <Box
                onClick={props.handleDrawer}
                sx={{
                  cursor: "pointer",
                  lineHeight: "12px",
                  path: {
                    stroke: theme_mode.iconBg,
                  },
                }}
              >
                {props?.icon}
              </Box>
            ) : (
              <Box
                onClick={props.handleDrawer}
                sx={{
                  cursor: "pointer",
                  lineHeight: "12px",
                  path: {
                    stroke: theme_mode.iconBg,
                  },
                }}
              >
                {IS_LARGE_SCREEN ? (
                  <Bar height={60} width={60} />
                ) : (
                  <Bar height={30} width={30} />
                )}
              </Box>
            )
          ) : null}
          {props.page == "orders" ? (
            <Box ml={2}>
              <SearchInput
                value={searchText}
                setValue={setSearchText}
                placeholder={"Search Order"}
                themeMode={props.themeMode}
                inputBg={theme_mode.search2}
              />
            </Box>
          ) : null}
        </Box>
        {props.showLogo ? (
          <Box>
            {props.restaurantDetail?.logo ? (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <img
                  src={props.restaurantDetail?.logo}
                  style={styles.logoImg}
                />
                <Box
                  style={styles.logoView}
                  sx={{
                    path: {
                      fill: theme_mode.iconBg,
                    },
                  }}
                >
                  <Typography mr={0.5} style={styles.poweredByText}>
                    Powered by
                  </Typography>
                  <LogoIcon height={10} width={45} />
                </Box>
              </Box>
            ) : null}
          </Box>
        ) : null}
        <Box sx={styles.navBox}>
          {/*<Grid style={{position: 'relative', cursor: 'pointer'}}>*/}
          {/*  <Bell style={{marginRight: 20}}/>*/}
          {/*  {notifCount > 0 ? <Grid container*/}
          {/*                          alignItems="center"*/}
          {/*                          justifyContent="center"*/}
          {/*                          sx={styles.notifGrid}>*/}
          {/*    <Typography style={{fontSize: 8}}>{notifCount}</Typography>*/}
          {/*  </Grid> : null}*/}
          {/*</Grid>*/}

          {/*<Grid mr={{xs: 0.5, md: 2}} style={styles.notifMainView}>*/}
          {/*  <Grid style={styles.notifIconView}>*/}
          {/*    <Bell width={24} height={24}/>*/}
          {/*    {diningOrderCount > 0 ? <Grid container*/}
          {/*                                  alignItems="center"*/}
          {/*                                  justifyContent="center"*/}
          {/*                                  sx={styles.notifGrid}>*/}
          {/*      <Typography style={styles.notifText}>{diningOrderCount}</Typography>*/}
          {/*    </Grid> : null}*/}
          {/*  </Grid>*/}
          {/*  <Typography style={styles.notifTitleText}>Dining Orders</Typography>*/}
          {/*</Grid>*/}
          {/*<Grid mr={{xs: 0.5, md: 2}} style={styles.notifMainView}>*/}
          {/*  <Grid style={styles.notifIconView}>*/}
          {/*    <Bell width={24} height={24}/>*/}
          {/*    {onlineOrderCount > 0 ? <Grid container*/}
          {/*                                  alignItems="center"*/}
          {/*                                  justifyContent="center"*/}
          {/*                                  sx={styles.notifGrid}>*/}
          {/*      <Typography style={styles.notifText}>{onlineOrderCount}</Typography>*/}
          {/*    </Grid> : null}*/}
          {/*  </Grid>*/}
          {/*  <Typography style={styles.notifTitleText}>Online Orders</Typography>*/}
          {/*</Grid>*/}

          {appType === APP_TYPE_GOPOSH || appType === APP_TYPE_BOTH ? (
            <>
              {props.restaurantId ? (
                <Grid sx={styles.tableOrderBtnView}>
                  <Box
                    sx={[
                      styles.tableOrOrderBtn,
                      window.location.pathname.includes("/dining") &&
                        styles.tableOrOrderBtnActive,
                      {
                        path: {
                          stroke: theme_mode.iconBg,
                        },
                      },
                    ]}
                    onClick={() => handleTableOrOrdersClick("table")}
                  >
                    <Box sx={styles.tableIconView}>
                      <OrdersTable height={"100%"} style={{ marginRight: 5 }} />
                    </Box>
                    <Typography sx={styles.tableOrOrderBtnText}>
                      TABLE
                    </Typography>
                  </Box>
                </Grid>
              ) : null}
              {props.restaurantId ? (
                <Grid ml={"3px"} sx={styles.tableOrderBtnView}>
                  <Box
                    sx={[
                      styles.tableOrOrderBtn,
                      window.location.pathname === "/" &&
                        styles.tableOrOrderBtnActive,
                      {
                        path: {
                          stroke: theme_mode.iconBg,
                        },
                      },
                    ]}
                    onClick={() => handleTableOrOrdersClick("orders")}
                  >
                    <Box sx={styles.orderIconView}>
                      <OrdersOnline
                        height={"100%"}
                        style={{ marginRight: 8 }}
                      />
                    </Box>
                    <Typography sx={styles.tableOrOrderBtnText}>
                      ORDERS
                    </Typography>
                  </Box>
                </Grid>
              ) : null}
            </>
          ) : (
            <Typography sx={styles.usernameText} mr={2}>
              {props.userDetail?.name}
            </Typography>
          )}
          <Box
            ml={"2px"}
            className={styles.logoutBtn}
            onClick={onLogout}
            sx={{
              backgroundColor: "#555",
              path: { fill: theme_mode.lightText },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              cursor: "pointer",
              width: NAVBAR_HEIGHT * 0.9,
            }}
          >
            <LogoutIcon width={ICON_SIZE} height={ICON_SIZE} />
          </Box>
          <Grid>
            {/*<Box sx={styles.createOrderBtn} onClick={onCreateOrder}>*/}
            {/*  <Typography sx={styles.createOrderBtnText}>*/}
            {/*    CREATE ORDER*/}
            {/*  </Typography>*/}
            {/*</Box>*/}
          </Grid>

          {/*<IconButton aria-describedby={menu_id} variant="contained" onClick={handleClick}>*/}
          {/*  {props.restaurantImg ?*/}
          {/*    <img alt={""} style={styles.proPic} src={props.restaurantImg} width="35" height="35"/> :*/}
          {/*    <ProfilePic width="35" height="35"/>}*/}
          {/*</IconButton>*/}
          {/*<Popover*/}
          {/*  id={menu_id}*/}
          {/*  open={open}*/}
          {/*  anchorEl={anchorEl}*/}
          {/*  onClose={handleClose}*/}
          {/*  anchorReference="anchorPosition"*/}
          {/*  anchorPosition={{*/}
          {/*    top: 50,*/}
          {/*    left: window.innerWidth - 180*/}
          {/*  }}>*/}
          {/*  <List sx={{width: 150}}>*/}
          {/*    <ListItem disablePadding>*/}
          {/*      <ListItemButton onClick={() => navigate('/logout')}>*/}
          {/*        <ListItemIcon sx={{minWidth: 30}}>*/}
          {/*          <LogoutIcon width={25} height={25}/>*/}
          {/*        </ListItemIcon>*/}
          {/*        <ListItemText primary="Logout"/>*/}
          {/*      </ListItemButton>*/}
          {/*    </ListItem>*/}
          {/*  </List>*/}
          {/*</Popover>*/}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCreateOrderModalVisible: (data) =>
      dispatch(updateCreateOrderModalVisible(data)),
    updateOrderOrTableType: (data) => dispatch(updateOrderOrTableType(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantImg: state.userData.restaurantImg,
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    orderOrTableType: state.userData.orderOrTableType,
    restaurantDetail: state.userData.restaurantDetail,
    userDetail: state.userData.userDetail,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

const styles1 = (Theme) => ({
  mainNav: {
    backgroundColor: Theme.nav,
    flexDirection: "row",
    justifyContent: "space-between",
    height: NAVBAR_HEIGHT,
    "&.MuiToolbar-root": {
      paddingRight: 0,
    },
  },
  leftView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  notifMainView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  notifIconView: {
    position: "relative",
    width: "fit-content",
    cursor: "pointer",
  },
  notifGrid: {
    backgroundColor: Theme.redBg,
    position: "absolute",
    borderRadius: 7.5,
    width: 15,
    height: 15,
    top: -3,
    right: -3,
  },
  notifText: {
    color: Theme.lightText,
    fontFamily: "InterSemiBold",
    fontSize: 9,
  },
  notifTitleText: {
    color: Theme.text,
    textAlign: "center",
    fontFamily: "InterBold",
    fontSize: 12,
    lineHeight: 1,
    marginTop: -5,
  },
  logoImg: {
    height: 30,
    objectFit: "contain",
  },
  logoView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  poweredByText: {
    color: Theme.text,
    fontFamily: "InterSemiBold",
    fontSize: 9,
  },
  createOrderBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.btnBg,
    border: `2px solid ${Theme.btnBg}`,
    borderRadius: "5px",
    cursor: "pointer",
    maxWidth: 140,
    px: 2,
    py: { xs: 0.5, md: 1.1 },
  },
  createOrderBtnText: {
    color: Theme.btnText,
    fontSize: 12,
    fontFamily: "InterSemiBold",
    textAlign: "center",
  },
  tableOrderBtnView: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableOrOrderBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${Theme.border6}`,
    cursor: "pointer",
    minWidth: { md: 160, xl: 180 },
    height: "100%",
    px: 2,
  },
  tableIconView: {
    height: { xl: 23, md: 22 },
  },
  orderIconView: {
    height: { xl: 20, md: 17 },
  },
  tableOrOrderBtnText: {
    color: Theme.text,
    fontSize: { xl: 17, md: 17 },
    fontFamily: "InterSemiBold",
    textAlign: "center",
  },
  tableOrOrderBtnActive: {
    backgroundColor: Theme.theme,
  },
  navTitleText: {
    color: Theme.text2,
    fontFamily: "InterSemiBold",
    fontSize: 18,
  },
  navBox: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  proPic: {
    borderRadius: 17,
  },
  usernameText: {
    fontSize: IS_LARGE_SCREEN
      ? "15px !important"
      : { xl: "13px !important", md: "15px !important" },
    fontFamily: "InterSemiBold",
    textTransform: "capitalize",
    textAlign: "center",
    color: "#F17400",
  },
});
