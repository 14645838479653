import React from "react";
import { createTheme, styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import styles from "./GiftCardHistoryTable.module.css";

import { THEME_MODE } from "../constants/Theme";

let table_header_list = ["Username", "Date", "Amount", "Status"];

const GiftCardHistoryTable = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme_mode.tableHeaderText2,
      fontFamily: "InterSemiBold",
      fontSize: 14,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: `${props.themeMode == "dark" ? 5 : 1}px solid ${
        theme_mode.tableBorder
      }`,
      color: theme_mode.tableRowText,
      fontFamily: "InterSemiBold",
      fontSize: 13,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme_mode.tableRow2,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme_mode.tableRow,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  let data = props.itemList;

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: props.themeMode == "dark" ? 0 : 5,
        "&.MuiTableContainer-root": { backgroundColor: theme_mode.table },
      }}
      elevation={0}
    >
      {data.length ? (
        <Table aria-label="customized table">
          <TableHead sx={{ backgroundColor: theme_mode.tableHeader2 }}>
            <TableRow>
              {table_header_list.map((x, i) => {
                return (
                  <StyledTableCell key={i} sx={{ borderBottom: "none" }}>
                    <Box>{x}</Box>
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <Box className={styles.nameText}>{item.name}</Box>
                  <Box className={styles.emailText}>{item.email}</Box>
                </StyledTableCell>
                <StyledTableCell>{item.date}</StyledTableCell>
                <StyledTableCell>${item.amount}</StyledTableCell>
                <StyledTableCell>
                  <Box
                    className={styles.statusView}
                    style={{
                      color: theme_mode.lightText,
                      backgroundColor:
                        item.status == "top_up"
                          ? "#1D86FF"
                          : theme_mode.greenBg,
                    }}
                  >
                    {item.status == "top_up" ? "Topup" : "Redeem"}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </TableContainer>
  );
};

export default GiftCardHistoryTable;
