import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import styles from "./OfflineSettings.module.css";

import { connect } from "react-redux";
import { updateOfflineSettings } from "../../redux/actions/userDataActions";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import SettingTextField from "../../components/SettingTextField";
import AlertMsg from "../../components/AlertMsg";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";

import { THEME_MODE } from "../../constants/Theme";
import { CONTAINER_HEIGHT } from "../../constants";

const AntSwitch = styled(Switch)(({ theme, themeMode }) => ({
  width: 50,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 45,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(-1px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#455A64",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    transition: theme.transitions.create(["width"], {
      duration: 100,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 50,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : themeMode == "light"
        ? "rgba(0,0,0,.25)"
        : "#333333",
    boxSizing: "border-box",
  },
}));

const OfflineSettings = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState(
    props.ipAddress ? props.ipAddress : process.env.REACT_APP_OFFLINE_IP_ADDRESS
  );
  const [port, setPort] = useState(
    props.port ? props.port : process.env.REACT_APP_OFFLINE_WS_PORT
  );
  const [offlineEnable, setOfflineEnable] = useState(props.offlineEnable);
  const [error, setError] = useState(null);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [syncInterval, setSyncInterval] = useState(props.syncInterval);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    if (
      props.port !== port ||
      props.ipAddress !== ipAddress ||
      props.offlineEnable !== offlineEnable ||
      props.syncInterval != syncInterval
    ) {
      setSaveBtnDisabled(false);
    } else {
      setSaveBtnDisabled(true);
    }
  }, [port, ipAddress, offlineEnable, syncInterval]);

  const onSaveAccountDetail = () => {
    let err = null;

    if (offlineEnable) {
      if (ipAddress == "" || ipAddress == null) {
        err = { ...err, ipAddress: "IP Address Required" };
      } else {
        if (ipAddress !== "localhost") {
          if (/[^0-9.]/g.test(ipAddress)) {
            err = { ...err, ipAddress: "Invalid IP Address" };
          }
        }
      }

      if (port == "" || port == null) {
        err = { ...err, port: "Port Required" };
      } else {
        if (/[^0-9]/g.test(port)) {
          err = { ...err, port: "Invalid Port" };
        }
      }
    }

    if (syncInterval == "") {
      err = { ...err, syncInterval: "Sync Interval Required" };
    }

    setError(err);
    if (err != null) {
      return;
    }

    let data = {
      offlineEnable: offlineEnable,
      ipAddress: ipAddress,
      port: port,
      syncInterval: syncInterval,
    };

    props.updateOfflineSettings(data);
    setSaveBtnDisabled(true);
  };

  return (
    <Container page={"settings"} p={1.5} fpx={0} fpy={0.5}>
      {loading ? (
        <Loader />
      ) : (
        <Grid
          p={1.5}
          item
          xs={12}
          style={{
            backgroundColor: theme_mode.card3,
            borderRadius: 15,
            minHeight: CONTAINER_HEIGHT - 70,
          }}
        >
          <Grid container item md={6} xs={12} mt={1}>
            <Box
              onClick={() => navigate("/settings")}
              sx={{ cursor: "pointer" }}
            >
              <BackBtnIcon width={33} height={33} />
            </Box>
            <Grid container item xs={12} className={styles.textInputMainView}>
              <Grid item xs={4.5} className={styles.textInputLabelView}>
                <Typography
                  className={styles.textInputLabel}
                  sx={{ color: theme_mode.text2 }}
                >
                  Offline Enabled
                </Typography>
              </Grid>
              <Grid item xs={7.5}>
                <AntSwitch
                  checked={offlineEnable}
                  onChange={() => setOfflineEnable(!offlineEnable)}
                  inputProps={{ "aria-label": "ant design" }}
                  themeMode={props.themeMode}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} className={styles.textInputMainView}>
              <Grid item xs={4.5} className={styles.textInputLabelView}>
                <Typography
                  className={styles.textInputLabel}
                  sx={{ color: theme_mode.text2 }}
                >
                  IP Address
                </Typography>
              </Grid>
              <Grid item xs={7.5}>
                <SettingTextField
                  page={"accountDetail"}
                  value={ipAddress}
                  setValue={setIpAddress}
                  editable={offlineEnable}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  error={error?.ipAddress}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} className={styles.textInputMainView}>
              <Grid item xs={4.5} className={styles.textInputLabelView}>
                <Typography
                  className={styles.textInputLabel}
                  sx={{ color: theme_mode.text2 }}
                >
                  Port
                </Typography>
              </Grid>
              <Grid item xs={7.5}>
                <SettingTextField
                  page={"accountDetail"}
                  value={port}
                  setValue={setPort}
                  editable={offlineEnable}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  type={"number"}
                  error={error?.port}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} className={styles.textInputMainView}>
              <Grid item xs={4.5} className={styles.textInputLabelView}>
                <Typography
                  className={styles.textInputLabel}
                  sx={{ color: theme_mode.text2 }}
                >
                  Sync Interval
                </Typography>
              </Grid>
              <Grid item xs={7.5}>
                <SettingTextField
                  page={"accountDetail"}
                  value={syncInterval}
                  setValue={setSyncInterval}
                  editable={true}
                  placeholder={"in minutes"}
                  required={true}
                  variant={"filled"}
                  type={"number"}
                  error={error?.syncInterval}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} className={styles.textInputMainView}>
              <Grid
                item
                xs={4.5}
                className={styles.textInputLabelView}
                sx={{ color: theme_mode.text2 }}
              ></Grid>
              <Grid item xs={7.5}>
                <Box className={styles.btnMainView}>
                  <Button
                    type="button"
                    disabled={saveBtnDisabled}
                    className={styles.saveBtn}
                    sx={{
                      color: theme_mode.btnText,
                      "&:hover": {
                        backgroundColor: theme_mode.btnBg4 + " !important",
                      },
                      backgroundColor: saveBtnDisabled
                        ? theme_mode.btnDisabledBg
                        : theme_mode.btnBg4,
                    }}
                    onClick={onSaveAccountDetail}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    offlineEnable: state.userData.offlineEnable,
    ipAddress: state.userData.offlineIpAddress,
    port: state.userData.offlinePort,
    syncInterval: state.userData.syncInterval,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOfflineSettings: (data) => dispatch(updateOfflineSettings(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfflineSettings);
