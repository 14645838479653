const LIGHT_THEME = {
  theme: "#ffe342",
  bg: "#eeeeee",
  bg2: "#ffffff",
  bg3: "#646D77",
  bg4: "#F3F3F3",
  bg5: "#474747",
  bg8: "#FFFFFF",
  bg9: "#FFFFFF",
  bg10: "#FFFFFF",
  darkLightBg: "#313E53",
  nav: "#ffffff",
  sidebar: "#ffe342",
  text: "#000000",
  text2: "#272D2F",
  text3: "#00000099",
  text4: "#C4C4C4",
  text5: "#263238",
  text6: "#434343",
  text7: "#6A7B83",
  text8: "#4B4B4B",
  text9: "#777777",
  text10: "#371A45",
  titleText: "#000000",
  placeholder: '#BBBBBB',
  redText: "#CD0000",
  redText2: "#ff5500",
  redText3: "#D80202",
  redBg: "#D42323",
  greenBg: "#148E20",
  lightText: "#FFFFFF",
  textInputVal: "#000000",
  inputBg: "#FFFFFF",
  inputBg2: "#F3F3F3",
  inputBg3: "#F3F3F3",
  inputBg4: "#FFFFFF",
  inputBg5: "#EEEEEE",
  inputDisabledBg: "#DDDDDD",
  inputDisabledText: "#00000061",
  inputLabelText: "#272D2F",
  inputLabel: "#AAAAAA",
  inputLabel2: "#333333",
  inputVal: "#000000",
  inputColor: "#868E96",
  settingsCardBg: "#000000",
  btnBg: "#000000",
  btnText: "#FFFFFF",
  btnBg2: "#FFFFFF",
  btnText2: "#000000",
  btnBg3: "#000000",
  btnBg4: "#000000",
  btnBg5: "#FFFFFF",
  btnBg6: "#DDDDDD",
  btnDisabledBg: "#DDDDDD",
  btnBg5Border: "#000000",
  black: "#000000",
  border: "#393C49",
  border2: "#E1E1E1",
  border3: "#BBBBBB",
  border4: "#C0C0C0",
  border5: "#F5F4F4",
  border6: "#ffe342",
  border7: "#000000",
  border8: "#C4C4C4",
  border9: "#DEDEDE",
  borderBottom: "#000000",
  borderBottom2: "#B3B3B3",
  borderBottom3: "#DEDEDE",
  borderBottom4: "#B5B5B5",
  tableActiveOrder: "#ffe342",
  table: "#FFFFFF",
  tableHeader2: "#000000",
  tableHeaderText2: "#FFFFFF",
  tableHeaderText: "#000000",
  tableRowText: "#000000",
  tableRow: "#F5F5F5",
  tableRow2: "#FFFFFF",
  tableBorder: "#e0e0e0",
  catBtnBg: "#FFFFFF",
  catBtnBg2: "#EEEEEE",
  catBtnBorder: "#000000",
  catBtnText: "#000000",
  catBtnText2: "#00000099",
  page: "#FFFFFF",
  pageText: "#000000",
  card: "#FFFFFF",
  card2: "#FFFFFF",
  card3: "#EEEEEE",
  card4: "#EEEEEE",
  card5: "#263238",
  card6: "#FFFFFF",
  card7: "#FFFFFF",
  cardText: "#000000",
  cardDescText: "#999999",
  cardDescText2: "#888888",
  cardDescText3: "#272D2F",
  search: "#FFFFFF",
  searchText: "#000000",
  search2: "#F1F1F1",
  modal: "#FFFFFF",
  uploadImgBorder: "#AAAAAA",
  uploadImgText: "#AAAAAA",
  imgBg: "#EEEEEE",
  orderTableBg: "#263238",
  orderTableFloorBg: "#FFFFFF",
  headerBg: "#263238",
  headerBg2: "#263238",
  headerBg3: "#aaaaaa",
  floorBg: "#313E53",
  modalHeaderBg: "#263238",
  modalHeaderBg2: "#263238",
  deliveryBg: "#1D86FF",
  pickupBg: "#F100D9",
  tableOrder: "#1D86FF",
  onlineOrder: "#960090",
  minutesText: "#646D77",
  activePlanBg: "#FE724C",
  blackText: "#000000",
  whiteBlack: "#FFFFFF",
  diningBg: "#eeeeee",
  diningDetailBg: "#eeeeee",
  diningMenuBg: "#263238",
  diningCartCourseTitle: "#E2E2E2",
  diningCartCourseBtn: "#E2E2E2",
  diningCartCourseList: "#E2E2E2",
  diningCartNoteText: "#272D2F",
  diningCartHeader: "#263238",
  diningContentBg: "#FFFFFF",
  orderSummaryCourseTitle: "#E2E2E2",
  addSubBtnBg: "#000000",
  addSubBorder: "#DDD",
  calcBg: "#263238",
  loader: "#000000",
  pinBg: "#000000",
  iconBg: "#000000",
  iconBg2: "#646D77",
  iconBg3: "#000000",
  slotBg: '#FFFFFF',
  activeSlotBg: '#263238',
  catMenuBg: 'transparent',
};

const DARK_THEME = {
  theme: "#DD5903",
  bg: "#000000",
  bg2: "#000000",
  bg3: "#646D77",
  bg4: "#404A59",
  bg5: "#474747",
  bg8: "#202A38",
  bg9: "#404A59",
  bg10: "#141414",
  darkLightBg: "#202a38",
  nav: "#141414",
  sidebar: "#050A0F",
  text: "#FFFFFF",
  text2: "#FFFFFF",
  text3: "#FFFFFF",
  text4: "#C4C4C4",
  text5: "#FFFFFF",
  text6: "#FFFFFF",
  text7: "#6A7B83",
  text8: "#FFFFFF",
  text9: "#FFFFFF",
  text10: "#371A45",
  titleText: "#FFFFFF",
  placeholder: '#BBBBBB',
  redText: "#CD0000",
  redText2: "#ff5500",
  redText3: "#D80202",
  redBg: "#D42323",
  greenBg: "#148E20",
  lightText: "#FFFFFF",
  textInputVal: "#000000",
  inputBg: "#404A59",
  inputBg2: "#404A59",
  inputBg3: "#202A38",
  inputBg4: "#202A38",
  inputBg5: "#313E53",
  inputDisabledBg: "#393C49",
  inputDisabledText: "#ffffffcc",
  inputLabelText: "#FFFFFF",
  inputLabel: "#AAAAAA",
  inputLabel2: "#FFFFFF",
  inputVal: "#FFFFFF",
  inputColor: "#FFFFFF",
  settingsCardBg: "#313E53",
  btnBg: "#DD5903",
  btnText: "#FFFFFF",
  btnBg2: "#FFFFFF",
  btnText2: "#000000",
  btnBg3: "#0E141C",
  btnBg4: "#148E20",
  btnBg5: "#D42323",
  btnBg6: "#0E141C",
  btnDisabledBg: "#719a75",
  btnBg5Border: "#D42323",
  black: "#000000",
  border: "#393C49",
  border2: "transparent",
  border3: "transparent",
  border4: "#3d3d3d",
  border5: "#333333",
  border6: "#DD5903",
  border7: "#DD5903",
  border8: "#FFFFFF",
  border9: "#3d3d3d",
  borderBottom: "#FFFFFF",
  borderBottom2: "#B3B3B3",
  borderBottom3: "#DEDEDE",
  borderBottom4: "#ffffff33",
  table: "#FFFFFF",
  tableHeader2: "#10161D",
  tableHeaderText2: "#FFFFFF",
  tableHeaderText: "#000000",
  tableRowText: "#FFFFFF",
  tableRow: "#000000",
  tableRow2: "#000000",
  tableBorder: "#10161D",
  catBtnBg: "#FFFFFF",
  catBtnBg2: "#EEEEEE",
  catBtnBorder: "transparent",
  catBtnText: "#FFFFFF",
  catBtnText2: "#00000099",
  page: "#404a59",
  pageText: "#FFFFFF",
  card: "#000000",
  card2: "#10161D",
  card3: "#10161D",
  card4: "#313E53",
  card5: "#10161D",
  card6: "#777777",
  card7: "#13191f",
  cardText: "#FFFFFF",
  cardDescText: "#999999",
  cardDescText2: "#888888",
  cardDescText3: "#FFFFFF",
  search: "#404A59",
  searchText: "#000000",
  search2: "#404A59",
  modal: "#202A38",
  uploadImgBorder: "#AAAAAA",
  uploadImgText: "#AAAAAA",
  imgBg: "#EEEEEE",
  orderTableBg: "#313E53",
  orderTableFloorBg: "#0E141B",
  headerBg: "#313E53",
  headerBg2: "#202A38",
  headerBg3: "#313E53",
  floorBg: "#313E53",
  modalHeaderBg: "#313E53",
  modalHeaderBg2: "#F17400",
  deliveryBg: "#1D86FF",
  pickupBg: "#F100D9",
  tableOrder: "#1D86FF",
  onlineOrder: "#960090",
  minutesText: "#FFFFFF",
  activePlanBg: "#FE724C",
  blackText: "#000000",
  whiteBlack: "#000000",
  tableActiveOrder: "#F17400",
  diningBg: "#000000",
  diningDetailBg: "#10161D",
  diningMenuBg: "#313E5380",
  diningCartCourseTitle: "#2f3340",
  diningCartCourseBtn: "#313E53",
  diningCartCourseList: "#000000",
  diningCartNoteText: "#E0E6E9",
  diningCartHeader: "#505F7A",
  diningContentBg: "#10161D",
  orderSummaryCourseTitle: "#1B2738",
  addSubBtnBg: "#FFFFFF",
  addSubBorder: "#10161D",
  calcBg: "#D9D9D912",
  loader: "#FFFFFF",
  pinBg: "#4F5868",
  iconBg: "#FFFFFF",
  iconBg2: "#FFFFFF",
  iconBg3: "#4F5868",
  slotBg: '#202A38',
  activeSlotBg: '#DD5903',
  catMenuBg: '#404A59',
};

export const THEME_MODE = {
  light: LIGHT_THEME,
  dark: DARK_THEME
};
