import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

import { ReactComponent as Email } from "../assets/images/email.svg";
import { ReactComponent as Lock } from "../assets/images/lock.svg";
import { THEME_MODE } from "../constants/Theme";

const theme = createTheme({
  palette: {
    white: {
      main: "#EEE",
    },
  },
});

const UserLogin = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [email, setEmail] = useState("");
  const [pswd, setPswd] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const onLogin = (e) => {
    e.preventDefault();
    let data = {
      email: email,
      password: pswd,
    };
    props.onLogin(data);
  };

  return (
    <Grid component="form" onSubmit={onLogin}>
      <ThemeProvider theme={theme}>
        <TextField
          id="email"
          color="white"
          type="email"
          focused
          fullWidth
          required
          onChange={(e) => setEmail(e.target.value)}
          sx={props?.inputsx ? props.inputsx : null}
          InputProps={{
            autoComplete: "none",
            sx: {
              px: 2,
              py: 1,
              fontSize: { xs: 14, md: 12 },
              fontFamily: "InterSemiBold",
              color: theme_mode.text,
              ...(props?.inputStyle ? props.inputStyle : null),
            },
            startAdornment: (
              <InputAdornment position="start" sx={styles.iconPos}>
                <Email height={20} width={20} />
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: 20 }}
          variant="standard"
        />
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <TextField
          id="password"
          type="password"
          color="white"
          focused
          fullWidth
          required
          onChange={(e) => setPswd(e.target.value)}
          sx={props?.inputsx ? props.inputsx : null}
          InputProps={{
            autoComplete: "none",
            sx: {
              px: 2,
              py: 1,
              fontSize: { xs: 14, md: 12 },
              fontFamily: "InterSemiBold",
              color: theme_mode.text,
              ...(props?.inputStyle ? props.inputStyle : null),
              ...(props?.pswdInputStyle ? props.pswdInputStyle : null),
            },
            startAdornment: (
              <InputAdornment position="start" sx={styles.iconPos}>
                <Lock height={20} width={20} />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </ThemeProvider>
      <Typography sx={styles.msgAlertText}>{props.msgAlert}</Typography>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        xs={12}
        mt={2}
        py={1}
      >
        <Grid
          onClick={() => setRememberMe(!rememberMe)}
          style={{ flexWrap: "nowrap" }}
          item
          xs={6}
          sx={{ pl: { xs: 0, md: props.themeMode == "light" ? 1 : 0 } }}
          container
          direction="row"
          alignItems="center"
        >
          <Checkbox
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 15 },
              color: theme_mode.text,
              "&.Mui-checked": {
                color: props.themeMode == "light" ? theme_mode.theme : "#FFF",
              },
              p: 0,
            }}
          />
          <Typography sx={styles.rememberMeText}>Remember Me</Typography>
        </Grid>
        {props?.showForgotPswd ? (
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ pr: { xs: 0, md: 1 } }}
          >
            <Typography sx={styles.forgotPswdText}>Forgot password?</Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid container justifyContent="space-between">
        {props?.showSignUp ? (
          <Button
            type="button"
            variant="contained"
            onClick={props.onRegister}
            disabled={props.btnDisabled}
            sx={{
              ...styles.signUpBtn,
              ...(props?.registerBtnStyle ? props.registerBtnStyle : null),
            }}
            fullWidth
          >
            Sign Up
          </Button>
        ) : null}
        <Button
          type="submit"
          variant="contained"
          disabled={props.btnDisabled}
          sx={{
            ...styles.loginBtn,
            ...(props?.loginBtnStyle ? props.loginBtnStyle : null),
          }}
          fullWidth
        >
          {props.btnDisabled ? "Please Wait" : props.loginText}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserLogin;

const styles1 = (Theme) => ({
  iconPos: {
    paddingRight: 2,
    path: {
      stroke: Theme.iconBg3,
    },
  },
  msgAlertText: {
    fontFamily: "InterSemiBold",
    fontSize: { xs: 12.5, md: 10 },
    textAlign: "center",
    color: "#900707",
    lineHeight: 1,
    marginTop: 2,
  },
  rememberMeText: {
    color: Theme.text,
    fontSize: { xs: 12, md: 10 },
    fontFamily: "InterSemiBold",
    cursor: "pointer",
    ml: 0.7,
  },
  forgotPswdText: {
    color: Theme.text,
    fontSize: { xs: 12, md: 10 },
    fontFamily: "InterSemiBold",
    cursor: "pointer",
  },
  signUpBtn: {
    flex: 1,
    backgroundColor: Theme.whiteBlack,
    border: "1.5px solid " + Theme.btnBg,
    color: Theme.text,
    fontFamily: "InterBold",
    textTransform: "capitalize",
    borderRadius: 2,
    marginTop: 2,
    boxShadow: "none",
    fontSize: { xs: 14, md: 11 },
    "&:hover": {
      backgroundColor: Theme.theme + " !important",
      boxShadow: "none",
    },
    mr: 2,
  },
  loginBtn: {
    flex: 1,
    backgroundColor: Theme.theme,
    color: Theme.text,
    fontFamily: "InterBold",
    textTransform: "capitalize",
    borderRadius: 2,
    marginTop: 2,
    boxShadow: "none",
    fontSize: { xs: 14, md: 11 },
    "&:hover": {
      backgroundColor: Theme.theme + " !important",
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      backgroundColor: Theme.theme + " !important",
      boxShadow: "none",
    },
  },
});
