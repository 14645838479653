import React from "react";
import Container from "../../components/Container";
import AddRestaurant from "../../components/AddRestaurant";

const AddRestaurantInfo = () => {
  return (
    <Container page={"settings"}>
      <AddRestaurant type={"add"} resData={""} drawerOpen={false} />
    </Container>
  );
};

export default AddRestaurantInfo;
