import React from "react";
import { createTheme, styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { THEME_MODE } from "../constants/Theme";
import styles from "./BookingsTableList.module.css";

let ongoing_table_header_list = [
  "Name",
  "Email",
  "Booking Date",
  "No of Guests",
  "Actions",
];

let cancelled_table_header_list = [
  "Name",
  "Email",
  "Booking Date",
  "No of Guests",
  "Status",
];

const BookingsTableList = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme_mode.tableHeaderText2,
      fontFamily: "InterMedium",
      fontSize: 14,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: `${props.themeMode == "dark" ? 5 : 1}px solid ${
        theme_mode.tableBorder
      }`,
      color: theme_mode.tableRowText,
      fontFamily: "InterMedium",
      fontSize: 13,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme_mode.tableRow2,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme_mode.tableRow,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  let data = props.itemList;

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: props.themeMode == "dark" ? 0 : 5,
        "&.MuiTableContainer-root": { backgroundColor: theme_mode.table },
      }}
      elevation={0}
    >
      {data.length ? (
        <Table aria-label="customized table">
          <TableHead sx={{ backgroundColor: theme_mode.tableHeader2 }}>
            <TableRow>
              {props.bookingStatus === "ongoing"
                ? ongoing_table_header_list.map((x, i) => {
                    return (
                      <StyledTableCell
                        key={i}
                        sx={{
                          borderBottom: "none",
                          textAlign: x == "Actions" ? "center" : "left",
                        }}
                      >
                        <Box>{x}</Box>
                      </StyledTableCell>
                    );
                  })
                : cancelled_table_header_list.map((x, i) => {
                    return (
                      <StyledTableCell key={i} sx={{ borderBottom: "none" }}>
                        <Box>{x}</Box>
                      </StyledTableCell>
                    );
                  })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  <Box className={styles.nameBox}>{item.name}</Box>
                </StyledTableCell>
                <StyledTableCell>{item.email}</StyledTableCell>
                <StyledTableCell>{item.date}</StyledTableCell>
                <StyledTableCell>{item.guests}</StyledTableCell>
                {props.bookingStatus === "ongoing" ? (
                  <StyledTableCell>
                    <Box className={styles.actionBox}>
                      <Box
                        className={styles.actionBtn}
                        sx={{
                          border: `1px solid ${theme_mode.text}`,
                          mr: 1,
                        }}
                      >
                        Edit
                      </Box>
                      <Box
                        className={styles.actionBtn}
                        sx={{
                          border: `1px solid ${theme_mode.redBg}`,
                          backgroundColor: theme_mode.redBg,
                          color: theme_mode.lightText,
                        }}
                      >
                        Cancel
                      </Box>
                    </Box>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell>
                    <Box display={"flex"}>
                      <Box
                        sx={{
                          backgroundColor: theme_mode.redBg,
                          color: theme_mode.lightText,
                        }}
                        className={styles.cancelledTag}
                      >
                        <Typography className={styles.statusTag}>
                          Cancelled
                        </Typography>
                      </Box>
                    </Box>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </TableContainer>
  );
};

export default BookingsTableList;
