import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useNavigate } from "react-router-dom";

import * as Api from "../../api";

import { connect } from "react-redux";
import { updateRestaurantDetail } from "../../redux/actions/userDataActions";

import Container from "../../components/Container";
import AddRestaurant from "../../components/AddRestaurant";
import MapView from "../../components/MapView";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";

import { FOOTER_XPADDING } from "../../constants";
import { THEME_MODE } from "../../constants/Theme";

import "../../assets/css/custom.css";

import { ReactComponent as LocationIcon } from "../../assets/images/location.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/phone.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit_pencil.svg";
import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import BannerImg from "../../assets/images/banner.png";

const RestaurantInfo = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [restName, setRestName] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [cuisine, setCuisine] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [restDesc, setRestDesc] = useState("");
  const [openHr, setOpenHr] = useState("");
  const [options, setOptions] = useState("");
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [images, setImages] = useState([]);
  const [resData, setResData] = useState("");
  const [drawerAddRestroOpen, setDrawerAddRestroOpen] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getRestaurantData();
  }, []);

  useEffect(() => {
    getRestaurantCategoryList();
  }, []);

  useEffect(() => {
    if (categoryList.length && resData != "") {
      let category = categoryList.filter((x) => x._id == resData?._idCategory);
      if (category.length) {
        setCuisine(category[0]?.name);
      }
    }
  }, [categoryList, resData]);

  const getRestaurantData = () => {
    Api.getRestaurantData(props.restaurantId).then((response) => {
      if (response.success) {
        setRestroData(response.data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const getRestaurantCategoryList = () => {
    Api.getRestaurantCategoryList().then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setCategoryList(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const setRestroData = (response) => {
    let coords = response.address.coords;
    setRestName(response.name);
    setAddress(response.address);
    setMobile(response.contactNumber);
    setEmail(response.email);
    setRestDesc(response.description);
    setOpenHr(response.openHours);
    setCenter({ lat: parseFloat(coords[0]), lng: parseFloat(coords[1]) });
    setOptions(response.options);
    setImages(response.images);
    let res_data = {
      ...response,
      name: response.name,
      email: response.email,
      address: response.address,
      mobile: response.contactNumber,
      description: response.description,
      category: response._idCategory,
      openHours: response.openHours,
      options: response.options,
      images: response.images,
      logo: response.logo,
      _idCategory: response._idCategory,
    };
    setResData(res_data);
    setDrawerAddRestroOpen(false);
    props.updateRestaurantDetail(response);
  };

  const toggleDrawer = (open) => {
    setDrawerAddRestroOpen(open);
  };

  return (
    <Container
      page={"settings"}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0}
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid
          p={1.5}
          style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
        >
          <Box sx={styles.headerDiv}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                mr={1.5}
                onClick={() => navigate("/settings")}
                sx={{ cursor: "pointer", lineHeight: "0.5" }}
              >
                <BackBtnIcon width={33} height={33} />
              </Box>
              <Typography variant={"h6"} sx={styles.titleText}>
                Restaurant Info
              </Typography>
            </Box>
            <Box sx={styles.editInfoDiv} onClick={() => toggleDrawer(true)}>
              <EditIcon width={14} height={14} />
              <Typography sx={styles.editInfoText} ml={0.5}>
                Edit Info
              </Typography>
            </Box>
          </Box>
          <Grid
            container
            direction={"row"}
            justifyContent={"space-between"}
            mt={3}
          >
            <Grid container direction={"column"} item md={8} xs={12}>
              {images.length === 1 || images.length === 2 ? (
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  {images.length === 1 ? (
                    <Grid item xs={12} sx={styles.restSingleImg1}>
                      <img alt="" src={images[0]} style={styles.imgStyle} />
                    </Grid>
                  ) : (
                    <Grid item xs={5.8} sx={styles.restTwoImg1}>
                      <img alt="" src={images[0]} style={styles.imgStyle} />
                    </Grid>
                  )}
                  {images.length === 2 ? (
                    <Grid item xs={5.8} mr={0.4} sx={styles.restTwoImg1}>
                      <img alt="" src={images[1]} style={styles.imgStyle} />
                    </Grid>
                  ) : null}
                </Grid>
              ) : images.length > 1 ? (
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={7.5} sx={styles.restImg1}>
                    <img alt="" src={images[0]} style={styles.imgStyle} />
                  </Grid>
                  <Grid
                    container
                    direction={"column"}
                    justifyContent={"space-between"}
                    item
                    xs={4.2}
                    sx={{ height: { md: window.innerWidth / 4.5, xs: 150 } }}
                  >
                    <Grid sx={styles.restImg2}>
                      <img alt="" src={images[1]} style={styles.imgStyle} />
                    </Grid>
                    <Grid sx={styles.restImg3}>
                      <Box
                        sx={[
                          styles.morePhotoGrid,
                          images.length > 3 ? styles.morePhotoSubGrid : "",
                        ]}
                      >
                        {images.length > 3 ? (
                          <Typography sx={styles.photoText}>
                            +{images.length - 2} Photos
                          </Typography>
                        ) : null}
                      </Box>
                      <img alt="" src={images[2]} style={styles.imgStyle} />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}

              <Grid mt={images.length > 0 ? 2 : 0.5} style={{ flexGrow: 1 }}>
                <Paper elevation={0} sx={styles.restInfo}>
                  <Grid>
                    <Typography sx={styles.restTitleText}>
                      {restName}
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      container
                      direction={"row"}
                      mt={2}
                      alignItems={"center"}
                    >
                      <Grid item xs={9}>
                        <Grid container direction={"row"} item xs={12}>
                          <Box
                            sx={{
                              ...styles.iconBox,
                              path: { stroke: theme_mode.iconBg },
                              circle: { stroke: theme_mode.iconBg },
                            }}
                          >
                            <LocationIcon width={20} height={20} />
                          </Box>
                          <Typography sx={styles.restAddressText}>
                            {address.firstLine}, {address.secondLine},{" "}
                            {address.city},{" "}
                            {address.countryCode}
                            , {address.postCode}
                          </Typography>
                        </Grid>
                        <Grid container direction={"row"} item xs={12} mt={0.5}>
                          <Box
                            sx={{
                              ...styles.iconBox,
                              path: { fill: theme_mode.iconBg },
                            }}
                          >
                            <PhoneIcon width={20} height={20} />
                          </Box>
                          <Typography sx={styles.restAddressText}>
                            {mobile} - {email}
                          </Typography>
                        </Grid>
                      </Grid>
                      {options.hasDineIn || options.hasTakeAway ? (
                        <Grid item xs={3}>
                          <Box sx={styles.takeAwayBtn}>
                            {options.hasDineIn && options.hasTakeAway
                              ? "Dine In & Take Away"
                              : options.hasDineIn && !options.hasTakeAway
                              ? "Dine In"
                              : "Take Away"}
                          </Box>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid mt={2}>
                    <Typography sx={styles.restCuisineDescTitleText}>
                      Cuisine
                    </Typography>
                    <Box sx={styles.borderBottomLine} />
                    <Typography sx={styles.restCuisineDescText}>
                      {cuisine}
                    </Typography>
                  </Grid>
                  <Grid sx={{ marginTop: 3 }}>
                    <Typography sx={styles.restCuisineDescTitleText}>
                      Description
                    </Typography>
                    <Box sx={styles.borderBottomLine} />
                    <Typography sx={styles.restCuisineDescText}>
                      {restDesc}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item md={3.8} xs={12} sx={{ marginTop: { md: 0, xs: 3 } }}>
              <Paper elevation={0} sx={styles.rightContentGrid}>
                <Box sx={styles.mapGrid}>
                  <MapView
                    center={center}
                    zoom={15}
                    address={address}
                    restName={restName}
                    type={"info"}
                  />
                </Box>
                <Box mt={2}>
                  <Typography sx={styles.openHrText}>Opening Hours</Typography>
                  <Box sx={styles.borderBottomLine} />
                  <Box mt={1.5}>
                    {openHr
                      ? Object.keys(openHr).map((key, index) => {
                          if (
                            key === "sunday" ||
                            key === "monday" ||
                            key === "tuesday" ||
                            key === "wednesday" ||
                            key === "thursday" ||
                            key === "friday" ||
                            key === "saturday"
                          ) {
                            return (
                              <Grid
                                key={index}
                                container
                                direction={"row"}
                                mt={1}
                                justifyContent={"space-between"}
                              >
                                <Typography sx={styles.dayText}>
                                  {key}
                                </Typography>
                                {openHr[key].isOpen ? (
                                  <Typography sx={styles.openTimeText}>
                                    {moment(
                                      openHr[key].timings[0]["from"],
                                      "H:mm"
                                    ).format("h:mm A")}
                                    -
                                    {moment(
                                      openHr[key].timings[0]["to"],
                                      "H:mm"
                                    ).format("h:mm A")}
                                  </Typography>
                                ) : (
                                  <Typography sx={styles.closedText}>
                                    Closed
                                  </Typography>
                                )}
                              </Grid>
                            );
                          }
                        })
                      : null}
                  </Box>
                </Box>
                <Box mt={1}>
                  <img alt="" src={BannerImg} style={styles.imgStyle} />
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <AlertMsg
            msgAlert={msgAlert}
            onCloseAlertMsg={() =>
              setMsgAlert({ open: false, message: "", msgType: "error" })
            }
          />
        </Grid>
      )}
      <SwipeableDrawer
        anchor={"right"}
        open={drawerAddRestroOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: theme_mode.card2,
          },
        }}
      >
        <Box
          sx={{
            width: { xs: window.innerWidth, md: window.innerWidth / 1.5 },
            height: "100%",
          }}
          pt={4}
        >
          <AddRestaurant
            type={"edit"}
            resData={resData}
            categoryList={categoryList}
            drawerOpen={drawerAddRestroOpen}
            onSubmit={setRestroData}
          />
        </Box>
      </SwipeableDrawer>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantInfo);

const styles1 = (Theme) => ({
  headerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleText: {
    color: Theme.text,
    fontFamily: "InterBold",
  },
  editInfoDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Theme.btnBg,
    borderRadius: 20,
    cursor: "pointer",
    px: 1.5,
    py: 0.8,
  },
  editInfoText: {
    color: Theme.btnText,
    fontSize: 12.5,
    fontFamily: "InterSemiBold",
    marginLeft: 1,
  },
  restImg1: {
    height: { md: window.innerWidth / 4.5, xs: 150 },
    overflow: "hidden",
    borderRadius: 3,
  },
  restTwoImg1: {
    height: { md: window.innerWidth / 5, xs: 150 },
    overflow: "hidden",
    borderRadius: 3,
  },
  restSingleImg1: {
    height: { md: window.innerWidth / 3, xs: "100%" },
    overflow: "hidden",
    borderRadius: 3,
  },
  restImg2: {
    height: "48%",
    overflow: "hidden",
    borderRadius: 3,
  },
  restImg3: {
    height: "48%",
    position: "relative",
    overflow: "hidden",
    borderRadius: 3,
  },
  imgStyle: {
    height: "100%",
    width: "100%",
  },
  morePhotoGrid: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  morePhotoSubGrid: {
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  photoText: {
    color: Theme.lightText,
    fontSize: 12,
    fontFamily: "InterSemiBold",
  },
  restInfo: {
    backgroundColor: Theme.card,
    width: "100%",
    height: "100%",
    borderRadius: 3,
    px: 2,
    py: 2.5,
  },
  restTitleText: {
    color: Theme.cardText,
    fontFamily: "InterBold",
  },
  iconBox: {
    width: 42,
  },
  restAddressText: {
    color: Theme.cardDescText2,
    fontSize: 12,
    fontFamily: "InterSemiBold",
  },
  restCuisineDescTitleText: {
    color: Theme.cardText,
    fontSize: 12,
    fontFamily: "InterBold",
  },
  restCuisineDescText: {
    color: Theme.cardText,
    fontSize: 12,
    fontFamily: "InterSemiBold",
    my: 2,
  },
  borderBottomLine: {
    borderTop: `3px solid ${Theme.theme}`,
    width: 25,
    marginLeft: 0.3,
  },
  takeAwayBtn: {
    color: Theme.cardText,
    width: "fit-content",
    border: "1.4px solid " + Theme.cardText,
    borderRadius: 5,
    fontSize: 11,
    fontFamily: "InterSemiBold",
    textAlign: "center",
    px: 2,
    py: 0.2,
  },
  rightContentGrid: {
    backgroundColor: Theme.card,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    minHeight: "100%",
    borderRadius: 3,
    px: 1.5,
    pt: 1.5,
    pb: 0,
  },
  mapGrid: {
    position: "relative",
    overflow: "hidden",
    borderRadius: 3,
    width: "100%",
    height: 250,
  },
  openHrText: {
    color: Theme.cardText,
    fontSize: 12,
    fontFamily: "InterBold",
  },
  dayText: {
    color: Theme.cardText,
    fontSize: 12,
    fontFamily: "InterMedium",
    width: 100,
  },
  openTimeText: {
    color: Theme.cardText,
    fontSize: 12,
    fontFamily: "InterBold",
  },
  closedText: {
    fontSize: 12,
    fontFamily: "InterBold",
    color: Theme.redText2,
  },
  mapLocationBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    margin: 0,
  },
});
