import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import moment from "moment";

import * as Api from "../api";

import SettingTextField from "./SettingTextField";
import Loader from "./Loader";
import AlertMsg from "./AlertMsg";
import SettingDatePicker from "./SettingDatePicker";

import { THEME_MODE } from "../constants/Theme";
import {
  DINING_DETAIL_BTN_HEIGHT,
  DINING_DETAIL_CONTENT_HEIGHT,
  IS_LARGE_SCREEN,
} from "../constants";

import { ReactComponent as CalenderIcon } from "../assets/images/calender_gray.svg";
import { ReactComponent as PlusIcon } from "../assets/images/plus3.svg";
import { ReactComponent as PlusIconWhite } from "../assets/images/plus2.svg";

import styles from "./DiningDetailAssign.module.css";

let CONTENT_HEIGHT = DINING_DETAIL_CONTENT_HEIGHT;

let HEADER_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;
let BOTTOM_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT - 10;
let MARGIN = 5;
let LABEL_FNT_SIZE = IS_LARGE_SCREEN ? 17 : 12;
let INPUT_FNT_SIZE = LABEL_FNT_SIZE;

const DiningDetailAssign = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [isLoading, setIsLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [eventLists, setEventLists] = useState([]);
  const [error, setError] = useState(null);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [eventDate, setEventDate] = useState(null);
  const [eventTitle, setEventTitle] = useState("");
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  let dataViewHeight = props?.dataViewHeight
    ? props.dataViewHeight
    : CONTENT_HEIGHT - HEADER_BTN_HEIGHT - BOTTOM_BTN_HEIGHT;
  let bgColor = theme_mode.inputBg3;

  useEffect(() => {
    if (props.customer) {
      let data = props.customer;
      setFullName(data.name);
      setEmail(data.email);
      setPhone(data.phoneNumber);
      setAddress(data.address);
      if (data?.events) {
        setEventLists(data?.events);
      }
    } else {
      setFullName("");
      setEmail("");
      setPhone("");
      setAddress("");
      setEventLists([]);
      setError(null);
    }
  }, [props.customer, props.modalVisible]);

  useEffect(() => {
    if (props.customer) {
      if (props.customer?.events?.length) {
        setEventLists(props.customer.events);
      } else {
        setEventLists([]);
      }
    }
  }, [props.customer]);

  useEffect(() => {
    if (props.editCustomer && props.customer != null) {
      let data = { ...props.customer };
      if (
        data.name !== fullName ||
        data.email !== email ||
        data.phoneNumber !== phone ||
        data.address !== address
      ) {
        setSaveBtnDisabled(false);
      } else {
        setSaveBtnDisabled(true);
      }
    }
  }, [fullName, email, phone, address]);

  const onSubmit = () => {
    let err = null;
    if (props.customer == null) {
      if (fullName.trim() == "") {
        err = { fullName: "FullName is required" };
      }

      if (email.trim() == "") {
        err = { ...err, email: "Email is required" };
      } else {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(email) == false) {
          err = { ...err, email: "Invalid Email" };
        }
      }

      if (phone.trim() == "") {
        err = { ...err, phone: "Phone Number is required" };
      } else {
        if (/[^0-9]/g.test(phone)) {
          err = { ...err, phone: "Invalid Phone Number" };
        } else {
          if (phone.length != 10) {
            err = {
              ...err,
              phone: "Length 10 is required for Contact Number",
            };
          }
        }
      }

      if (address.trim() == "") {
        err = { ...err, address: "Address is required" };
      }
    }

    setError(err);
    if (err != null) {
      return;
    }

    let data = {};

    if (props.customer) {
      data = {
        ...props.customer,
      };
    }

    data = {
      ...data,
      name: fullName,
      phoneNumber: phone,
      email: email,
      address: address,
      events: eventLists,
      previousVisits: [],
    };

    if (props.customer == null) {
      addCustomer(data);
    } else {
      if (props.editCustomer) {
        addCustomer(data);
      } else {
        props.onSubmit(data);
      }
    }
  };

  const addCustomer = (data) => {
    setIsLoading(true);
    let edit_id = data?._id ? data._id : null;
    let data1 = {
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      address: data.address,
    };
    let datas = JSON.stringify(data1);
    Api.addUpdateCustomer(props.restaurantId, datas, edit_id).then(
      (response) => {
        if (response.success) {
          if (eventLists.length && edit_id == null) {
            let events = [];
            eventLists.map((x) => {
              events.push({
                _idRestaurant: props.restaurantId,
                _idRestaurantCustomer: response.data._id,
                name: x.name,
                eventDate: x.eventDate,
              });
            });
            addCustomerEvents(events, response.data);
          } else {
            if (edit_id == null) {
              props.updateRestaurantCustomerList([
                ...props.restaurantCustomerList,
                { ...response.data, events: [] },
              ]);
              props.onSubmit(response.data);
            } else {
              let customer_list = [...props.restaurantCustomerList];
              let findIndex = customer_list.findIndex((y) => y._id == edit_id);
              if (findIndex >= 0) {
                customer_list[findIndex] = {
                  ...customer_list[findIndex],
                  ...data,
                };
                props.updateRestaurantCustomerList(customer_list);
              }
              props.onSubmit(data);
            }
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      }
    );
  };

  const addEventData = () => {
    let err = null;
    if (eventDate == null) {
      err = { eventDate: "Event Date is required" };
    }

    if (eventTitle.trim() == "") {
      err = { ...err, eventTitle: "Event Title is required" };
    }

    setError(err);
    if (err != null) {
      return;
    }

    if (props.customer?._id) {
      let data1 = {
        _idRestaurant: props.restaurantId,
        _idRestaurantCustomer: props.customer._id,
        name: eventTitle,
        eventDate: eventDate,
      };

      addCustomerEvents(data1, props.customer);
    } else {
      let list = [...eventLists, { name: eventTitle, eventDate: eventDate }];
      setEventLists(list);
      setShowAddEvent(false);
      setEventDate(null);
      setEventTitle("");
    }
  };

  const addCustomerEvents = (data, customer) => {
    setIsLoading(true);
    let datas = JSON.stringify(data);
    Api.addCustomerEvents(datas, props.restaurantId).then((response) => {
      if (response.success) {
        let list = [];
        if (props.customer?._id) {
          list = [...eventLists, response.data];
          let customer_list = [...props.restaurantCustomerList];
          let findIndex = customer_list.findIndex(
            (y) => y._id == props.customer._id
          );
          if (findIndex >= 0) {
            customer_list[findIndex]["events"] = list;
            props.updateRestaurantCustomerList(customer_list);
            if (props.page == "Customers") {
              props.onSubmit(customer_list[findIndex]);
            }
          }
        } else {
          list = response.data.rows;
          props.updateRestaurantCustomerList([
            ...props.restaurantCustomerList,
            { ...customer, events: response.data.rows },
          ]);
          if (props.page == "Customers") {
            props.onSubmit(customer);
          }
        }
        setEventLists(list);
        setShowAddEvent(false);
        setEventDate(null);
        setEventTitle("");
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const onAddEvent = () => {
    setShowAddEvent(true);
  };

  return (
    <Box
      className={styles.mainView}
      mx={`${MARGIN}px`}
      style={{
        backgroundColor: theme_mode.diningContentBg,
        height:
          CONTENT_HEIGHT - props.page == "DiningDetail" ? HEADER_BTN_HEIGHT : 0,
      }}
    >
      {isLoading ? (
        <Loader height={{ height: CONTENT_HEIGHT - HEADER_BTN_HEIGHT - 5 }} />
      ) : (
        <>
          <Box
            className={styles.formView}
            px={1.5}
            pt={1}
            pb={1}
            style={{
              height: dataViewHeight,
            }}
          >
            <SettingTextField
              page={"diningDetailAssign"}
              editable={props.editCustomer}
              disabledBg={theme_mode.inputBg3}
              value={fullName}
              setValue={setFullName}
              backgroundColor={theme_mode.inputBg3}
              label={"Full Name*"}
              placeholder={""}
              variant={"filled"}
              borderRadius={"10px"}
              labelStyle={{ fontSize: LABEL_FNT_SIZE }}
              inputFontSize={INPUT_FNT_SIZE}
              error={error?.fullName}
            />
            <Box mt={1}>
              <SettingTextField
                page={"diningDetailAssign"}
                editable={props.editCustomer}
                disabledBg={theme_mode.inputBg3}
                value={email}
                setValue={setEmail}
                backgroundColor={theme_mode.inputBg3}
                label={"Email"}
                placeholder={""}
                variant={"filled"}
                type={"email"}
                borderRadius={"10px"}
                labelStyle={{ fontSize: LABEL_FNT_SIZE }}
                inputFontSize={INPUT_FNT_SIZE}
                error={error?.email}
              />
            </Box>
            <Box mt={1}>
              <SettingTextField
                page={"diningDetailAssign"}
                editable={props.editCustomer}
                disabledBg={theme_mode.inputBg3}
                value={phone}
                setValue={(value) => setPhone(value.replace(/[^0-9]/g, ""))}
                backgroundColor={theme_mode.inputBg3}
                label={"Phone Number"}
                placeholder={""}
                variant={"filled"}
                borderRadius={"10px"}
                labelStyle={{ fontSize: LABEL_FNT_SIZE }}
                inputFontSize={INPUT_FNT_SIZE}
                type={"number"}
                error={error?.phone}
              />
            </Box>
            <Box mt={1}>
              <Box mb={0.8}>
                <Typography
                  className={styles.inputLabel}
                  sx={{
                    color: theme_mode.inputLabelText,
                    fontSize: LABEL_FNT_SIZE,
                  }}
                >
                  Address
                </Typography>
              </Box>
              <TextField
                fullWidth
                disabled={!props.editCustomer}
                variant={"filled"}
                value={address}
                multiline={true}
                minRows={IS_LARGE_SCREEN ? 4 : 2}
                onChange={(e) => setAddress(e.target.value)}
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "10px",
                    py: 2,
                    px: 1,
                    "&:hover": {
                      backgroundColor: bgColor,
                    },
                    "&.Mui-focused": {
                      backgroundColor: bgColor,
                    },
                    "&.Mui-disabled": {
                      backgroundColor: bgColor,
                    },
                    "&:before, :after, :hover:not(.Mui-disabled):before": {
                      borderBottom: 0,
                    },
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: theme_mode.inputDisabledText,
                  },
                  "& .MuiInputAdornment-positionStart": {
                    margin: "0 !important",
                  },
                  "& .MuiFilledInput-input": {
                    textAlign: "left",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    mt: 0.8,
                    "& .MuiFormLabel-asterisk": {
                      display: "none",
                    },
                  },
                }}
                InputProps={{
                  autoComplete: "none",
                  sx: {
                    color: theme_mode.inputVal,
                    backgroundColor: bgColor,
                    fontFamily: "InterSemiBold",
                    fontSize: INPUT_FNT_SIZE,
                  },
                }}
              />
              <Box>
                {error?.address ? (
                  <Typography
                    className={styles.errorText}
                    sx={{ color: theme_mode.redText }}
                  >
                    {error.address}
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <Box
              mt={2}
              p={1.5}
              className={styles.eventMainView}
              sx={{ backgroundColor: theme_mode.bg8 }}
            >
              <Box className={styles.eventHeader}>
                <Typography
                  className={styles.eventHeaderText}
                  sx={{ color: theme_mode.cardText }}
                >
                  Events
                </Typography>
              </Box>
              {eventLists.map((x, i) => {
                return (
                  <Box
                    key={i}
                    mt={1}
                    py={1.5}
                    px={1}
                    className={styles.eventListMainView}
                    sx={{ backgroundColor: theme_mode.bg4 }}
                  >
                    <Box className={styles.eventListView}>
                      <Typography
                        className={styles.eventText}
                        sx={{ color: theme_mode.cardText }}
                      >
                        {x.name}
                      </Typography>
                    </Box>
                    <Box className={styles.eventDateView}>
                      <Typography
                        mr={0.5}
                        className={styles.eventDateText}
                        sx={{ color: theme_mode.cardText }}
                      >
                        {moment(x.eventDate).format("DD MMM YYYY")}
                      </Typography>
                      <CalenderIcon
                        width={IS_LARGE_SCREEN ? 20 : 14}
                        height={IS_LARGE_SCREEN ? 20 : 14}
                      />
                    </Box>
                  </Box>
                );
              })}
              {showAddEvent ? (
                <Box>
                  <Box mt={1}>
                    <Typography
                      className={styles.eventHeaderText}
                      sx={{ color: theme_mode.cardText }}
                    >
                      Add New Event
                    </Typography>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Box mr={0.5} mt={1} style={{ width: "100%" }}>
                        <SettingDatePicker
                          value={eventDate}
                          disabled={false}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setEventDate(newValue.format("YYYY-MM-DD"));
                          }}
                          inputFontSize={INPUT_FNT_SIZE}
                          placeholder={"Date"}
                          backgroundColor={theme_mode.bg4}
                          borderRadius={"10px"}
                          error={error?.eventDate}
                          leftView={null}
                        />
                      </Box>
                      <Box ml={0.5} mt={1} style={{ width: "100%" }}>
                        <SettingTextField
                          page={"diningDetailAssign"}
                          editable={true}
                          value={eventTitle}
                          setValue={setEventTitle}
                          backgroundColor={theme_mode.bg4}
                          placeholder={"Event Title"}
                          variant={"filled"}
                          borderRadius={"10px"}
                          labelStyle={{ fontSize: LABEL_FNT_SIZE }}
                          inputFontSize={INPUT_FNT_SIZE}
                          type={"text"}
                          error={error?.eventTitle}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className={styles.addEventBtn}
                    mt={1.5}
                    py={1}
                    sx={{
                      backgroundColor: theme_mode.greenBg,
                    }}
                    onClick={() => addEventData()}
                  >
                    <PlusIconWhite width={12} height={12} />
                    <Typography
                      ml={0.5}
                      className={styles.addEventBtnTxt}
                      sx={{ color: theme_mode.lightText }}
                    >
                      Add event
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  className={styles.addEventBtn}
                  mt={1.5}
                  py={1.7}
                  sx={{
                    path: {
                      fill: theme_mode.iconBg,
                      stroke: theme_mode.iconBg,
                    },
                    backgroundColor: eventLists.length
                      ? theme_mode.whiteBlack
                      : theme_mode.bg4,
                  }}
                  onClick={() => onAddEvent()}
                >
                  <PlusIcon width={12} height={12} />
                  <Typography
                    ml={0.5}
                    className={styles.addEventBtnTxt}
                    sx={{ color: theme_mode.cardText }}
                  >
                    Add an event
                  </Typography>
                </Box>
              )}
            </Box>

            {props.customer?.previousVisits?.length ? (
              <Box
                mt={2}
                p={1.5}
                className={styles.prevVisitMainView}
                sx={{ backgroundColor: theme_mode.card }}
              >
                <Box className={styles.prevVisitHeader}>
                  <Box className={styles.prevVisitTextView}>
                    <Typography
                      className={styles.prevVisitText}
                      sx={{ color: theme_mode.cardText }}
                    >
                      Previous Visits
                    </Typography>
                  </Box>
                  <Box
                    px={1}
                    py={0.3}
                    mb={2}
                    className={styles.prevVisitCountView}
                    sx={{ backgroundColor: theme_mode.theme }}
                  >
                    <Typography
                      className={styles.prevVisitCountText}
                      sx={{ color: theme_mode.cardText }}
                    >
                      {props.customer?.previousVisits.length} Visits
                    </Typography>
                    <Typography
                      ml={1.5}
                      className={styles.prevVisitSpendText}
                      sx={{ color: theme_mode.cardText }}
                    >
                      Total Spend: $
                      {props.customer.previousVisits.reduce(
                        (partialSum, a) => partialSum + a.price,
                        0
                      )}
                    </Typography>
                  </Box>
                </Box>
                {props.customer?.previousVisits.map((x, i) => {
                  return (
                    <Box
                      mt={1}
                      py={1.5}
                      px={1}
                      className={styles.prevVisitListView}
                      sx={{ backgroundColor: theme_mode.bg4 }}
                    >
                      <Box className={styles.prevVisitDateView}>
                        <Typography
                          className={styles.prevVisitDateText}
                          sx={{ color: theme_mode.cardText }}
                        >
                          {x.date}
                        </Typography>
                      </Box>
                      <Typography
                        className={styles.prevVisitPriceText}
                        sx={{ color: theme_mode.cardText }}
                      >
                        ${x.price}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </Box>
          {!saveBtnDisabled ? (
            <Box className={styles.btnView}>
              <Box
                onClick={props.onCancel}
                className={styles.submitBtn}
                style={{
                  backgroundColor: theme_mode.redBg,
                  height: BOTTOM_BTN_HEIGHT,
                }}
              >
                <Typography
                  className={styles.submitBtnText}
                  style={{ color: theme_mode.lightText }}
                >
                  CANCEL
                </Typography>
              </Box>
              <Box
                onClick={onSubmit}
                className={styles.submitBtn}
                style={{
                  backgroundColor: theme_mode.greenBg,
                  height: BOTTOM_BTN_HEIGHT,
                }}
              >
                <Typography
                  className={styles.submitBtnText}
                  style={{ color: theme_mode.lightText }}
                >
                  UPDATE
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              onClick={onSubmit}
              className={styles.submitBtn}
              style={{
                backgroundColor:
                  props.assigned != null &&
                  props.assigned?._id == props.customer?._id
                    ? theme_mode.redBg
                    : theme_mode.greenBg,
                height: BOTTOM_BTN_HEIGHT,
              }}
            >
              <Typography
                className={styles.submitBtnText}
                style={{ color: theme_mode.lightText }}
              >
                {props?.btnText
                  ? props.btnText
                  : props.assigned != null &&
                    props.assigned?._id == props.customer?._id
                  ? "REMOVE ASSIGN"
                  : props.customer == null
                  ? "ADD CUSTOMER & ASSIGN"
                  : "ASSIGN"}
              </Typography>
            </Box>
          )}
        </>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Box>
  );
};

export default DiningDetailAssign;
