import axios from "axios";
import { store } from "../redux/store";
import { logOut, updateUserToken } from "../redux/actions/userDataActions";

let apiUrl = process.env.REACT_APP_RESTAURANT_API_URL;
let apiSdkUrl = process.env.REACT_APP_SDK_API_URL;
let sdkModelApiKey = process.env.REACT_APP_SDK_MODEL_API_KEY;

function userToken() {
  const state = store.getState();
  let token = state.userData.userToken;
  return token;
}

export async function login(data, is2faLogin) {
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  if (is2faLogin) {
    return callApi("auth/v1/login", config);
  } else {
    return callApi("auth/login", config);
  }
}

export async function addRestaurant(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant", config);
}

export async function getRestaurantData(restaurantId, uToken) {
  let token = uToken ? uToken : userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
    },
  };

  return callApi("restaurant/" + restaurantId, config);
}

export async function updateRestaurant(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant/" + restaurantId, config);
}

export async function uploadImageFile(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("files/upload-image", config);
}

export async function addMenu(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu?Restaurant-ID=" + restaurantId, config);
}

export async function updateMenu(data, restaurantId, itemId) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu/" + itemId, config);
}

export async function getMenuList(restaurantId, filter, uToken) {
  let token = uToken ? uToken : userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu" + filter, config);
}

export async function getCategoryList(restaurantId, filter, uToken) {
  let token = uToken ? uToken : userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  if (filter == "all") {
    return callApi("menu/category/all", config);
  } else {
    return callApi("menu/category?" + filter, config);
  }
}

export async function addMenuCategory(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu/category", config);
}

export async function updateMenuCategory(data, restaurantId, catId) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu/category/" + catId, config);
}

export async function deleteMenu(itemId, restaurantId) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu/" + itemId, config);
}

export async function getRestaurantCategoryList() {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant/category/all", config);
}

export async function logout() {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  return callApi("auth/logout", config);
}

export async function sendEmailOtp(data) {
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return callApi("onboard/send-email-otp", config);
}

export async function userRegister(data) {
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return callApi("onboard/create-user", config);
}

export async function getNotification(restaurantId, filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };
  let endpoint = "notification";
  if (filter != "") {
    endpoint = "notification?" + filter;
  }
  return callApi(endpoint, config);
}

export async function getMyEventList(filter, restaurantId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("event/mine" + filter, config);
}

export async function menuRearrange(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu/rearrange", config);
}

export async function getOrders(restaurantId, filter) {
  // let token = userToken();
  let config = {
    method: "GET",
    headers: {
      // Authorization: 'Bearer ' + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("orders?" + filter, config);
}

export async function createOrder(data) {
  // let token = userToken();
  let config = {
    method: "POST",
    headers: {
      // Authorization: 'Bearer ' + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("orders", config);
}

export async function updateOrder(data, orderId) {
  // let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      // Authorization: 'Bearer ' + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("orders/" + orderId, config);
}

export async function createUpdateUserRoles(
  data,
  uToken,
  userRoleId,
  restaurantId
) {
  let token = uToken ? uToken : userToken();
  let config = {
    method: userRoleId ? "PUT" : "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  if (userRoleId) {
    return callApi("restaurant-user-roles/" + userRoleId, config);
  } else {
    return callApi("restaurant-user-roles", config);
  }
}

export async function addUpdateUser(data, userId) {
  let token = userToken();
  let config = {
    method: userId ? "PUT" : "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  if (userId) {
    return callApi("user/" + userId, config);
  } else {
    return callApi("user", config);
  }
}

export async function getUserRolesList(restaurantId, uToken) {
  let token = uToken ? uToken : userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant-user-roles", config);
}

export async function getUserByPin(restaurantId, pin) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("user/pin/" + pin, config);
}

export async function deleteUserRole(userRoleId, restaurantId) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant-user-roles/" + userRoleId, config);
}

export async function deleteUser(userId) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("user/" + userId, config);
}

export async function getSubscriptions() {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("subscriptions", config);
}

export async function getSubscriptionFeatures() {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("subscriptions-features", config);
}

export async function createRestaurantFloors(data, restaurantId, floorId) {
  let token = userToken();
  let config = {
    method: floorId ? "PUT" : "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  if (floorId) {
    return callApi("restaurant-floors/" + floorId, config);
  } else {
    return callApi("restaurant-floors", config);
  }
}

export async function createRestaurantTables(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant-table", config);
}

export async function updateRestaurantTables(data, id) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant-table/" + id, config);
}

export async function getRestaurantFloors(restaurantId, uToken) {
  let token = uToken ? uToken : userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant-floors", config);
}

export async function getRestaurantTables(restaurantId, filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant-table?" + filter, config);
}

export async function deleteRestaurantTables(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant-table/delete", config);
}

export async function deleteRestaurantFloor(floorId, restaurantId) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant-floors/" + floorId, config);
}

export async function getMenuData(restaurantId, itemId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu/" + itemId, config);
}

export async function getCategoryData(restaurantId, itemId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu/category/" + itemId, config);
}

export async function getCourseList(restaurantId, filter, uToken) {
  let token = uToken ? uToken : userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant-course" + filter, config);
}

export async function addUpdateCourse(data, restaurantId, courseId) {
  let token = userToken();
  let config = {
    method: courseId ? "PUT" : "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  if (courseId) {
    return callApi("restaurant-course/" + courseId, config);
  } else {
    return callApi("restaurant-course", config);
  }
}

export async function deleteCourse(restaurantId, courseId) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant-course/" + courseId, config);
}

export async function getCustomerList(restaurantId, filter, uToken) {
  let token = uToken ? uToken : userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant-customers" + filter, config);
}

export async function addUpdateCustomer(restaurantId, data, customerId) {
  let token = userToken();
  let config = {
    method: customerId ? "PUT" : "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  if (customerId) {
    return callApi("restaurant-customers/" + customerId, config);
  } else {
    return callApi("restaurant-customers", config);
  }
}

export async function getRestaurantAccountDetail(restaurantId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
    },
  };

  return callApi("restaurant-account-details", config);
}

export async function addUpdateRestaurantAccountDetail(
  data,
  restaurantId,
  detailId
) {
  let token = userToken();
  let config = {
    method: detailId ? "PUT" : "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  if (detailId) {
    return callApi("restaurant-account-details/" + detailId, config);
  } else {
    return callApi("restaurant-account-details", config);
  }
}

export async function getCustomerEvents(restaurantId, filter, uToken) {
  let token = uToken ? uToken : userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
    },
  };

  return callApi("restaurant-customers-events" + filter, config);
}

export async function addCustomerEvents(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant-customers-events", config);
}

export async function getBookingSlots(restaurantId, filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
    },
  };

  return callApi("table-booking-slots" + filter, config);
}

export async function getBookingSlotsData(restaurantId, slotId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
    },
  };

  return callApi("table-booking-slots/" + slotId, config);
}

export async function addBookingSlot(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("table-booking-slots", config);
}

export async function getTableBooking(restaurantId, filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
    },
  };

  return callApi("table-bookings" + filter, config);
}

export async function getTableBookingData(restaurantId, bookingId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
    },
  };

  return callApi("table-bookings/" + bookingId, config);
}

export async function addUpdateTableBooking(data, restaurantId, bookingId) {
  let token = userToken();
  let config = {
    method: bookingId ? "PUT" : "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  if (bookingId) {
    return callApi("table-bookings/" + bookingId, config);
  } else {
    return callApi("table-bookings", config);
  }
}

export async function addRestaurantUserCheckIn(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant-user-checkin", config);
}

export async function getPrinterList(restaurantId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant-printer", config);
}

export async function addUpdatePrinter(data, restaurantId, printerId) {
  let token = userToken();
  let config = {
    method: printerId ? "PUT" : "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  if (printerId) {
    return callApi("restaurant-printer/" + printerId, config);
  } else {
    return callApi("restaurant-printer", config);
  }
}

export async function deletePrinter(itemId, restaurantId) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant-printer/" + itemId, config);
}

export async function createBulkUpload(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("orders/create-bulk-order", config);
}

export async function deleteMenuCategory(catId, restaurantId) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu/category/" + catId, config);
}

export async function getUserEnquiries(filter) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return callApi("restaurant-enquiry" + filter, config);
}

export async function getAnalytics(data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant/analytics", config);
}

export async function getArModelData(modelId) {
  let config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sdkModelApiKey,
    },
  };

  return callSdkApi("model/" + modelId, config);
}

export async function categoryRearrange(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu/category/rearrange", config);
}

export async function getMenuModifier(filter, restaurantId) {
  let token = userToken();
  let config = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi(`menu_modifier${filter}`, config);
}

export async function createMenuModifier(restaurantId, data) {
  let token = userToken();
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu_modifier", config);
}

export async function updateMenuModifier(restaurantId, data, id) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu_modifier/" + id, config);
}

export async function deleteMenuModifier(restaurantId, id) {
  let token = userToken();
  let config = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
  };

  return callApi("menu_modifier/" + id, config);
}

export async function linkMenuModifier(restaurantId, data) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu_modifier/link", config);
}

export async function unlinkMenuModifier(restaurantId, data) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("menu_modifier/unlink", config);
}

export async function courseRearrange(data, restaurantId) {
  let token = userToken();
  let config = {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Restaurant-ID": restaurantId,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return callApi("restaurant-course/rearrange", config);
}


/**
 * Private functions
 */
async function callApi(endpoint, config) {
  const url = `${apiUrl}${endpoint}`;

  console.log("Url: ", url);
  console.log("Config: ", config);

  return axios({
    ...config,
    url: url,
  })
    .then((response) => {
      console.log("-----API RESPONSE-----", response.data);
      return response.data;
    })
    .catch(async function (error) {
      console.log(
        "There has been a problem with your fetch operation: " + error.message
      );

      if (typeof error.response !== "undefined") {
        if (typeof error.response.data !== "undefined") {
          if (error.response.data.data === "TokenExpiredError") {
            //refresh token
            return await refreshToken(endpoint, config);
          } else if (error.response.data.data === "JsonWebTokenError") {
            console.log("logout");
            await store.dispatch(logOut(""));
          } else if (
            error.response.data.code == 500 &&
            error.response.data.msg === "jwt expired"
          ) {
            console.log("logout");
            await store.dispatch(logOut(""));
          } else {
            return {
              statusCode: error.response?.data?.code,
              success: false,
              msg: error.response.data.msg,
            };
          }
        }
      }
      return {
        success: false,
        msg: "Api Error",
      };
    });
}

async function callSdkApi(endpoint, config) {
  const url = `${apiSdkUrl}${endpoint}`;

  console.log("Url: ", url);
  console.log("Config: ", config);

  return axios({
    ...config,
    url: url,
  })
    .then((response) => {
      console.log("-----API RESPONSE-----", response.data);
      return response.data;
    })
    .catch(async function (error) {
      console.log(
        "There has been a problem with your fetch operation: " + error.message
      );
      if (typeof error.response !== "undefined") {
        if (typeof error.response.data !== "undefined") {
          if (error.response.data.data === "TokenExpiredError") {
            //refresh token
            return await refreshToken(endpoint, config);
          } else if (error.response.data.data === "JsonWebTokenError") {
            console.log("logout");
            await store.dispatch(logOut(""));
          } else {
            return {
              success: false,
              msg: error.response.data.msg,
            };
          }
        }
      }
      return {
        success: false,
        msg: "Api Error",
      };
    });
}

async function refreshToken(endpoint1, config1) {
  const state = store.getState();
  let token = state.userData.refreshToken;
  let config = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };
  const url = `${apiUrl}auth/refresh`;
  return await axios({
    ...config,
    url: url,
  })
    .then(async (response) => {
      console.log("-----API RESPONSE-----", response.data);
      await store.dispatch(updateUserToken(response.data.data.token));
      config1["headers"] = {
        ...config1["headers"],
        Authorization: "Bearer " + response.data.data.token,
      };
      return await callApi(endpoint1, config1);
    })
    .catch(async function (error) {
      console.log(
        "There has been a problem with your fetch operation: " + error.message
      );
      console.log("logout");
      await store.dispatch(logOut(""));
      if (typeof error.response !== "undefined") {
        if (typeof error.response.data !== "undefined") {
          return {
            success: false,
            msg: error.response.data.msg,
          };
        }
      }
      return {
        success: false,
        msg: "Api Error",
      };
    });
}
