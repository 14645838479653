import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import SearchInput from "./SearchInput";
import Page from "./Page";

import { THEME_MODE } from "../constants/Theme";
import {
  DINING_DETAIL_BTN_HEIGHT,
  DINING_DETAIL_CONTENT_HEIGHT,
  IS_LARGE_SCREEN,
} from "../constants";

import { ReactComponent as FilterIcon } from "../assets/images/filter2.svg";

import styles from "./DiningDetailCustomer.module.css";

let BTN_HEIGHT = IS_LARGE_SCREEN ? 70 : 40;
let MARGIN = 5;

const DiningDetailCustomer = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  let CONTENT_HEIGHT = props?.contentHeight
    ? props.contentHeight
    : DINING_DETAIL_CONTENT_HEIGHT - DINING_DETAIL_BTN_HEIGHT - 5;

  return (
    <Box>
    {/*<Box style={{ width: props.leftViewWidth }}>*/}
      <Box mx={1} mt={`${MARGIN}px`}>
        <Box
          p={1}
          style={
            props?.showHeight
              ? {
                  backgroundColor: theme_mode.card2,
                  height: CONTENT_HEIGHT,
                }
              : {
                  backgroundColor: theme_mode.card2,
                }
          }
        >
          <Box mb={`${MARGIN}px`} mx={0.05} className={styles.searchAddBtnView}>
            <Box className={styles.searchView} mr={0.5}>
              <SearchInput
                value={props.searchCustomer}
                setValue={props.onSearchCustomer}
                placeholder={"Search Customer"}
                themeMode={props.themeMode}
                inputBg={theme_mode.card2}
                border={`${props.themeMode == "dark" ? 1.5 : 0}px solid ${
                  theme_mode.diningCartHeader
                }`}
                rightView={
                  <Box
                    ml={1}
                    mt={0.4}
                    sx={{ path: { stroke: theme_mode.iconBg } }}
                  >
                    <FilterIcon
                      width={IS_LARGE_SCREEN ? 30 : 14}
                      height={IS_LARGE_SCREEN ? 30 : 14}
                    />
                  </Box>
                }
              />
            </Box>
            <Box
              ml={0.5}
              className={styles.addCustomerBtn}
              sx={{
                backgroundColor: theme_mode.black,
                height: BTN_HEIGHT,
                border: `1px solid ${theme_mode.border7}`,
              }}
              onClick={props.addCustomer}
            >
              <Typography
                className={styles.addCustomerBtnTxt}
                sx={{ color: theme_mode.btnText }}
              >
                Add Customer
              </Typography>
            </Box>
          </Box>
          <Box
            className={styles.customerListView}
            style={
              props?.showHeight
                ? { height: CONTENT_HEIGHT - BTN_HEIGHT - MARGIN * 2 - 20 }
                : null
            }
          >
            {props.customers.map((x, i) => {
              return (
                <Box
                  key={i}
                  className={styles.customerView}
                  sx={{
                    backgroundColor:
                      props.selected?._id == x._id
                        ? theme_mode.theme
                        : theme_mode.card,
                  }}
                  px={1}
                  py={1}
                  mt={1}
                  mx={0.05}
                  onClick={() => props.onSelect(x)}
                >
                  <Box className={styles.customerTxtView}>
                    <Box>
                      <Typography
                        className={styles.customerTxt}
                        style={{ color: theme_mode.text }}
                      >
                        {x.name}
                      </Typography>
                    </Box>
                    {x?.previousVisits?.length ? (
                      <Box
                        px={1}
                        py={0.3}
                        mt={1}
                        className={styles.prevVisitCountView}
                        sx={{
                          backgroundColor:
                            props.selected?._id == x._id
                              ? theme_mode.card
                              : theme_mode.theme,
                        }}
                      >
                        <Typography
                          className={styles.prevVisitCountText}
                          sx={{ color: theme_mode.cardText }}
                        >
                          {x.previousVisits.length} Visits
                        </Typography>
                        <Typography
                          ml={1.5}
                          className={styles.prevVisitSpendText}
                          sx={{ color: theme_mode.cardText }}
                        >
                          Total Spend: $
                          {x.previousVisits.reduce(
                            (partialSum, a) => partialSum + a.price,
                            0
                          )}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                  <Box className={styles.customerPhNoView}>
                    <Typography
                      className={styles.customerTxt}
                      style={{ color: theme_mode.text }}
                    >
                      {x.phoneNumber}
                    </Typography>
                  </Box>
                  <Box className={styles.customerEmailView}>
                    <Typography
                      className={styles.customerTxt}
                      style={{ color: theme_mode.text }}
                    >
                      {x.email}
                    </Typography>
                  </Box>
                  <Box className={styles.customerAddressView}>
                    <Typography
                      className={styles.customerTxt}
                      style={{ color: theme_mode.text }}
                    >
                      {x.address}
                    </Typography>
                  </Box>
                </Box>
              );
            })}

            {props.totalPages > 1 ? (
              <Box mt={2}>
                <Page
                  themeMode={props.themeMode}
                  totalPages={props.totalPages}
                  pageNum={props.pageNum}
                  onSelectPage={props.onSelectPage}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DiningDetailCustomer;
