import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import moment from "moment";

import styles from "./AddBooking.module.css";

import { connect } from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import SettingTextField from "../../components/SettingTextField";
import SettingTextArea from "../../components/SettingTextArea";
import SettingDatePicker from "../../components/SettingDatePicker";
import SettingTimePicker from "../../components/SettingTimePicker";
import SearchInput from "../../components/SearchInput";
import ConfirmAlertBox from "../../components/ConfirmAlertBox";
import useDebounce from "../../components/UseDebounce";

import { THEME_MODE } from "../../constants/Theme";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import { ReactComponent as PlusBtnIcon } from "../../assets/images/plus_btn.svg";

let BTN_HEIGHT = 70;

const AddBooking = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [bookingId, setBookingId] = useState("");
  const [bookingSlotId, setBookingSlotId] = useState("");
  const [dateVal, setDateVal] = useState("");
  const [timeVal, setTimeVal] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [noOfQuests, setNoOfQuests] = useState("");
  const [address, setAddress] = useState("");
  const [occasion, setOccasion] = useState("");
  const [bookingData, setBookingData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState(null);
  const [confirmCancelBooking, setConfirmCancelBooking] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const debouncedSearch = useDebounce(searchText, 500);

  useEffect(() => {
    let path = location.pathname.split("/");
    if (path.length >= 4) {
      let page = path[3];
      setBookingSlotId(path[2]);
      if (page == "cancel") {
        setBookingId(path[4]);
        getTableBookingDate(path[4]);
      } else {
        getBookingSlotsData(path[2]);
      }
    }
  }, []);

  const getTableBookingDate = (booking_id) => {
    Api.getTableBookingData(props.restaurantId, booking_id).then((response) => {
      if (response.success) {
        let data = response.data;
        setDateVal(moment(data.bookingDate).format("DD MMM YYYY"));
        setTimeVal(
          new Date(
            moment(data.bookingTime, "HH:mm").format("YYYY-MM-DD HH:mm:ss")
          )
        );
        setName(data.name);
        setEmail(data.email);
        setPhone(data.phoneNumber);
        setNoOfQuests(data.noOfGuests);
        setAddress(data.address);
        setOccasion(data.occasion);
        setBookingData(data);
      } else {
        setMsgAlert({
          open: true,
          message: response.msg,
          msgType: "error",
        });
      }
      setIsLoading(false);
    });
  };

  const getBookingSlotsData = (slot_id) => {
    Api.getBookingSlotsData(props.restaurantId, slot_id).then((response) => {
      if (response.success) {
        let data = response.data;
        setDateVal(moment(data.bookingDate).format("DD MMM YYYY"));
        setTimeVal(
          new Date(
            moment(data.bookingTime, "HH:mm").format("YYYY-MM-DD HH:mm:ss")
          )
        );
      } else {
        setMsgAlert({
          open: true,
          message: response.msg,
          msgType: "error",
        });
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (debouncedSearch != "") {
      getTableBooking();
    } else {
      setCustomerList([]);
    }
  }, [debouncedSearch]);

  const getTableBooking = () => {
    // let filter = "?pageSize=30000&pageNum=1&filter_phoneNumber=" + searchText + "&filter_name=" + searchText;
    let filter = "?pageSize=30000&pageNum=1&filter_name=" + searchText;
    Api.getTableBooking(props.restaurantId, filter).then((response) => {
      if (response.success) {
        let rows = response.data.rows;
        setCustomerList(rows);
      }
    });
  };

  const onSubmitClick = () => {
    if (bookingId) {
      setConfirmCancelBooking(true);
      return;
    }

    let err = null;

    if (dateVal == "") {
      err = { date: "Date is required" };
    }
    if (timeVal == "") {
      err = { ...err, time: "Time is required" };
    }

    if (name.trim() == "") {
      err = { ...err, name: "Name is required" };
    }

    if (email.trim() == "") {
      err = { ...err, email: "Email is required" };
    } else {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email) == false) {
        err = { ...err, email: "Invalid Email" };
      }
    }

    if (phone.trim() == "") {
      err = { ...err, phone: "Phone Number is required" };
    } else {
      if (/[^0-9]/g.test(phone)) {
        err = { ...err, phone: "Invalid Phone Number" };
      } else {
        if (phone.length != 10) {
          err = {
            ...err,
            phone: "Length 10 is required for Phone Number",
          };
        }
      }
    }

    if (noOfQuests == "") {
      err = { ...err, noOfQuests: "No of quests is required" };
    } else {
      if (/[^0-9]/g.test(noOfQuests)) {
        err = { ...err, noOfQuests: "Invalid No of quests" };
      }
    }

    if (address.trim() == "") {
      err = { ...err, address: "Address is required" };
    }

    if (occasion.trim() == "") {
      err = { ...err, occasion: "Occasion is required" };
    }

    setError(err);
    if (err != null) {
      return;
    }

    let data = {
      _idRestaurant: props.restaurantId,
      _idTableBookingSlot: bookingSlotId,
      noOfGuests: noOfQuests,
      status: 1,
      bookingDate: moment(dateVal, "DD MMM YYYY").format("YYYY-MM-DD"),
      bookingTime: moment(timeVal, "hh:mm a").format("HH:mm"),
      name: name,
      email: email,
      phoneNumber: phone,
      address: address,
      occasion: occasion,
    };

    onAddTableBooking(data, null);
  };

  const onAddTableBooking = (data1, booking_id) => {
    setIsLoading(true);
    let datas = JSON.stringify(data1);
    Api.addUpdateTableBooking(datas, props.restaurantId, booking_id).then(
      (response) => {
        if (response.success) {
          setMsgAlert({
            open: true,
            message: booking_id
              ? "Table Booking Cancelled"
              : "Table Booking Added",
            msgType: "success",
          });
          navigate("/bookings");
        } else {
          setMsgAlert({
            open: true,
            message: response.msg,
            msgType: "error",
          });
        }
        setIsLoading(false);
      }
    );
  };

  const onSubmitCancelClick = () => {
    let data = {
      status: 0,
    };

    onAddTableBooking(data, bookingId);
  };

  return (
    <Container page={"bookings"} p={1.5} fpx={3} fpy={1}>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid
          container
          item
          xs={12}
          className={styles.mainView}
          style={{ backgroundColor: theme_mode.card2 }}
        >
          <Grid
            container
            item
            xs={12}
            p={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={12} mt={1} mb={2} className={styles.topLeftView}>
              <Box
                onClick={() => navigate("/bookings")}
                mr={2}
                className={styles.btnView}
              >
                <BackBtnIcon width={33} height={33} />
              </Box>
              {bookingId ? null : (
                <Box className={styles.searchMainView}>
                  <SearchInput
                    value={searchText}
                    setValue={setSearchText}
                    placeholder={"Search guest by name or phone"}
                    themeMode={props.themeMode}
                    inputBg={theme_mode.inputBg2}
                  />
                  {customerList.length && searchText != "" ? (
                    <Box py={1} className={styles.customerListView}>
                      {customerList.map((x, i) => {
                        return (
                          <Box key={i} className={styles.customerView}>
                            <Typography className={styles.customerText}>
                              {x.name}
                            </Typography>
                            <Typography className={styles.customerText}>
                              {x.phoneNumber}
                            </Typography>
                            <Typography className={styles.customerText}>
                              {x.email}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  ) : null}
                </Box>
              )}
              {bookingId ? null : (
                <Box
                  ml={2}
                  className={styles.btnView}
                  sx={{
                    rect: {
                      fill: props.themeMode == "dark" ? "#F17400" : "#202A38",
                    },
                  }}
                >
                  <PlusBtnIcon width={45} height={45} />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={5.8} mt={1}>
              <SettingDatePicker
                value={dateVal}
                // disabled={bookingId != ""}
                disabled={true}
                inputFormat="DD MMM YYYY"
                onChange={(newValue) => {
                  setDateVal(newValue.format("DD MMM YYYY"));
                }}
                labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
                inputFontSize={12}
                label={"Date"}
                placeholder={"Date"}
                backgroundColor={theme_mode.inputBg2}
                borderRadius={"10px"}
                error={error?.date}
                leftView={null}
              />
            </Grid>
            <Grid item xs={12} md={5.8} mt={1}>
              <SettingTimePicker
                value={timeVal}
                disabled={true}
                inputFormat="hh:mm a"
                onChange={(newValue) => {
                  setTimeVal(newValue);
                }}
                labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
                inputFontSize={12}
                label={"Time"}
                placeholder={"Time"}
                backgroundColor={theme_mode.inputBg2}
                borderRadius={"10px"}
                error={error?.time}
              />
            </Grid>
            <Grid item xs={12} md={5.8} mt={2}>
              <SettingTextField
                page={"addBooking"}
                editable={bookingId == ""}
                value={name}
                setValue={setName}
                label={"Name"}
                placeholder={"Enter Name"}
                variant={"filled"}
                backgroundColor={theme_mode.inputBg2}
                disabledBg={theme_mode.inputBg2}
                borderRadius={"10px"}
                labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
                inputFontSize={12}
                error={error?.name}
              />
            </Grid>
            <Grid item xs={12} md={5.8} mt={2}>
              <SettingTextField
                page={"addBooking"}
                editable={bookingId == ""}
                value={email}
                setValue={setEmail}
                backgroundColor={theme_mode.inputBg2}
                disabledBg={theme_mode.inputBg2}
                label={"Email"}
                placeholder={"Enter Email"}
                variant={"filled"}
                type={"email"}
                borderRadius={"10px"}
                labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
                inputFontSize={12}
                error={error?.email}
              />
            </Grid>
            <Grid item xs={12} md={5.8} mt={2}>
              <SettingTextField
                page={"addBooking"}
                editable={bookingId == ""}
                disabledBg={theme_mode.inputBg2}
                value={phone}
                setValue={(value) => setPhone(value.replace(/[^0-9]/g, ""))}
                backgroundColor={theme_mode.inputBg2}
                label={"Phone Number"}
                placeholder={"Enter Phone Number"}
                variant={"filled"}
                borderRadius={"10px"}
                labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
                inputFontSize={12}
                type={"number"}
                error={error?.phone}
              />
            </Grid>
            <Grid item xs={12} md={5.8} mt={2}>
              <SettingTextField
                page={"addBooking"}
                editable={bookingId == ""}
                disabledBg={theme_mode.inputBg2}
                value={noOfQuests}
                setValue={(value) =>
                  setNoOfQuests(value.replace(/[^0-9]/g, ""))
                }
                backgroundColor={theme_mode.inputBg2}
                label={"No of Guests"}
                placeholder={"Enter guest number"}
                variant={"filled"}
                borderRadius={"10px"}
                labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
                inputFontSize={12}
                type={"number"}
                error={error?.noOfQuests}
              />
            </Grid>
            <Grid item xs={12} md={5.8} mt={2}>
              <SettingTextArea
                page={"addBooking"}
                editable={bookingId == ""}
                value={address}
                setValue={(value) => setAddress(value)}
                minRows={3}
                backgroundColor={theme_mode.inputBg2}
                label={"Address"}
                placeholder={"Enter address"}
                variant={"filled"}
                borderRadius={"10px"}
                labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
                inputFontSize={12}
                error={error?.address}
              />
            </Grid>
            <Grid item xs={12} md={5.8} mt={2}>
              <SettingTextArea
                page={"addBooking"}
                editable={bookingId == ""}
                value={occasion}
                setValue={(value) => setOccasion(value)}
                minRows={3}
                backgroundColor={theme_mode.inputBg2}
                label={"Occasion"}
                placeholder={"Add occasion"}
                variant={"filled"}
                borderRadius={"10px"}
                labelStyle={{ fontSize: 14, fontFamily: "InterBold" }}
                inputFontSize={12}
                error={error?.occasion}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box
              onClick={() =>
                bookingData?.status == 0 ? null : onSubmitClick()
              }
              className={styles.addBtn}
              style={{
                backgroundColor: bookingId
                  ? theme_mode.redBg
                  : theme_mode.btnBg4,
                height: BTN_HEIGHT,
              }}
            >
              <Typography
                className={styles.addBtnText}
                style={{ color: theme_mode.btnText }}
              >
                {bookingId
                  ? bookingData?.status == 0
                    ? "Cancelled"
                    : "Cancel Booking"
                  : "Add New Booking"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
      <ConfirmAlertBox
        open={confirmCancelBooking}
        title={"Are you sure?"}
        description={"Are you sure you want to cancel this"}
        hightlight={"Booking?"}
        firstBtn={"No"}
        secondBtn={"Yes"}
        firstBtnBg={theme_mode.redBg}
        secondBtnBg={theme_mode.greenBg}
        firstBtnClick={() => setConfirmCancelBooking(false)}
        secondBtnClick={onSubmitCancelClick}
        onClose={() => setConfirmCancelBooking(false)}
        themeMode={props.themeMode}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    drawerOpen: state.userData.drawerOpen,
    restaurantId: state.userData.restaurantId,
  };
};

export default connect(mapStateToProps, null)(AddBooking);
