import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { connect } from "react-redux";

import { THEME_MODE } from "../constants/Theme";
import { IS_LARGE_SCREEN } from "../constants";

import styles from "./SettingFileInput.module.css";

const SettingFileInput = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const uploadFile = (event, type) => {
    let file = event.target.files[0];
    let fileSize = file.size;
    let sizeMb = (fileSize / (1024 * 1024)).toFixed(3);
    let image = URL.createObjectURL(file);
    let new_id = new Date() + type;
    let data = {
      id: new_id,
      name: file.name,
      displayName: file.name,
      size: sizeMb,
      image: image,
      upload: false,
    };
    props.uploadFile(file, data, type);
  };

  return (
    <Box style={{ width: "100%" }}>
      <Box
        className={styles.mainView}
        style={{
          backgroundColor: theme_mode.inputBg,
        }}
      >
        <input
          id={props.id}
          multiple={false}
          type="file"
          className={styles.inputFile}
          onChange={(e) => {
            e.stopPropagation();
            uploadFile(e, props.type);
          }}
        />
        <label
          htmlFor={props.id}
          className={styles.label}
          style={{
            backgroundColor: theme_mode.inputBg,
          }}
        >
          <Box px={2} className={styles.uploadView}>
            <Typography
              className={styles.uploadText}
              style={{
                color: theme_mode.inputVal,
                fontSize: IS_LARGE_SCREEN ? 20 : 15,
                opacity: props?.value ? 1 : 0.5,
              }}
            >
              {props?.value ? props.value : "Upload File"}
            </Typography>
          </Box>
        </label>
        {props?.value ? (
          <Typography
            style={{
              color: theme_mode.inputVal,
              fontSize: IS_LARGE_SCREEN ? 22 : 17,
            }}
            className={styles.close}
            onClick={(e) => {
              e.stopPropagation();
              props.onRemove();
            }}
          >
            x
          </Typography>
        ) : null}
      </Box>
      <Box>
        {props?.error ? (
          <Typography
            className={styles.errorText}
            sx={{ color: theme_mode.redText }}
          >
            {props.error}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(SettingFileInput);
