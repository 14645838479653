export const getModifierTotalPrice = (modifiers, modList) => {
  let menuTotal = 0;
  modifiers
    ?.filter((item) => modList?.map((x) => x?._idModifier)?.includes(item))
    ?.map((item) => {
      const objMax = modList
        ?.filter((item1) => item == item1?._idModifier)
        ?.reduce(
          (max, curren) =>
            max?.price?.value > curren?.price?.value ? max : curren,
          null
        );
      menuTotal += objMax?.price?.value ?? 0;
    });
  modList
    ?.filter((item) => !modifiers.includes(item?._idModifier))
    ?.map((item) => {
      menuTotal += item?.price?.value ?? 0;
    });
  return menuTotal;
};

export const onSetCartModifierData = (modifiers, menuList) => {
  return modifiers?.map((mod_list1) => {
    if (isObject(mod_list1)) {
      const modifier = mod_list1;
      const menuItem = menuList.find(
        (menu) => menu?._id === modifier?._idMenuItem
      );
      return { ...modifier, menuData: { ...menuItem, price: modifier.price } };
    } else {
      const parsedModifiers = Array.isArray(mod_list1)
        ? mod_list1
        : JSON.parse(mod_list1);
      return parsedModifiers.map((modifier) => {
        const menuItem = menuList.find(
          (menu) => menu?._id === modifier?._idMenuItem
        );
        return {
          ...modifier,
          menuData: { ...menuItem, price: modifier.price },
        };
      });
    }
  });
};

export const getCartItemsTotalPrice = (item, menuList) => {
  const qty = item?.quantity ? item?.quantity : 1;

  let itemTotal = item.price.value * qty;

  const modifiers = (
    item?.cartModifier && item?.modifiers
      ? item?.modifiers
      : menuList?.find((y) => y?._id == item?._idMenuItem)?.modifiers ?? []
  )
    ?.filter((x) => !x.addUpPrices)
    ?.map((x) => x?._id);

  const cartModList = item?.cartModifier ?? item?.modifiers;

  if (cartModList.length && isObject(cartModList?.[0])) {
    itemTotal += getModifierTotalPrice(modifiers, cartModList) * qty;
  } else {
    cartModList?.forEach((x1) => {
      let modifier = Array.isArray(x1) ? x1 : JSON.parse(x1);
      itemTotal += getModifierTotalPrice(modifiers, modifier);
    });
  }

  return itemTotal;
};

export const getOrdersTotalPrice = (cartItems, menuList) => {
  let total = 0;
  cartItems.forEach((item) => {
    total += getCartItemsTotalPrice(item, menuList);
  });
  return total;
};

export const isObject = (item) => {
  return typeof item === "object" && !Array.isArray(item) && item !== null;
};
