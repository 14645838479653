import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { THEME_COLOR } from "../constants";
import { THEME_MODE } from "../constants/Theme";

const theme = createTheme({
  palette: {
    white: {
      main: "#fff",
    },
  },
});

const textInputCss = {
  "& .MuiInput-underline:before": {
    borderBottom: `1.5px solid #EEE`,
  },
  "& .MuiInput-underline:after": {
    borderBottom: `1.5px solid #EEE`,
  },
  "& .MuiInput-underline:hover:before": {
    borderBottom: 0,
  },
};

const OtpVerify = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [otp, setOtp] = useState("");

  const onOtpVerify = (e) => {
    e.preventDefault();
    let data = {
      otp: otp,
    };
    props.onVerifyRegister(data);
  };

  return (
    <Grid component="form" onSubmit={onOtpVerify} style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <TextField
          id="otp"
          color="white"
          type="text"
          placeholder="OTP"
          focused
          fullWidth
          required
          value={otp}
          onChange={(e) => setOtp(e.target.value.replace(/[^0-9]/g, ""))}
          sx={textInputCss}
          InputProps={{
            autoComplete: "none",
            sx: {
              mb: 2,
              px: 1,
              py: 1,
              fontSize: { xs: 14, md: 12 },
              fontFamily: "InterSemiBold",
              color: theme_mode.text,
              ...(props?.inputStyle ? props.inputStyle : null),
            },
          }}
          variant="standard"
        />
      </ThemeProvider>
      <Typography sx={styles.msgAlertText}>{props.msgAlert}</Typography>
      <Grid container justifyContent="center" mt={4}>
        <Button
          type="submit"
          variant="contained"
          disabled={props.btnDisabled}
          sx={{
            ...styles.sendOtpBtn,
            ...(props?.btnStyle ? props.btnStyle : null),
          }}
          fullWidth
        >
          {props.btnDisabled
            ? "Please Wait"
            : props.pageType == "loginOtpVerify"
              ? "Verify & Login"
              : "Verify & Register"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default OtpVerify;

const styles1 = (Theme) => ({
  msgAlertText: {
    fontFamily: "InterSemiBold",
    fontSize: { xs: 12.5, md: 10 },
    textAlign: "center",
    color: "#900707",
    lineHeight: 1,
    marginTop: 2,
  },
  sendOtpBtn: {
    backgroundColor: Theme.theme,
    color: Theme.text,
    fontFamily: "InterBold",
    textTransform: "capitalize",
    borderRadius: 2,
    marginTop: 2,
    boxShadow: "none",
    width: "60%",
    fontSize: { xs: 14, md: 11 },
    "&:hover": {
      backgroundColor: Theme.theme + " !important",
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      backgroundColor: Theme.theme + " !important",
      boxShadow: "none",
    },
  },
});
