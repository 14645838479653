import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

import { THEME_COLOR } from "../constants";

import { ReactComponent as Email } from "../assets/images/email.svg";
import { ReactComponent as Lock } from "../assets/images/lock.svg";
import { ReactComponent as User } from "../assets/images/user.svg";
import { THEME_MODE } from "../constants/Theme";

const theme = createTheme({
  palette: {
    white: {
      main: "#fff",
    },
  },
});

const textInputCss = {
  "& .MuiInput-underline:before": {
    borderBottom: `1.5px solid #EEE`,
  },
  "& .MuiInput-underline:after": {
    borderBottom: `1.5px solid #EEE`,
  },
  "& .MuiInput-underline:hover:before": {
    borderBottom: 0,
  },
};

const UserRegister = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [pswd, setPswd] = useState("");
  const [confirmPswd, setConfirmPswd] = useState("");
  const [termsPrivacy, setTermsPrivacy] = useState(false);

  const onSendOtp = (e) => {
    e.preventDefault();
    let data = {
      fullName: fullName,
      email: email,
      password: pswd,
      confirmPassword: confirmPswd,
      termsPrivacy: termsPrivacy,
    };
    props.onSendOtp(data);
  };

  return (
    <Grid component="form" onSubmit={onSendOtp}>
      <ThemeProvider theme={theme}>
        <TextField
          id="fullName"
          color="white"
          type="text"
          placeholder="Full Name"
          focused
          fullWidth
          required
          onChange={(e) => setFullName(e.target.value)}
          sx={textInputCss}
          InputProps={{
            autoComplete: "none",
            sx: {
              px: 1,
              py: 1,
              fontSize: { xs: 14, md: 12 },
              fontFamily: "InterSemiBold",
              color: theme_mode.text,
              ...(props?.inputStyle ? props.inputStyle : null),
            },
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ ...styles.iconPos, path: { fill: theme_mode.iconBg } }}
              >
                <User height={20} width={20} />
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: 10 }}
          variant="standard"
        />
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <TextField
          id="email"
          color="white"
          type="email"
          placeholder="Email"
          focused
          fullWidth
          required
          onChange={(e) => setEmail(e.target.value)}
          sx={textInputCss}
          InputProps={{
            autoComplete: "none",
            sx: {
              px: 1,
              py: 1,
              fontSize: { xs: 14, md: 12 },
              fontFamily: "InterSemiBold",
              color: theme_mode.text,
              ...(props?.inputStyle ? props.inputStyle : null),
            },
            startAdornment: (
              <InputAdornment position="start" sx={styles.iconPos}>
                <Email height={20} width={20} />
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: 10 }}
          variant="standard"
        />
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <TextField
          id="password"
          type="password"
          color="white"
          placeholder="Password"
          focused
          fullWidth
          required
          onChange={(e) => setPswd(e.target.value)}
          sx={textInputCss}
          InputProps={{
            autoComplete: "none",
            sx: {
              px: 1,
              py: 1,
              fontSize: { xs: 14, md: 12 },
              fontFamily: "InterSemiBold",
              color: theme_mode.text,
              ...(props?.inputStyle ? props.inputStyle : null),
            },
            startAdornment: (
              <InputAdornment position="start" sx={styles.iconPos}>
                <Lock height={20} width={20} />
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: 10 }}
          variant="standard"
        />
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <TextField
          id="confirmPassword"
          type="password"
          color="white"
          placeholder="Confirm Password"
          focused
          fullWidth
          required
          onChange={(e) => setConfirmPswd(e.target.value)}
          sx={textInputCss}
          InputProps={{
            autoComplete: "none",
            sx: {
              px: 1,
              py: 1,
              fontSize: { xs: 14, md: 12 },
              fontFamily: "InterSemiBold",
              color: theme_mode.text,
              ...(props?.inputStyle ? props.inputStyle : null),
            },
            startAdornment: (
              <InputAdornment position="start" sx={styles.iconPos}>
                <Lock height={20} width={20} />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </ThemeProvider>
      <Typography sx={styles.msgAlertText}>{props.msgAlert}</Typography>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        xs={12}
      >
        <Grid
          onClick={() => setTermsPrivacy(!termsPrivacy)}
          item
          xs={12}
          sx={{ pl: { xs: 0, md: 1 } }}
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={1} container direction="row">
            <Checkbox
              checked={termsPrivacy}
              onChange={() => setTermsPrivacy(!termsPrivacy)}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 15 },
                color: theme_mode.text,
                "&.Mui-checked": {
                  color: theme_mode.theme,
                },
              }}
            />
          </Grid>
          <Grid item xs={10} container direction="row" ml={1.5}>
            <Typography
              sx={{
                ...styles.agreeTermText,
                ...(props?.agreeTermStyle ? props.agreeTermStyle : null),
              }}
            >
              I agree to all the
            </Typography>
            <Typography
              sx={{
                ...styles.termPrivacyText,
                ...(props?.agreeTermStyle ? props.agreeTermStyle : null),
              }}
            >
              {" "}
              Term and Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Button
          type="button"
          variant="contained"
          onClick={props.onLogin}
          disabled={props.btnDisabled}
          sx={{
            ...styles.loginBtn,
            ...(props?.loginBtnStyle ? props.loginBtnStyle : null),
          }}
          fullWidth
        >
          Login Now
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={props.btnDisabled}
          sx={{
            ...styles.signUpBtn,
            ...(props?.registerBtnStyle ? props.registerBtnStyle : null),
          }}
          fullWidth
        >
          {props.btnDisabled ? "Please Wait" : "Sign Up"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserRegister;

const styles1 = (Theme) => ({
  iconPos: {
    paddingRight: 2,
    path: {
      stroke: Theme.iconBg,
    },
  },
  msgAlertText: {
    fontFamily: "InterSemiBold",
    fontSize: { xs: 12.5, md: 10 },
    textAlign: "center",
    color: "#900707",
    lineHeight: 1,
    marginTop: 2,
  },
  agreeTermText: {
    color: "#AAA",
    fontSize: { xs: 12, md: 9.5 },
    fontFamily: "InterSemiBold",
    cursor: "pointer",
    mr: 0.5,
  },
  termPrivacyText: {
    color: Theme.text,
    fontSize: { xs: 12, md: 9.5 },
    fontFamily: "InterSemiBold",
    cursor: "pointer",
  },
  loginBtn: {
    backgroundColor: Theme.whiteBlack,
    border: "1.5px solid " + Theme.btnBg,
    color: Theme.text,
    fontFamily: "InterBold",
    textTransform: "capitalize",
    borderRadius: 2,
    marginTop: 2,
    width: "45%",
    boxShadow: "none",
    fontSize: { xs: 14, md: 11 },
    "&:hover": {
      backgroundColor: Theme.theme + " !important",
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      backgroundColor: Theme.theme + " !important",
      boxShadow: "none",
    },
  },
  signUpBtn: {
    backgroundColor: Theme.theme,
    color: Theme.text,
    fontFamily: "InterBold",
    textTransform: "capitalize",
    borderRadius: 2,
    marginTop: 2,
    width: "45%",
    boxShadow: "none",
    fontSize: { xs: 14, md: 11 },
    "&:hover": {
      backgroundColor: Theme.theme + " !important",
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      backgroundColor: Theme.theme + " !important",
      boxShadow: "none",
    },
  },
});
