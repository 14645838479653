import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import moment from "moment";

import AddRestaurantInfo from "./AddRestaurantInfo";
import AddRestaurantImagesUpload from "./AddRestaurantImagesUpload";
import AddRestaurantLocation from "./AddRestaurantLocation";
import AddRestaurantOpeningHours from "./AddRestaurantOpeningHours";
import Loader from "./Loader";
import AlertMsg from "./AlertMsg";
import getUserLocation from "../functions/getUserLocation";

import { connect } from "react-redux";
import {
  updatePinLogin,
  updateRestaurantDetail,
  updateRestaurantId,
  updateRestaurantImg,
  updateUserRole,
} from "../../src/redux/actions/userDataActions";

import * as Api from "../../src/api";

import "../../src/assets/css/custom.css";

import { APP_TYPE_FOODO, RESTAURANT_ADMIN_ROLE_ID } from "../../src/constants";
import { THEME_MODE } from "../constants/Theme";

const steps = ["Restaurant Info", "Images Upload", "Location", "Opening Hours"];

export const allDays = "all days";

const daysList = [
  allDays,
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const openTime = new Date(
  moment("10:00", "H:mm").format("YYYY-MM-DD HH:mm:ss")
);
const closeTime = new Date(
  moment("22:00", "H:mm").format("YYYY-MM-DD HH:mm:ss")
);

const openHour = daysList.map((day) => {
  return {
    day: day,
    active: true,
    open: openTime,
    close: closeTime,
  };
});

const AddRestaurant = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  const [loading, setLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [phCountryCode, setPhCountryCode] = useState("");
  const [desc, setDesc] = useState("");
  const [category, setCategory] = useState("");
  const [option, setOption] = useState("dine_in");
  const [image, setImage] = useState([]);
  const [logoImage, setLogoImage] = useState("");
  const [firstLine, setFirstLine] = useState("");
  const [secondLine, setSecondLine] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [vatVal, setVatVal] = useState(0);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  const navigate = useNavigate();

  const [openingHourList, setOpeningHourList] = useState(
    props.type == "edit" ? [] : openHour
  );

  useEffect(() => {
    if (props.type === "edit") {
      setActiveTab(0);

      let resData = props.resData;
      if (resData) {
        setName(resData.name);
        setEmail(resData.email);
        let contactNumber = resData.mobile;
        setMobile(contactNumber);
        setPhCountryCode(resData.country);
        setDesc(resData.description);
        setCategory(resData.category);
        setVatVal(resData?.vat ?? 0);

        let options = "";
        if (resData.options.hasDineIn && resData.options.hasTakeAway) {
          options = "both";
        } else if (resData.options.hasDineIn && !resData.options.hasTakeAway) {
          options = "dine_in";
        } else if (resData.options.hasTakeAway && !resData.options.hasDineIn) {
          options = "take_away";
        }
        setOption(options);

        setImage(
          resData.images.map((x) => {
            let index = x.lastIndexOf("/") + 1;
            let filename = x.substr(index);
            return {
              id: new Date(),
              name: filename,
              displayName: filename,
              size: 0,
              image: x,
              upload: true,
            };
          })
        );

        setFirstLine(resData.address.firstLine);
        setSecondLine(resData.address.secondLine);
        setPostcode(resData.address.postCode);
        setCity(resData.address.city);
        setCountry(resData.address.countryCode);
        setLat(resData.address.coords[0]);
        setLng(resData.address.coords[1]);

        let res_logo = resData.logo;
        let index1 = res_logo.lastIndexOf("/") + 1;
        let filename1 = res_logo.substr(index1);
        setLogoImage({
          id: new Date(),
          name: filename1,
          displayName: filename1,
          size: 0,
          image: res_logo,
          upload: true,
        });

        const monTime = resData?.openHours?.["monday"]?.timings?.[0];

        const openingHrList = daysList.map(function (day) {
          const workHr = resData?.openHours?.[day];
          let openHrTime = openTime;
          let closeHrTime = closeTime;
          if (workHr?.isOpen || (day === allDays && monTime)) {
            const { from, to } =
              day === allDays ? monTime : workHr?.timings?.[0];
            openHrTime = new Date(
              moment(from, "H:mm").format("YYYY-MM-DD HH:mm:ss")
            );
            closeHrTime = new Date(
              moment(to, "H:mm").format("YYYY-MM-DD HH:mm:ss")
            );
          }
          return {
            day: day,
            active: workHr?.isOpen || day === allDays,
            open: openHrTime,
            close: closeHrTime,
          };
        });

        setOpeningHourList(openingHrList);
      }
    }
  }, [props.resData, props.drawerOpen]);

  useEffect(() => {
    if (typeof props?.categoryList == "undefined") {
      getRestaurantCategoryList();
    } else {
      setCategoryList(props?.categoryList);
      setLoading(false);
    }
  }, [props?.categoryList]);

  useEffect(() => {
    if (props.type === "add") {
      getCurrentLocation();
    }
  }, []);

  const getCurrentLocation = () => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
      setMsgAlert({
        open: true,
        message: "Geolocation is not supported by your browser",
        msgType: "error",
      });
    } else {
      // setIsLoading(true);
      console.log("Locating...");
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);
          setPhCountryCode("GB");
          let result = await getUserLocation(position.coords);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          if (result.success) {
            setPostcode(result?.data?.pincode);
            setCity(result?.data?.city);
            setCountry(result?.data?.country);
            if(result?.data?.countryCode) {
              setPhCountryCode(result?.data?.countryCode);
            }
          } else {
            setMsgAlert({
              open: true,
              message: result.msg,
              msgType: "error",
            });
          }
        },
        () => {
          // setIsLoading(false);
          console.log("Unable to retrieve your location");
          setMsgAlert({
            open: true,
            message: "Unable to retrieve your location",
            msgType: "error",
          });
        }
      );
    }
  };

  const getRestaurantCategoryList = () => {
    Api.getRestaurantCategoryList().then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setCategoryList(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const resInfo = (data) => {
    setName(data.name);
    setEmail(data.email);
    setMobile(data.mobile);
    setPhCountryCode(data.countryCode);
    setDesc(data.desc);
    setCategory(data.category);
    setVatVal(data.vat);
    setOption(data.option);
    setActiveTab(1);
  };

  const resImageUpload = (data, logo, type) => {
    if (type == "prev") {
      setActiveTab(0);
    } else {
      setImage(data);
      setLogoImage(logo);
      setActiveTab(2);
    }
  };

  const resLocation = (data, type) => {
    if (type == "prev") {
      setActiveTab(1);
    } else {
      setFirstLine(data.firstLine);
      setSecondLine(data.secondLine);
      setPostcode(data.postcode);
      setCity(data.city);
      setCountry(data.country);
      setLat(data.lat);
      setLng(data.lng);
      setActiveTab(3);
    }
  };

  const resOpeningHours = (data, type) => {
    if (type == "prev") {
      setActiveTab(2);
    } else {
      setOpeningHourList(data);

      let imageData = [];
      let imageUrlList = [];
      image.forEach((item, index) => {
        imageData.push(item.name);
        imageUrlList.push(item.image);
      });

      let openHourData = [];
      data.forEach((item, index) => {
        if (item.active) {
          openHourData[item.day] = {
            isOpen: true,
            timings: [
              {
                from: moment(item.open).format("H:mm"),
                to: moment(item.close).format("H:mm"),
              },
            ],
          };
        } else {
          openHourData[item.day] = {
            isOpen: false,
            timings: [],
          };
        }
      });

      let data1 = {
        name: name,
        contactNumber: mobile,
        country: phCountryCode,
        _idCategory: category,
        description: desc,
        vat: vatVal ? vatVal : 0,
        options: {
          hasDineIn: option == "dine_in" || option == "both",
          hasTakeAway: option == "take_away" || option == "both",
        },
        address: {
          firstLine: firstLine,
          secondLine: secondLine,
          city: city,
          countryCode: country,
          postCode: postcode,
          coords: [lat, lng],
        },
        openHours: {
          sunday: openHourData["sunday"],
          monday: openHourData["monday"],
          tuesday: openHourData["tuesday"],
          wednesday: openHourData["wednesday"],
          thursday: openHourData["thursday"],
          friday: openHourData["friday"],
          saturday: openHourData["saturday"],
        },
        images: imageData,
        logo: logoImage.name,
      };

      if (props.type == "add") {
        data1 = {
          ...data1,
          email: email,
          appType: APP_TYPE_FOODO,
        };
      }
      let datas = JSON.stringify(data1);

      setCardLoading(true);
      if (props.type === "edit") {
        Api.updateRestaurant(datas, props.restaurantId).then((response) => {
          if (response.success) {
            setMsgAlert({
              open: true,
              message: response.msg,
              msgType: "success",
            });
            const resData = {
              ...props.restaurantDetail,
              ...data1,
              images: imageUrlList,
              logo: logoImage.image,
            };

            if (imageUrlList.length > 0) {
              props.updateRestaurantImg(imageUrlList[0]);
            }

            props.updateRestaurantDetail(resData);
            setCardLoading(false);
            setActiveTab(0);
            props.onSubmit(resData);
          } else {
            setCardLoading(false);
            setMsgAlert({
              open: true,
              message: response.msg,
              msgType: "error",
            });
          }
        });
      } else {
        Api.addRestaurant(datas).then((response) => {
          if (response.success) {
            setMsgAlert({
              open: true,
              message: response.msg,
              msgType: "success",
            });
            getUserRolesList(response.data);
          } else {
            setCardLoading(false);
            setMsgAlert({
              open: true,
              message: response.msg,
              msgType: "error",
            });
          }
        });
      }
    }
  };

  const getUserRolesList = (restaurant) => {
    let restaurantId = restaurant._id;
    Api.getUserRolesList(restaurantId, null).then((response) => {
      if (response.success) {
        let data1 = response.data;
        let list = data1.rows.filter((y) => y._idUser == props.userId);
        if (list.length) {
          let role_id = list[0]._id;
          createUserRoles(restaurant, role_id);
        } else {
          createUserRoles(restaurant, null);
        }
      } else {
        setCardLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const createUserRoles = (restaurant, userRoleId) => {
    let restaurantId = restaurant._id;
    let param = {
      _idUser: props.userId,
      _idRestaurant: restaurantId,
      _idUserRoles: RESTAURANT_ADMIN_ROLE_ID,
    };
    let data = JSON.stringify(param);
    Api.createUpdateUserRoles(data, null, userRoleId, restaurantId).then(
      (response) => {
        if (response.success) {
          if (restaurant.images.length) {
            props.updateRestaurantImg(restaurant.images[0]);
          }
          props.updateRestaurantId(restaurantId);
          props.updateRestaurantDetail(restaurant);
          let pin_data = {
            pinLogin: true,
            pinUserId: "",
            userRoleId: userRoleId ? userRoleId : response.data._id,
            userRole: RESTAURANT_ADMIN_ROLE_ID,
            userDetail: props.userDetail,
          };
          props.updatePinLogin(pin_data);
          navigate("/restaurant_info");
        } else {
          setCardLoading(false);
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
      }
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Grid>
          {props.type == "add" ? (
            <Typography variant={"h6"} sx={styles.restaurantInfo}>
              Restaurant Info
            </Typography>
          ) : null}
          <Grid container alignItems="center" justifyContent="center" mt={3}>
            {props.type == "edit" ? (
              <Grid item xs={12} px={7}>
                <Typography variant={"h6"} sx={styles.restaurantInfo}>
                  Edit Info
                </Typography>
              </Grid>
            ) : null}
            <Card
              elevation={0}
              className="add-card"
              sx={{ backgroundColor: theme_mode.card2 }}
            >
              <Box
                sx={{ width: "80%" }}
                mt={props.type == "edit" ? 1 : 5}
                mb={5}
              >
                <Stepper activeStep={activeTab} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label} sx={styles.mainStep}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              {activeTab == 0 ? (
                <Grid
                  container
                  direction="column"
                  style={{ width: props.type == "edit" ? "90%" : "75%" }}
                  mt={4}
                >
                  <AddRestaurantInfo
                    type={props.type}
                    name={name}
                    email={email}
                    desc={desc}
                    mobile={mobile}
                    countryCode={phCountryCode}
                    category={category}
                    vat={vatVal}
                    option={option}
                    categoryList={categoryList}
                    themeMode={props.themeMode}
                    resInfo={resInfo}
                  />
                </Grid>
              ) : activeTab == 1 ? (
                <Grid
                  container
                  direction="column"
                  style={{ width: props.type == "edit" ? "90%" : "75%" }}
                  mt={4}
                >
                  <AddRestaurantImagesUpload
                    addEdit={props.type}
                    image={image}
                    logoImage={logoImage}
                    themeMode={props.themeMode}
                    resImageUpload={resImageUpload}
                  />
                </Grid>
              ) : activeTab == 2 ? (
                <Grid
                  container
                  direction="column"
                  style={{ width: props.type == "edit" ? "90%" : "75%" }}
                  mt={4}
                >
                  <AddRestaurantLocation
                    firstLine={firstLine}
                    secondLine={secondLine}
                    postcode={postcode}
                    city={city}
                    country={country}
                    lat={lat}
                    lng={lng}
                    resLocation={resLocation}
                    themeMode={props.themeMode}
                  />
                </Grid>
              ) : activeTab == 3 ? (
                cardLoading ? (
                  <Loader />
                ) : (
                  <Grid
                    container
                    direction="column"
                    style={{ width: props.type == "edit" ? "90%" : "75%" }}
                  >
                    <AddRestaurantOpeningHours
                      openingHourList={openingHourList}
                      resOpeningHours={resOpeningHours}
                      themeMode={props.themeMode}
                    />
                  </Grid>
                )
              ) : null}
            </Card>
          </Grid>

          <AlertMsg
            msgAlert={msgAlert}
            onCloseAlertMsg={() =>
              setMsgAlert({ open: false, message: "", msgType: "error" })
            }
          />
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    userId: state.userData.userId,
    userRoleId: state.userData.userRoleId,
    themeMode: state.userData.themeMode,
    restaurantDetail: state.userData.restaurantDetail,
    userDetail: state.userData.userDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantId: (data) => dispatch(updateRestaurantId(data)),
    updateRestaurantImg: (data) => dispatch(updateRestaurantImg(data)),
    updateUserRole: (data) => dispatch(updateUserRole(data)),
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
    updatePinLogin: (data) => dispatch(updatePinLogin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRestaurant);

const styles1 = (Theme) => ({
  restaurantInfo: {
    fontFamily: "InterBold",
    color: Theme.text,
  },
  mainStep: {
    "& .MuiStepLabel-label": {
      color: "#AAAAAA",
      fontFamily: "InterSemiBold",
    },
    "& .MuiStepLabel-root .Mui-active": {
      color: Theme.theme, // circle color (ACTIVE)
      fontFamily: "InterBold",
    },
    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
      color: Theme.text, // Just text label (ACTIVE)
      fontFamily: "InterBold",
    },
    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
      fill: Theme.text, // circle's number (ACTIVE)
      fontFamily: "InterBold",
    },
    "& .MuiStepLabel-root .Mui-completed": {
      color: Theme.theme, // circle color (COMPLETED)
      fontFamily: "InterBold",
    },
    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
      color: Theme.text, // Just text label (COMPLETED)
      fontFamily: "InterSemiBold",
    },
    "& .MuiStepLabel-root .Mui-completed .MuiStepIcon-text": {
      fill: Theme.text, // circle's number (COMPLETED)
      fontFamily: "InterBold",
    },
  },
  btnText: {
    backgroundColor: Theme.theme,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontFamily: "InterBold",
    fontSize: 13,
    borderRadius: 1,
    width: 100,
    height: 35,
  },
});
