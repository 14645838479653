import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { updateRestaurantCategoryList } from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import EditCategoryOrder from "../../components/EditCategoryOrder";

import { THEME_MODE } from "../../constants/Theme";
import { FOOTER_XPADDING } from "../../constants";

const CategoryReorder = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [catList, setCatList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getCategoryList();
  }, []);

  const getCategoryList = () => {
    setIsLoading(true);
    let filter = "all";
    Api.getCategoryList(props.restaurantId, filter).then((response) => {
      if (response.success) {
        const data = response.data.rows.sort((a, b) => a?.order - b?.order);
        props.updateRestaurantCategoryList(data);
        setCatList(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Container
      page={"settings"}
      bgColor={theme_mode.bg2}
      p={1.5}
      fpx={FOOTER_XPADDING[props.themeMode]}
      fpy={0}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Grid
          container
          item
          xs={12}
          p={1.5}
          style={{ backgroundColor: theme_mode.card2, borderRadius: 15 }}
        >
          <Grid item xs={12} md={6} pb={2}>
            <EditCategoryOrder
              themeMode={props.themeMode}
              restaurantId={props.restaurantId}
              categoryList={catList}
              setMsgAlert={setMsgAlert}
              onCancel={() => navigate("/settings/category")}
              onSubmit={() => navigate("/settings/category")}
            />
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantCategoryList: (data) =>
      dispatch(updateRestaurantCategoryList(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantId: state.userData.restaurantId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryReorder);
