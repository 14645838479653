import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import DiningDetailCartItem from "./DiningDetailCartItem";

import { THEME_MODE } from "../constants/Theme";
import {
  CURRENCY_SYMBOL,
  DINING_DETAIL_BTN_HEIGHT,
  DINING_DETAIL_CONTENT_HEIGHT,
  IS_LARGE_SCREEN,
} from "../constants";

import styles from "./DiningDetailCart.module.css";

let CONTENT_HEIGHT = DINING_DETAIL_CONTENT_HEIGHT;
let HEADER_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;
let BOTTOM_BTN_HEIGHT = DINING_DETAIL_BTN_HEIGHT;

const DiningDetailCart = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [priceHeight, setPriceHeight] = useState(0);
  const [cartItemLength, setCartItemLength] = useState(0);

  let { cartItems, currencyVal, totalPrice } = props;

  let BTN_FONT_SIZE = IS_LARGE_SCREEN ? 23 : 18;
  let addCourseList = cartItems
    .map((x) => {
      return {
        _id: x._idRestaurantCourse,
        name: x?.restaurantCourseName ? x.restaurantCourseName : null,
      };
    })
    .filter((x, i, a) => a.map((y) => y._id).indexOf(x._id) == i);

  useEffect(() => {
    if (document.getElementById("priceValViewId")) {
      setPriceHeight(document.getElementById("priceValViewId").offsetHeight);
    }
  }, [props.cartItems]);

  useEffect(() => {
    if (props.cartItems.length > cartItemLength) {
      let sectionRight = document.querySelector(
        "#cartListSourceId" + props.selectedCourse?._id
      );
      if (sectionRight) {
        sectionRight.scrollIntoView({
          behavior: "auto",
          block: "end",
          inline: "nearest",
        });
      }
    }
    setCartItemLength(props.cartItems.length);
  }, [props.cartItems]);

  const onSendCheckout = () => {
    props.onCheckOut();
  };

  const onCourseAwayClick = (course) => {
    props.onCourseAway(course);
  };

  const equalsCheck = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  return (
    <Box>
      <Box
        className={styles.cartMainView}
        style={{
          height:
            CONTENT_HEIGHT -
            HEADER_BTN_HEIGHT -
            BOTTOM_BTN_HEIGHT -
            priceHeight,
        }}
      >
        <Box id={"cartItemsListId"}>
          {addCourseList.reverse().map((y1, i1) => {
            let courseData = null;
            let list = JSON.parse(JSON.stringify([...props.courseList]));
            let index = list.findIndex((c) => c._id == y1._id);
            if (index >= 0) {
              courseData = list[index];
              if (y1.name) {
                courseData.name = y1.name;
              }
              return (
                <Box key={i1} id={"cartListSourceId" + courseData._id}>
                  <Box
                    className={styles.addCourseMenuView}
                    onClick={() => props.onSetCourse(courseData)}
                    style={{
                      backgroundColor:
                        props.selectedCourse?._id == courseData._id
                          ? // ? theme_mode.theme
                            "#1D86FF"
                          : theme_mode.headerBg3,
                    }}
                    px={1}
                    py={1}
                    mb={0.3}
                  >
                    <Typography
                      className={styles.addCourseText}
                      style={{ color: theme_mode.text }}
                    >
                      {courseData.name}
                    </Typography>
                  </Box>
                  {cartItems
                    .filter((z) => z._idRestaurantCourse == courseData._id)
                    .map((item, index) => {
                      let place_order = props.checkout;
                      if (place_order == false) {
                        if (props.orderData) {
                          let findItemIndex = props.orderData?.cart.findIndex(
                            (x) =>
                              x._idMenuItem == item._id &&
                              x._idRestaurantCourse == item._idRestaurantCourse
                          );
                          if (findItemIndex >= 0) {
                            let cItem = props.orderData?.cart[findItemIndex];
                            let mod = [];
                            JSON.parse(JSON.stringify(item.cartModifier)).map(
                              (x1, i1) => {
                                let mod1 = [];
                                x1.map((x2, i2) => {
                                  delete x2.menuData;
                                  mod1.push(x2);
                                });
                                if (mod1.length) {
                                  mod.push(JSON.stringify(mod1));
                                }
                              }
                            );
                            if (
                              cItem["quantity"] == item.quantity &&
                              cItem["notes"] == item.notes &&
                              equalsCheck(cItem["modifiers"], mod)
                            ) {
                              place_order = true;
                            } else {
                              place_order = false;
                            }
                          } else {
                            place_order = false;
                          }
                        }
                      }
                      return (
                        <DiningDetailCartItem
                          key={index}
                          item={item}
                          selected={props.selectedMenu?._id == item._id}
                          themeMode={props.themeMode}
                          onAddSubMenu={props.onAddSubMenu}
                          onDelete={props.onDeleteCartItem}
                          onSelectMenu={props.onSelectMenu}
                          placedOrder={place_order}
                        />
                      );
                    })}
                </Box>
              );
            } else {
              return null;
            }
          })}
        </Box>
      </Box>
      <Box
        sx={{ borderTop: "1px solid " + theme_mode.border }}
        px={1}
        py={2}
        id={"priceValViewId"}
      >
        <Box className={styles.orderPriceSubView}>
          <Box className={styles.subPriceView}>
            <Typography
              className={styles.totalPriceText}
              sx={{ color: theme_mode.text, fontSize: { xs: 20, md: 19 } }}
            >
              Total
            </Typography>
          </Box>
          <Typography
            className={styles.totalPrice}
            sx={{ color: theme_mode.text, fontSize: { xs: 20, md: 19 } }}
          >
            {cartItems.length
              ? `${CURRENCY_SYMBOL[currencyVal]} ${totalPrice}`
              : ""}
          </Typography>
        </Box>
      </Box>
      {/*// )}*/}
      <Box
        className={styles.bottomMainView}
        style={{ height: BOTTOM_BTN_HEIGHT }}
      >
        <Box
          onClick={props.orderData ? props.onPayment : null}
          className={styles.bottomView}
          style={{
            width: "60%",
            backgroundColor: props.orderData ? "rgb(212, 35, 35)" : "#777",
            cursor: props.orderData ? "pointer" : "default",
          }}
          mx={0.2}
        >
          <Typography
            className={styles.bottomText}
            style={{ color: theme_mode.lightText, fontSize: BTN_FONT_SIZE }}
          >
            PAYMENT
          </Typography>
        </Box>
        <Box
          onClick={() => onSendCheckout()}
          className={styles.bottomView}
          style={{ flex: 1, backgroundColor: theme_mode.greenBg }}
        >
          <Typography
            className={styles.bottomText}
            style={{ color: theme_mode.lightText, fontSize: BTN_FONT_SIZE }}
          >
            {props.checkout ? "RE-SEND ORDER" : "SEND ORDER"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DiningDetailCart;
