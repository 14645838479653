import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";

import SettingTextField from "./SettingTextField";

import styles from "./TableSetupPopup.module.css";

import { ReactComponent as AddIcon } from "./../assets/images/add.svg";
import { ReactComponent as MinusIcon } from "./../assets/images/minus.svg";

import { POPUP_WIDTH } from "./TableSetupData";

const TableSetupPopup = (props) => {
  const [cover, setCover] = useState(0);
  const [tableNumber, setTableNumber] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    let { data } = props;
    setTableNumber(data?.name ? data.name : 0);
    setCover(data?.cover ? data.cover : 0);
  }, [props.data]);

  const onSaveClick = () => {
    let data = {
      cover: cover,
      name: tableNumber,
    };

    props.onSave(data);
  };

  const onAddSub = (type) => {
    let val = cover;
    if (type == "add") {
      val = parseInt(val) + 1;
    } else {
      if (val > 1) {
        val = parseInt(val) - 1;
      }
    }
    setCover(val);
  };

  return (
    <Grid className={styles.mainView} sx={{ minWidth: POPUP_WIDTH }}>
      <Box className={styles.editTableView}>
        <Typography className={styles.editTableText}>Edit Table</Typography>
      </Box>

      <Box className={styles.borderBottomLine} />

      <Box className={styles.textFieldView}>
        <SettingTextField
          page={"TableSetupPopup"}
          editable={false}
          value={cover}
          setValue={(val) => setCover(val.replace(/[^0-9]/g, ""))}
          label={"Cover"}
          border={"1px solid #202C38"}
          borderRadius={"3px"}
          height={30}
          required={true}
          variant={"filled"}
          labelStyle={{
            fontSize: 13,
            fontFamily: "InterMedium",
            color: "#878D94",
          }}
          error={error}
          inputDisabledBg={"#1B232D"}
          textInputStyle={{ pr: 0 }}
          rightView={
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                onClick={() => onAddSub("sub")}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                className={styles.plusMinusIcon}
              >
                <MinusIcon height={13} width={13} />
              </Box>
              <Box
                onClick={() => onAddSub("add")}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                ml={1}
                mr={1}
                className={styles.plusMinusIcon}
              >
                <AddIcon height={13} width={13} />
              </Box>
            </Box>
          }
        />

        <Box mt={2}>
          <SettingTextField
            page={"TableSetupPopup"}
            value={tableNumber}
            setValue={(val) => setTableNumber(val.replace(/[^0-9]/g, ""))}
            label={"Table Number"}
            border={"1px solid #202C38"}
            borderRadius={"3px"}
            height={30}
            required={true}
            variant={"filled"}
            backgroundColor={"#1B232D"}
            labelStyle={{
              fontSize: 13,
              fontFamily: "InterMedium",
              color: "#878D94",
            }}
            error={error}
          />
        </Box>
      </Box>

      <Box className={styles.borderBottomLine} />

      <Box className={styles.saveBtnView} onClick={() => onSaveClick()}>
        <Typography className={styles.saveBtnText}>Save</Typography>
      </Box>
    </Grid>
  );
};

export default TableSetupPopup;
