import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";

import * as Api from "../../api";

import { connect } from "react-redux";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import FromToDatePicker from "../../components/FromToDatePicker";
import ViewedItemsTableList from "../../components/ViewedItemsTableList";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";

import { THEME_MODE } from "../../constants/Theme";

const ViewItems = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [viewedItemList, setViewedItemList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [error, setError] = useState(null);

  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getViewItems(
      fromDate ? moment(fromDate, "DD MMM YYYY").format("YYYY-MM-DD") : null,
      toDate ? moment(toDate, "DD MMM YYYY").format("YYYY-MM-DD") : null
    );
  }, [fromDate, toDate]);

  const getViewItems = (from, to) => {
    setLoading(true);

    let startDate = from ? from : to ? to : "2023-11-01";
    let endDate = to ? to : from ? from : "today";
    let restId = props.restaurantId;

    const requestBody = {
      dateRanges: [{ startDate, endDate }],
      dimensions: [
        { name: "eventName" },
        { name: "customEvent:restaurant_id" },
        { name: "customEvent:item_id" },
      ],
      metrics: [{ name: "eventCount" }],
      dimensionFilter: {
        andGroup: {
          expressions: [
            {
              filter: {
                fieldName: "eventName",
                inListFilter: {
                  values: ["menu_view"],
                },
              },
            },
            {
              filter: {
                fieldName: "customEvent:restaurant_id",
                inListFilter: {
                  values: [restId],
                },
              },
            },
          ],
        },
      },
      limit: "30000",
      orderBys: [
        {
          desc: true,
          metric: {
            metricName: "eventCount",
          },
        },
      ],
    };

    let data = { requestBody: requestBody };
    data = JSON.stringify(data);
    Api.getAnalytics(data).then((response) => {
      if (response.success) {
        const responseData = response.data;
        let header = responseData.dimensionHeaders;
        let itemIndex = header.findIndex(
          (x) => x.name == "customEvent:item_id"
        );

        let row = responseData?.rows;
        if (row?.length) {
          let list = [];
          if (itemIndex >= 0) {
            row.map((x) => {
              let item_id = x?.dimensionValues?.[itemIndex]?.["value"]
                ? x?.dimensionValues?.[itemIndex]?.["value"]
                : null;
              if (item_id) {
                let item_data = props.restaurantMenuList.filter(
                  (x) => x._id == item_id
                );
                let menu = "";
                if (item_data.length) {
                  menu = item_data[0]["name"];
                }
                list.push({
                  item_id: item_id,
                  menu: menu,
                  count: x?.metricValues?.[0]?.["value"]
                    ? x.metricValues?.[0]?.["value"]
                    : 0,
                });
              }
            });
          }
          setViewedItemList(list);
        } else {
          setViewedItemList([]);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onResetClick = () => {
    setFromDate(null);
    setToDate(null);
    setError(null);
    getViewItems(null, null);
  };

  return (
    <Container page={"settings"} p={1.5} fpx={2} fpy={0.5}>
      {loading ? (
        <Loader />
      ) : (
        <Grid px={2} mb={2}>
          <Grid mb={2} container item xs={12}>
            <Grid item xs={6}>
              <Box
                onClick={() => navigate("/analytics")}
                sx={{ cursor: "pointer", width: "fit-content" }}
              >
                <BackBtnIcon width={33} height={33} />
              </Box>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
              <Grid>
                <FromToDatePicker
                  themeMode={props.themeMode}
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  error={error}
                  showReset={true}
                  showIcon={true}
                  onReset={onResetClick}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <ViewedItemsTableList
              themeMode={props.themeMode}
              itemList={viewedItemList}
            />
            {viewedItemList.length ? null : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 300,
                }}
              >
                <Typography
                  py={8}
                  sx={{
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "InterSemiBold",
                    textAlign: "center",
                  }}
                >
                  No Viewed Items
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
    restaurantMenuList: state.userData.restaurantMenuList,
  };
};

export default connect(mapStateToProps, null)(ViewItems);
