import React, { useState, useEffect } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import styles from "./AddPrinterModal.module.css";

import * as Api from "../api";

import SettingTextField from "./SettingTextField";
import AlertMsg from "./AlertMsg";
import Loader from "./Loader";

import { THEME_MODE } from "../constants/Theme";
import { IS_LARGE_SCREEN } from "../constants";

import { ReactComponent as CloseIcon } from "../assets/images/close_black.svg";

const BTN_HEIGHT = IS_LARGE_SCREEN ? 90 : 60;

const AntSwitch = styled(Switch)(({ theme, themeMode }) => ({
  width: 50,
  height: 24,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 45,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(-1px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#455A64",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    transition: theme.transitions.create(["width"], {
      duration: 100,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 50,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : themeMode == "light"
        ? "rgba(0,0,0,.25)"
        : "#333333",
    boxSizing: "border-box",
  },
}));

const AddPrinterModal = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  const [isLoading, setIsLoading] = useState(true);
  const [printerName, setPrinterName] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [portName, setPortName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [receiptEnabled, setReceiptEnabled] = useState(false);
  const [error, setError] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    setIsLoading(false);
    if (props.editData) {
      let data = props.editData;
      setPrinterName(data.name);
      setIpAddress(data.IPAddress);
      setPortName(data.portNumber);
      setDisplayName(data.displayName);
      setReceiptEnabled(data.receiptEnabled);
    } else {
      setPrinterName("");
      setIpAddress("");
      setPortName("");
      setDisplayName("");
      setReceiptEnabled(false);
    }
  }, [props.editData]);

  const onSubmit = () => {
    let err = null;
    if (printerName.trim() == "") {
      err = { printerName: "Name is required" };
    }

    if (displayName.trim() == "") {
      err = { ...err, displayName: "Display Name is required" };
    }

    if (ipAddress == "" || ipAddress == null) {
      err = { ...err, ipAddress: "IP Address Required" };
    } else {
      if (ipAddress !== "localhost") {
        if (/[^0-9.]/g.test(ipAddress)) {
          err = { ...err, ipAddress: "Invalid IP Address" };
        }
      }
    }

    if (portName == "" || portName == null) {
      err = { ...err, portName: "Port Name Required" };
    } else {
      if (/[^0-9]/g.test(portName)) {
        err = { ...err, portName: "Invalid Port Name" };
      }
    }

    setError(err);
    if (err != null) {
      return;
    }

    onAddPrinter();
  };

  const onAddPrinter = () => {
    setIsLoading(true);
    let data1 = {
      _idRestaurant: props.restaurantId,
      name: printerName,
      receiptEnabled: receiptEnabled,
      IPAddress: ipAddress,
      portNumber: portName,
      displayName: displayName,
    };
    let datas = JSON.stringify(data1);
    Api.addUpdatePrinter(
      datas,
      props.restaurantId,
      props.editData?._id ? props.editData._id : null
    ).then((response) => {
      if (response.success) {
        if (props.editData) {
          props.onSubmit(data1);
        } else {
          props.onSubmit(response.data);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      } else {
        setIsLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={props.modelVisible}
      onClose={() => props.onToggle(false)}
      onOpen={() => props.onToggle(true)}
      sx={{
        "& .MuiPaper-root": {
          width: { xs: window.innerWidth / 3, md: window.innerWidth / 3 },
          backgroundColor: theme_mode.card2,
        },
      }}
    >
      <Box>
        <Box
          className={styles.modalHeaderView}
          sx={{ backgroundColor: theme_mode.modalHeaderBg }}
          px={2}
        >
          <Typography
            className={styles.modalHeaderText}
            sx={{ color: theme_mode.lightText }}
          >
            {props.editData ? "Edit" : "Add"} Printer
          </Typography>
          <Box className="cursor-pointer" onClick={() => props.onToggle(false)}>
            <CloseIcon width={40} height={40} />
          </Box>
        </Box>
        {isLoading ? (
          <Loader
            height={{
              height: window.innerHeight - (IS_LARGE_SCREEN ? 100 : 70),
            }}
          />
        ) : (
          <Box
            p={2}
            sx={{
              height:
                window.innerHeight - BTN_HEIGHT - (IS_LARGE_SCREEN ? 100 : 70),
              overflow: "auto",
            }}
          >
            <Box>
              <SettingTextField
                page={"addPrinter"}
                value={printerName}
                setValue={setPrinterName}
                backgroundColor={theme_mode.inputBg2}
                label={"Name"}
                placeholder={"Enter here"}
                required={true}
                variant={"filled"}
                error={error?.printerName}
              />
            </Box>
            <Box mt={2}>
              <SettingTextField
                page={"addPrinter"}
                value={displayName}
                setValue={setDisplayName}
                backgroundColor={theme_mode.inputBg2}
                label={"Display Name"}
                placeholder={"Enter here"}
                required={true}
                variant={"filled"}
                error={error?.displayName}
              />
            </Box>
            <Box mt={2}>
              <SettingTextField
                page={"addPrinter"}
                value={ipAddress}
                setValue={setIpAddress}
                backgroundColor={theme_mode.inputBg2}
                label={"IP Address"}
                placeholder={"Enter here"}
                required={true}
                variant={"filled"}
                error={error?.ipAddress}
              />
            </Box>
            <Box mt={2}>
              <SettingTextField
                page={"addPrinter"}
                value={portName}
                setValue={setPortName}
                backgroundColor={theme_mode.inputBg2}
                label={"Port Name"}
                placeholder={"Enter here"}
                required={true}
                variant={"filled"}
                type={"number"}
                error={error?.portName}
              />
            </Box>
            <Box mt={3} mb={0.8} display={"flex"} alignItems={"center"}>
              <Box mr={2}>
                <Typography
                  sx={{
                    color: theme_mode.inputLabelText,
                    fontSize: 15,
                    fontFamily: "InterSemiBold",
                  }}
                >
                  Receipt Enabled
                </Typography>
              </Box>
              <AntSwitch
                checked={receiptEnabled}
                onChange={() => setReceiptEnabled(!receiptEnabled)}
                inputProps={{ "aria-label": "ant design" }}
                themeMode={props.themeMode}
              />
            </Box>
          </Box>
        )}
        {isLoading ? null : (
          <Box className={styles.btnView}>
            <Box
              onClick={() => props.onToggle(false)}
              className={styles.submitBtn}
              style={{
                backgroundColor: theme_mode.redBg,
                height: BTN_HEIGHT,
              }}
            >
              <Typography
                className={styles.submitBtnText}
                style={{ color: theme_mode.lightText }}
              >
                CANCEL
              </Typography>
            </Box>
            <Box
              onClick={onSubmit}
              className={styles.submitBtn}
              style={{
                backgroundColor: theme_mode.greenBg,
                height: BTN_HEIGHT,
              }}
            >
              <Typography
                className={styles.submitBtnText}
                style={{ color: theme_mode.lightText }}
              >
                SUBMIT
              </Typography>
            </Box>
          </Box>
        )}{" "}
        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      </Box>
    </SwipeableDrawer>
  );
};

export default AddPrinterModal;
