import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import styles from "./ConfirmAlertBox.module.css";

import { ReactComponent as DeleteIcon } from "../assets/images/delete.svg";
import { ReactComponent as CancelOrderIcon } from "../assets/images/cancel_order.svg";
import { ReactComponent as CancelOrderDarkIcon } from "../assets/images/cancel_order_dark.svg";

import { THEME_MODE } from "../constants/Theme";

let CONTENT_HEIGHT = window.innerHeight;
let BTN_HEIGHT = 70;

const ConfirmAlertBox = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={props.open}
      onClose={props.onClose}
      sx={{
        "& .MuiPaper-root": {
          right: 0,
          top: 0,
          bottom: 0,
          height: CONTENT_HEIGHT,
          width: { xs: window.innerWidth, md: window.innerWidth / 2.5 },
          borderRadius: "10px",
          backgroundColor: theme_mode.modal,
        },
      }}
    >
      <Grid
        container
        xs={12}
        // sx={styles.mainView}
      >
        <Grid
          item
          xs={12}
          style={{ height: CONTENT_HEIGHT - BTN_HEIGHT }}
          className={styles.dataView}
        >
          {props.themeMode == "light" ? (
            <CancelOrderIcon width={80} height={80} />
          ) : (
            <CancelOrderDarkIcon width={80} height={80} />
          )}
          <Typography
            mt={1}
            className={styles.sureText}
            style={{ color: theme_mode.cardDescText3 }}
          >
            {props.title}
          </Typography>
          <Typography
            mt={1}
            className={styles.sureDescText}
            style={{ color: theme_mode.cardText }}
          >
            {props.description}
            {props?.hightlight ? (
              <Typography
                className={styles.nameText}
                style={{ color: theme_mode.cardText }}
              >
                {props.hightlight}
              </Typography>
            ) : null}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ height: BTN_HEIGHT }}
          className={styles.btnMainView}
        >
          <Box
            onClick={props.firstBtnClick}
            className={styles.btnView}
            style={{ backgroundColor: props.firstBtnBg }}
          >
            <Typography
              className={styles.btnText}
              style={{ color: theme_mode.lightText }}
            >
              {props.firstBtn}
            </Typography>
          </Box>
          <Box
            onClick={props.secondBtnClick}
            className={styles.btnView}
            style={{ backgroundColor: props.secondBtnBg }}
          >
            <Typography
              className={styles.btnText}
              style={{ color: theme_mode.lightText }}
            >
              {props.secondBtn}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
};

export default ConfirmAlertBox;
