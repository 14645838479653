import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import DiningAddSubMenuBtn from "./DiningAddSubMenuBtn";

import {ReactComponent as DeleteIcon} from "../assets/images/delete_circle.svg";
import {THEME_MODE} from "../constants/Theme";
import CreateOrderModalCartItem from "./CreateOrderModalCartItem";

const CreateOrderModalItem = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles = styles1(theme_mode);

  let item = props.item;

  return (
    <Box sx={styles.mainView}>
      <Box sx={styles.dataView}>
        <Box sx={{flex: 1}}>
          <Typography sx={styles.nameText}>{item.name}</Typography>
          <Typography sx={styles.priceText}>{item.price.currency} {item.price.value.toFixed(2)}</Typography>
        </Box>
        <Box sx={styles.btnView} mt={1}>
          {item?.quantity > 0 ?
            <Box sx={{flex: 1}} mr={1.5}>
              <Box sx={{cursor: 'pointer', lineHeight: 0}} onClick={() => props.onDeleteMenu(item)}>
                <DeleteIcon width={28} height={28}/>
              </Box>
            </Box>
            : null}
          <DiningAddSubMenuBtn
            showAddBtnOnly={item?.quantity > 0 ? false : true}
            count={item?.quantity > 0 ? item?.quantity : 0}
            minWidth={item?.quantity > 0 ? 90 : 20}
            circleSize={28}
            addSubFontSize={18}
            onAddSubMenu={(type) => props.onAddSubMenu(type, item)}
            themeMode={props.themeMode}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CreateOrderModalItem;

const styles1 = Theme => ({
  mainView: {
    width: {md: '25%', xs: '100%'},
    minWidth: {md: 200, xs: '100%'},
  },
  dataView: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Theme.card,
    border: '2px solid ' + Theme.card,
    boxShadow: '0px 2px 15px -1px rgba(117, 117, 117, 0.25)',
    borderRadius: '20px',
    width: {md: '95%', xs: '100%'},
    minHeight: 170,
    mb: 1.2,
    p: 1.2,
  },
  nameText: {
    color: Theme.text2,
    fontFamily: 'InterBold',
    fontSize: 15,
  },
  priceText: {
    color: Theme.text2,
    fontFamily: 'InterMedium',
    fontSize: 13,
  },
  btnView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }
});
