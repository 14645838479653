import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import styles from "./SalesReport.module.css";

import { connect } from "react-redux";

import Container from "../../components/Container";

import { ReactComponent as SalesIcon } from "../../assets/images/sales_report.svg";
import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";

import { NAVBAR_HEIGHT } from "../../constants";
import { THEME_MODE } from "../../constants/Theme";

let ICON_SIZE = 25;
let REPORT_LIST = [
  {
    label: "X Report",
    href: "/x_report",
    icon: <SalesIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
  {
    label: "Z Report",
    href: "/z_report",
    icon: <SalesIcon width={ICON_SIZE} height={ICON_SIZE} />,
  },
];

const SalesReport = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  return (
    <Container page={"settings"} p={"8px"} showFooter={false}>
      <Grid
        className={styles.cardMainView}
        style={{
          backgroundColor: theme_mode.card3,
          borderRadius: 10,
          height: window.innerHeight - NAVBAR_HEIGHT - 8 * 2,
        }}
      >
        <Box
          mr={2}
          onClick={() => navigate("/settings")}
          className={styles.backBtn}
        >
          <BackBtnIcon width={33} height={33} />
        </Box>
        {REPORT_LIST.map((x, i) => {
          return (
            <Box
              key={i}
              className={styles.cardView}
              my={1}
              p={3}
              sx={{ backgroundColor: theme_mode.settingsCardBg }}
              onClick={() => navigate(x.href)}
            >
              {x.icon}
              <Typography ml={1} className={styles.itemText}>
                {x.label}
              </Typography>
            </Box>
          );
        })}
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantId: state.userData.restaurantId,
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(SalesReport);
